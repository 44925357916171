import './RunningGameTableCard.scss';

import {
  CopyOutlined,
  DeleteOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { useDeleteTableMutation } from '@api-3/endpoints/games';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import { useModals } from '@providers/ModalProvider';
import { GameCreateFormShape } from '@routes-3/main/cashGameManagement/runningGamesMap/modals/AddGameMapModal';
import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Card, message, Modal, Tooltip } from 'antd';
import { parseInt } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RunningGameTableCard = ({
  table,
  game,
  onChange
}: {
  table: Table;
  game: RunningGame;
  onChange?: (key: string) => void;
}) => {
  const { t } = useTranslation();
  const seatPlayers = table.players ? table.players.length : 0;
  const openModal = useModals();
  const tableCapacity = table.maxPlayers;
  const seatsAvailable = tableCapacity - seatPlayers;
  const levelCapacity = Math.floor(tableCapacity / 3);
  const [deleteTable] = useDeleteTableMutation();
  let tableState = 'danger';
  if (seatPlayers > levelCapacity) {
    tableState = 'warning';
  }
  if (seatsAvailable === 0) {
    tableState = 'primary';
  }
  const truncate = (str: string) => {
    return str.length > 13 ? str.substring(0, 12) + '...' : str;
  };

  const createDefaults = (table: Table) => {
    return ({
      ...table,
      smallBlind: parseInt(table.gameSize.split('/')[0])!,
      bigBlind: parseInt(table.gameSize.split('/')[1])!,
      tables: [
        {
          tableId: '',
          maxPlayers: table.maxPlayers
        }
      ]
    } as unknown) as Partial<GameCreateFormShape>;
  };

  return (
    <Card
      className={`gx-card-widget gx-card-full gx-py-2 gx-px-2 w-100 table-card text-center`}
    >
      <div className="gx-justify-content-center gx-text-center gx-mb-3 gx-mb-sm-4 gx-mt-2">
        <div className={'mb-2'}>
          <span style={{ fontSize: 16 }}>#{table.tableId} </span>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            {truncate(table.gameVariant)}
          </span>
        </div>
        <div className={'mb-2'} style={{ fontSize: 12, opacity: 0.65 }}>
          {table.gameSize}
          {table.ante ? `/${table.ante}` : null}
        </div>
        <div className={'mb-2'} style={{ fontSize: 12 }}>
          Buy-In: {table.buyInMin ? formatBuyIn(table.buyInMin) : 'NO'} -{' '}
          {table.buyInMax ? formatBuyIn(table.buyInMax) : 'NO'}
        </div>
        <div className={'mb-3'} style={{ fontSize: 12, fontWeight: 'bold' }}>
          <span>{table.maxPlayers} max | </span>
          <span className={`gx-text-${tableState}`}>{seatsAvailable} Free</span>
        </div>
        <div className={'ant-row-flex justify-content-between gx-px-3'}>
          <Tooltip placement="bottom" title={'Manage players'}>
            <UserAddOutlined
              className={'gx-pointer'}
              style={{ fontSize: 25, color: '#4A7A8A' }}
              onClick={() => {
                openModal({
                  type: 'TABLE_PLAYER',
                  props: {
                    game,
                    table,
                    expectedWaitingTime: game?.expectedWaitingTime
                  }
                });
              }}
            />
          </Tooltip>
          <Tooltip placement="bottom" title={'Duplicate game'}>
            <CopyOutlined
              className={'gx-pointer'}
              style={{ fontSize: 25, color: '#4A7A8A' }}
              onClick={() => {
                openModal({
                  type: 'CREATE_GAME',
                  props: { defaults: createDefaults(table) }
                });
              }}
            />
          </Tooltip>
          <Tooltip placement="bottom" title={'Close table'}>
            <DeleteOutlined
              className={'gx-pointer'}
              style={{ fontSize: 25, color: '#4A7A8A' }}
              onClick={() => {
                Modal.confirm({
                  title:
                    'Are you sure you want to delete this table from the map?',
                  okText: t('YES_BUTTON'),
                  okType: 'danger',
                  cancelText: t('NO_BUTTON'),
                  async onOk() {
                    await deleteTable({ id: table.id }).then(() => {
                      if (onChange && game && game?.tables.length === 1) {
                        onChange('ALL');
                      }
                    });
                    message.success('Table has been deleted.');
                    return null;
                  }
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default RunningGameTableCard;
