import saMessages from '@lngProvider-3/locales/fr_FR.json';
import antdFR from 'antd/lib/locale-provider/fr_FR';

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdFR,
  locale: 'fr-FR'
};
export default saLang;
