import PlayerInfo from '@components-3/PlayerInfo';
import usePlayerProfile from '@features/players/usePlayerProfile';
import React from 'react';

const SubGroupPlayerItem = ({ flopId }: { flopId: number }) => {
  const { data: player } = usePlayerProfile(flopId.toString());
  return (
    <PlayerInfo
      player={Object.keys(player.data).length !== 0 ? player.data : flopId}
    />
  );
};

export default SubGroupPlayerItem;
