import { api } from '@api/api';
import { ApiResponseType } from '@api/types';

export type SignupForm = {
  type: 'CASINO';
  password: string;
  casinoName: string;
  firstName: string;
  lastName: string;
  emailAdminUser: string;
};
export const accountApi = api.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation<ApiResponseType<unknown>, SignupForm>({
      query: data => ({
        method: 'POST',
        url: `/customers/api/`,
        body: data
      })
    }),
    resetPassword: builder.mutation<
      ApiResponseType<unknown>,
      { email: string; password: string; token: string }
    >({
      query: ({ email, password, token }) => ({
        method: 'POST',
        url: `/accounts/api/customer/${email}/reset_password`,
        body: {
          newPassword: password,
          passwordCheck: password,
          resetPasswordToken: token
        }
      })
    }),

    requestPasswordReset: builder.mutation<ApiResponseType<unknown>, string>({
      query: email => ({
        method: 'POST',
        url: `/accounts/api/customer/${email}/forgot_password`
      })
    })
  })
});

export const {
  useResetPasswordMutation,
  useRequestPasswordResetMutation,
  useRegisterMutation
  // useLoginMutation
} = accountApi;
