import {
  ListAnnouncementItem,
  useDeleteAnnouncementMutation
} from '@api-3/endpoints/Announcements';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModals } from '@providers/ModalProvider';
import { Modal, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const AnnouncementActionColumn = ({
  announcement
}: {
  announcement: ListAnnouncementItem;
}) => {
  const { t } = useTranslation();
  const openModal = useModals();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  return (
    <Row>
      <Tooltip title={'Edit Announcement'} placement="bottom">
        <FontAwesomeIcon
          style={{ fontSize: 18 }}
          className={'gx-text-primary cursor-pointer mr-3'}
          icon={faPen}
          onClick={() => {
            openModal({
              type: 'CREATE_UPDATE_ANNOUNCEMENT',
              props: { announcement }
            });
          }}
        />
      </Tooltip>
      <Tooltip title={'Remove Announcement'} placement="bottom">
        <FontAwesomeIcon
          style={{ fontSize: 18 }}
          className={'gx-text-danger cursor-pointer'}
          icon={faTrashCan}
          onClick={() => {
            Modal.confirm({
              title:
                'Are you sure you want to remove this announcement? This action can’t be undone.',
              okText: t('REMOVE_BUTTON'),
              okType: 'danger',
              cancelText: t('NO_BUTTON'),
              async onOk() {
                await deleteAnnouncement({ announcementId: announcement.id });
              }
            });
          }}
        />
      </Tooltip>
    </Row>
  );
};

export default AnnouncementActionColumn;
