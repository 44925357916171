import { InterestList } from '@api-3/endpoints/interestList';
import { Game } from '@routes-3/main/cashGameManagement/interestList/types';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React from 'react';

type ModalCashGamesTitleProps = {
  game?: RunningGame | InterestList | Game;
  title: string;
  tableId?: string;
  running?: boolean;
};

const ModalTitleWithGameInfo = ({
  game,
  title,
  tableId
}: ModalCashGamesTitleProps) => {
  const tableInfo = tableId ? ` - Table ${tableId}` : '';
  return (
    <span>
      {title.toLocaleUpperCase()}
      {game && (
        <small className="ml-2">
          - {game.gameVariant} {game.gameSize}
          {game.ante && `/${game.ante}`}
          {tableInfo}
        </small>
      )}
    </span>
  );
};

export default ModalTitleWithGameInfo;
