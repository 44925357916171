import { useRemovePlayerFromGameMutation } from '@api-3/endpoints/games';
import { PlayerProfile } from '@api-3/endpoints/player';
import PlayerInfo from '@components-3/PlayerInfo';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timer from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/Timer';
import { Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

function RemovePlayerFromGameAction({ player }: { player: PlayerProfile }) {
  const [
    removePlayerFromGame,
    { isLoading }
  ] = useRemovePlayerFromGameMutation();
  const { t } = useTranslation();
  return player.id ? (
    <div className={'gx-text-left'}>
      <Tooltip title={t('REMOVE_PLAYER')} placement="bottom">
        <FontAwesomeIcon
          onClick={() => removePlayerFromGame({ playerId: player.id })}
          icon={faTrash}
          style={{ color: 'red', cursor: isLoading ? 'wait' : 'pointer' }}
        />
      </Tooltip>
    </div>
  ) : null;
}

const SeatedPlayers = ({ data }: { data: any }) => {
  const columns = [
    {
      title: 'Player',
      dataIndex: 'image',
      width: 250,
      render: (text: string, record: any) => {
        return record.id ? (
          <PlayerInfo player={record} />
        ) : (
          <Tag className="gx-bg-success gx-text-white gx-rounded-xxl gx-order-sm-2 m-0">
            free
          </Tag>
        );
      }
    },
    {
      title: 'Seat',
      dataIndex: 'seatId',
      width: 100,
      render: (value: string, record: any) => {
        return (
          <span>
            <b>{record.seatId}</b>
          </span>
        );
      }
    },
    {
      title: 'Stack',
      dataIndex: 'stack',
      width: 100,
      render: (value: string, record: any) => {
        return (
          <span>
            <b>{record.stack}</b>
          </span>
        );
      }
    },
    {
      title: 'Time Played',
      dataIndex: 'transfer',
      render: (text: string, record: any) => {
        return (
          record.id && (
            <span className="gx-text-grey">
              {record.sittedDate && <Timer date={record.sittedDate} />}
            </span>
          )
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      render: (text: string, record: PlayerProfile) => (
        <RemovePlayerFromGameAction player={record} />
      )
    }
  ];

  return (
    <Table
      className="gx-table-no-bordered"
      columns={columns}
      dataSource={data}
      pagination={false}
      size="small"
      // @ts-ignore
      rowKey={record => (record.key ? record.key : record.id)}
      scroll={{ y: 400 }}
    />
  );
};

export default SeatedPlayers;
