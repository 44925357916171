export const tournamentsTableColumns = [
  {
    key: 'time',
    title: 'Time',
    dataIndex: 'date'
  },
  {
    key: 'number',
    title: 'Number',
    dataIndex: 'eventNumber'
  },
  {
    key: 'buyIn',
    title: 'Buy In (Min - Max)',
    dataIndex: 'buyIn'
  },
  {
    key: 'gameVariant',
    title: 'Game Variant',
    dataIndex: 'gameVariant'
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name'
  },
  {
    key: 'startStack',
    title: 'Start. Stack',
    dataIndex: 'startingStack'
  },
  {
    key: 'entries',
    title: 'Entries',
    dataIndex: 'entries'
  },
  {
    key: 'levelDuration',
    title: 'Level',
    dataIndex: 'levelDuration'
  },
  {
    key: 'lateRegistrationLevel',
    title: 'Reg. Closes',
    dataIndex: 'lateRegistrationLevel'
  },
  {
    key: 'actions',
    title: '',
    dataIndex: 'actions'
  }
];
