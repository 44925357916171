import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './FullDisplay.scss';

import {
  ANNOUNCEMENT_STATUS,
  useGetAllAnnouncementsQuery
} from '@api-3/endpoints/Announcements';
import { useGetRunningGamesQuery } from '@api-3/endpoints/games';
import {
  Filter,
  InterestList,
  useGetInterestListQuery
} from '@api-3/endpoints/interestList';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import AnnouncementMarquee from '@routes-3/main/cashGameManagement/fullDisplay/components/AnnouncementMarquee';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Button, Layout } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useTime } from 'react-timer-hook';

import CasinoAvatar from '../../../../../../common/layouts/DefaultLayout/components/CasinoAvatar';
import {
  CARD_WIDTH,
  closeFullscreen,
  openFullscreen
} from '../constants/FullDisplayConstants';
import { useWindowSize } from '../hooks/FullDisplayPageHooks';
import DisplayCard from './cards/Card';

const ZoomButton = ({
  isFullDisplaying,
  onClickBtn
}: {
  isFullDisplaying: boolean;
  onClickBtn: (fullscreen: boolean) => void;
}) => {
  return isFullDisplaying ? (
    <Button
      className={'btn-shadow'}
      type="primary"
      style={{ height: 35, width: 35, padding: 0 }}
      icon={<AiOutlineFullscreenExit size={20} />}
      onClick={() => {
        onClickBtn(false);
        closeFullscreen();
      }}
    />
  ) : (
    <Button
      className={'btn-shadow'}
      type="primary"
      style={{ height: 35, width: 35, padding: 0 }}
      icon={<AiOutlineFullscreen size={20} />}
      onClick={() => {
        onClickBtn(true);
        openFullscreen();
      }}
    />
  );
};

export type GameObject =
  | {
      gameType: 'RUNNING_GAME';
      content: RunningGame;
    }
  | {
      gameType: 'UPCOMING_GAME';
      content: InterestList;
    };

const FullDisplay = () => {
  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' });
  const window_width = useWindowSize();
  const [displayCount, setDisplayCount] = useState(0);
  const [chunkedGames, setChunkedGames] = useState<Array<Array<GameObject>>>(
    []
  );
  const [showInterestList, setShowInterestList] = useState<boolean>(true);
  const [isFullDisplaying, setIsFullDisplaying] = useState<boolean>(false);
  const casinoId = useSelector(currentCasinoSelector);
  const filter: Filter = {
    gameStates: ['ACCEPTED'],
    gameVariant: []
  };

  const { data: runningGames } = useGetRunningGamesQuery(
    {
      queryParams: {
        page: 1,
        size: 40,
        casinoId
      }
    },
    { pollingInterval: 5000 }
  );

  const { data: interestList } = useGetInterestListQuery(
    {
      queryParams: {
        ...filter,
        page: 1,
        size: 50,
        casinoId: casinoId,
        sort: 'ASC',
        // date By Default is Current Date Minus 6 Hours
        dateFrom: encodeURIComponent(
          moment()
            .startOf('day')
            .subtract(12, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
        )
      }
    },
    { pollingInterval: 50000 }
  );

  const { data: announcements } = useGetAllAnnouncementsQuery({
    queryParams: {
      page: 1,
      size: 500,
      status: ANNOUNCEMENT_STATUS.CURRENT,
      publish: true
    },
    casinoId
  });

  useEffect(() => {
    const count = Math.floor(window_width / CARD_WIDTH);
    setDisplayCount(prevCount => {
      if (prevCount !== count) return count;
      return prevCount;
    });
  }, [window_width]);

  useEffect(() => {
    const gamesList: Array<GameObject> = [];
    if (runningGames && runningGames.content.length > 0) {
      runningGames.content.map(game => {
        const newElement: GameObject = {
          content: game,
          gameType: 'RUNNING_GAME'
        };
        gamesList.push(newElement);
      });
    }
    if (showInterestList && interestList && interestList.content.length > 0) {
      interestList.content.map(game => {
        const newElement: GameObject = {
          content: game,
          gameType: 'UPCOMING_GAME'
        };
        gamesList.push(newElement);
      });
    }
    if (gamesList.length > 0) {
      const gamesArray = _.chunk(gamesList, displayCount);
      const newGames: Array<Array<GameObject>> = _.cloneDeep(gamesArray);
      setChunkedGames(newGames);
    }
  }, [displayCount, runningGames, interestList, showInterestList]);

  const handleOnClickZoom = (isShowingFullDisplay: boolean) => {
    setIsFullDisplaying(isShowingFullDisplay);
  };
  /**
   * Render
   */
  return (
    <Layout className="full-display">
      <Layout.Header className="full-display-header" style={{ minHeight: 100 }}>
        <div />
        <CasinoAvatar />
        <div className={'font18 fontWeight600'}>
          <span>{hours < 10 ? '0' + hours : hours}</span>:
          <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
          <span>{seconds < 10 ? '0' + seconds : seconds}</span>
          <span> {ampm}</span>
        </div>
      </Layout.Header>
      <Layout.Content className="full-display-body">
        {chunkedGames.length > 0 && (
          <Carousel
            className="carousel-body"
            showThumbs={false}
            autoPlay={true}
            interval={10000}
            infiniteLoop={true}
          >
            {chunkedGames.map((page, index) => {
              return (
                <div className="carousel-body-item" key={index}>
                  {page.map((item, i) => (
                    <DisplayCard game={item} key={i} />
                  ))}
                </div>
              );
            })}
          </Carousel>
        )}
      </Layout.Content>
      <div>
        <div className={'full-display-button'}>
          <ZoomButton
            isFullDisplaying={isFullDisplaying}
            onClickBtn={handleOnClickZoom}
          />
        </div>
        {/*<div className={'full-display-show-interest-list'}>*/}
        {/*  <Switch*/}
        {/*    checkedChildren={'IL'}*/}
        {/*    unCheckedChildren={'NO IL'}*/}
        {/*    defaultChecked={showInterestList}*/}
        {/*    onChange={() => setShowInterestList(!showInterestList)}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {announcements && announcements.content.length > 0 && (
        <AnnouncementMarquee announcements={announcements.content} />
      )}
    </Layout>
  );
};

export default React.memo(FullDisplay);
