import ApiClient from '@old-world/ApiClient';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import { isUserAdmin } from '@old-world/helpers/user';
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSave } from 'react-icons/ai';

function ProfileForm() {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(currentUser.data.firstName);
  const [lastName, setLastName] = useState(currentUser.data.lastName);

  function reset() {
    setIsEditing(false);
    setFirstName(currentUser.data.firstName);
    setLastName(currentUser.data.lastName);
  }

  async function handleSubmit() {
    setSubmitting(true);

    await ApiClient.updateCustomerProfile({
      firstName,
      id: currentUser.data.id,
      lastName
    }).then(() => {
      setCurrentUser({
        data: {
          ...currentUser.data,
          firstName,
          lastName
        },
        profilePicture: currentUser.profilePicture
      });
    });

    message.success('Profile has been updated.');
    setIsEditing(false);
    setSubmitting(false);
  }

  return (
    <Form className="form d-flex flex-column flex-fill" layout="vertical">
      <Row justify="start">
        <Col className="w-100">
          <Form.Item label={t('PLACEHOLDER_FIRSTNAME')} className="mb-2">
            <Input
              placeholder={t('PLACEHOLDER_FIRSTNAME')}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              required
              disabled={!isEditing}
            />
          </Form.Item>
          <Form.Item label={t('PLACEHOLDER_LASTNAME')} className="mb-2 nt">
            <Input
              placeholder={t('PLACEHOLDER_LASTNAME')}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              required
              disabled={!isEditing}
            />
          </Form.Item>
          <Form.Item label={t('PLACEHOLDER_EMAIL')} className="mb-0 pb-0">
            <Input
              placeholder={t('PLACEHOLDER_EMAIL')}
              defaultValue={currentUser.data.email}
              disabled
            />
          </Form.Item>
          <div className="mb-4">
            <Typography.Text type="warning">
              {isUserAdmin(currentUser)
                ? '* Contact FLOP PM support to change this information'
                : '* Contact your administrator to change this information'}
            </Typography.Text>
          </div>
        </Col>
        <Row justify="center" className="w-100">
          <Fragment>
            {!isEditing ? (
              <Button
                type="primary"
                onClick={() => setIsEditing(true)}
                loading={isSubmitting}
              >
                {t('EDIT_BUTTON')}
              </Button>
            ) : (
              <div className="d-flex">
                <Button className={'w-50'} type="default" onClick={reset}>
                  {t('CANCEL_BUTTON')}
                </Button>
                <Button
                  className={'w-50'}
                  type="primary"
                  icon={<AiOutlineSave />}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  {t('SAVE_BUTTON')}
                </Button>
              </div>
            )}
          </Fragment>
        </Row>
      </Row>
    </Form>
  );
}

export default React.memo(ProfileForm);
