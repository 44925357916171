import enMessages from '@lngProvider-3/locales/en_US.json';
import antdEN from 'antd/lib/locale-provider/en_US';

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: antdEN,
  locale: 'en-US'
};
export default EnLang;
