import './Timer.scss';

import { useCasinoQuery } from '@api-3/endpoints/casino';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Timer = ({
  date,
  withHours = true,
  countDown = false,
  withBackground = false,
  size = 'normal',
  warningThreshold
}: {
  date: string;
  withHours?: boolean;
  countDown?: boolean;
  withBackground?: boolean;
  size?: 'small' | 'normal';
  warningThreshold?: number;
}) => {
  const casinoId = useSelector(currentCasinoSelector);
  const { data: casino } = useCasinoQuery({ id: casinoId });
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [hasExpired, setHasExpired] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    setHasExpired(false);
    setShowWarning(false);
    const now = moment();
    const currentDateMoment = moment(date) ?? now;
    let diffMinutes = now.diff(currentDateMoment, 'minutes') % 60;
    let diffHours = now.diff(currentDateMoment, 'hours') % 60;
    let diffSeconds = now.diff(currentDateMoment, 'seconds') % 60;

    if (countDown) {
      const reserveAllowedTime = moment(casino?.reserveAllowedTime, 'mm:ss');
      const reserveAllowedMinutes = reserveAllowedTime.minutes();
      const reserveAllowedSeconds = reserveAllowedTime.seconds();
      const maxAllowedTimeMoment = moment(currentDateMoment)
        .add(reserveAllowedMinutes, 'minutes')
        .add(reserveAllowedSeconds, 'seconds');

      diffMinutes = maxAllowedTimeMoment.diff(now, 'minutes') % 60;
      diffHours = maxAllowedTimeMoment.diff(now, 'hours') % 60;
      diffSeconds = maxAllowedTimeMoment.diff(now, 'seconds') % 60;
      if (countDown && maxAllowedTimeMoment.diff(now) <= 0) {
        setHasExpired(true);
      }
    }

    setSeconds(countDown ? diffSeconds : Math.abs(diffSeconds));
    setMinutes(countDown ? diffMinutes : Math.abs(diffMinutes));
    setHours(countDown ? diffHours : Math.abs(diffHours));
  }, [date]);

  useEffect(() => {
    if (!hasExpired) {
      if (countDown) {
        const interval = setInterval(() => {
          if (hours === 0 && minutes === 0 && seconds === 0) {
            setHasExpired(true);
          } else if (minutes === 0 && seconds === 0) {
            setSeconds(59);
            setMinutes(59);
            setHours(hours => hours - 1);
          } else if (seconds === 0) {
            setSeconds(59);
            setMinutes(minutes => minutes - 1);
          } else {
            setSeconds(seconds => seconds - 1);
          }
        }, 1000);
        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => {
          if (minutes === 59 && seconds === 59) {
            setSeconds(0);
            setMinutes(0);
            setHours(hours => hours + 1);
          } else if (seconds === 59) {
            setSeconds(0);
            setMinutes(minutes => minutes + 1);
          } else {
            setSeconds(seconds => seconds + 1);
          }
          if (warningThreshold !== undefined) {
            const totalMinutes = hours * 60 + minutes;
            if (!countDown && totalMinutes >= warningThreshold) {
              setShowWarning(true);
            }
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [hours, minutes, seconds, hasExpired]);

  return hasExpired ? (
    <div
      style={{
        fontSize: size === 'small' ? 11 : 14,
        width: size === 'small' ? 55 : '',
        marginLeft: 'small' ? -3 : 0
      }}
      className={'gx-text-danger'}
    >
      <b>No Show</b>
    </div>
  ) : (
    <div
      className={`timer-container status-${withBackground ? 'normal' : ''} ${
        showWarning ? 'timer-warning' : ''
      }`}
    >
      {withHours && (
        <>
          <div
            className={`time-wrapper ${
              size === 'small' ? 'small-size' : 'normal-size'
            }`}
          >
            <span>{hours < 10 ? '0' : Array.from(hours.toString())[0]}</span>
            <span>
              {hours < 10
                ? Array.from(hours.toString())[0]
                : Array.from(hours.toString())[1]}
            </span>
          </div>
          <span
            style={{
              marginRight: 2,
              marginLeft: 2,
              fontSize: size === 'small' ? 11 : 14
            }}
          >
            :
          </span>
        </>
      )}
      <div
        className={`time-wrapper ${
          size === 'small' ? 'small-size' : 'normal-size'
        }`}
      >
        <span>{minutes < 10 ? '0' : Array.from(minutes.toString())[0]}</span>
        <span>
          {minutes < 10
            ? Array.from(minutes.toString())[0]
            : Array.from(minutes.toString())[1]}
        </span>
      </div>
      <span
        style={{
          marginRight: 2,
          marginLeft: 2,
          fontSize: size === 'small' ? 11 : 14
        }}
      >
        :
      </span>
      <div
        className={`time-wrapper ${
          size === 'small' ? 'small-size' : 'normal-size'
        }`}
      >
        <span>{seconds < 10 ? '0' : Array.from(seconds.toString())[0]}</span>
        <span>
          {seconds < 10
            ? Array.from(seconds.toString())[0]
            : Array.from(seconds.toString())[1]}
        </span>
      </div>
    </div>
  );
};

export default Timer;
