import { createSelector } from 'reselect';

import { IRootState } from '../common/models/StateModel';

export const getCurrentCasinoId = (state: IRootState) =>
  state.authentication.signin.managedCasinoId;

const getCasinos = (state: IRootState) => state.casinos.list;

export const getCurrentCasino = createSelector(
  [getCurrentCasinoId, getCasinos],
  (casinoId, casinos) => casinos.find(x => x.id === casinoId)
);
