import { InterestList } from '@api-3/endpoints/interestList';
import { Notification } from '@api-3/endpoints/notifications';
import CustomScrollbars from '@old-world/utils/CustomScrollbars';
import ChatItem from '@routes-3/main/chat/components/ChatItem';

const ChatSideNav = ({
  messageNotifications,
  interestList,
  selectedInterestGameId,
  setSelectedInterestGameId
}: {
  messageNotifications: Notification[];
  interestList: InterestList[];
  selectedInterestGameId: string;
  setSelectedInterestGameId: (selectedInterestGameId: string) => void;
}) => {
  return (
    <div
      className="gx-chat-sidenav gx-d-none gx-d-lg-flex"
      style={{ height: 'calc(100vh - 248px)' }}
    >
      <div className="gx-chat-sidenav-main">
        <div className="gx-chat-sidenav-content">
          <div className="gx-chat-user">
            <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
              {interestList.map((game, index) => (
                <ChatItem
                  messageNotifications={messageNotifications.filter(
                    m => m.entityId === game.id
                  )}
                  key={index}
                  interestList={game}
                  selectedInterestGameId={selectedInterestGameId}
                  setSelectedInterestGameId={setSelectedInterestGameId}
                />
              ))}
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSideNav;
