import './AddGameSizeItem.scss';

import { ReactComponent as Check } from '@assets/svgs/blueCheck.svg';
import redCrossSvg from '@assets/svgs/redCross.svg';
import { Button, Icon } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
/**
 * Game Size Field
 */
type GameSizeType = {
  smallBlind: number;
  bigBlind: number;
};

type Props = {
  sizeList: GameSizeType[];
  onAddSize: (smallBlind: number, bigBlind: number) => void;
  onHide: () => void;
};
const AddGameSizeItem = ({ onAddSize, onHide, sizeList }: Props) => {
  const { t } = useTranslation();
  const [smallBlind, setSmallBlind] = useState(0);
  const [bigBlind, setBigBlind] = useState(0);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.name === 'smallBlind') setSmallBlind(parseInt(target.value, 10));
    else if (target.name === 'bigBlind')
      setBigBlind(parseInt(target.value, 10));
  };

  const validator = () => {
    const existingSize = sizeList.find(
      sizeItem =>
        sizeItem.smallBlind === smallBlind && sizeItem.bigBlind === bigBlind
    );
    return !(existingSize || !smallBlind || !bigBlind);
  };
  return (
    <div className="d-flex flex-column align-items-left justify-content-center">
      <label>{t('ADD_A_GAME_SIZE')}:</label>
      <div className="d-flex flex-row align-items-center">
        <input
          id="smallBlind"
          name="smallBlind"
          type="number"
          min="1"
          placeholder="SB"
          className="ant-input small-input"
          style={{ width: 72 }}
          value={smallBlind}
          onChange={handleChange}
        />
        <span className="ml-2 mr-2 mt-1">{'/'} </span>
        <input
          id="bigBlind"
          name="bigBlind"
          type="number"
          min="1"
          placeholder="BB"
          className="ant-input small-input"
          value={bigBlind}
          onChange={handleChange}
          style={{ width: 72, marginRight: 12 }}
        />
        <Button className="icon-btn" shape="circle" onClick={onHide}>
          <Icon component={() => <img src={redCrossSvg} />} />
        </Button>
        <Button
          className="add-game-size-icon-btn"
          shape="circle"
          disabled={!validator()}
          onClick={() => {
            onAddSize(smallBlind, bigBlind);
            setSmallBlind(0);
            setBigBlind(0);
          }}
        >
          <Icon component={() => <Check />} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(AddGameSizeItem);
