import './EventCard.scss';

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import {
  ListEventItem,
  useDeleteEventMutation,
  useGetEventBannerQuery,
  useGetTournamentsCountQuery
} from '@api-3/endpoints/festivals';
import { useModals } from '@providers/ModalProvider';
import { ADMIN_EVENTS_TOURNAMENTS } from '@routes-3/routes';
import { Card, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
// @ts-ignore
import { useHistory } from 'react-router-dom';

export const determineEventStatus = (startDate: string, endDate: string) => {
  const now = moment();
  const start = moment(startDate);
  const end = moment(endDate).endOf('day');

  if (now.isBefore(start)) {
    return 'upcoming';
  } else if (now.isAfter(end)) {
    return 'finished';
  } else {
    return 'running';
  }
};

const EventCard = ({ event }: { event: ListEventItem }) => {
  const history = useHistory();
  const openModal = useModals();
  const [deleteEvent] = useDeleteEventMutation();
  const { data: eventBanner } = useGetEventBannerQuery({
    festivalId: event.id
  });
  const { data: tournamentsCount } = useGetTournamentsCountQuery({
    festivalId: event.id
  });

  const truncate = (str: string) => {
    return str.length > 20 ? str.substring(0, 19) + '...' : str;
  };

  return (
    <Card
      className={`gx-card-widget gx-card-full w-100 text-center event-card event-status-${determineEventStatus(
        event.startDate,
        event.endDate
      )}`}
    >
      <div className={'event-publish event-publish'}>
        {event.published ? 'PUBLISHED' : 'UNPUBLISHED'}
      </div>
      <div
        className={'gx-text-capitalize gx-font-weight-bold mb-2'}
        style={{ fontSize: 16 }}
      >
        {truncate(event.name)}
      </div>
      {tournamentsCount !== undefined && (
        <div
          className={'mb-3'}
          style={{
            fontWeight: 'bold',
            color: '#A6C8E6',
            opacity: tournamentsCount === 0 ? 0.65 : 1
          }}
        >
          {tournamentsCount}{' '}
          {tournamentsCount === 1 ? 'Tournament' : 'Tournaments'}
        </div>
      )}
      <div className={'gx-fs-sm mb-2'} style={{ opacity: 0.65 }}>
        {event.website}
      </div>
      <div className={'gx-text-center gx-fs-md mb-4'}>
        <span>{moment(event.startDate).format('Do MMM')}</span>
        {' - '}
        <span>{moment(event.endDate).format('Do MMM, YYYY')}</span>
      </div>
      <div className={'ant-row-flex justify-content-between gx-px-5'}>
        <EyeOutlined
          style={{ fontSize: 25, color: '#4A7A8A' }}
          onClick={() => {
            history.push(`${ADMIN_EVENTS_TOURNAMENTS}/${event.id}`);
          }}
        />
        <EditOutlined
          style={{
            fontSize: 25,
            cursor:
              determineEventStatus(event.startDate, event.endDate) ===
              'finished'
                ? 'default'
                : 'pointer',
            color:
              determineEventStatus(event.startDate, event.endDate) !==
              'finished'
                ? '#4A7A8A'
                : '#5C5E60'
          }}
          onClick={() => {
            if (
              determineEventStatus(event.startDate, event.endDate) ===
              'finished'
            ) {
              return null;
            } else {
              openModal({
                type: 'CREATE_EVENT',
                props: { event, eventBanner }
              });
            }
          }}
        />
        <PlusCircleOutlined
          style={{
            fontSize: 25,
            cursor:
              determineEventStatus(event.startDate, event.endDate) ===
              'finished'
                ? 'default'
                : 'pointer',
            color:
              determineEventStatus(event.startDate, event.endDate) !==
              'finished'
                ? '#4A7A8A'
                : '#5C5E60'
          }}
          onClick={() => {
            if (
              determineEventStatus(event.startDate, event.endDate) ===
              'finished'
            ) {
              return null;
            } else {
              openModal({
                type: 'CREATE_TOURNAMENT',
                props: {
                  type: 'EVENT_TOURNAMENTS',
                  event: event
                }
              });
            }
          }}
        />
        <DeleteOutlined
          style={{ fontSize: 25, color: '#4A7A8A' }}
          onClick={() => {
            Modal.confirm({
              title:
                'Are you sure you want to remove this event? This action can’t be undone.',
              okText: 'Remove',
              okType: 'danger',
              cancelText: 'No',
              async onOk() {
                await deleteEvent({ festivalId: event.id });
              }
            });
          }}
        />
      </div>
    </Card>
  );
};
export default EventCard;
