import './largeUpload.css';

import AntdFileUploadField from '@components/AntdFileUploadField';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import Form from '@components/forms/Form';
import AddressSection from '@routes-3/main/myPokerRoom/generalInformation/components/AddressForm';
import { Select } from 'antd/es';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const StandardInformation = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation();

  const brandLogoController = useController({
    name: 'brandLogo',
    rules: {
      validate: {
        exists: files => {
          return files.imageUrl !== '' || 'Please select an logo';
        }
      }
    }
  });

  const nameController = useController({
    name: 'name',
    rules: { required: true }
  });

  const establishmentTypeController = useController({
    name: 'establishmentType',
    rules: { required: true }
  });

  // @ts-ignore
  return (
    <Form>
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Branding
      </p>
      <div className={'row'}>
        <div className={'col-6'}>
          <AntdFileUploadField
            // @ts-ignore
            controller={brandLogoController}
            size={180}
            className={'settings-brand-logo d-flex align-items-center'}
            label={t('SETTINGS_BRAND_LOGO_LABEL')}
            hasFeedback
            disabled={disabled}
            accept=".png,.jpg"
            mandatory
          />
        </div>
        <div className={'col-6'}>
          <AInput
            className={'d-flex flex-column mb-4'}
            label={t('SETTINGS_BRAND_NAME_LABEL')}
            id="name"
            name="name"
            hasFeedback
            disabled={disabled}
            controller={nameController}
            mandatory
          />
          <ASelect
            label="You Are"
            controller={establishmentTypeController}
            disabled={disabled}
          >
            <Select.Option value="CASINO">Casino</Select.Option>
            <Select.Option value="CLUB">Club</Select.Option>
          </ASelect>
        </div>
      </div>
      <AddressSection disabled={disabled} />
    </Form>
  );
};

export default StandardInformation;
