import { CaretLeftOutlined } from '@ant-design/icons';
import flopPmLogo from '@assets/images/flop_pm_logo_new.png';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import Chair from '@routes-3/main/cashGameManagement/dealerDisplay/components/Chair';
import Notification from '@routes-3/main/cashGameManagement/dealerDisplay/components/Notification';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React from 'react';

const DealerTable = ({
  table,
  onBack
}: {
  table: Table;
  onBack: () => void;
}) => {
  const chairsPositions = (numPlayers: number) => {
    const allPositions = [
      { left: '0%', top: '70%' },
      { left: 'calc(0% - 30px)', top: '40%' },
      { left: '0%', top: '10%' },
      { left: '15%', top: '-5%' },
      { left: '35%', top: '-10%' },
      { left: '45%', top: '-10%' },
      { left: '55%', top: '-10%' },
      { left: '75%', top: '-5%' },
      { left: '90%', top: '10%' },
      { left: 'calc(100% - 20px)', top: '40%' },
      { left: 'calc(100% - 50px)', top: '70%' }
    ];
    const positionIndices: any = {
      6: [2, 3, 4, 6, 7, 8],
      7: [1, 2, 3, 5, 7, 8, 9],
      8: [1, 2, 3, 4, 6, 7, 8, 9],
      9: [0, 1, 2, 3, 5, 7, 8, 9, 10],
      10: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10]
    };
    return positionIndices[numPlayers].map(
      (index: number) => allPositions[index]
    );
  };
  const positions = chairsPositions(table.maxPlayers);
  return (
    <>
      <div
        style={{ position: 'absolute', top: 60, left: 20 }}
        className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
        onClick={onBack}
      >
        <CaretLeftOutlined className={'mr-1'} />
        <b style={{ position: 'relative', top: 2 }}>back</b>
      </div>
      <Notification tableId={table.tableId} />
      <div className="poker-table">
        <div className="table-center">
          <div className={'mb-4 gx-fs-2xl'}>
            <b>#{table.tableId}</b>
          </div>
          <img
            src={flopPmLogo}
            alt="Flop PM logo"
            className={'mb-4'}
            style={{ width: '50%' }}
          />
          <div className={'gx-fs-lg mb-2'}>
            <b>
              {table.gameVariant} - {table.gameSize}
              {table.ante ? '/' + table.ante : ''}
            </b>
          </div>
          <div className={'gx-fs-lg'}>
            Buy-In:{' '}
            <b>
              {table.buyInMin ? formatBuyIn(table.buyInMin) : 'NO'} -{' '}
              {table.buyInMax ? formatBuyIn(table.buyInMax) : 'NO'}
            </b>
          </div>
        </div>
        {positions.map((pos: any, index: number) => (
          <Chair key={index} pos={pos} seatNr={index + 1} table={table} />
        ))}
      </div>
    </>
  );
};

export default DealerTable;
