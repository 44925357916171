import flopPmLogoAlpha from '@assets/images/flop-pm-logo-alpha.png';
import flopPmLogo from '@assets/images/flop-pm-logo.png';
import React from 'react';
import { Link } from 'react-router-dom';

const SidebarLogo = ({
  isCollapsed,
  setCollapsed
}: {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}) => {
  return (
    <div className="gx-layout-sider-header">
      <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${
            !isCollapsed ? 'menu-unfold' : 'menu-fold'
          } gx-text-white`}
          onClick={() => {
            setCollapsed(!isCollapsed);
          }}
        />
      </div>
      {/*@ts-ignore*/}
      <Link to="/" className="gx-site-logo">
        <img
          src={isCollapsed ? flopPmLogoAlpha : flopPmLogo}
          alt="Company logo"
          style={{ width: '80%' }}
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
