import 'react-phone-input-2/lib/style.css';
import './TelInputDarkTheme.scss';

import { phoneCountries } from '@old-world/utils/data/phoneCountries';
import {
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  TimePicker,
  Tooltip
} from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { InputProps } from 'antd/lib/input/Input';
import { TimePickerProps } from 'antd/lib/time-picker';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { omit } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { UseControllerReturn } from 'react-hook-form/dist/types/controller';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const TextArea = Input.TextArea;

export type Props = {
  children?: JSX.Element | JSX.Element[];
  style?: unknown;
  label: JSX.Element | string | null;
  mandatory?: boolean;
  className?: string;
  withToolTip?: boolean;
  toolTipMsg?: JSX.Element;
  placement?: TooltipPlacement;
  hasFeedback?: boolean;
  type?: string;
  maxLength?: number;
  controller: UseControllerReturn<any, any>;
  disabled?: boolean;
};

export function makeField<T>(Component: React.ComponentType) {
  return ({
    children,
    hasFeedback,
    label,
    className,
    mandatory,
    withToolTip,
    toolTipMsg,
    placement = 'top',
    controller,
    ...rest
  }: Props & T) => {
    const fieldState = controller.fieldState;
    const field = controller.field;
    const hasError = fieldState.invalid;
    const labelToDisplay = mandatory ? `${label} *` : label;
    return (
      <FormItem
        label={label ? labelToDisplay : null}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && fieldState.error?.message}
        className={className}
      >
        {withToolTip ? (
          <Tooltip title={toolTipMsg} placement={placement}>
            <Component {...omit(field, 'ref')} {...rest} children={children} />
          </Tooltip>
        ) : (
          <Component {...omit(field, 'ref')} {...rest} children={children} />
        )}
      </FormItem>
    );
  };
}

export const AInput = makeField<InputProps>(Input);
export const ARadioGroup = makeField(RadioGroup);
export const ASelect = makeField(Select);
export const ATextarea = makeField(TextArea);

export const ADatePicker = ({
  controller,
  children,
  label,
  className,
  mandatory,
  withToolTip,
  toolTipMsg,
  placement = 'top',
  format = 'YYYY-MM-DD',
  ...rest
}: Props & {
  dateFormat?: string;
} & DatePickerProps) => {
  const fieldState = controller.fieldState;
  const field = controller.field;

  const hasError =
    (fieldState.invalid && controller.formState.isSubmitted) ||
    controller.formState.isSubmitting;
  const labelToDisplay = mandatory ? `${label} *` : label;

  const props = {
    ...omit(field, 'ref'),
    ...rest,
    value: field.value ? moment(field.value) : undefined,
    onChange: (e: Moment | null) => {
      field.onChange(e ? moment(e).format('YYYY-MM-DD') : null);
    },
    format
  };
  return (
    <FormItem
      label={label ? labelToDisplay : null}
      validateStatus={hasError ? 'error' : 'success'}
      help={hasError && fieldState.error?.message}
      className={className}
    >
      {withToolTip ? (
        <Tooltip title={toolTipMsg} placement={placement}>
          {/*@ts-ignore*/}
          <DatePicker {...props} children={children} />
        </Tooltip>
      ) : (
        <DatePicker {...props} children={children} />
      )}
    </FormItem>
  );
};

export const ATimePicker = ({
  controller,
  children,
  label,
  className,
  mandatory,
  withToolTip,
  toolTipMsg,
  placement = 'top',
  format = 'h:mm a',
  disabled,
  ...rest
}: Props & TimePickerProps) => {
  const fieldState = controller.fieldState;
  const field = controller.field;

  const hasError =
    (fieldState.invalid && controller.formState.isSubmitted) ||
    controller.formState.isSubmitting;
  const labelToDisplay = mandatory ? `${label} *` : label;

  const props = {
    ...omit(field, 'ref'),
    value: field.value ? moment(field.value) : undefined,
    ...rest
  };
  return (
    <FormItem
      label={label ? labelToDisplay : null}
      validateStatus={hasError ? 'error' : 'success'}
      help={hasError && fieldState.error?.message}
      className={className}
    >
      {withToolTip ? (
        <Tooltip title={toolTipMsg} placement={placement}>
          <TimePicker {...props} format={format} />
        </Tooltip>
      ) : (
        <TimePicker
          {...props}
          format={format}
          onSelect={field.onChange}
          disabled={disabled}
        />
      )}
    </FormItem>
  );
};

const isKnownCountry = (countryCode: string) => {
  let knownCountry;
  phoneCountries.forEach((country: unknown[]) => {
    if (country[2] === countryCode) {
      knownCountry = true;
      return;
    }
  });
  return knownCountry;
};

const AntPhoneInput = (props: any) => (
  <ReactPhoneInput
    {...props}
    defaultCountry={
      (isKnownCountry(props.defaultCountry) && props.defaultCountry) || 'us'
    }
    inputClass="ant-input"
    containerClass="react-tel-input"
  />
);

export const APhoneInput = makeField<PhoneInputProps>(AntPhoneInput);
