import flopLogo from '@assets-3/images/flop-pm-logo.png';
import RedirectImage from '@assets-3/images/redirect.png';
import AuthenticationActions from '@old-world/features/authentication/redux/AuthenticationRedux';
import { parseParam } from '@old-world/utils/LocationUtils';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';

const RedirectPage = () => {
  const { location } = useReactRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      AuthenticationActions.validateEmailRequest(
        parseParam(location, 'customerEmail'),
        parseParam(location, 'activationToken')
      )
    );
  }, []);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img
                alt="flop-logo"
                src={flopLogo}
                height={'60'}
                className={'gx-mb-3'}
              />
              <div className={'gx-text-center'}>
                <img src={RedirectImage} alt="Not Found" />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content gx-text-center">
            <div className="gx-login-header gx-mb-5">
              <h1 className="gx-login-title gx-text-center">
                Waiting to Redirect
              </h1>
            </div>
            <Spin size="large" className={'gx-mt-3'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
