import { useCreateInterestListMutation } from '@api-3/endpoints/interestList';
import {
  currentCasinoSelector,
  ownerSelector
} from '@old-world/features/authentication/redux/AuthenticationRedux';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { casinoFromListSelector } from '@old-world/features/casinos/redux/CasinosRedux';
import InterestListCreationForm from '@routes-3/main/cashGameManagement/interestList/forms/InterestListCreationForm';
import { Form, Modal } from 'antd';
import { notification } from 'antd';
import moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export type InterestListCreationFormShape = {
  gameVariant: string;
  smallBlind: number;
  bigBlind: number;
  ante?: number;
  buyInMin: number;
  buyInMax?: number;
  startingDate: Date;
  startingTime: Date;
  templateId?: string;
};

type Props = {
  onClose: () => void;
};
const DATE_SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:s.SSSZZ';

const InterestListCreationModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const managedCasinoId = useSelector(currentCasinoSelector);
  const casino = useSelector(state =>
    // @ts-ignore
    casinoFromListSelector(state, managedCasinoId)
  );
  const owner = useSelector(ownerSelector);
  const [createInterestList] = useCreateInterestListMutation();

  const formMethods = useForm<InterestListCreationFormShape>({
    mode: 'all'
  });

  const { handleSubmit } = formMethods;

  const onSubmit = async (data: InterestListCreationFormShape) => {
    const hour = moment(data.startingTime).format('HH');
    const minute = moment(data.startingTime).format('mm');
    const startTime = `${hour}:${minute}`;
    const date = MomentTimeZone.tz(
      `${data.startingDate} ${startTime}+0000`,
      'YYYY-MM-DD HH:mmZZ',
      'UTC'
    ).format(DATE_SERVER_FORMAT);

    try {
      await createInterestList({
        body: {
          date,
          casinoId: managedCasinoId,
          casino: casino.name,
          country: casino.country,
          ante: data.ante,
          bigBlind: data.bigBlind,
          buyInMin: data.buyInMin,
          buyInMax: data.buyInMax,
          smallBlind: data.smallBlind,
          city: casino.city,
          ownerId: owner.id,
          gameOrigin: 'CASINO',
          gameVariant: data.gameVariant,
          gameSize: `${data.smallBlind}/${data.bigBlind}`
          // templateId: data.templateId
        }
      }).unwrap();

      notification.success({
        message: 'Success',
        description: `Your interest list was successfully created.`,
        placement: 'topRight'
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to create this interest list. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }

    onClose();
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={t('CREATE_NEW_INTEREST_LIST').toUpperCase()}
      onCancel={onClose}
      okText={t('CREATE_BTN')}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      onOk={formMethods.handleSubmit(onSubmit)}
      closable={false}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <InterestListCreationForm
            reset={() => {
              formMethods.reset({
                gameLabel: undefined,
                gameVariant: undefined,
                smallBlind: undefined,
                bigBlind: undefined,
                ante: undefined,
                buyInMin: undefined,
                buyInMax: undefined,
                tables: [
                  {
                    tableId: '',
                    maxPlayers: 8
                  }
                ]
              });
            }}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default InterestListCreationModal;
