import { useGetWaitingListQuery } from '@api-3/endpoints/games';
import {
  PlayerProfile,
  useGetPlayersProfileByIdsQuery
} from '@api-3/endpoints/player';
import useMapProfileResponses from '@old-world/flop-3.0/hooks/useMapProfileResponses';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';

export default function useGetWaitingListForGame({
  gameId,
  size = 200
}: {
  gameId: string;
  size?: number;
}) {
  const { data: waitingListPlayers } = useGetWaitingListQuery(
    {
      gameId,
      size: size
    },
    { pollingInterval: 5000 }
  );

  const { data: playerProfiles, isLoading } = useGetPlayersProfileByIdsQuery(
    {
      ids: (waitingListPlayers ?? [])
        .filter(player => player.flopId)
        .map(player => player.flopId!)
    },
    { skip: !waitingListPlayers?.length }
  );

  const players = useMapProfileResponses<(WaitingPlayer & PlayerProfile)[]>({
    secondary: playerProfiles ?? [],
    primary: waitingListPlayers ?? []
  });

  return { players, isLoading };
}
