import { Input } from 'antd';
import React from 'react';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';

type Props = {
  address: string;
  onAddressSelect: any;
  disabled?: boolean;
  placeholder: string;
  setAddress: any;
  error: any;
};

const LocationSearchInput = ({
  address,
  onAddressSelect,
  disabled,
  placeholder,
  setAddress,
  error
}: Props) => {
  const handleAddressChange = (address: string): void => {
    setAddress(address);
  };

  return (
    // @ts-ignore
    <PlacesAutocomplete
      onChange={handleAddressChange}
      onSelect={onAddressSelect}
      value={address}
    >
      {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
        <React.Fragment>
          {/*@ts-ignore*/}
          <Input
            {...getInputProps({
              placeholder: placeholder,
              disabled: disabled,
              id: 'address-input'
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading ? <div>Loading...</div> : null}
            {suggestions.map((suggestion: Suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              const style = suggestion.active
                ? {
                    backgroundColor: '#38424b',
                    cursor: 'pointer',
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 4
                  }
                : { backgroundColor: '#4e575e', cursor: 'pointer' };

              const spread: any = {
                ...getSuggestionItemProps(suggestion, {
                  className,
                  style
                })
              };

              return (
                <div {...spread} key={suggestion.placeId}>
                  <div>{suggestion.description}</div>
                </div>
              );
            })}
          </div>
          {!!error.address?.addressLabel && (
            <div
              style={{ fontSize: 11 }}
              className={'ant-form-item-explain ant-form-item-explain-error'}
            >
              <div role="alert">{error.address.addressLabel?.message}</div>
            </div>
          )}
        </React.Fragment>
      )}
    </PlacesAutocomplete>
  );
  // }
};

export default LocationSearchInput;
