import { call, put } from 'redux-saga/effects';

import MessagesAction from '../../../common/redux/SystemMessagesRedux';
import i18n from '../../../i18n';
import AuthenticationActions from '../../authentication/redux/AuthenticationRedux';
import SettingsActions from '../../settings/redux/SettingsRedux';
import { CasinosServiceType } from '../models/CasinosModel.d';
import CasinosAction from '../redux/CasinosRedux';
import { manageOpeningDaysFromApi } from './ManagedOpeningTime';
/**
 * Fetch Casino Details
 */
export function* fetchCasinoDetails(
  api: CasinosServiceType,
  action: { id: string }
) {
  const { id } = action;

  const casinoDetailsResponse = yield call(api.fetchCasinoDetails, id);

  if (casinoDetailsResponse.status === 200) {
    // * ***************************
    // Fetch Casino Image
    // * ***************************
    const casinoImageResponse = yield call(api.fetchCasinoImage, id);

    if (
      casinoImageResponse.status === 200 ||
      casinoImageResponse.status === 404
    ) {
      // * ***************************
      // Fetch Casino Banner
      // * ***************************
      const casinoBannerResponse = { status: 404 };

      if (
        casinoBannerResponse.status === 200 ||
        casinoBannerResponse.status === 404
      ) {
        // Success
        yield put(
          CasinosAction.fetchCasinoDetailsSuccessResponse(id, {
            ...casinoDetailsResponse.data,
            ...manageOpeningDaysFromApi(
              casinoDetailsResponse.data.alwaysOpen,
              casinoDetailsResponse.data.openingDays,
              casinoDetailsResponse.data.timeZoneStr
            ),
            banner:
              casinoBannerResponse.data && casinoBannerResponse.data.resizedUrl,
            resizedUrl:
              casinoImageResponse.data && casinoImageResponse.data.resizedUrl
          })
        );
      } else {
        // If we haven't logo we can even send a success response
        yield put(
          CasinosAction.fetchCasinoDetailsSuccessResponse(id, {
            ...casinoDetailsResponse.data,
            ...manageOpeningDaysFromApi(
              casinoDetailsResponse.data.alwaysOpen,
              casinoDetailsResponse.data.openingDays,
              casinoDetailsResponse.data.timeZoneStr
            )
          })
        );
      }
      // TODO Add a condition of casino owner
      yield put(
        SettingsActions.initGames(casinoDetailsResponse.data.offeredGames || [])
      );
    }
  } else {
    // Logout
    yield put(AuthenticationActions.logoutRequest());

    // Manage Error
    yield put(CasinosAction.fetchCasinoDetailsFailureResponse());
    yield put(
      MessagesAction.addMessage(
        'FETCH_CASINO_DETAILS_ERROR',
        'ERROR',
        i18n.t('GLOBAL_ERROR_MESSAGE', {
          action: i18n.t('FETCH_CASINO_DETAILS_ACTION_ERROR')
        }),
        '',
        'PANEL'
      )
    );
  }
}

export function* fetchNumberUsersAroundCasino(
  api: CasinosServiceType,
  action: { casinoId: string; position: any }
) {
  const { casinoId, position, callBack } = action;

  const maxDistance = 100;
  const distanceUnit = 'KILOMETERS';
  const page = 1;

  // const userId = yield select(userIdSelector);

  const fetchUserAroundCasinoResponse = yield call(
    api.fetchUserAroundEntity,
    casinoId,
    1,
    maxDistance,
    position.lat,
    position.lon,
    page,
    distanceUnit,
    1
  );

  if (fetchUserAroundCasinoResponse.status === 200) {
    // Success
    const number = fetchUserAroundCasinoResponse.data.totalElements;
    yield put(
      CasinosAction.fetchNumberUsersAroundCasinoSuccessResponse(
        casinoId,
        number
      )
    );
    if (callBack && typeof callBack === 'function') {
      callBack(number);
    }
  } else {
    // Manage Error
    yield put(
      CasinosAction.fetchNumberUsersAroundCasinoFailureResponse(
        fetchUserAroundCasinoResponse
      )
    );
  }
}
