import {
  useCasinoQuery,
  useUpdateCasinoLogoMutation,
  useUpdateCasinoMutation
} from '@api-3/endpoints/casino';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import CasinosActions from '@old-world/features/casinos/redux/CasinosRedux';
import countryCode from '@old-world/utils/data/countryCode.json';
import { PokerRoomInformationFormShape } from '@routes-3/main/myPokerRoom/forms/PokerRoomInformationForm';
import { PokerRoomSectionFormShape } from '@routes-3/main/myPokerRoom/generalInformation/components/PokerRoomSection';
import { SettingsFormShape } from '@routes-3/main/myPokerRoom/settings/SettingsPage';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const countryCodes = countryCode as Record<string, string>;
export type CasinoInformationFormShape = {
  brandLogo: {
    imageUrl: string;
  };
  name: string;
  webSite: string;
  mailContact: string;
  telephoneNumber: string;
  description: string;
  youtubeUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  entryFee?: unknown;
  establishmentType?: 'CASINO' | 'CLUB';
  address: {
    addressLabel: string;
    streetAddress: string;
    streetNumber: string;
    country: string;
    countryCode: string;
    city: string;
    postalCode: string;
    state: string;
    lat: number;
    lon: number;
  };
};

export default function useSettingsForm() {
  const dispatch = useDispatch();
  const owner = useSelector(ownerSelector);
  const casinoId = owner.managedCasinoId;
  const { data } = useCasinoQuery({ id: casinoId });
  const [updateCasinoData] = useUpdateCasinoMutation();
  const [updateLogo] = useUpdateCasinoLogoMutation();
  const casino = data || ({} as any);

  const defaultValues = {
    games: casino?.offeredGames
      ? _.orderBy(casino?.offeredGames, ['variant'], ['asc'])
      : [],
    brandLogo: {
      imageUrl: casino.resizedUrl || ''
    },
    name: casino.name,
    webSite: casino.webSite,
    mailContact: casino.mailContact,
    telephoneNumber: casino.telephoneNumber,
    address: {
      addressLabel: casino.addressLabel,
      streetAddress: casino.address,
      streetNumber: casino.streetNumber,
      country: casino.country,
      countryCode:
        casino.countryCode && casino.countryCode.length > 2
          ? countryCodes[casino.countryCode]
          : casino.countryCode,
      city: casino.city,
      postalCode: casino.postalCode,
      state: casino.state
    },
    description: casino.description,
    youtubeUrl: casino.youtubeUrl,
    facebookUrl: casino.facebookUrl,
    instagramUrl: casino.instagramUrl,
    mainCurrency: casino.mainCurrency,
    minimumAgeEntrance: casino.minimumAgeEntrance,
    dressCode: casino.dressCode,
    tablesNumber: casino.tablesNumber,
    entryFee: casino.entryFee,
    establishmentType: casino.establishmentType,
    reserveAllowedTime:
      casino.reserveAllowedTime === 'Invalid date'
        ? undefined
        : moment(casino.reserveAllowedTime, 'mm:ss'),
    userMaxDistance: casino.userMaxDistance,
    userMaxDistanceUnit: casino.userMaxDistanceUnit,
    lat: casino.position?.lat,
    lon: casino.position?.lon
  };

  const formMethods = useForm<
    PokerRoomInformationFormShape &
      CasinoInformationFormShape &
      PokerRoomSectionFormShape &
      SettingsFormShape
  >({
    defaultValues
  });

  const stepsData = {
    0: ['brandLogo', 'name', 'address.addressLabel'],
    1: [
      'tablesNumber',
      'dressCode',
      'minimumAgeEntrance',
      'mainCurrency',
      'entryFee',
      'webSite',
      'mailContact',
      'telephoneNumber'
    ],
    2: ['description']
  };

  const stepsValidation = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        stepsData[currentStep].map(item => {
          if (
            // @ts-ignore
            !formMethods.getValues(item) ||
            (item === 'brandLogo' &&
              formMethods.getValues(item).imageUrl === '')
          ) {
            // @ts-ignore
            formMethods.setError(item, {
              message: 'Missing info! This field is mandatory'
            });
          } else {
            // @ts-ignore
            formMethods.clearErrors(item);
          }
        });
        return (
          // @ts-ignore
          formMethods.getValues('brandLogo').imageUrl !== '' &&
          !!formMethods.getValues('name') &&
          !!formMethods.getValues('address.addressLabel')
        );
      case 1:
        stepsData[currentStep].map(item => {
          if (
            // @ts-ignore
            !formMethods.getValues(item) ||
            // @ts-ignore
            formMethods.getValues(item) === '+null'
          ) {
            // @ts-ignore
            formMethods.setError(item, {
              message: 'Missing info! This field is mandatory'
            });
          } else {
            // @ts-ignore
            formMethods.clearErrors(item);
          }
        });
        return (
          !!formMethods.getValues('tablesNumber') &&
          !!formMethods.getValues('dressCode') &&
          !!formMethods.getValues('minimumAgeEntrance') &&
          !!formMethods.getValues('mainCurrency') &&
          !!formMethods.getValues('entryFee') &&
          !!formMethods.getValues('webSite') &&
          !!formMethods.getValues('mailContact') &&
          formMethods.getValues('telephoneNumber') !== '+null'
        );
      case 2:
        stepsData[currentStep].map(item => {
          // @ts-ignore
          if (!formMethods.getValues(item)) {
            // @ts-ignore
            formMethods.setError(item, {
              message: 'Missing info! This field is mandatory'
            });
          } else {
            // @ts-ignore
            formMethods.clearErrors(item);
          }
        });
        return !!formMethods.getValues('description');
    }
    return false;
  };
  const onSubmitForm = async (
    data: PokerRoomInformationFormShape &
      CasinoInformationFormShape &
      PokerRoomSectionFormShape &
      SettingsFormShape
  ) => {
    // @ts-ignore
    if (data?.brandLogo?.file) {
      const formData = new FormData();
      // @ts-ignore
      formData.append(
        'image',
        // @ts-ignore
        data.brandLogo.file,
        // @ts-ignore
        Math.random() * 100 + data.brandLogo.file.name
      );
      await updateLogo({ id: casinoId, data: formData });
    }

    const dataToSend = {
      name: data.name,
      addressLabel: data.address.addressLabel,
      address: data.address.streetAddress,
      streetNumber: data.address.streetNumber,
      country: data.address.country,
      countryCode: data.address.countryCode,
      city: data.address.city,
      state: data.address.state,
      postalCode: data.address.postalCode,
      webSite: data.webSite,
      mailContact: data.mailContact,
      telephoneNumber:
        data.telephoneNumber?.charAt(0) === '+'
          ? data.telephoneNumber
          : '+' + data.telephoneNumber,
      mainCurrency: data.mainCurrency,
      minimumAgeEntrance: data.minimumAgeEntrance,
      dressCode: data.dressCode,
      tablesNumber: data.tablesNumber,
      pokerRoom: true,
      entryFee: data.entryFee,
      description: data.description,
      establishmentType: data.establishmentType,
      offeredGames: data.games || [],
      youtubeUrl: data.youtubeUrl,
      facebookUrl: data.facebookUrl,
      instagramUrl: data.instagramUrl,
      reserveAllowedTime: data.reserveAllowedTime
        ? moment(data.reserveAllowedTime).format('mm:ss')
        : '30:00',
      userMaxDistance: data.userMaxDistance,
      userMaxDistanceUnit: data.userMaxDistanceUnit,
      lat: data.address.lat,
      lon: data.address.lon
    };

    await updateCasinoData({ id: casinoId, data: dataToSend });

    dispatch(CasinosActions.fetchCasinoDetailsRequest(casinoId, true));
  };

  return { formMethods, onSubmitForm, defaultValues, stepsValidation };
}
