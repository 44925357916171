export const SMALL_MODAL = 337;
import { Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';
import React from 'react';

export const SlimModal: React.FC<ModalProps> = ({ children, ...rest }) => {
  return (
    <Modal {...rest} width={SMALL_MODAL}>
      {children}
    </Modal>
  );
};

export default SlimModal;
