import DefaultPicture from '@assets-3/images/default_profile_picture.png';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import { logout } from '@redux-3/AuthenticationRedux';
import {
  ADMIN_PROFILE_ACCOUNT_SETTINGS,
  ADMIN_PROFILE_MY_INFORMATION
} from '@routes-3/routes';
import { Avatar, Popover } from 'antd';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(CurrentUserContext);
  function handleLogout() {
    dispatch(logout());
  }
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        {/*@ts-ignore*/}
        <NavLink
          className={'gx-text-white'}
          to={ADMIN_PROFILE_MY_INFORMATION}
          activeClassName="gx-text-warning"
        >
          <span>My Information</span>
        </NavLink>
      </li>
      <li>
        {/*@ts-ignore*/}
        <NavLink
          className={'gx-text-white'}
          to={ADMIN_PROFILE_ACCOUNT_SETTINGS}
          activeClassName="gx-text-warning"
        >
          <span>Account Settings</span>
        </NavLink>
      </li>
      <li>
        {/*@ts-ignore*/}
        <NavLink onClick={handleLogout} to={'/'}>
          <span style={{ color: 'var(--danger)' }}>Logout</span>
        </NavLink>
      </li>
    </ul>
  );

  const truncate = (str: string) => {
    return str.length > 19 ? str.substring(0, 18) + '...' : str;
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={currentUser.profilePicture || DefaultPicture}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {truncate(
            currentUser.data.firstName + ' ' + currentUser.data.lastName
          )}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
