import {
  PlayerSearchProfile,
  useGetPlayerSearchQuery,
  useGetPlayersProfileByIdsQuery
} from '@api-3/endpoints/player';
import { useGetSubGroupQuery } from '@api-3/endpoints/subGroup';
import PlayerRowItem from '@components-3/addPlayerModal/PlayerRowItem';
import { AInput } from '@components/AntdSimpleFieldForReactFormHooks';
import PlayersList from '@features/players/components/PlayersList';
import useDebounce from '@old-world/flop-3.0/hooks/useDebounce';
import useMapProfileResponses from '@old-world/flop-3.0/hooks/useMapProfileResponses';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetGame';
import { Form } from 'antd';
import React from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';

type FlopUserFormProps = {
  isInterestList?: boolean;
  table?: Table;
  gameId?: string;
  subGroupId?: number;
  setSubGroupPlayersIds?: (players: number[]) => void;
  removeText?: string;
  addText?: string;
  onChange?: (player: PlayerSearchProfile) => void;
  checkIsSelected?: (player: PlayerSearchProfile) => boolean;
  addToSubGroup?: boolean;
};
const FlopUserForm = ({
  isInterestList,
  table,
  gameId,
  subGroupId,
  setSubGroupPlayersIds,
  addText,
  removeText,
  onChange,
  addToSubGroup,
  checkIsSelected = () => false
}: FlopUserFormProps) => {
  const formMethods = useForm<{
    flopUserName: string;
  }>();
  const flopUserController = useController({
    control: formMethods.control,
    name: 'flopUserName'
  });
  const flopUserName = useWatch({
    control: formMethods.control,
    name: 'flopUserName'
  });
  const debouncedSearchTerm = useDebounce(flopUserName, 500);
  const { data: searchResults } = useGetPlayerSearchQuery(
    {
      search: debouncedSearchTerm
    },
    { skip: !debouncedSearchTerm?.length }
  );
  const { data: profiles } = useGetPlayersProfileByIdsQuery(
    {
      ids: (searchResults ?? []).map(p => p.id).filter(Boolean)
    },
    { skip: !searchResults }
  );
  const enrichedProfiles = useMapProfileResponses({
    secondary: profiles ?? [],
    primary: searchResults ?? [],
    key: 'id'
  });
  const { game } = useGetGame({ gameId });
  const { data: subGroup } = useGetSubGroupQuery(
    // @ts-ignore
    { subGroupId },
    { skip: !subGroupId || !addToSubGroup }
  );

  const renderPlopPlayerItem = (player: any) => (
    <PlayerRowItem
      addToSubGroup={addToSubGroup}
      table={table}
      player={player}
      game={game || { id: gameId }}
      text={checkIsSelected(player) ? removeText : addText}
      isInterestList={isInterestList}
      registeredPlayerSubGroup={subGroup?.players}
      subGroupId={subGroupId}
      setSubGroupPlayersIds={setSubGroupPlayersIds}
      onChange={onChange}
    />
  );

  return (
    <>
      <Form layout="vertical">
        <AInput
          placeholder={'search a flop App user'}
          label={'Flop App User'}
          controller={flopUserController}
        />
      </Form>
      <PlayersList
        renderItem={renderPlopPlayerItem}
        searchResults={flopUserName ? enrichedProfiles : []}
      />
    </>
  );
};

export default FlopUserForm;
