import { useGetRunningGamesQuery } from '@api-3/endpoints/games';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { useModals } from '@providers/ModalProvider';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import RunningGamesMap from '@routes-3/main/cashGameManagement/runningGamesMap/components/RunningGamesMap';
import WaitingList from '@routes-3/main/cashGameManagement/runningGamesMap/components/WaitingList';
import { Button, Card, Col, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const RunningGamesMapPage = () => {
  const casinoId = useSelector(currentCasinoSelector);
  const openModal = useModals();
  const [selectedTab, setSelectedTab] = useState<string>('ALL');
  const { data, isLoading } = useGetRunningGamesQuery(
    {
      queryParams: {
        page: 1,
        size: 40,
        casinoId
      }
    },
    { pollingInterval: 5000 }
  );
  const onChangeTab = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Cash Game Management'}
        pageIcon={
          <i
            className="icon icon-dasbhoard"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Running Games'}
      />
      {data && data.content.length > 0 ? (
        <>
          <Row className="gx-layouts-view">
            <Col xl={17} lg={17} md={17} sm={17} xs={17}>
              <div className={'gx-font-weight-bold mb-2'}>Running Games</div>
              <RunningGamesMap
                runningGames={data?.content}
                isLoading={isLoading}
                onChange={onChangeTab}
                selectedTab={selectedTab}
              />
            </Col>
            <Col xl={7} lg={7} md={7} sm={7} xs={7}>
              <div
                style={{ marginBottom: 54 }}
                className={'gx-font-weight-bold mt-2'}
              >
                Waiting Lists
              </div>
              <WaitingList
                runningGames={data?.content}
                selectedTab={selectedTab}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Card
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            display: 'flex'
          }}
        >
          {isLoading ? (
            <Spin tip="Loading" size="large" />
          ) : (
            <>
              <h2>No Poker Cash Games Running Yet!</h2>
              <p>Here's your stage to create some action!</p>
              <ol
                style={{
                  textAlign: 'left',
                  marginBottom: '20px'
                }}
              >
                <li>
                  <b>Set Up Tables</b>: Open your table and decide on the number
                  of players.
                </li>
                <li>
                  <b>Choose Your Games</b>: Whether it's Texas Hold'em, Omaha,
                  or any other variation, set the rules and let the games begin.
                </li>
              </ol>
              <div>
                <Button
                  className={'gx-btn'}
                  key="submit"
                  type="default"
                  onClick={() => {
                    openModal({
                      type: 'CREATE_GAME',
                      props: {}
                    });
                  }}
                >
                  Add Game
                </Button>
              </div>
              <div>
                <i
                  className="icon icon-sweet-alert text-warning"
                  style={{ position: 'relative', top: 3 }}
                />{' '}
                <strong>Every Table needs to have a Unique ID.</strong>
              </div>
            </>
          )}
        </Card>
      )}
    </LayoutContentContainer>
  );
};

export default RunningGamesMapPage;
