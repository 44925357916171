import {
  NOTIFICATION_TYPE,
  useGetNotificationCountQuery
} from '@api-3/endpoints/notifications';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { ADMIN_CHAT } from '@routes-3/routes';
import { Badge } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const ChatIcon = () => {
  const history = useHistory();
  const owner = useSelector(ownerSelector);
  const { data: notificationMessageCount } = useGetNotificationCountQuery(
    { userId: owner.id, notificationType: NOTIFICATION_TYPE.GAME_MESSAGE },
    { pollingInterval: 10000 }
  );
  return (
    <Badge size="small" count={notificationMessageCount}>
      <i
        style={{ cursor: 'pointer', fontSize: 20 }}
        className="icon icon-chat-new"
        onClick={() => {
          history.push(ADMIN_CHAT);
        }}
      />
    </Badge>
  );
};

export default ChatIcon;
