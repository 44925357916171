import { InterestList } from '@api-3/endpoints/interestList';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import React from 'react';

export type Game = {
  gameVariant: string;
  gameSize: number | string;
  gameId: string;
  tables: Table[];
  ante?: number;
};

export type Table = {
  id: string;
  tableId: string;
  players: string[];
  maxPlayers: number;
};

export type InterestListDataTableProps = {
  data: InterestList[];
  // currentInterestListId: string;
  totalElements: number | undefined;
  size: number | undefined;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filter: Record<any, any>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => void;
};

export type ColumnObjType = {
  children: React.ReactElement;
  props: {
    colSpan?: number;
  };
};

export interface DataType {
  key: React.Key;
  title: string;
  render?: () => React.ReactElement;
}

export enum INTEREST_LIST_COLUMN {
  CREATED_BY = 'createdBy',
  STATE = 'gameStates',
  DATE = 'date',
  TIME = 'time',
  GAME_SIZE = 'gameSize',
  GAME_VARIANT = 'gameVariant',
  GAME_PLAYERS = 'gamePlayers',
  GAME_MESSAGES = 'gameMessages',
  GAME_ACTION = 'gameAction',
  BUY_IN = 'buyIn'
}

export type InterestListType = {
  address: string;
  bigBlind: number;
  buyIn: number;
  casino: string;
  casinoId: string;
  city: string;
  country: string;
  creationDate: string;
  date: string;
  festivalId: string;
  gameOrigin: 'FLOP_USER' | 'CASINO';
  gameSize: string;
  gameType: string;
  gameVariant: string;
  id: string;
  key: string;
  liveGameKey: string;
  maxPlayer: number;
  modificationDate: string;
  name: string;
  ownerId: number;
  playersListIds: Array<string>;
  playersNumber: number;
  position: PositionType;
  publicGame: boolean;
  smallBlind: number;
  state: 'ACCEPTED' | 'PENDING' | 'DECLINED';
};

export type PositionType = {
  lat: number;
  lon: number;
};

export type Owner = {
  id: string;
};
