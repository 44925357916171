import flopLogo from '@assets-3/images/flop-pm-logo.png';
import ResetPasswordForm from '@routes-3/userAuth/resetPassword/ResetPasswordForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background:
                'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)',
              height: '380px'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div>Welcome on Flop PM</div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">
                {t('FORGOT_PASSWORD_MAIN_TITLE')}
              </h1>
              <h6>{t('FORGOT_PASSWORD_TITLE')}</h6>
            </div>
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
