import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import ProfileForm from '@routes-3/main/userProfile/components/ProfileForm';
import ProfilePicture from '@routes-3/main/userProfile/components/ProfilePicture';
import { Card } from 'antd';
import React, { useContext } from 'react';

const MyInformationPage = () => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={`${currentUser.data.firstName} ${currentUser.data.lastName}`}
        pageIcon={
          <i
            className="icon icon-avatar"
            style={{ fontSize: 14, position: 'absolute', top: 1 }}
          />
        }
        subPage={'My Information'}
      />
      <Card className="gx-card w-50" title="Personal Information">
        <div className="d-flex w-100">
          <div
            className={'w-50 align-items-center justify-content-center d-flex'}
          >
            <ProfilePicture />
          </div>
          <div className={'w-50'}>
            <ProfileForm />
          </div>
        </div>
      </Card>
    </LayoutContentContainer>
  );
};

export default React.memo(MyInformationPage);
