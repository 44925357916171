import flopLogo from '@assets-3/images/flop-pm-logo.png';
import BadRequestImage from '@assets/images/400.png';
import UnAuthorizedImage from '@assets/images/403.png';
import ServerErrorImage from '@assets/images/500.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type ErrorPageProps = {
  image: any;
  altImage: string;
  title: any;
  text: any;
  variableText?: string;
  hideButton?: boolean;
};

function ErrorPage({
  image,
  altImage,
  title,
  text,
  hideButton
}: ErrorPageProps) {
  const { t } = useTranslation();

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: '800px' }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div className={'gx-text-center'}>
                <img src={image} alt={altImage} width="150" />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">{title}</h1>
              <h4>{text}</h4>
            </div>
            {!hideButton && (
              <h4 className={'gx-text-center'}>
                {/*@ts-ignore*/}
                <Link
                  className="gx-text-underline"
                  style={{ color: '#4F8DCA' }}
                  to="/"
                >
                  {t('BACK_TO_HOME_PAGE')}
                </Link>
              </h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function BadRequestPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={BadRequestImage}
      altImage="Bad Request"
      title={t('BAD_REQUEST_TITLE')}
      text={t('BAD_REQUEST_TEXT')}
    />
  );
}

export function ServerErrorPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={ServerErrorImage}
      altImage="Server Error"
      title={t('SERVER_ERROR_TITLE')}
      text={t('SERVER_ERROR_TEXT')}
    />
  );
}

export function UnAuthorizedPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={UnAuthorizedImage}
      altImage="UnAuthorized"
      title={t('UNAUTHORIZED_TITLE')}
      text={t('UNAUTHORIZED_TEXT')}
    />
  );
}
