// Game Size values
const GameSize = [
  {
    smallBlind: 1,
    bigBlind: 1
  },
  {
    smallBlind: 1,
    bigBlind: 2
  },
  {
    smallBlind: 2,
    bigBlind: 2
  },
  {
    smallBlind: 2,
    bigBlind: 4
  },
  {
    smallBlind: 3,
    bigBlind: 6
  },
  {
    smallBlind: 4,
    bigBlind: 8
  },
  {
    smallBlind: 5,
    bigBlind: 5
  },
  {
    smallBlind: 5,
    bigBlind: 10
  },
  {
    smallBlind: 10,
    bigBlind: 10
  },
  {
    smallBlind: 10,
    bigBlind: 20
  },
  {
    smallBlind: 20,
    bigBlind: 40
  },
  {
    smallBlind: 25,
    bigBlind: 25
  },
  {
    smallBlind: 30,
    bigBlind: 60
  },
  {
    smallBlind: 40,
    bigBlind: 80
  },
  {
    smallBlind: 50,
    bigBlind: 50
  },
  {
    smallBlind: 50,
    bigBlind: 100
  },
  {
    smallBlind: 75,
    bigBlind: 150
  },
  {
    smallBlind: 100,
    bigBlind: 200
  },
  {
    smallBlind: 150,
    bigBlind: 300
  },
  {
    smallBlind: 200,
    bigBlind: 200
  },
  {
    smallBlind: 200,
    bigBlind: 400
  },
  {
    smallBlind: 300,
    bigBlind: 300
  },
  {
    smallBlind: 300,
    bigBlind: 600
  },
  {
    smallBlind: 400,
    bigBlind: 400
  },
  {
    smallBlind: 400,
    bigBlind: 800
  },
  {
    smallBlind: 500,
    bigBlind: 500
  },
  {
    smallBlind: 500,
    bigBlind: 1000
  }
];

// Export Default
export default GameSize;
