import notification_img from '@assets/svgs/notification.svg';
import GameSize from '@constants/gamesizes';
import SetDistanceFormComponent from '@features/notifyPlayers/components/SetDistanceFormComponent';
import { LoadingContainer } from '@old-world/common/components/container';
import { Form } from 'antd';
import throttle from 'lodash/throttle';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ModifyGameSizes from './ModifyGameSizes';
import ModifyGameVariants from './ModifyGameVariants';

export type GameSize = {
  smallBlind: number;
  bigBlind: number;
  active?: any;
};
type Props = {
  defaults: { gameVariant: string; bigBlind: number; smallBlind: number };
  countOfPlayersEstimated: number;
};

export type FormShape = {
  gameVariants: string[];
  distance: number;
  gameSizes: GameSize[];
};

const NotifyPlayersForm = (props: Props) => {
  const { t } = useTranslation();
  const formContext = useFormContext<FormShape>();

  const gameSizes = useWatch({
    control: formContext.control,
    name: 'gameSizes'
  });

  const gameVariants = useWatch({
    control: formContext.control,
    name: 'gameVariants'
  });
  const throttleDistanceChange = useCallback(
    throttle(value => {
      formContext.setValue('distance', value);
    }, 500),
    []
  );

  return (
    <>
      <div className="notification-bar d-flex mb-4 gx-justify-content-center">
        <img src={notification_img} className={'mr-2'} />
        <span>{t('A_NOTIFICATION_WILL_BE_SENT_TO')}&nbsp;</span>
        <strong>
          {props.countOfPlayersEstimated} {t('PLAYERS')}
        </strong>
      </div>
      <SetDistanceFormComponent onChange={throttleDistanceChange} />
      <hr />
      <ModifyGameSizes
        defaults={props.defaults}
        gameSizes={gameSizes}
        onChange={gameSizes => formContext.setValue('gameSizes', gameSizes)}
      />
      <hr />
      <ModifyGameVariants
        defaults={props.defaults}
        gameVariants={gameVariants}
        onChange={gameVariants =>
          formContext.setValue('gameVariants', gameVariants)
        }
      />
    </>
  );
};

// Export Default
export default LoadingContainer(['FETCH_REGISTERED_PLAYERS'])(
  React.memo(NotifyPlayersForm)
);
