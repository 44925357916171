import { InterestList } from '@api-3/endpoints/interestList';
import CustomScrollbars from '@old-world/utils/CustomScrollbars';
import ChatCommunicationFooter from '@routes-3/main/chat/components/ChatCommunicationFooter';
import ChatCommunicationHeader from '@routes-3/main/chat/components/ChatCommunicationHeader';
import Conversation from '@routes-3/main/chat/components/Conversation';
import React from 'react';

const ChatCommunication = ({
  selectedInterestGameId,
  interestList
}: {
  selectedInterestGameId: string;
  interestList: InterestList;
}) => {
  return (
    <div className="gx-chat-main">
      <ChatCommunicationHeader interestList={interestList} />
      <CustomScrollbars className="gx-chat-list-scroll">
        <Conversation selectedInterestGameId={selectedInterestGameId} />
      </CustomScrollbars>
      <ChatCommunicationFooter
        selectedInterestGameId={selectedInterestGameId}
      />
    </div>
  );
};

export default ChatCommunication;
