import '@style-3/wieldy.less';
import '@assets-3/vendors/style';

// @ts-ignore
import AppLocale from '@lngProvider-3';
import AccountActions from '@old-world/features/account/redux/AccountRedux';
import AuthenticationActions from '@old-world/features/authentication/redux/AuthenticationRedux';
import {
  setThemeColor,
  setThemeType,
  THEME_COLOR,
  THEME_TYPE
} from '@old-world/utils/ThemeUtils';
import ModalProvider from '@providers/ModalProvider';
import DealerDisplayPage from '@routes-3/main/cashGameManagement/dealerDisplay/DealerDisplayPage';
import FullDisplay from '@routes-3/main/cashGameManagement/fullDisplay/FullDisplayPage';
import MobileView from '@routes-3/main/mobileView/MobileView';
import RunningGamesMobilePage from '@routes-3/main/mobileView/RunningGames/RunningGamesMobilePage';
import {
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ADMIN_CASH_GAME_DEALER_DISPLAY,
  ADMIN_CASH_GAME_FULL_DISPLAY,
  ADMIN_CASH_GAME_MOBILE,
  ADMIN_MOBILE,
  AUTH_BAD_REQUEST,
  AUTH_ERROR,
  AUTH_UNAUTHORIZED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_UNAUTHORIZED,
  EMAIL_CHECK,
  EMAIL_UNVERIFIED,
  NEW_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_AFTER_SIGN_UP,
  SIGN_UP,
  UNAUTHORIZED
} from '@routes-3/routes';
import ChangePasswordPage from '@routes-3/userAuth/changePassword/ChangePasswordPage';
import ChangePasswordUnauthorized from '@routes-3/userAuth/changePasswordUnauthorized/ChangePasswordUnauthorized';
import EmailCheckPage from '@routes-3/userAuth/emailCheck/EmailCheckPage';
import EmailUnverifiedPage from '@routes-3/userAuth/emailUnverified/EmailUnverifiedPage';
import {
  BadRequestPage,
  ServerErrorPage,
  UnAuthorizedPage
} from '@routes-3/userAuth/error/ErrorPage';
import ActivateAccountSuccessPage from '@routes-3/userAuth/licenseVerification/ActivateAccountSuccessPage';
import NewPasswordPage from '@routes-3/userAuth/newPassword/NewPasswordPage';
import RedirectPage from '@routes-3/userAuth/redirect/RedirectPage';
import ResetPasswordPage from '@routes-3/userAuth/resetPassword/ResetPasswordPage';
import ResetPasswordSuccessPage from '@routes-3/userAuth/resetPasswordSuccess/ResetPasswordSuccessPage';
import SignInPage from '@routes-3/userAuth/signIn/SignInPage';
import SignUpPage from '@routes-3/userAuth/signUp/SignUpPage';
import { push } from 'connected-react-router';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import MessagePanel from './common/components/layout/SystemMessagePanel/SystemMessagePanel';
import LoadingPage from './common/containers/LoadingPage';
import { authorizationTokenSelector } from './features/authentication/redux/AuthenticationRedux';
import CoreLayout from './flop-3.0/layout/CoreLayout';
import i18n from './i18n';

function PrivateRoute({ component: Component, ...rest }: { component: any }) {
  const authorizationToken = useSelector(authorizationTokenSelector);

  return (
    // @ts-ignore
    <Route
      {...rest}
      render={props =>
        authorizationToken ? (
          <Component {...rest} {...props} />
        ) : (
          // @ts-ignore
          <Redirect
            to={{
              pathname: SIGN_IN,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  const SignInAfterSignUp = (props: any) => {
    const {
      authorization,
      email,
      id,
      customerId,
      managedCasinoId
    } = props.match.params;
    const dispatch = useDispatch();
    const signin = {
      email,
      id,
      customerId,
      managedCasinoId
    };
    dispatch(AuthenticationActions.storeAuth(signin, authorization));
    dispatch(AuthenticationActions.storeAuth(signin, authorization));
    dispatch(AccountActions.saveCurrentStep(1));
    dispatch(push(`/admin/account/`));
    return null;
  };

  const currentAppLocale = AppLocale['en'];

  const themeTypeFromLocalStorage = localStorage.getItem('themeType');
  const themeColorFromLocalStorage = localStorage.getItem('themeColor');

  if (!themeTypeFromLocalStorage) {
    setThemeType(THEME_TYPE.DARK);
  } else if (themeTypeFromLocalStorage !== THEME_TYPE.DARK) {
    setThemeColor(
      themeColorFromLocalStorage
        ? (themeColorFromLocalStorage as THEME_COLOR)
        : THEME_COLOR.LIGHT_PURPLE
    );
  } else {
    setThemeType(THEME_TYPE.DARK);
  }

  return (
    <I18nextProvider i18n={i18n}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ModalProvider>
          {/*<MaintenanceContext.Provider value={setMaintenanceModalVisible}>*/}
          <Suspense fallback={<LoadingPage />}>
            {/*@ts-ignore*/}
            <Switch>
              {/*@ts-ignore*/}
              <Route exact path={SIGN_IN} component={SignInPage} />
              {/*@ts-ignore*/}
              <Route exact path={SIGN_UP} component={SignUpPage} />
              {/*@ts-ignore*/}
              <Route exact path={EMAIL_CHECK} component={EmailCheckPage} />
              {/*@ts-ignore*/}
              <Route
                exact
                path={RESET_PASSWORD}
                component={ResetPasswordPage}
              />
              {/*@ts-ignore*/}
              <Route
                exact
                path={EMAIL_UNVERIFIED}
                component={EmailUnverifiedPage}
              />
              {/*@ts-ignore*/}
              <Route
                exact
                path={RESET_PASSWORD_SUCCESS}
                component={ResetPasswordSuccessPage}
              />
              {/*@ts-ignore*/}
              <Route exact path={ACTIVATE_ACCOUNT} component={RedirectPage} />
              {/*@ts-ignore*/}
              <Route
                exact
                path={CHANGE_PASSWORD}
                component={ChangePasswordPage}
              />
              {/*@ts-ignore*/}
              <Route
                exact
                path={CHANGE_PASSWORD_UNAUTHORIZED}
                component={ChangePasswordUnauthorized}
              />
              {/*@ts-ignore*/}
              <Route exact path={AUTH_BAD_REQUEST} component={BadRequestPage} />
              {/*@ts-ignore*/}
              <Route path={AUTH_ERROR} component={ServerErrorPage} />
              {/*@ts-ignore*/}
              <Route
                exact
                path={SIGN_IN_AFTER_SIGN_UP}
                component={SignInAfterSignUp}
              />
              {/*@ts-ignore*/}
              <Route
                path={[AUTH_UNAUTHORIZED, UNAUTHORIZED]}
                component={UnAuthorizedPage}
              />
              <Route exact path={ADMIN_MOBILE} component={MobileView} />
              <Route
                exact
                path={ADMIN_CASH_GAME_MOBILE}
                component={RunningGamesMobilePage}
              />
              <Route
                exact
                path={ADMIN_CASH_GAME_FULL_DISPLAY}
                component={FullDisplay}
              />
              <Route
                exact
                path={`${ADMIN_CASH_GAME_DEALER_DISPLAY}/:id?`}
                component={DealerDisplayPage}
              />
              {/*@ts-ignore*/}
              <Route
                exact
                path={ACTIVATE_ACCOUNT_SUCCESS}
                component={ActivateAccountSuccessPage}
              />
              {/*@ts-ignore*/}
              <Route path={[NEW_PASSWORD]} component={NewPasswordPage} />
              <PrivateRoute component={CoreLayout} />
            </Switch>
            {/*) : (*/}
            {/*  <ServerMaintenancePage />*/}
            {/*)}*/}
          </Suspense>
          {/*<MaintenanceModal*/}
          {/*  visible={maintenanceModalVisible}*/}
          {/*  onClose={onCloseMaintenanceModal}*/}
          {/*/>*/}
          {/*</MaintenanceContext.Provider>*/}
          {/* @ts-ignore */}
          <MessagePanel />
        </ModalProvider>
      </IntlProvider>
    </I18nextProvider>
  );
}

export default App;
