import ApiClient from '@old-world/ApiClient';
import { IRootState } from '@old-world/common/models/StateModel';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../flop-2.0/redux/types';

type LoginDTO = {
  managedCasinoId: string;
  customerId: string;
  id: number;
  lastConnexion: Date;
};

const URL = `${process.env.REACT_APP_API_URL}/accounts/api/login`;

export const loginWithEmailAndPassword = createAsyncThunk<
  { authorization: string; data: LoginDTO },
  {
    email: string;
    password: string;
  },
  { state: RootState }
>('account/loginWithUserAndPassword', async payload => {
  const { email, password } = payload;
  const loginData = await fetch(URL, {
    method: 'post',
    body: JSON.stringify({
      email,
      password,
      loginOrigin: 'FLOP_AD'
    })
  });

  const response = await loginData.json();
  if (response.active === false) {
    throw new Error('email_verification');
  }

  const token = loginData.headers.get('Authorization');
  if (loginData.ok && token) {
    // for now the api client copies the token over.
    ApiClient.token = token;
    localStorage.setItem('token', token);
    return {
      authorization: token,
      data: response
    };
  }
  throw new Error(loginData.status.toString());
});

export type AuthorizationState = {
  signin: LoginDTO | null;
  authorizationToken: null | string;
};
const INITIAL_STATE: AuthorizationState = {
  authorizationToken: null,
  signin: null
};

const slice = createSlice({
  name: 'account',
  initialState: INITIAL_STATE,
  reducers: {
    logout(state) {
      state.authorizationToken = null;
      state.signin = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      loginWithEmailAndPassword.fulfilled,
      (state, { payload }) => {
        state.authorizationToken = payload.authorization;
        state.signin = payload.data;
      }
    );
  }
});

export default slice.reducer;
export const { logout } = slice.actions;

export const getCurrentUserId = (state: IRootState) =>
  state.authentication.signin.id;

// Get Owner Id
export const ownerSelector = (state: IRootState) => state.authentication.signin;

// Get Authorization Token
export const authorizationTokenSelector = (state: IRootState) =>
  state.authentication.authorizationToken;

export const currentCasinoSelector = (state: IRootState) =>
  state.authentication.signin.managedCasinoId;
export const currentCustomerIdSelector = (state: IRootState) =>
  state.authentication.signin.customerId;
export const currentUserIdSelector = (state: IRootState) =>
  state.authentication.signin.id;
