import { PauseOutlined } from '@ant-design/icons';
import DefaultProfileImage from '@assets-3/images/default_profile_picture.png';
import PlayerInfo from '@components-3/PlayerInfo';
import PlayerName from '@components-3/PlayerName';
import usePlayerProfile from '@features/players/usePlayerProfile';
import { useModals } from '@providers/ModalProvider';
import Timer from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/Timer';
import {
  Table,
  TablePlayers
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

const OccupiedChair = ({
  player,
  table,
  seatNr
}: {
  player: TablePlayers;
  seatNr: number;
  table: Table;
}) => {
  const openModal = useModals();
  const { data: playerProfile } = usePlayerProfile(player.flopId?.toString());
  const [showName, setShowName] = useState(false);

  useEffect(() => {
    if (player?.requestState === 'PAUSED') {
      const timerInterval = setInterval(() => {
        setShowName(true);
        setTimeout(() => setShowName(false), 3000); // Show the name for 3 seconds
      }, 5000); // Trigger every 10 seconds

      return () => clearInterval(timerInterval);
    }
  }, [player?.requestState]);

  const PlayerInfo = () => (
    <>
      <PlayerName
        player={{
          firstName: playerProfile.data.firstName!,
          lastName: playerProfile.data.lastName!,
          name: player.name
        }}
        showFullName={false}
        textAlign={'center'}
      />
      {player.flopId && (
        <>
          <div className={'mr-2'} />
          <ReactCountryFlag
            className="country-flag"
            code={playerProfile.data.countryCode}
            svg
          />
        </>
      )}
    </>
  );

  return (
    <>
      <div
        className="chair"
        style={{ opacity: 1 }}
        onClick={() =>
          openModal({
            type: 'DEALER_ACTION_MODAL',
            props: {
              table: table,
              seatNr: seatNr,
              seatedPlayer: player
            }
          })
        }
      >
        <Avatar
          src={
            playerProfile.data.profilePictureUrl
              ? playerProfile.data.profilePictureUrl
              : DefaultProfileImage
          }
          size={46}
          style={{ opacity: player?.requestState === 'PAUSED' ? 0.5 : 1 }}
        />
        {player?.requestState === 'PAUSED' && (
          <PauseOutlined
            className={'gx-text-warning'}
            style={{
              position: 'absolute',
              fontWeight: 'bold',
              fontSize: 30
            }}
          />
        )}
      </div>
      <div className={'player-name gx-flex-row'}>
        {player?.requestState === 'PAUSED' ? (
          showName ? (
            <PlayerInfo />
          ) : (
            <Timer
              date={player.breakDate}
              size={'small'}
              warningThreshold={60}
            />
          )
        ) : (
          <PlayerInfo />
        )}
      </div>
    </>
  );
};

export default OccupiedChair;
