import { api } from '@api/api';
import { ListResponse } from '@api/types';
import { parseParams } from '@old-world/utils/ApiUtils';
import _ from 'lodash';

export type UserListPayload = {
  customerId: string;
  queryParams: {
    states: ('ALL' | 'PENDING' | 'ACTIVE')[];
    roles: ('ADMIN' | 'ROOM_POKER_MANAGER' | 'LIMITED_ADMIN')[];
    page: number;
    search: string;
    size: number;
  };
};

export type AccountRole = 'CASINO_MANAGER';
export type RoleType =
  | 'ADMIN'
  | 'LIMITED_ADMIN'
  | 'POKER_MANAGER'
  | 'COMMUNITY_MANAGER';

export type User = {
  accountRole: AccountRole;
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  managedCasinoId: string;
  role: RoleType;
};

export type UserInvitePayload = {
  casinoId?: string;
  customerId?: string | number;
  customerType?: 'CASINO' | 'OPERATOR';
  email?: string;
  role?: 'ADMIN' | 'LIMITED_ADMIN' | 'POKER_ROOM_MANAGER';
};

const userManagementApi = api
  .enhanceEndpoints({ addTagTypes: ['Users'] })
  .injectEndpoints({
    endpoints: builder => ({
      getUsersList: builder.query<ListResponse<User>, UserListPayload>({
        providesTags: ['Users'],
        query: ({ customerId, queryParams }) =>
          `/accounts/api/customer/${customerId}/customer_account/list?${parseParams(
            _.omit(queryParams, ['roles', 'states'])
          )}`
      }),
      inviteUser: builder.mutation<
        void,
        { customerId: string; body: UserInvitePayload }
      >({
        invalidatesTags: ['Users'],
        query: params => ({
          method: 'POST',
          url: `/accounts/api/customer/${params.customerId}/invite`,
          body: { requestState: 'PENDING', ...params.body }
        })
      }),
      emailUser: builder.mutation<void, { customerId: string; email: string }>({
        query: params => ({
          method: 'POST',
          url: `/accounts/api/customer/${params.customerId}/invite/${params.email}/resend`
        })
      }),
      editUserRole: builder.mutation<
        void,
        {
          customerId: string;
          userId: string;
          body: { role: string };
        }
      >({
        invalidatesTags: ['Users'],
        query: args => ({
          method: 'PUT',
          url: `/accounts/api/customer/${args.customerId}/customer_account/${args.userId}/role}`,
          body: {
            role: args.body.role
          }
        })
      }),
      deleteUser: builder.mutation<
        void,
        {
          customerId: string;
          userId: string;
        }
      >({
        invalidatesTags: ['Users'],
        query: params => ({
          method: 'DELETE',
          url: `/accounts/api/customer/${params.customerId}/customer_account/${params.userId}`
        })
      })
    })
  })
  .enhanceEndpoints({ addTagTypes: ['Users'] });

export const {
  useGetUsersListQuery,
  useDeleteUserMutation,
  useEmailUserMutation,
  useEditUserRoleMutation,
  useInviteUserMutation
} = userManagementApi;
