import WaitingListPlayersDnD from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/WaitingListPlayersDnD';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React from 'react';

const WaitingListItems = ({
  gameId,
  isReordering,
  waitingListPlayers,
  wListPlayers,
  setWLPlayers
}: {
  gameId: string;
  isReordering: boolean;
  waitingListPlayers: WaitingPlayer[];
  wListPlayers: WaitingPlayer[] | undefined;
  setWLPlayers: (waitingList: any) => void;
}) => {
  return (
    <WaitingListPlayersDnD
      waitingListPlayers={waitingListPlayers}
      reorderWaitingListPlayers={wListPlayers}
      setWaitingListPlayers={setWLPlayers}
      gameId={gameId}
      isReordering={isReordering}
    />
  );
};

export default WaitingListItems;
