import { api } from '@api/api';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { IRootState } from '../common/models/StateModel.d';
import { persistConfig } from '../config/ReduxPersistConfig';
import rootSaga from '../sagas';
import cacheMiddleware from './middleware/cache-middleware';
import reducers from './reducers';

/**
 * Manage Modal When Want to leave an edition screen
 * @param {*} dialogKey
 * @param {*} callback
 */
const getUserConfirmation = (dialogKey: string, callback: any) => {
  //@ts-ignore
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    return dialogTrigger(callback);
  }

  callback(true);
};

export const history = createBrowserHistory({
  getUserConfirmation
});

const reducer = reducers(history);

// Export Default
export default () => {
  const sagaMiddleware = createSagaMiddleware();
  const reactRouterMiddleware = routerMiddleware(history);

  const store: Store<IRootState, any> = configureStore({
    reducer: persistReducer(persistConfig, reducer),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST']
        }
      }).concat(
        ...[
          cacheMiddleware,
          sagaMiddleware,
          reactRouterMiddleware,
          api.middleware
        ]
      )
  });

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept(() => {
      store.replaceReducer(persistReducer(persistConfig, reducers(history)));

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const newYieldedSagas = require('../sagas').default;
      sagasManager.cancel();
      //@ts-ignore
      sagasManager.done.then(() => {
        //@ts-ignore
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }
  const persistor = persistStore(store);

  return {
    store,
    persistor,
    history
  };
};
