import { Spin } from 'antd';
import { COLORS } from 'constants/colors';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

export interface CasinoAvatarProps {
  casino: any;
  height?: number;
  textSize?: number;
  fontWeight?: any;
  borderRadius?: number;
  showText?: boolean;
}

export default function CasinoAvatar({
  casino,
  height = 50,
  textSize = 22,
  fontWeight = 'bold',
  borderRadius = 6,
  showText = true
}: CasinoAvatarProps) {
  return (
    <>
      {/*@ts-ignore*/}
      <BrowserView>
        <div>
          {casino && casino.resizedUrl ? (
            <img
              src={casino.resizedUrl}
              height={height}
              alt="casino logo"
              className="mr-2"
              style={{ borderRadius: borderRadius }}
            />
          ) : null}
          {showText && (
            <span
              className={'gx-text-white'}
              style={{
                fontSize: textSize,
                fontWeight: fontWeight
              }}
            >
              {casino?.name}
            </span>
          )}
        </div>
      </BrowserView>
      {/*@ts-ignore*/}
      <MobileView>
        {casino && casino.resizedUrl ? (
          <img
            src={casino.resizedUrl}
            height={height}
            alt="casino logo"
            className="mr-2"
            style={{ borderRadius: borderRadius }}
          />
        ) : (
          <Spin />
        )}
        {showText && (
          <span
            className={'gx-text-white'}
            style={{
              fontSize: textSize,
              fontWeight: fontWeight
            }}
          >
            {casino?.name}
          </span>
        )}
      </MobileView>
    </>
  );
}
