import { api } from '@api/api';
import { parseParams } from '@old-world/utils/ApiUtils';
import take from 'lodash/take';

export type PlayerProfile = {
  id: string;
  firstName: string;
  lastName: string;
  flopId: number;
  countryCode: string;
  country: string;
  profilePictureUrl: string;
  distance?: { value: number; metric: 'KILOMETERS' | 'FEET' };
};

export const PlayerProfileInit: PlayerProfile = {
  // @ts-ignore
  id: null,
  // @ts-ignore
  firstName: null,
  // @ts-ignore
  lastName: null,
  // @ts-ignore
  flopId: null,
  // @ts-ignore
  countryCode: null,
  // @ts-ignore
  country: null,
  // @ts-ignore
  profilePictureUrl: null,
  distance: {
    // @ts-ignore
    value: null,
    // @ts-ignore
    metric: null
  }
};

export type PlayerSearchProfile = {
  id: number; // is really a flopId!
  firstName: string;
  lastName: string;
  flopId: number;
  countryCode: string;
  country: string;
};

export const playersApi = api
  .enhanceEndpoints({ addTagTypes: ['Status'] })
  .injectEndpoints({
    endpoints: builder => ({
      getPlayerStatus: builder.query<
        { requestState: string },
        { userId: string }
      >({
        providesTags: ['Status'],
        query: ({ userId }) => `/games/api/user/${userId}/status`
      }),
      getPlayerLocation: builder.query<
        // TODO: change in the backend so that we no longer need to send the long lat but the backend
        // should resolve it
        { distance: { metric: 'KILOMETERS' | 'FEET'; value: number } },
        {
          casinoLat: number;
          casinoLon: number;
          distanceUnit: 'KILOMETERS';
          userId: string;
        }
      >({
        query: ({ userId, ...rest }) =>
          `/user-geolocation/api/user/${userId}/distance?${parseParams(rest)}`
      }),
      getPlayerProfileInfo: builder.query<PlayerProfile, { userId: string }>({
        query: ({ userId }) => `/profiles/api/${userId}`,
        extraOptions: { maxRetries: 1 }
      }),
      getPlayersProfileByIds: builder.query<
        Array<Omit<PlayerProfile, 'id'> & { id: number }>,
        { ids: Array<string | number> }
      >({
        query: ({ ids }) =>
          // We set a limit here because not every api supports pagination.
          // After 100 profiles we stop fetching and start ignoring.
          // The effort of implementing pagination isn' worth it.
          //  This guide provides a guideline on how to implement it:
          //  https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#performing-multiple-requests-with-a-single-query
          `/profiles/api/by-ids?${parseParams({ ids: take(ids, 100) })}`
      }),
      getPlayerSearch: builder.query<
        Array<PlayerSearchProfile>,
        { search: string }
      >({
        query: ({ search }) => `/profiles/api/search?query=${search}`,
        transformResponse(baseQueryReturnValue: Array<PlayerSearchProfile>) {
          return take(baseQueryReturnValue, 20);
        }
      })
    })
  });

export const {
  useGetPlayerLocationQuery,
  useGetPlayerProfileInfoQuery,
  useGetPlayersProfileByIdsQuery,
  useGetPlayerSearchQuery,
  useGetPlayerStatusQuery
} = playersApi;
