import { useGetPlayersProfileByIdsQuery } from '@api-3/endpoints/player';
import useMapProfileResponses from '@old-world/flop-3.0/hooks/useMapProfileResponses';
import { TablePlayers } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetGame';

export default function useGetTableForRunningGame({
  gameId,
  tableId
}: {
  gameId: string;
  tableId: string;
}) {
  const { game, isLoading } = useGetGame({
    gameId
  });
  const table = game?.tables?.find(table => table.id == tableId);

  const players = table?.players ?? [];

  const { data: seatedPlayersProfiles } = useGetPlayersProfileByIdsQuery(
    {
      ids: players
        .map(p => p.flopId)
        // @ts-ignore
        .concat(game?.waitingPlayers?.filter(p => p !== 'null') ?? [])
        .filter(Boolean)
    },
    { skip: !table }
  );
  const seatedPlayers = useMapProfileResponses<TablePlayers[]>({
    secondary: seatedPlayersProfiles ?? [],
    primary: players
  });

  return { table, game, isLoading, seatedPlayers };
}
