import {
  Notification,
  useDismissNotificationMutation,
  useReadNotificationMutation
} from '@api-3/endpoints/notifications';
import NotificationConstants from '@components-3/notifications/NotificationConstants';
import { NotificationMetData } from '@components-3/notifications/NotificationList';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADMIN_CASH_GAME_INTEREST_LIST, ADMIN_CHAT } from '@routes-3/routes';
import { List, Tooltip } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const NotificationItem = (props: {
  item: Notification;
  onHide: () => void;
  index: number;
}) => {
  const { item, index, onHide } = props;
  const history = useHistory();
  const [dismissNotification] = useDismissNotificationMutation();
  const [readNotification] = useReadNotificationMutation();
  const notificationMetaData = NotificationMetData.find(
    (n: any) => n.type === item.notificationType
  );

  const onReadNotification = async () => {
    try {
      await readNotification(item.id).unwrap();
    } finally {
      onHide();
      switch (item.notificationType) {
        case NotificationConstants.NEW_GAME_REQUEST: {
          history.push(ADMIN_CASH_GAME_INTEREST_LIST);
          break;
        }
        case NotificationConstants.GAME_NEW_MESSAGE_FROM_PLAYER: {
          history.push(ADMIN_CHAT);
          break;
        }
        case NotificationConstants.GAME_SEAT_REQUEST_SENT: {
          history.push(ADMIN_CASH_GAME_INTEREST_LIST);
          break;
        }
      }
    }
  };

  const onDismissNotification = (e: any) => {
    dismissNotification(item.id);
    e.stopPropagation();
  };

  return (
    <List.Item
      key={index}
      onClick={onReadNotification}
      className={item.read ? '' : 'unread'}
      extra={
        <Tooltip title="Delete" placement="bottom">
          <div
            className="cursor-pointer"
            style={{ color: 'red' }}
            onClick={onDismissNotification}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </Tooltip>
      }
    >
      <List.Item.Meta
        avatar={notificationMetaData?.image}
        title={notificationMetaData && notificationMetaData.title}
        description={
          <Fragment>
            <p dangerouslySetInnerHTML={{ __html: item.message }} />
            <span className="datetime">
              {moment(item.creationDate).fromNow()}
            </span>
          </Fragment>
        }
      />
    </List.Item>
  );
};

export default NotificationItem;
