import {
  DailyTournament,
  EventTournament,
  ListEventItem,
  useCreateDailyTournamentMutation,
  useCreateTournamentMutation,
  useUpdateDailyTournamentMutation,
  useUpdateTournamentMutation
} from '@api-3/endpoints/festivals';
import {
  ADatePicker,
  AInput,
  ATimePicker
} from '@components/AntdSimpleFieldForReactFormHooks';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { Button, Col, Form, Modal, notification, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddTournamentModalProps = {
  onClose: () => void;
  type: 'EVENT_TOURNAMENTS' | 'DAILY_TOURNAMENTS';
  event?: ListEventItem;
  tournament?: EventTournament | DailyTournament;
  startDate?: string;
};

type TournamentCreationFormShape = {
  startingDate: string | Moment;
  startingTime: string | Moment;
  eventNumber: string;
  buyIn: number;
  fee: number;
  gameVariant: string;
  name: string;
  startStack: number;
  entries: string;
  levelDuration: number;
  lateRegistrationLevel: number;
};

const DATE_SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';

const AddTournamentModal = ({
  onClose,
  type,
  event,
  tournament,
  startDate
}: AddTournamentModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createTournament] = useCreateTournamentMutation();
  const [updateTournament] = useUpdateTournamentMutation();
  const [createDailyTournament] = useCreateDailyTournamentMutation();
  const [updateDailyTournament] = useUpdateDailyTournamentMutation();
  const formMethods = useForm<TournamentCreationFormShape>({
    defaultValues: {}
  });
  const { handleSubmit, control } = formMethods;
  const eventNumberController = useController({
    control,
    name: 'eventNumber',
    rules: { required: 'is required' },
    defaultValue: tournament ? tournament.eventNumber : ''
  });
  const nameController = useController({
    control,
    name: 'name',
    rules: { required: 'is required' },
    defaultValue: tournament ? tournament.name : ''
  });
  const dateController = useController({
    control,
    name: 'startingDate',
    rules: { required: 'is required' },
    defaultValue: tournament ? tournament.date : startDate ? startDate : ''
  });
  const timeController = useController({
    control,
    name: 'startingTime',
    rules: { required: 'is required' },
    defaultValue: tournament ? moment.utc(tournament.date) : ''
  });
  const gameVariant = useController({
    control,
    name: 'gameVariant',
    rules: { required: 'is required' },
    defaultValue: tournament ? tournament.gameVariant : ''
  });
  const buyInController = useController({
    control,
    name: 'buyIn',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: tournament ? tournament.buyIn : null
  });
  const feeController = useController({
    control,
    name: 'fee',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: tournament ? tournament.fee : null
  });
  const startStackController = useController({
    control,
    name: 'startStack',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: tournament ? tournament.startStack : null
  });
  const entriesController = useController({
    control,
    name: 'entries',
    rules: { required: 'is required' },
    defaultValue: tournament ? tournament.entries : ''
  });
  const levelDurationController = useController({
    control,
    name: 'levelDuration',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: tournament ? tournament.levelDuration : null
  });
  const lateRegistrationLevelController = useController({
    control,
    name: 'lateRegistrationLevel',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: tournament ? tournament.lateRegistrationLevel : null
  });
  const onSubmit = async (data: TournamentCreationFormShape) => {
    setIsLoading(true);
    const hour = moment(data.startingTime).format('HH');
    const minute = moment(data.startingTime).format('mm');
    const startTime = `${hour}:${minute}`;
    const date = moment
      .tz(`${data.startingDate} ${startTime}+0000`, 'YYYY-MM-DD HH:mmZZ', 'UTC')
      .format(DATE_SERVER_FORMAT);
    const params = {
      body: {
        date,
        eventNumber: data.eventNumber,
        buyIn: data.buyIn,
        fee: data.fee,
        gameVariant: data.gameVariant,
        name: data.name,
        startStack: data.startStack,
        entries: data.entries,
        levelDuration: data.levelDuration,
        lateRegistrationLevel: data.lateRegistrationLevel
      }
    };
    try {
      if (type === 'EVENT_TOURNAMENTS' && !!event) {
        if (tournament) {
          await updateTournament({
            ...params,
            tournamentId: tournament.id,
            festivalId: event.id
          });
        } else {
          await createTournament({
            ...params,
            festivalId: event.id
          });
        }
      } else if (type === 'DAILY_TOURNAMENTS') {
        if (tournament) {
          await updateDailyTournament({
            ...params,
            tournamentId: tournament.id
          });
        } else {
          await createDailyTournament(params);
        }
      } else {
        return null;
      }

      notification.success({
        message: 'Success',
        description: `Your Tournament was successfully ${
          tournament ? 'updated' : 'created'
        }.`,
        placement: 'topRight'
      });
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description:
          'An error occurred, while trying to create this tournament. Please Contact your administrator.',
        placement: 'topRight'
      });
    }
  };

  const renderModalTitle = () => {
    if (type === 'EVENT_TOURNAMENTS') {
      return tournament ? 'EDIT TOURNAMENT' : 'CREATE NEW TOURNAMENT';
    } else if (type === 'DAILY_TOURNAMENTS') {
      return tournament
        ? 'EDIT DAILY TOURNAMENT'
        : 'CREATE NEW DAILY TOURNAMENT';
    } else return '';
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={renderModalTitle()}
      onCancel={onClose}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      closable={false}
      footer={[
        <Button onClick={onClose} className="">
          {t('CANCEL_BUTTON')}
        </Button>,
        <Button
          onClick={handleSubmit(onSubmit)}
          className="gx-btn"
          loading={isLoading}
        >
          {tournament?.id ? 'Update' : t('ADD_BUTTON')}
        </Button>
      ]}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Row
            className={'gx-flex-row'}
            style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
          >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <AInput
                controller={nameController}
                label={'Event Name'}
                type={'text'}
                mandatory
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={eventNumberController}
                label={'Event Number'}
                type={'text'}
                mandatory
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                label={t('INTEREST_GAME_VARIANT_LABEL')}
                controller={gameVariant}
                type={'text'}
                mandatory
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ADatePicker
                controller={dateController}
                mandatory
                label={t('INTEREST_STARTING_DATE_LABEL')}
                disabledDate={d =>
                  !!event &&
                  (!d ||
                    d.isBefore(event?.startDate) ||
                    d.isAfter(moment(event?.endDate).add(1, 'days')))
                }
                style={{ height: 32, width: '100%' }}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ATimePicker
                controller={timeController}
                mandatory
                label={t('INTEREST_STARTING_TIME_LABEL')}
                use12Hours={(t('USE_12_HOURS') as unknown) == true}
                minuteStep={30}
                style={{ height: 32, width: '100%' }}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={buyInController}
                label={'Buy-In'}
                type={'number'}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={feeController}
                label={'Fee'}
                type={'number'}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={startStackController}
                label={'Start Stack'}
                type={'number'}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={entriesController}
                label={'Entries'}
                type={'text'}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={levelDurationController}
                label={'Level duration (min)'}
                type={'number'}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={lateRegistrationLevelController}
                label={'Late Registration (level)'}
                type={'number'}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddTournamentModal;
