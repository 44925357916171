import { AInput } from '@components/AntdSimpleFieldForReactFormHooks';
import AutoCompleteField from '@components/AutoCompleteField';
import Currencies from '@old-world/common/sagas/static/Currencies';
import { Select } from 'antd';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type PokerRoomSectionFormShape = {
  entryFee: number;
  mainCurrency: number;
  minimumAgeEntrance: number;
  dressCode: string;
  tablesNumber: number;
};

const PokerRoomSection = ({
  control,
  isEditing
}: {
  isEditing: boolean;
  control: Control<PokerRoomSectionFormShape>;
}) => {
  const { t } = useTranslation();
  const currencyController = useController({
    control,
    name: 'mainCurrency',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  const dressCodeController = useController({
    control,
    name: 'dressCode',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  const minimumAgeEntranceController = useController({
    control,
    name: 'minimumAgeEntrance',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  const entryFeeController = useController({
    control,
    name: 'entryFee',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  const tablesNumberController = useController({
    control,
    name: 'tablesNumber',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  return (
    <>
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Entry Rules
      </p>
      <div className={'row'}>
        <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
          <AInput
            disabled={!isEditing}
            className={'d-flex flex-column'}
            id="tablesNumber"
            label={'Table Number'}
            mandatory
            type="tel"
            controller={tablesNumberController}
          />
          <AInput
            disabled={!isEditing}
            className={'d-flex flex-column mb-3'}
            id="minimumAgeEntrance"
            label={'Minimum Legal Age'}
            name="minimumAgeEntrance"
            mandatory
            type="tel"
            controller={minimumAgeEntranceController}
          />
        </div>
        <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
          <AInput
            disabled={!isEditing}
            className={'d-flex flex-column'}
            controller={dressCodeController}
            id="dressCode"
            label={'Dress Code'}
            mandatory
          />
          <AutoCompleteField
            disabled={!isEditing}
            controller={currencyController}
            className={'d-flex flex-column mb-3'}
            label={'Currency Used'}
            mandatory
          >
            {Currencies.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.id}
              </Select.Option>
            ))}
          </AutoCompleteField>
        </div>
        <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
          <AInput
            disabled={!isEditing}
            className={'d-flex flex-column mb-4'}
            id="entryFees"
            label="Entry Fees"
            name="entryFees"
            mandatory
            type="tel"
            controller={entryFeeController}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(PokerRoomSection);
