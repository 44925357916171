import { InfoCircleOutlined } from '@ant-design/icons';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { useAppDispatch } from '@old-world/flop-2.0/redux/useAppDispatch';
import { history } from '@old-world/redux';
import { loginWithEmailAndPassword } from '@redux-3/AuthenticationRedux';
import { ADMIN_MOBILE } from '@routes-3/routes';
import { Button, Form, Input, message as toast, Tooltip } from 'antd';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;

const SignInForm = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onSignin = async (data: { email: string; password: string }) => {
    setIsLoading(true);
    try {
      await dispatch(loginWithEmailAndPassword(data)).unwrap();
      if (isMobile) {
        history.push(ADMIN_MOBILE);
      } else {
        history.push('/');
      }

      toast.success(t('SIGNIN_ACTION_SUCCESS', { email: data.email }));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // @ts-ignore
      if (e.message === 'email_verification') {
        history.push(`/user-auth/email_unverified/${data.email}`);
        return null;
      }
      form.setFields([
        {
          name: 'email',
          errors: [
            'The password is invalid or the user does not have a password'
          ]
        },
        {
          name: 'password',
          errors: [
            'The password is invalid or the user does not have a password'
          ]
        }
      ]);
      console.log('Signin error: ', e);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{ remember: true }}
      name="basic"
      onFinish={onSignin}
      className="gx-signin-form gx-form-row0"
    >
      <FormItem
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!'
          },
          {
            required: true,
            message: 'Please input your E-mail!'
          }
        ]}
        name="email"
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          name="email"
        />
      </FormItem>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please input your Password!'
          }
        ]}
        name="password"
      >
        <div className={'gx-position-relative'}>
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
          <div
            className="gx-position-absolute"
            style={{ top: '6px', right: '-21px' }}
          >
            <Tooltip
              title={
                <>
                  <div>
                    <b>{t('PASS_RULE')}</b>
                  </div>
                  <div>- {t('UPPER_CASE')}</div>
                  <div>- {t('LOWER_CASE')}</div>
                  <div>- {t('NUMBER')}</div>
                </>
              }
            >
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </div>
        </div>
      </FormItem>
      <div className={'gx-d-flex gx-justify-content-end gx-mb-3'}>
        {/*@ts-ignore*/}
        <Link
          className="gx-login-form-forgot gx-text-underline"
          to="/user-auth/reset-password"
        >
          Forgot password
        </Link>
      </div>
      <FormItem className="gx-text-center">
        <Button
          type="primary"
          htmlType="submit"
          className={'w-100'}
          loading={isLoading}
        >
          Log in
        </Button>
      </FormItem>
    </Form>
  );
};

export default SignInForm;
