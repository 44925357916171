export default {
  ADD_PLAYER_FORM: 'add-player-form',
  ADD_TABLE_FORM: 'add-table-form',
  EDIT_TABLE_FORM: 'edit-table-form',
  CREATE_RUNNING_GAME_FORM: 'create-running-game-form',
  NOTIFICATION_SETTING_FORM: 'notification-setting-form',
  NOTIFY_USER_FORM: 'notify-user-form',
  PICK_GAME_SIZES_FORM: 'pick-game-sizes-form',
  MODAL_WIDTH: 700
};
