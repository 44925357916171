import { api } from '@api/api';
// import mockData from '@routes-3/main/myPokerRoom/dashboard/data/dashboardData.json';

export type StatisticsType = {
  numberOfPlayersSeated: {
    playerCounter: number;
  };
  averageTablesFillingRate: {
    average: number;
    segments: {
      date: string;
      playerCounter: number;
    }[];
  };
  waitingListRegistrations: {
    received: number;
    seated: number;
    notShowed: number;
    notCalled: number;
  };
  popularGamesIndicator: {
    gameVariant: string;
    bigBlind: number;
    smallBlind: number;
    ante: number;
    playerCounter: number;
    percentage: number;
  }[];
  averageSessionPerGame: {
    gameVariant: string;
    bigBlind: number;
    smallBlind: number;
    ante: number;
    playerCounter: number;
    tableOpened: number;
    cumulativePlayTime: number;
    averageRunningSessionTime: number;
  }[];
  playersLeaderboard: {
    gameVariant: string;
    bigBlind: number;
    smallBlind: number;
    ante: number | null;
    players: {
      flopId: number | null;
      profilePictureUrl: string | null;
      firstName: string;
      lastName: string;
      countryCode: string | null;
      playedGame: string;
      numberOfSessions: number;
      totalPlayTime: number;
    }[];
  }[];
};

const statisticsApi = api
  .enhanceEndpoints({ addTagTypes: ['Statistics'] })
  .injectEndpoints({
    endpoints: builder => ({
      statistics: builder.query<StatisticsType, { start: string; end: string }>(
        {
          providesTags: ['Statistics'],
          query: ({ start, end }) =>
            `/games/api/reporting/statistics?startDate=${start}&endDate=${end}`
          // queryFn: async () => {
          //   return {
          //     data: {
          //       ...mockData
          //     }
          //   };
          // }
        }
      )
    })
  });

export const { useStatisticsQuery } = statisticsApi;
