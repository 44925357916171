import React from 'react';

const GameSizeColumn = ({
  interestList
}: {
  interestList: { ante?: string; gameSize: string };
}) => {
  return interestList ? (
    <span>
      {interestList.gameSize}
      {interestList.ante && `/${interestList.ante}`}
    </span>
  ) : (
    <span>NO DATA</span>
  );
};

export default GameSizeColumn;
