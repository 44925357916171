import { InterestList } from '@api-3/endpoints/interestList';
import {
  useGetEstimatedPlayerCountQuery,
  useNotifyPlayersMutation
} from '@api-3/endpoints/targeting';
import ModalTitleWithGameInfo from '@components-3/ModalTitleWithGameInfo';
import { Game } from '@features/runningGames/types';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { Form, Modal } from 'antd';
import React, { useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import NotifyPlayersForm, { FormShape } from './NotifyPlayersForm';

type NotifyPlayersModalProps = {
  game: Game | InterestList;
  onClose: () => void;
};

type GameSize = {
  smallBlind: number;
  bigBlind: number;
};

const NotifyPlayersModal = ({ onClose, game }: NotifyPlayersModalProps) => {
  const { t } = useTranslation();
  const owner = useSelector(ownerSelector);
  const formMethods = useForm<FormShape>({
    defaultValues: {
      gameVariants: [],
      gameSizes: []
    }
  });

  const gameSizes = useWatch({
    control: formMethods.control,
    name: 'gameSizes',
    defaultValue: []
  }).map((gameSize: GameSize) => gameSize.smallBlind + '/' + gameSize.bigBlind);

  const distance = useWatch({
    control: formMethods.control,
    name: 'distance',
    defaultValue: 50
  });

  const gameVariants = useWatch({
    control: formMethods.control,
    name: 'gameVariants'
  });

  const { handleSubmit } = formMethods;

  const payload = useMemo(
    () => ({
      authorId: owner.id,
      authorRepresentativeId: game.casinoId,
      contextId: game.casinoId,
      entityId: game.id,
      'queryCriteria.gameSize': [game.gameSize].concat(gameSizes),
      'queryCriteria.gameVariants': [game.gameVariant].concat(gameVariants),
      'queryCriteria.latitude': game?.tables?.length
        ? game.tables[0].position.lat
        : game.position.lat,
      'queryCriteria.longitude': game?.tables?.length
        ? game.tables[0].position.lon
        : game.position.lon,
      'queryCriteria.maxDistance': distance,
      'queryCriteria.targetType': 'PLAYER',
      targetingNotificationType: 'FREE_SEAT',
      targetingType: 'PUSH'
    }),
    [distance, game, gameVariants, gameSizes, owner]
  );
  const { data: countOfPlayersEstimated } = useGetEstimatedPlayerCountQuery(
    payload
  );

  const defaultGameVariant = {
    smallBlind: parseInt(game?.gameSize?.split('/')[0], 10),
    bigBlind: parseInt(game?.gameSize?.split('/')[1], 10)
  };

  const [notifyPlayers] = useNotifyPlayersMutation();

  const onSubmit = () => {
    notifyPlayers(payload);
    onClose();
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={
        <ModalTitleWithGameInfo
          // @ts-ignore
          game={game}
          running
          title={t('NOTIFY PLAYERS')}
        />
      }
      onCancel={onClose}
      okText={t('SEND NOTIFICATION')}
      okButtonProps={{
        className: 'gx-btn',
        type: 'default',
        disabled: countOfPlayersEstimated === 0
      }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      onOk={formMethods.handleSubmit(onSubmit)}
      closable={false}
      afterClose={formMethods.reset}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <NotifyPlayersForm
            defaults={{
              ...defaultGameVariant,
              gameVariant: game.gameVariant
            }}
            countOfPlayersEstimated={countOfPlayersEstimated || 0}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default NotifyPlayersModal;
