import { api } from '@api/api';
import { parseParams } from '@old-world/utils/ApiUtils';

export type CreateEventPayload = {
  casinoId: string;
  name: string;
  website: string;
  startDate: string;
  endDate: string;
  published: boolean;
};

export type ListEventsResult = {
  content: ListEventItem[];
  empty: boolean;
  number: number;
  numberOfElements: number;
  totalElements: number;
};

export type ListEventItem = {
  address: string;
  casinoId: string;
  city: string;
  country: string;
  distance: number;
  distanceUnit: string;
  endDate: string;
  id: string;
  name: string;
  position: { lat: number; lon: number };
  startDate: string;
  website: string;
  published: boolean;
};

export type EventBannerType = {
  festivalId: string;
  id: string;
  resizedUrl: string;
  url: string;
};

export type CreateTournamentPayload = {
  date: string;
  eventNumber: string;
  buyIn: number;
  fee: number;
  gameVariant: string;
  name: string;
  startStack: number;
  entries: string;
  levelDuration: number;
  lateRegistrationLevel: number;
};

export type EventTournamentsPerDay = {
  date: string;
  children: EventTournament[];
};

export type DailyTournamentsPerDay = {
  date: string;
  children: DailyTournament[];
};

export type DailyTournament = {
  buyIn: number;
  date: string;
  entries: string;
  eventNumber: string;
  fee: number;
  gameVariant: string;
  id: string;
  lateRegistrationLevel: number;
  levelDuration: number;
  name: string;
  startStack: number;
};

export type EventTournament = DailyTournament & {
  festivalId: string;
};

export enum TOURNAMENT_STATUS {
  ALL = 'ALL',
  UPCOMING = 'UPCOMING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED'
}

const festivalsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'Events',
      'Event',
      'EventBanner',
      'Tournament',
      'Tournaments',
      'Daily-Tournaments'
    ]
  })
  .injectEndpoints({
    endpoints: builder => ({
      getAllEvents: builder.query<
        ListEventsResult,
        {
          queryParams: { page: number; size: number; status?: string };
          casinoId: string;
        }
      >({
        providesTags: ['Events'],
        query: ({ queryParams, casinoId }) =>
          `/games/api/festivals/casino/${casinoId}/list?${parseParams(
            queryParams
          )}`
      }),
      getEvent: builder.query<ListEventItem, { festivalId: string }>({
        providesTags: ['Event'],
        query: ({ festivalId }) => `/games/api/festivals/${festivalId}`
      }),
      createEvent: builder.mutation<void, { body: CreateEventPayload }>({
        invalidatesTags: ['Events', 'EventBanner'],
        query: params => ({
          method: 'POST',
          url: `/games/api/festivals`,
          body: params.body
        }),
        transformResponse: (response: any, data: any) => {
          const split = data.response.headers.get('location').split('/');
          return split[split.length - 1];
        }
      }),
      updateEvent: builder.mutation<
        void,
        { body: CreateEventPayload; festivalId: string }
      >({
        invalidatesTags: ['Event', 'Events', 'EventBanner'],
        query: params => ({
          method: 'PUT',
          url: `/games/api/festivals/${params.festivalId}`,
          body: params.body
        })
      }),
      updateEventBanner: builder.mutation<
        void,
        { data: unknown; festivalId: string }
      >({
        invalidatesTags: ['EventBanner'],
        query: ({ festivalId, data }) => ({
          method: 'POST',
          url: `/gallery/api/festival/${festivalId}`,
          body: data
        })
      }),
      getEventBanner: builder.query<EventBannerType, { festivalId: string }>({
        providesTags: ['EventBanner'],
        query: ({ festivalId }) => `/gallery/api/festival/${festivalId}`
      }),
      deleteEvent: builder.mutation<void, { festivalId: string }>({
        invalidatesTags: ['Events', 'EventBanner'],
        query: ({ festivalId }) => ({
          method: 'DELETE',
          url: `/games/api/festivals/${festivalId}`
        })
      }),
      getTournamentsCount: builder.query<number, { festivalId: string }>({
        providesTags: ['Tournaments'],
        query: ({ festivalId }) =>
          `/games/api/festivals/${festivalId}/tournaments/count`
      }),
      createTournament: builder.mutation<
        void,
        { festivalId: string; body: CreateTournamentPayload }
      >({
        invalidatesTags: ['Tournaments'],
        query: params => ({
          method: 'POST',
          url: `/games/api/festivals/${params.festivalId}/tournament`,
          body: params.body
        })
      }),
      updateTournament: builder.mutation<
        void,
        {
          body: CreateTournamentPayload;
          festivalId: string;
          tournamentId: string;
        }
      >({
        invalidatesTags: ['Tournaments', 'Tournament'],
        query: params => ({
          method: 'PUT',
          url: `/games/api/festivals/${params.festivalId}/tournament/${params.tournamentId}/informations`,
          body: params.body
        })
      }),
      deleteTournament: builder.mutation<
        void,
        { festivalId: string; tournamentId: string }
      >({
        invalidatesTags: ['Events', 'Tournaments'],
        query: ({ festivalId, tournamentId }) => ({
          method: 'DELETE',
          url: `/games/api/festivals/${festivalId}/tournament/${tournamentId}`
        })
      }),
      getAllTournaments: builder.query<
        EventTournamentsPerDay[],
        {
          festivalId: string;
        }
      >({
        providesTags: ['Tournaments'],
        query: ({ festivalId }) =>
          `/games/api/festivals/${festivalId}/tournaments`
      }),
      createDailyTournament: builder.mutation<
        void,
        { body: CreateTournamentPayload }
      >({
        invalidatesTags: ['Daily-Tournaments'],
        query: params => ({
          method: 'POST',
          url: `/games/api/daily-tournament`,
          body: params.body
        })
      }),
      getDailyTournamentsListByStatus: builder.query<
        DailyTournamentsPerDay[],
        {
          casinoId: string;
          tournamentStatus: TOURNAMENT_STATUS;
        }
      >({
        providesTags: ['Daily-Tournaments'],
        query: ({ casinoId, tournamentStatus }) =>
          `/games/api/daily-tournament/${casinoId}/${tournamentStatus}`
      }),
      updateDailyTournament: builder.mutation<
        void,
        {
          body: CreateTournamentPayload;
          tournamentId: string;
        }
      >({
        invalidatesTags: ['Daily-Tournaments'],
        query: params => ({
          method: 'PUT',
          url: `/games/api/daily-tournament/${params.tournamentId}/informations`,
          body: params.body
        })
      }),
      deleteDailyTournament: builder.mutation<void, { tournamentId: string }>({
        invalidatesTags: ['Daily-Tournaments'],
        query: ({ tournamentId }) => ({
          method: 'DELETE',
          url: `/games/api/daily-tournament/${tournamentId}`
        })
      })
    })
  });

export const {
  useGetAllEventsQuery,
  useCreateEventMutation,
  useUpdateEventBannerMutation,
  useGetEventBannerQuery,
  useGetTournamentsCountQuery,
  useDeleteEventMutation,
  useUpdateEventMutation,
  useGetEventQuery,
  useCreateTournamentMutation,
  useUpdateTournamentMutation,
  useDeleteTournamentMutation,
  useGetAllTournamentsQuery,
  useCreateDailyTournamentMutation,
  useGetDailyTournamentsListByStatusQuery,
  useUpdateDailyTournamentMutation,
  useDeleteDailyTournamentMutation
} = festivalsApi;
