import {
  useSeatIdPlayerMutation,
  useStackPlayerMutation
} from '@api-3/endpoints/games';
import { useGetPlayerProfileInfoQuery } from '@api-3/endpoints/player';
import PlayerName from '@components-3/PlayerName';
import DealerActionButton from '@routes-3/main/cashGameManagement/dealerDisplay/modal/DealerActionButton';
import DealerPlayerForm from '@routes-3/main/cashGameManagement/dealerDisplay/modal/DealerPlayerForm';
import {
  Table,
  TablePlayers
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Button, Form, Modal, Spin } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
  table: Table;
  seatNr: number;
  seatedPlayer?: TablePlayers;
};

type SelectedPlayer = {
  id: string;
  stack: number;
};
const DealerActionModal = ({ onClose, table, seatNr, seatedPlayer }: Props) => {
  const {
    data: seatedPlayerProfile,
    isLoading: seatedPlayerProfileIsLoading
  } = useGetPlayerProfileInfoQuery(
    // @ts-ignore
    { userId: seatedPlayer?.flopId?.toString() },
    { skip: !seatedPlayer }
  );
  const formMethods = useForm<SelectedPlayer>();
  const [stackPlayer] = useStackPlayerMutation();
  const [seatIdPlayer] = useSeatIdPlayerMutation();

  const onSubmit = async (data: SelectedPlayer) => {
    if (data.id) {
      await seatIdPlayer({
        playerId: data.id,
        seatId: seatNr
      }).unwrap();
      if (data.stack) {
        await stackPlayer({
          playerId: data.id,
          stack: data.stack
        }).unwrap();
      }
      onClose();
    }
  };

  const PlayerNameComponent = () => {
    // @ts-ignore
    return (
      <div className={'ml-1'}>
        <PlayerName
          player={{
            // @ts-ignore
            firstName: seatedPlayerProfile?.firstName,
            // @ts-ignore
            lastName: seatedPlayerProfile?.lastName,
            name: seatedPlayer?.name
          }}
          showFullName={false}
          textSize={18}
        />
      </div>
    );
  };

  const title = `Seat Nr: ${seatNr}`;
  const ModalTitle = () => (
    <div className={'gx-flex-row'}>
      {title}
      {seatedPlayer && ` - `} <PlayerNameComponent />
    </div>
  );

  return (
    <Modal
      open={true}
      centered
      title={<ModalTitle />}
      width={'35%'}
      closable={false}
      footer={[
        <Button onClick={onClose} className="">
          Cancel
        </Button>,
        !seatedPlayer && (
          <Button
            onClick={formMethods.handleSubmit(onSubmit)}
            className="gx-btn"
            loading={false}
          >
            Sit
          </Button>
        )
      ]}
    >
      {seatedPlayerProfileIsLoading ? (
        <div className={'gx-text-center'}>
          <Spin />
        </div>
      ) : seatedPlayer ? (
        <DealerActionButton onClose={onClose} seatedPlayer={seatedPlayer} />
      ) : (
        // @ts-ignore
        <FormProvider {...formMethods}>
          <Form
            onFinish={formMethods.handleSubmit(onSubmit)}
            layout="vertical"
            style={{ width: '100%' }}
          >
            <DealerPlayerForm
              formMethods={formMethods}
              players={table.players}
            />
          </Form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default DealerActionModal;
