import { useCancelInterestListMutation } from '@api-3/endpoints/interestList';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

export default function useCancelInterestList() {
  const [cancelInterestListRequest] = useCancelInterestListMutation();
  const { t } = useTranslation();

  return async (id: string) => {
    try {
      await cancelInterestListRequest({
        gameId: id
      }).unwrap();
      notification.success({
        message: 'Success',
        description: t('DELETE_INTEREST_LIST_ACTION_SUCCESS'),
        placement: 'topRight'
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: t('GLOBAL_ERROR_MESSAGE', {
          action: t('DELETE_INTEREST_LIST_ACTION_ERROR')
        }),
        placement: 'topRight'
      });
    }
  };
}
