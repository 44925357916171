import { useInviteUserMutation } from '@api-3/endpoints/userManagement';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import Form from '@components/forms/Form';
import MessagesAction from '@old-world/common/redux/SystemMessagesRedux';
import { customerSelector } from '@old-world/features/customers/redux/CustomersRedux';
import { RoleType } from '@old-world/features/users/models/UsersModel';
import i18n from '@old-world/i18n';
import { Modal } from 'antd';
import { Select } from 'antd/es';
import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  customerId: string;
};

export const Roles = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'POKER_ROOM_MANAGER', label: 'Poker Room Manager' }
];

const InviteUserModal = ({ onClose, customerId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector(customerSelector);
  const [inviteUser, { isLoading: isSaving }] = useInviteUserMutation();

  const { control, handleSubmit } = useForm<{
    emailAddress: string;
    role: RoleType;
  }>({ defaultValues: { emailAddress: '', role: 'ADMIN' } });
  const mailController = useController({
    control,
    name: 'emailAddress',
    rules: {
      required: { value: true, message: t('VALIDATOR_REQUIRED')! },
      pattern: {
        message: '',
        value: /^.*@.*\.([a-zA-Z]{2,5})$/
      }
    }
  });

  const roleController = useController({
    control,
    name: 'role',
    rules: { required: { value: true, message: t('VALIDATOR_REQUIRED')! } }
  });

  const onSave = async (data: any) => {
    const payload = {
      email: data.emailAddress,
      role: data.role,
      customerId,
      casinoId: customer.casinoId,
      customerType: customer.type
    };

    try {
      await inviteUser({ customerId, body: payload }).unwrap();
      dispatch(
        MessagesAction.addMessage(
          'INVITE_USER_SUCCESS',
          'SUCCESS',
          i18n.t('INVITE_USER_SUCCESS', {
            emailAddress: data.emailAddress
          }),
          '',
          'PANEL'
        )
      );
      onClose();
    } catch (e) {
      dispatch(
        MessagesAction.addMessage(
          'INVITE_USER_ERROR',
          'ERROR',
          i18n.t('GLOBAL_ERROR_MESSAGE', {
            action: i18n.t('INVITE_USER_ACTION_ERROR')
          }),
          '',
          'PANEL'
        )
      );
    }
  };

  return (
    <Modal
      open={true}
      onCancel={onClose}
      centered
      title={t('USERS_INVITE_COLLABORATOR')}
      okText={t('SEND_BUTTON')}
      onOk={handleSubmit(onSave)}
      okButtonProps={{ loading: isSaving }}
      cancelText={t('CANCEL_BUTTON')}
    >
      <Form
        onSubmit={handleSubmit(onSave)}
        className="form d-flex flex-column flex-fill"
      >
        <AInput
          className={'d-flex flex-column mb-2'}
          label={t('USERS_INVITE_EMAIL_ADDRESS_LABEL')}
          hasFeedback
          mandatory
          controller={mailController}
        />
        <ASelect
          className={'d-flex flex-column'}
          label={t('USERS_INVITE_ROLE_LABEL')}
          controller={roleController}
          style={{ width: '150px' }}
          mandatory
        >
          {Roles.map((role: any, index: number) => (
            <Select.Option key={index} value={role.value}>
              {role.label}
            </Select.Option>
          ))}
        </ASelect>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
