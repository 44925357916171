import GameVariant from '@constants/gamevariants';
import { MAIN_FOUR_GAME_VARIANTS } from '@features/notifyPlayers/constants';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  gameVariant: string;
  onPick: (gameVariants: string[]) => void;
  initialOtherVariants: string[];
};
const PickGameVariantsItem = ({
  onPick,
  gameVariant,
  initialOtherVariants
}: Props) => {
  const { t } = useTranslation();
  const CheckboxGroup = Checkbox.Group;
  const [checkedList, setCheckedList] = useState<string[]>([]);

  useEffect(() => {
    setCheckedList([...initialOtherVariants]);
  }, [initialOtherVariants]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list as string[]);
    onPick(list as string[]);
  };

  const _GameVariant = GameVariant.filter(
    variant =>
      variant.label !== gameVariant &&
      !MAIN_FOUR_GAME_VARIANTS.includes(variant.label)
  ).map(item => item.label);
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ width: '100%' }}
    >
      <div className="d-line">
        <div className="line" />
        <p>{t('OTHER_VARIANTS')}</p>
        <div className="line" />
      </div>
      <CheckboxGroup
        className="checkbox-list"
        options={_GameVariant}
        value={checkedList}
        onChange={onChange}
      />
    </div>
  );
};

export default React.memo(PickGameVariantsItem);
