import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetTableForRunningGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetTableForRunningGame';
import useGetWaitingListForGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetWaitingListForGame';
import { Modal } from 'antd';
import React from 'react';

import GamePlayers from './GamePlayers';

export interface SeatedPlayersModalProps {
  game: RunningGame;
  table: Table;
  expectedWaitingTime: string | undefined;
  onClose: () => void;
}

function TablePlayersModal({
  game,
  table,
  expectedWaitingTime,
  onClose
}: SeatedPlayersModalProps) {
  const { seatedPlayers } = useGetTableForRunningGame({
    gameId: game.id,
    tableId: table.id
  });

  const { players: waitingPlayersProfiles } = useGetWaitingListForGame({
    gameId: game.id
  });

  return (
    <Modal
      open={true}
      centered
      destroyOnClose
      onCancel={onClose}
      className="w-75"
      okButtonProps={{
        id: 'add-player'
      }}
      footer={null}
      title={
        table
          ? '#' + table.tableId + ' ' + table.gameVariant + ' ' + table.gameSize
          : ''
      }
    >
      {!table ? null : (
        <GamePlayers
          acceptedPlayers={seatedPlayers ?? []}
          game={game}
          waitingListPlayers={waitingPlayersProfiles}
          table={table}
          maxPlayers={table?.maxPlayers}
          expectedWaitingTime={expectedWaitingTime}
        />
      )}
    </Modal>
  );
}

export default TablePlayersModal;
