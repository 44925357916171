import {
  PlayerProfile,
  useGetPlayerLocationQuery,
  useGetPlayerProfileInfoQuery
} from '@api-3/endpoints/player';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { casinoFromListSelector } from '@old-world/features/casinos/redux/CasinosRedux';
import { useSelector } from 'react-redux';

export default function usePlayerProfile(userId: string) {
  const casinoId = useSelector(currentCasinoSelector);
  // @ts-ignore
  const casino = useSelector(state => casinoFromListSelector(state, casinoId));
  const { data: profile, isLoading, isFetching } = useGetPlayerProfileInfoQuery(
    { userId: userId },
    { skip: !userId }
  );
  const { data: position } = useGetPlayerLocationQuery(
    {
      userId,
      casinoLat: casino?.position?.lat,
      distanceUnit: 'KILOMETERS',
      casinoLon: casino?.position?.lon
    },
    { skip: !userId }
  );

  const data: {
    data: PlayerProfile;
    position: {
      content: {
        knowAt: string;
        latitude: number;
        longitude: number;
      };
      distance: {
        metric: 'KILOMETERS' | 'FEET';
        value: number;
      };
    };
  } = {
    position: position || {},
    data: profile || {}
    //TODO: Change type def.
  } as any;
  return { data, isLoading, isFetching };
}
