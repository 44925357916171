import DefaultProfile from '@assets-3/images/default_profile_picture.png';
import { Avatar, message, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import React from 'react';

import EditableImage from './EditableImage';

function beforeUpload(file: File) {
  const isJPG = file.type === 'image/jpeg';
  const isPNG = file.type === 'image/png';

  if (!isJPG && !isPNG) {
    message.error('You can only upload JPG or PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  // Return false for manually upload
  return false;
}

export interface AvatarUploaderProps {
  disabled?: boolean;
  previewWidth: number;
  onChange: (image: any) => void;
  profilePicture?: string;
}

export default function AvatarUploader({
  disabled,
  previewWidth,
  onChange,
  profilePicture
}: AvatarUploaderProps) {
  function handleChange(info: UploadChangeParam) {
    onChange(info.file);
  }

  return (
    <Upload
      accept=".png,.jpg"
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      disabled={disabled}
    >
      <EditableImage
        circle
        editable={!disabled}
        size={previewWidth}
        src={profilePicture}
      >
        {({ src, size }) => <Avatar size={size} src={src || DefaultProfile} />}
      </EditableImage>
    </Upload>
  );
}
