// a library to wrap and simplify api calls
import { ApiResponseType } from '../../../common/models/index.d';
import { api } from '../../../services/Api';
import CustomersConstants from '../constants/CustomersConstants';

// our "constructor"
const create = () => {
  /**
   * Fetch customers details
   * @param id
   */
  const fetchCustomerDetails = (
    id: string
  ): Promise<ApiResponseType<CustomerApiDefinitions.Customer>> =>
    api.get(`${CustomersConstants.FETCH_CUSTOMER_DETAILS}/${id}`);

  /**
   * Fetch update profile details
   * @param id
   */
  const updateCustomerProfile = (
    id: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
  ): Promise<ApiResponseType<CustomerApiDefinitions.Customer>> =>
    api.put(`/accounts/api/customer/${id}/profile`, {
      firstName,
      lastName,
      phoneNumber
    });

  return {
    fetchCustomerDetails,
    updateCustomerProfile
  };
};

// let's return back our create method as the default.
// Export Default
export default {
  create
};
