import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import AnnouncementsPageContent from '@routes-3/main/myPokerRoom/announcements/components/AnnouncementsPageContent';
import React from 'react';

const AnnouncementsPage = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Announcements'}
      />
      <AnnouncementsPageContent />
    </LayoutContentContainer>
  );
};

export default AnnouncementsPage;
