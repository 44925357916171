import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import ComingSoonPage from '@old-world/common/containers/ComingSoonPage';
import React from 'react';

const AdsMonitoringPage = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Ads Monitoring'}
      />
      <ComingSoonPage />
    </LayoutContentContainer>
  );
};

export default AdsMonitoringPage;
