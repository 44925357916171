import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { TableFormShape } from '@components/forms/TableFieldArray';
import { Col, Row, Select, Tooltip } from 'antd';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TableForm = ({
  index,
  fields,
  remove
}: {
  index: number;
  fields: TableFormShape[];
  remove: (idx: number) => void;
}) => {
  const { control, getValues } = useFormContext<{
    tables: {
      tableId: string;
      maxPlayers: number;
    }[];
  }>();
  const { t } = useTranslation();
  const tableCapacity = useController({
    control,
    name: `tables.${index}.maxPlayers`,
    rules: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      required: t('VALIDATOR_REQUIRED')! as string
    }
  });
  const tableId = useController({
    control,
    name: `tables.${index}.tableId`,
    rules: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      required: t('VALIDATOR_REQUIRED')! as string,
      minLength: 1,
      maxLength: 10,
      validate: {
        unique: (val: string) => {
          return (
            getValues('tables').filter(
              // @ts-ignore
              (table: TableFormShape) => table.tableId == val.toString()
            ).length == 1
          );
        }
      }
    }
  });

  return (
    <Row
      className={'gx-flex-row'}
      style={{ marginLeft: -16, position: 'relative' }}
    >
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <AInput
          controller={tableId}
          label={t('TABLE_ID')}
          type="text"
          maxLength={10}
          mandatory
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <ASelect
          controller={tableCapacity}
          label={t('TABLE_CAPACITY')}
          // @ts-ignore
          defaultValue={{ value: 8 }}
          mandatory
        >
          {[6, 7, 8, 9, 10].map((num: number) => (
            <Select.Option key={num} value={num}>
              {num}
            </Select.Option>
          ))}
        </ASelect>
      </Col>
      {fields.length > 1 && (
        <Tooltip title={t('REMOVE_TABLE')} placement="bottom">
          <i
            className={'icon icon-close-circle'}
            style={{
              position: 'absolute',
              right: -12,
              top: 37,
              zIndex: 2,
              cursor: 'pointer'
            }}
            onClick={() => {
              remove(index);
            }}
          />
        </Tooltip>
      )}
    </Row>
  );
};
export default TableForm;
