import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import {
  faPen,
  faPlay,
  faTrashCan,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import {
  Template,
  useDeleteTemplateMutation,
  useGetTemplatesQuery
} from '@old-world/flop-3.0/api/endpoints/templates';
import { useModals } from '@providers/ModalProvider';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import GameVariantColumn from '@routes-3/main/cashGameManagement/columns/GameVariantColumn';
import { Button, Card, message as toast, Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const templateListColumns: ColumnProps<any>[] = [
  {
    key: 'variant',
    title: 'Variant',
    render: (template: Template) => {
      return <GameVariantColumn interestList={template} />;
    }
  },
  {
    key: 'size',
    title: 'Size',
    render: (template: Template) => {
      return (
        <span>
          {template.smallBlind} / {template.bigBlind}{' '}
          {template.ante && `/${template.ante}`}
        </span>
      );
    }
  },
  {
    key: 'buyIn',
    title: 'Buy In (Min - Max)',
    render: (template: Template) => {
      return (
        <div>
          {template.minBuyIn ? formatBuyIn(template.minBuyIn) : 'NO'} -{' '}
          {template.maxBuyIn ? formatBuyIn(template.maxBuyIn) : 'NO'}
        </div>
      );
    }
  },
  {
    key: 'players',
    title: 'Players',
    render: (template: Template) => {
      return template?.players?.length ? (
        <span className="gx-font-weight-heavy">
          {template?.players?.length}
          <FontAwesomeIcon
            icon={faUser}
            className={'gx-text-primary ml-1'}
            style={{ fontSize: 12, position: 'relative', bottom: 1 }}
          />
        </span>
      ) : (
        <span style={{ opacity: 0.3, fontSize: 12 }}>NO DATA</span>
      );
    }
  },
  {
    key: 'tableId',
    title: 'Table ID',
    render: (template: Template) => {
      return (
        template.tableId || (
          <span style={{ opacity: 0.3, fontSize: 12 }}>NO DATA</span>
        )
      );
    }
  },
  {
    key: 'capacity',
    title: 'Table capacity',
    render: (template: Template) => {
      return (
        template.maxPlayers || (
          <span style={{ opacity: 0.3, fontSize: 12 }}>NO DATA</span>
        )
      );
    }
  },
  {
    key: 'actions',
    title: '',
    className: 'text-right action-buttons-column',
    render: (template: Template) => {
      return <Actions template={template} />;
    }
  }
];

const Actions = ({ template }: { template: Template }) => {
  const { t } = useTranslation();
  const [deleteTemplate] = useDeleteTemplateMutation();
  const openModal = useModals();
  return (
    <>
      <FontAwesomeIcon
        style={{ fontSize: 18 }}
        className={'cursor-pointer gx-text-success mr-3'}
        icon={faPlay}
        onClick={() => {
          openModal({
            type: 'CREATE_GAME',
            props: {
              templateId: template.id,
              onSuccess: () => {
                toast.success(
                  <span>
                    Game was created{' '}
                    <a href={'/admin/cash-game/running-games'}>here</a>
                  </span>
                );
              }
            }
          });
        }}
      />

      <FontAwesomeIcon
        style={{ fontSize: 18 }}
        className={'gx-text-primary cursor-pointer mr-3'}
        icon={faPen}
        onClick={() => {
          openModal({
            type: 'CREATE_UPDATE_TEMPLATE',
            props: {
              template
            }
          });
        }}
      />

      <FontAwesomeIcon
        style={{ fontSize: 18 }}
        className={'gx-text-danger cursor-pointer mr-3'}
        icon={faTrashCan}
        onClick={() => {
          Modal.confirm({
            title: t('REMOVE_TEMPLATE_TEXT'),
            okText: t('REMOVE_BUTTON'),
            okType: 'danger',
            cancelText: t('NO_BUTTON'),
            async onOk() {
              await deleteTemplate({ id: template.id });
            }
          });
        }}
      />
    </>
  );
};

export default function Templates() {
  const casinoId = useSelector(currentCasinoSelector);
  const openModal = useModals();
  const { data, isLoading } = useGetTemplatesQuery(
    { casinoId },
    { pollingInterval: 5000 }
  );

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Cash Game Management'}
        pageIcon={
          <i
            className="icon icon-dasbhoard"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Templates'}
      />
      <div className={'d-flex flex-row justify-content-between pr-3'}>
        <div className={'gx-font-weight-bold'}>Template</div>
        <Button
          className={'gx-btn'}
          key="submit"
          type="default"
          style={{ position: 'relative', top: -8, marginBottom: 0 }}
          onClick={() => {
            openModal({
              type: 'CREATE_UPDATE_TEMPLATE',
              props: {}
            });
          }}
        >
          Create New Template
        </Button>
      </div>
      {data && data.content.length > 0 ? (
        <Card
          className="gx-layouts-view mt-3"
          style={{ height: 'calc(100vh - 278px )', overflowY: 'scroll' }}
        >
          <Table
            className="gx-table w-100 "
            dataSource={data?.content}
            columns={templateListColumns}
            size={'large'}
            rowKey={(record: any) => record.id}
            pagination={{
              defaultPageSize: 10,
              total: data?.totalElements,
              showSizeChanger: false
            }}
          />
        </Card>
      ) : (
        <Card
          className={'mt-3'}
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            display: 'flex'
          }}
        >
          <h2>No Poker Cash Templates yet!</h2>
          <p>Here's your stage to create some action!</p>
          <ol
            style={{
              textAlign: 'left',
              marginBottom: '20px'
            }}
          >
            <li>
              <b>Set Up Templates</b>:
            </li>
            <li>
              <b>Create your template</b>: Whether it's Texas Hold'em, Omaha, or
              any other variation, set the rules and let the games begin.
            </li>
          </ol>
          <div>
            <Button
              className={'gx-btn'}
              key="submit"
              type="default"
              loading={isLoading}
              onClick={() => {
                openModal({
                  type: 'CREATE_UPDATE_TEMPLATE',
                  props: {}
                });
              }}
            >
              Add Template
            </Button>
          </div>
        </Card>
      )}
    </LayoutContentContainer>
  );
}
