import flopLogo from '@assets-3/images/flop-pm-logo.png';
import { parseParam } from '@old-world/utils/LocationUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';

const ResetPasswordSuccessPage = () => {
  const { t } = useTranslation();
  const { location } = useReactRouter();
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background:
                'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)',
              height: '380px'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div>Welcome on Flop PM</div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">
                {t('EMAIL_SENT_TITLE')}
              </h1>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t('RESET_PASSWORD_SUCCEED_TEXT', {
                    addressEmail: parseParam(location, 'email')
                  })
                }}
              />
            </div>
            <h4 className={'gx-text-center'}>
              {/*@ts-ignore*/}
              <Link
                className="gx-text-underline"
                style={{ color: '#4F8DCA' }}
                to="/"
              >
                Go to Login
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessPage;
