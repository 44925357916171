import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import {
  setThemeColor,
  setThemeType,
  THEME_COLOR,
  THEME_TYPE
} from '@old-world/utils/ThemeUtils';
import { Modal, Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';

export const ACTIVE_COLOR_OPTION = '#ffffff';
export const LIGHT_PURPLE_SEC = '#00B378';
export const RED_SEC = '#00D9C9';
export const BLUE_SEC = '#FCB53B';
export const LIGHT_PURPLE_1_SEC = '#E14594';
export const DARK_BLUE_SEC = '#17BDE5';
export const ORANGE_SEC = '#F1D065';
export const LIGHT_BLUE_SEC = '#59DCFF';
export const LIGHT_PURPLE_2_SEC = '#64D7D6';
export const DEEP_ORANGE_SEC = '#70A288';

type Props = {
  onClose: () => void;
};
const ThemeSettingsModal = ({ onClose }: Props) => {
  const [typeValue, setTypeValue] = useState(localStorage.getItem('themeType'));
  const [colorValue, setColorValue] = useState(
    localStorage.getItem('themeColor')
  );

  const onThemeTypeChange = (e: RadioChangeEvent) => {
    setTypeValue(e.target.value);
    setThemeType(e.target.value);
  };

  const handleThemeColor = (fileName: THEME_COLOR) => {
    setColorValue(fileName);
    setThemeColor(fileName);
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={'Theme Settings'}
      onCancel={onClose}
      cancelText={'close'}
      okButtonProps={{ style: { display: 'none' } }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      closable={true}
    >
      <div className={'text-center'}>
        <Radio.Group value={typeValue} onChange={onThemeTypeChange}>
          <Radio.Button value={THEME_TYPE.LITE}>Lite</Radio.Button>
          <Radio.Button value={THEME_TYPE.SEMI_DARK}>Semi Dark</Radio.Button>
          <Radio.Button value={THEME_TYPE.DARK}>Dark</Radio.Button>
        </Radio.Group>
        {typeValue !== THEME_TYPE.DARK && (
          <>
            <hr />
            <ul className="gx-color-option gx-list-inline">
              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.LIGHT_PURPLE)}
                  style={{
                    backgroundColor: LIGHT_PURPLE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-light-purple ${colorValue ===
                    THEME_COLOR.LIGHT_PURPLE && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.RED)}
                  style={{
                    backgroundColor: RED_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-red ${colorValue ===
                    THEME_COLOR.RED && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.BLUE)}
                  style={{
                    backgroundColor: BLUE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-blue ${colorValue ===
                    THEME_COLOR.BLUE && 'active'}`}
                />
              </li>
              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.DARK_BLUE)}
                  style={{
                    backgroundColor: DARK_BLUE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-dark-blue ${colorValue ===
                    THEME_COLOR.DARK_BLUE && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.ORANGE)}
                  style={{
                    backgroundColor: ORANGE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-orange ${colorValue ===
                    THEME_COLOR.ORANGE && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.LIGHT_BLUE)}
                  style={{
                    backgroundColor: LIGHT_BLUE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-light-blue ${colorValue ===
                    THEME_COLOR.LIGHT_BLUE && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.DEEP_ORANGE)}
                  style={{
                    backgroundColor: DEEP_ORANGE_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-deep-orange ${colorValue ===
                    THEME_COLOR.DEEP_ORANGE && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.LIGHT_PURPLE_1)}
                  style={{
                    backgroundColor: LIGHT_PURPLE_1_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-light-purple1 ${colorValue ===
                    THEME_COLOR.LIGHT_PURPLE_1 && 'active'}`}
                />
              </li>

              <li>
                <span
                  onClick={() => handleThemeColor(THEME_COLOR.LIGHT_PURPLE_2)}
                  style={{
                    backgroundColor: LIGHT_PURPLE_2_SEC,
                    color: ACTIVE_COLOR_OPTION
                  }}
                  className={`gx-link gx-color-light-purple2 ${colorValue ===
                    THEME_COLOR.LIGHT_PURPLE_2 && 'active'}`}
                />
              </li>
            </ul>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ThemeSettingsModal;
