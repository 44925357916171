import {
  useGetRegisteredPlayersQuery,
  useRemovePlayerFromInterestListMutation
} from '@api-3/endpoints/interestList';
import { PlayerProfile, PlayerSearchProfile } from '@api-3/endpoints/player';
import {
  useAddPlayerToSubGroupMutation,
  useRemovePlayerFromSubGroupMutation
} from '@api-3/endpoints/subGroup';
import { usePlacePlayer } from '@components-3/addPlayerModal/PlayerModalServices';
import PlayerInfo from '@components-3/PlayerInfo';
import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Button, Row } from 'antd';
import { useState } from 'react';

type PlayerRowItemProps = {
  game?: RunningGame;
  player: PlayerSearchProfile & Omit<PlayerProfile, 'id'>;
  table?: Table;
  isInterestList?: boolean;
  registeredPlayerSubGroup?: number[];
  subGroupId?: number;
  setSubGroupPlayersIds?: (players: number[]) => void;
  text?: string;
  onChange?: (player: PlayerSearchProfile) => void;
  addToSubGroup?: boolean;
};
const PlayerRowItem = ({
  game,
  player,
  table,
  isInterestList = false,
  registeredPlayerSubGroup,
  subGroupId,
  setSubGroupPlayersIds,
  text,
  onChange,
  addToSubGroup
}: PlayerRowItemProps) => {
  const [isInSubGroup, setIsInSubGroup] = useState<boolean>(
    !!registeredPlayerSubGroup?.includes(player.id)
  );
  const {
    onSubmit: onPlacePlayerSubmit,
    isSaving: isPlacePlayerSaving
  } = usePlacePlayer({
    isInterestList: isInterestList,
    table,
    gameId: isInterestList && game ? game?.id : game ? game?.liveGameKey : ''
  });

  const [removePlayer] = useRemovePlayerFromSubGroupMutation();
  const [addPlayer] = useAddPlayerToSubGroupMutation();
  const isSat = table?.players?.find(p => p.flopId == player.id);

  const { data: registeredMembers } = useGetRegisteredPlayersQuery(game!, {
    skip: !isInterestList
  });
  const isRegistered = registeredMembers?.find(p => p.accountId === player.id);
  const [
    removePlayerFromInterestList,
    { isLoading: isSavingRemovePlayer }
  ] = useRemovePlayerFromInterestListMutation();

  const isWaiting = game?.waitingPlayers?.find(p => p === player.id.toString());
  const isRegisteredSubGroup = registeredPlayerSubGroup?.find(
    id => id === player.id
  );

  const handleAddRemoveSubGroupPlayerId = () => {
    if (isInSubGroup) {
      // @ts-ignore
      setSubGroupPlayersIds((prevPlayers: number[]) =>
        prevPlayers.filter(id => id !== player.id)
      );
    } else {
      // @ts-ignore
      setSubGroupPlayersIds((prevPlayers: number[]) => [
        ...prevPlayers,
        player.id
      ]);
    }
  };

  const getButtonLabel = (
    isInterestList: boolean,
    table: Table | undefined,
    subGroupId?: number,
    setSubGroupPlayers?: (players: number[]) => void
  ) => {
    if (isInterestList) {
      if (isRegistered) {
        return 'Remove from IL';
      }
      return 'Add to IL';
    } else if (table && table.players.length < table.maxPlayers) {
      return 'SIT';
    } else if (subGroupId || setSubGroupPlayers) {
      return isInSubGroup || isRegisteredSubGroup
        ? 'Remove from Sub-Group'
        : 'Add to Sub-Group';
    } else {
      return text;
    }
  };

  return (
    <Row
      justify="space-between"
      className={`align-items-center border-bottom mx-0`}
    >
      <PlayerInfo player={player} showFullName={true} />
      {isSat || isWaiting ? null : (
        <Button
          className={'mb-0'}
          type="primary"
          onClick={() => {
            // fix subgroup issue of adding player to subgroup but not reflecting in the list
            setIsInSubGroup(prevState => !prevState);

            if (onChange) {
              onChange(player);
            }
            if (subGroupId && addToSubGroup) {
              if (isRegisteredSubGroup) {
                removePlayer({
                  body: { flopId: player.id, subgroupId: subGroupId }
                });
              } else {
                addPlayer({
                  body: { flopId: player.id, subgroupId: subGroupId }
                });
              }
            } else if (setSubGroupPlayersIds) {
              handleAddRemoveSubGroupPlayerId();
            } else {
              if (isInterestList) {
                removePlayerFromInterestList({
                  accountId: player.id,
                  id: undefined,
                  gameId: game!.id
                });
              }
              if (game) {
                onPlacePlayerSubmit({ flopId: player.id });
              }
            }
          }}
          loading={isPlacePlayerSaving || isSavingRemovePlayer}
        >
          {getButtonLabel(
            isInterestList,
            table,
            subGroupId,
            setSubGroupPlayersIds
          )}
        </Button>
      )}
    </Row>
  );
};

export default PlayerRowItem;
