import { InterestList } from '@api-3/endpoints/interestList';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DateColumn = ({ interestList }: { interestList: InterestList }) => {
  const { t } = useTranslation();
  return interestList?.date ? (
    <span>
      {interestList.date &&
        t('DATE_GAME', { date: moment(interestList.date).parseZone() })}
      ,{' '}
      {interestList.date &&
        t('TIME_GAME', { time: moment(interestList.date).parseZone() })}
    </span>
  ) : (
    <span>NO DATA</span>
  );
};

export default DateColumn;
