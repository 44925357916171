// import { RightOutlined } from '@ant-design/icons';
import { Template, useGetTemplatesQuery } from '@api-3/endpoints/templates';
import {
  ADatePicker,
  AInput,
  ASelect,
  ATimePicker
} from '@components/AntdSimpleFieldForReactFormHooks';
import { GameVariantType } from '@old-world/common/redux/ParametersModel.d';
import GameVariant from '@old-world/common/sagas/static/GameVariant';
import { isOutstideRange } from '@old-world/utils/DateUtils';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import { InterestListCreationFormShape } from '@routes-3/main/cashGameManagement/interestList/modals/InterestListCreationModal';
// import TemplateListItem from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TemplateListItem';
// import { GAME_TYPE } from '@routes-3/main/cashGameManagement/runningGamesMap/modals/AddGameMapModal';
import { Col, List, Row, Select } from 'antd';
// import { Tabs } from 'antd';
import moment, { Moment } from 'moment/moment';
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function InterestListCreationForm({ reset }: { reset: () => void }) {
  const { t } = useTranslation();

  const { control } = useFormContext<InterestListCreationFormShape>();
  const disableDate = (date: Moment | undefined) =>
    date ? isOutstideRange(date, moment().subtract(1, 'days')) : true;
  const gameVariant = useController({
    control,
    name: 'gameVariant',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });
  const smallBlind = useController({
    control,
    name: 'smallBlind',
    rules: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      required: t('VALIDATOR_REQUIRED')! as string,
      pattern: { value: /^\d+$/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const bigBlind = useController({
    name: 'bigBlind',
    rules: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      required: t('VALIDATOR_REQUIRED')! as string,
      pattern: { value: /^\d+$/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const ante = useController({
    name: 'ante',
    rules: {
      pattern: { value: /^\d+$/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const min = useController({
    control,
    name: 'buyInMin',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });
  const max = useController({
    control,
    name: 'buyInMax'
  });
  const dateController = useController({
    control,
    name: 'startingDate',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });
  const timeController = useController({
    control,
    name: 'startingTime',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    rules: { required: t('VALIDATOR_REQUIRED')! as string }
  });

  // const onChange = () => {
  //   reset();
  //   setSelectedExistingGameIndex(undefined);
  // };

  const casinoId = useSelector(currentCasinoSelector);
  const { data } = useGetTemplatesQuery({ casinoId });
  // const templateGames: Template[] = data?.content ?? [];
  // const [selectedExistingGameIndex, setSelectedExistingGameIndex] = useState<
  //   number
  // >();

  // const template = (
  //   <List
  //     style={{ height: 378, overflowY: 'scroll' }}
  //     className="game-list-items"
  //     itemLayout="horizontal"
  //     dataSource={templateGames}
  //     locale={{ emptyText: <div /> }}
  //     renderItem={(item, index) =>
  //       templateGames?.length > 0 && (
  //         <List.Item>
  //           <TemplateListItem
  //             game={item}
  //             index={index}
  //             isSelected={index == selectedExistingGameIndex}
  //             onSelectionChange={() => {
  //               setSelectedExistingGameIndex(index);
  //             }}
  //           />
  //         </List.Item>
  //       )
  //     }
  //   />
  // );

  return (
    <>
      <ASelect
        label={t('INTEREST_GAME_VARIANT_LABEL') + '*'}
        controller={gameVariant}
        hasFeedback
      >
        {GameVariant.map((c: GameVariantType, index: number) => (
          <Select.Option key={index} value={c.label}>
            {c.label}
          </Select.Option>
        ))}
      </ASelect>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={smallBlind} label={'SB*'} type={'text'} />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={bigBlind} label={'BB*'} type={'text'} />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={ante} label={'Straddle'} type={'text'} />
        </Col>
      </Row>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput controller={min} label={'Min. Buy-In*'} type={'text'} />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput controller={max} label={'Max. Buy-In'} type={'text'} />
        </Col>
      </Row>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <ADatePicker
            controller={dateController}
            mandatory
            label={t('INTEREST_STARTING_DATE_LABEL')}
            disabledDate={disableDate}
            style={{ height: 32, width: '100%' }}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <ATimePicker
            controller={timeController}
            mandatory
            label={t('INTEREST_STARTING_TIME_LABEL')}
            use12Hours={(t('USE_12_HOURS') as unknown) == true}
            minuteStep={30}
            style={{ height: 32, width: '100%' }}
          />
        </Col>
      </Row>
    </>
  );

  // return (
  //   <Tabs
  //     defaultActiveKey={GAME_TYPE.CUSTOM}
  //     size={'large'}
  //     moreIcon={<RightOutlined />}
  //     onChange={onChange}
  //     centered
  //   >
  //     <Tabs.TabPane key={GAME_TYPE.CUSTOM} tab="Custom">
  //       {custom}
  //     </Tabs.TabPane>
  //     <Tabs.TabPane key={GAME_TYPE.TEMPLATE} tab="Template">
  //       {template}
  //     </Tabs.TabPane>
  //   </Tabs>
  // );
}

export default InterestListCreationForm;
