import { usePlacePlayer } from '@components-3/addPlayerModal/PlayerModalServices';
import { AInput } from '@components/AntdSimpleFieldForReactFormHooks';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type NonFlopUserFormProps = {
  isInterestList?: boolean;
  isWaitingList?: boolean;
  isSat?: boolean;
  table?: Table;
  gameId?: string;
};
const NonFlopUserForm = ({
  isInterestList,
  isWaitingList,
  isSat,
  table,
  gameId
}: NonFlopUserFormProps) => {
  const { t } = useTranslation();
  const formMethods = useForm<{
    flopUserName: string;
    name: string;
  }>();
  const nonFlopUserController = useController({
    control: formMethods.control,
    name: 'name'
  });
  const nonFlopUserName = useWatch({
    control: formMethods.control,
    name: 'name'
  });
  const { onSubmit, isSaving } = usePlacePlayer({
    isInterestList: isInterestList,
    table: table,
    gameId: gameId || 'UNDEFINED_GAME_ID'
  });
  return (
    <>
      <Row className="gx-layouts-view">
        <Col xl={18} lg={18} md={18} sm={18} xs={18}>
          <Form layout="vertical">
            <AInput
              placeholder={'type the name of non flop App user'}
              label={'Non Flop App User'}
              controller={nonFlopUserController}
            />
          </Form>
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <Button
            type="default"
            className={'gx-btn w-100'}
            style={{ marginTop: 27 }}
            onClick={() => {
              if (gameId) {
                onSubmit(formMethods.getValues());
              }
              formMethods.reset({ name: '' });
            }}
            disabled={!nonFlopUserName}
            loading={isSaving}
          >
            {isInterestList
              ? 'Add to IL'
              : table && table.players.length < table.maxPlayers
              ? t('SIT')
              : t('ADD_TO_WL')}
          </Button>
        </Col>
      </Row>
      <hr className={'mb-4'} />
      <div
        className={
          'position-relative d-flex justify-content-center align-items-center'
        }
        style={{ backgroundColor: '#444f5a' }}
      >
        <span
          className={'position-absolute px-2'}
          style={{ backgroundColor: '#444f5a', top: -36, fontSize: 18 }}
        >
          OR
        </span>
      </div>
    </>
  );
};

export default NonFlopUserForm;
