import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import SubGroupsPageContent from '@routes-3/main/playersDatabase/sub-groups/components/SubGroupsPageContent';
import React from 'react';

export default function SubGroups() {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Players Database'}
        pageIcon={
          <i
            className="icon icon-contacts"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Sup-Groups'}
      />
      <SubGroupsPageContent />
    </LayoutContentContainer>
  );
}
