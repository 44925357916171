import { api } from '@api/api';
import { ListResponse } from '@api/types';
import { parseParams } from '@old-world/utils/ApiUtils';
import {
  CreateRunningGamePayload,
  RunningGame,
  TablePlayers,
  UpdateRunningGamePayload,
  WaitingPlayer,
  WaitingPlayerOrder
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';

const runningGameListApi = api
  .enhanceEndpoints({ addTagTypes: ['RunningGame', 'WaitingList', 'Status'] })
  .injectEndpoints({
    endpoints: builder => ({
      closeTable: builder.mutation<void, { tableId: string }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ tableId }) => ({
          method: 'PUT',
          url: `/games/api/live/cash-games/table/${tableId}/stop`
        })
      }),
      deleteTable: builder.mutation<void, { id: string }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ id }) => ({
          method: 'DELETE',
          url: `/games/api/live/table/${id}`
        })
      }),
      updateRunningGame: builder.mutation<
        void,
        { body: Partial<UpdateRunningGamePayload>; gameId: string }
      >({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ gameId, body }) => ({
          method: 'PUT',
          url: `/games/api/live/cash-games/${gameId}`,
          body: body
        })
      }),
      createRunningGame: builder.mutation<
        { gameId: string },
        { body: CreateRunningGamePayload }
      >({
        invalidatesTags: ['RunningGame', 'Status'],
        query: params => ({
          method: 'POST',
          url: `/games/api/live/cash-games/`,
          body: params.body
        })
      }),
      getRunningGame: builder.query<
        RunningGame,
        {
          gameId: string;
        }
      >({
        providesTags: ['RunningGame'],
        transformResponse: (
          response: Omit<RunningGame, 'id'> & { liveGameKey: string }
        ) => {
          return {
            ...response,
            id: response.liveGameKey
          };
        },
        query: ({ gameId }) => `/games/api/live/cash-games/${gameId}`
      }),
      getRunningGames: builder.query<
        ListResponse<RunningGame>,
        {
          queryParams: {
            page: number;
            size: number;
            casinoId?: string;
            liveGameKey?: string;
          };
        }
      >({
        providesTags: ['RunningGame'],
        extraOptions: { maxRetries: 1 },
        transformResponse: (
          response: ListResponse<
            Omit<RunningGame, 'id'> & { liveGameKey: string }
          >
        ) => {
          return {
            ...response,
            content: response.content.map(r => ({ ...r, id: r.liveGameKey }))
          };
        },
        query: ({ queryParams }) =>
          `/games/api/live/cash-games/search?${parseParams(queryParams)}`
      }),
      getWaitingList: builder.query<
        WaitingPlayer[],
        { gameId: string; size?: number }
      >({
        providesTags: ['WaitingList'],
        extraOptions: { maxRetries: 1 },
        query: ({ gameId, size = 20 }) =>
          `/games/api/live/${gameId}/waiting-list?size=${size}`,
        transformResponse: (response: ListResponse<WaitingPlayer>) => {
          return response.content;
        }
      }),
      removePlayerFromWaitingList: builder.mutation<
        void,
        { gameId: string | number; id: string | number }
      >({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ gameId, id }) => ({
          method: 'DELETE',
          url: `/games/api/live/${gameId}/waiting-list/${id}`
        })
      }),
      addPlayerToWaitingList: builder.mutation<
        void,
        {
          gameId: string;
          payload: { flopId?: number; name?: string; order: number };
        }
      >({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ gameId, payload }) => ({
          method: 'POST',
          body: payload,
          url: `/games/api/live/${gameId}/waiting-list`
        })
      }),
      orderWaitingList: builder.mutation<
        void,
        { gameId: string; payload: WaitingPlayerOrder[] }
      >({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ gameId, payload }) => ({
          method: 'PUT',
          body: payload,
          url: `/games/api/live/${gameId}/waiting-list/order`
        })
      }),
      callPlayer: builder.mutation<void, { gameId: string; id: string }>({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ gameId, id }) => ({
          method: 'PUT',
          url: `/games/api/live/${gameId}/waiting-list/${id}/call?callDate=${new Date().toISOString()}`
        })
      }),
      removePlayerFromGame: builder.mutation<
        void,
        { playerId: string | number }
      >({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId }) => ({
          method: 'POST',
          url: `/games/api/live/cash-games/player/${playerId}/stop`
        })
      }),
      sitPlayer: builder.mutation<
        void,
        { gameId: string; id: string; tableId: string }
      >({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ gameId, id, tableId }) => ({
          method: 'PUT',
          url: `/games/api/live/${gameId}/waiting-list/${id}/sit/${tableId}`
        }),
        async onQueryStarted(
          { gameId, tableId, id },
          { dispatch, queryFulfilled }
        ) {
          dispatch(
            runningGameListApi.util.updateQueryData(
              'getRunningGames',
              {
                queryParams: {
                  liveGameKey: gameId,
                  page: 1,
                  size: 1
                }
              },
              draft => {
                // we try to temporary update so that the player count increases
                const game = draft?.content?.[0];
                const table = game?.tables?.find(t => t.id == tableId);
                const players = table?.players ?? [];
                const tmpPlayer: TablePlayers = {
                  breakDate: '',
                  casinoId: '',
                  casinoName: '',
                  currentLatitude: '',
                  currentLongitude: '',
                  endDate: '',
                  flopId: 0,
                  gameSize: '',
                  gameVariant: '',
                  id: '',
                  name: '',
                  registrationDate: '',
                  requestState: '',
                  sittedDate: '',
                  seatId: 0,
                  stack: 0
                };
                players.push(tmpPlayer);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch (e) {
            runningGameListApi.util.invalidateTags(['RunningGame']);
          }
        }
      }),
      addNewPlayerToTable: builder.mutation<
        void,
        { tableId: string; player: { id?: string; name: string } }
      >({
        invalidatesTags: ['RunningGame', 'WaitingList', 'Status'],
        query: ({ tableId, player }) => ({
          method: 'POST',
          body: player,
          url: `/games/api/live/table/${tableId}/join`
        })
      }),
      seatIdPlayer: builder.mutation<
        void,
        { playerId: string; seatId: number }
      >({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId, seatId }) => ({
          method: 'PUT',
          url: `/games/api/live/player/${playerId}/seat/${seatId}`
        })
      }),
      removePlayerSeat: builder.mutation<void, { playerId: string }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId }) => ({
          method: 'DELETE',
          url: `/games/api/live/player/${playerId}/seat`
        })
      }),
      breakPlayer: builder.mutation<void, { playerId: string }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId }) => ({
          method: 'PUT',
          url: `/games/api/live/player/${playerId}/break`
        })
      }),
      resumePlayer: builder.mutation<void, { playerId: string }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId }) => ({
          method: 'PUT',
          url: `/games/api/live/player/${playerId}/resume`
        })
      }),
      stackPlayer: builder.mutation<void, { playerId: string; stack: number }>({
        invalidatesTags: ['RunningGame', 'Status'],
        query: ({ playerId, stack }) => ({
          method: 'PUT',
          url: `/games/api/live/player/${playerId}/stack/${stack}`
        })
      })
    })
  });

export const {
  useDeleteTableMutation,
  useGetRunningGamesQuery,
  useCreateRunningGameMutation,
  useCloseTableMutation,
  useGetWaitingListQuery,
  useSitPlayerMutation,
  useCallPlayerMutation,
  useAddPlayerToWaitingListMutation,
  useRemovePlayerFromWaitingListMutation,
  useRemovePlayerFromGameMutation,
  useGetRunningGameQuery,
  useAddNewPlayerToTableMutation,
  useUpdateRunningGameMutation,
  useOrderWaitingListMutation,
  useSeatIdPlayerMutation,
  useRemovePlayerSeatMutation,
  useBreakPlayerMutation,
  useResumePlayerMutation,
  useStackPlayerMutation
} = runningGameListApi;
