import './AdditionButton.scss';

import { ReactComponent as Cross } from '@assets/svgs/cross.svg';
import React from 'react';

type AdditionButtonProps = {
  text?: string;
  onClickCross: () => void;
};

const AdditionButton = ({ onClickCross, text }: AdditionButtonProps) => {
  return (
    <div className="addition-button">
      <p>{text}</p>
      <Cross onClick={onClickCross} />
    </div>
  );
};

export default React.memo(AdditionButton);
