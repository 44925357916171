import {
  AInput,
  ATimePicker
} from '@components/AntdSimpleFieldForReactFormHooks';
import AutoCompleteField from '@components/AutoCompleteField';
import { Col, Form, Row, Select } from 'antd';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const GameSettings = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation();
  const allowedTimeController = useController({
    name: 'reserveAllowedTime',
    // @ts-ignore
    rules: { required: t('VALIDATOR_REQUIRED')! }
  });
  const DistanceUnit = [
    { id: 'KILOMETERS', label: 'Kilometers' },
    { id: 'MILES', label: 'Miles' }
  ];
  const maxDistanceController = useController({
    name: 'userMaxDistance',
    // @ts-ignore
    rules: { required: t('VALIDATOR_REQUIRED')! }
  });
  const maxDistanceUnitController = useController({
    name: 'userMaxDistanceUnit',
    // @ts-ignore
    rules: { required: t('VALIDATOR_REQUIRED')! }
  });

  return (
    <Form layout="vertical">
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Allowed time to get a seat (Minutes:Seconds)
      </p>
      <small>
        When a player from the waiting list is called to get his seat, he needs
        to know for how long his seat will be reserved. Please fill-in the
        following field with the maximum time allowed.
      </small>
      <ATimePicker
        disabled={disabled}
        controller={allowedTimeController}
        mandatory
        label={''}
        style={{ width: '100%' }}
        name="reserveAllowedTime"
        className={'d-flex flex-column mb-4 mt-3'}
        id="reserveAllowedTime"
        format={'mm:ss'}
        secondStep={30}
        showNow={false}
        placeholder={'Allowed time'}
      />
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Allowed distance to register to a WL
      </p>
      <small>
        You can put a restriction on the players who are geo-located far from
        the venue to register to the waiting list by setting up a maximum
        allowed distance to the venue.
      </small>
      <Row
        className={'gx-flex-row mt-3'}
        style={{
          marginLeft: -16,
          marginRight: -16,
          position: 'relative'
        }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput
            disabled={disabled}
            className={'d-flex flex-column mb-4'}
            id="userMaxDistance"
            name="userMaxDistance"
            label={''}
            mandatory
            type="number"
            controller={maxDistanceController}
            placeholder={'Type a distance'}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteField
            disabled={disabled}
            controller={maxDistanceUnitController}
            className={'d-flex flex-column mb-4'}
            label={''}
            mandatory
          >
            {DistanceUnit.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.label}
              </Select.Option>
            ))}
          </AutoCompleteField>
        </Col>
      </Row>
    </Form>
  );
};

export default GameSettings;
