import { LoadingOutlined } from '@ant-design/icons';
import {
  GameInvitationsDTO,
  useRemovePlayerFromInterestListMutation
} from '@api-3/endpoints/interestList';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RegisteredPlayerAction = ({
  gameInvitation,
  gameId
}: {
  gameInvitation: GameInvitationsDTO;
  gameId: string;
}) => {
  const { t } = useTranslation();
  const [
    removePlayer,
    { isLoading: isSavingRemovePlayer }
  ] = useRemovePlayerFromInterestListMutation();

  const Loader = () => <LoadingOutlined style={{ fontSize: 12 }} spin />;

  return (
    <div className={'gx-flex-row'} style={{ marginLeft: 'auto' }}>
      <Tooltip title={t('REMOVE_PLAYER')} placement="bottom">
        <div
          className="cursor-pointer"
          style={{ color: 'red' }}
          onClick={() =>
            removePlayer({
              accountId: gameInvitation.accountId
                ? gameInvitation.accountId
                : undefined,
              id: !gameInvitation.accountId ? gameInvitation.id : undefined,
              gameId
            })
          }
        >
          {isSavingRemovePlayer ? (
            <Loader />
          ) : (
            <FontAwesomeIcon icon={faTrash} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default RegisteredPlayerAction;
