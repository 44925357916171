import {
  AInput,
  ATextarea
} from '@components/AntdSimpleFieldForReactFormHooks';
import Form from '@components/forms/Form';
import React from 'react';
import { useController } from 'react-hook-form';

const AboutUs = ({ disabled }: { disabled?: boolean }) => {
  const description = useController({
    name: 'description',
    rules: { required: true }
  });

  const facebook = useController({
    name: 'facebookUrl',
    rules: { pattern: /(www|http:|https:)+[^\s]+[\w]/ }
  });
  // const twitter = useController({
  //   name: 'twitter'
  // });
  const instagram = useController({
    name: 'instagramUrl'
  });
  // const snapchat = useController({
  //   name: 'snapchat'
  // });
  const youtube = useController({
    name: 'youtubeUrl',
    rules: { pattern: /(www|http:|https:)+[^\s]+[\w]/ }
  });
  // const twitch = useController({
  //   name: 'twitch'
  // });

  const socialMedia = [
    {
      name: 'Facebook',
      controller: facebook
    },
    // {
    //   name: 'Twitter',
    //   controller: twitter
    // },
    {
      name: 'Instagram',
      controller: instagram
    },
    // {
    //   name: 'Snapchat',
    //   controller: snapchat
    // },
    {
      name: 'Youtube Url',
      controller: youtube
    }
    // {
    //   name: 'Twitch',
    //   controller: twitch
    // }
  ];

  return (
    <Form>
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        About Us
      </p>
      <small className={'mb-3'}>
        Tell more to the flop APP community about your Poker Room, regular
        games, rake structure, daily tournaments, and promotions
      </small>
      <ATextarea
        className={'d-flex flex-column mb-4'}
        style={{ minHeight: 100 }}
        //@ts-ignore
        id="description"
        name="description"
        hasFeedback
        disabled={disabled}
        controller={description}
      />
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Social Media Links
      </p>
      <small className={'mb-3'}>
        Share with the flop APP community your social media Links and get more
        followers and engagement. We advise you to share at least one link.
      </small>
      <div className={'row'}>
        {socialMedia.map(({ name, controller }) => (
          <div className={'col-4'} key={name}>
            <AInput
              className={'mb-4'}
              label={name}
              id={name}
              disabled={disabled}
              name={name.toLowerCase().replace(' ', '')}
              hasFeedback
              controller={controller}
            />
          </div>
        ))}
      </div>
    </Form>
  );
};

export default AboutUs;
