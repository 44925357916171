import AvatarUploader from '@components/AvatarUploader';
import ApiClient from '@old-world/ApiClient';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import { Col, message, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

export default function ProfilePicture() {
  const [file, setFile] = useState<any>();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [profilePicture, setProfilePicture] = useState(
    currentUser.profilePicture
  );

  useEffect(() => {
    if (file) {
      ApiClient.uploadCustomerProfilePicture(currentUser.data.id, file).then(
        () => {
          setCurrentUser({
            data: {
              ...currentUser.data
            },
            profilePicture
          });
          message.success('Profile picture has been updated.');
        }
      );
    }
  }, [profilePicture]);

  return (
    <Row justify="center">
      <Col className="d-flex flex-column align-items-center w-100 circle-upload">
        <Row justify="center">
          <div>
            <AvatarUploader
              previewWidth={115}
              onChange={file => {
                setFile(file);
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                  setProfilePicture(reader.result);
                });
                reader.readAsDataURL(file);
              }}
              profilePicture={profilePicture}
            />
          </div>
        </Row>
        <h5 className="mt-2">{`${currentUser.data.firstName} ${currentUser.data.lastName}`}</h5>
        <p className="mt-1">{currentUser.data.role}</p>
      </Col>
    </Row>
  );
}
