import { useAddMessageMutation } from '@api-3/endpoints/message';
import { Button, Form } from 'antd';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const ChatCommunicationFooter = ({
  selectedInterestGameId
}: {
  selectedInterestGameId: string;
}) => {
  const [addMessage, { isLoading: isSaving }] = useAddMessageMutation();
  const formMethods = useForm<{ message: string }>({
    defaultValues: {
      message: ''
    }
  });
  const handleSubmit = formMethods.handleSubmit;
  const control = formMethods.control;
  const onSubmit = async (data: { message: string }) => {
    try {
      await addMessage({
        body: {
          content: data.message,
          entityId: selectedInterestGameId,
          entityType: 'GAME'
        }
      }).unwrap();
      formMethods.reset();
    } catch (e) {
      console.log('add Message error: ', e);
    }
  };
  return (
    <div className="gx-chat-main-footer">
      <div
        className="gx-flex-row gx-align-items-center"
        style={{ maxHeight: 51 }}
      >
        <div className="gx-col">
          <div className="gx-form-group">
            {/*@ts-ignore*/}
            <FormProvider {...formMethods}>
              <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                <Controller
                  control={control}
                  name={'message'}
                  render={({ field: { onChange, value, name } }) => (
                    <textarea
                      style={{ borderColor: 'white' }}
                      className="gx-border-1 ant-input gx-chat-textarea"
                      onChange={onChange}
                      value={value}
                      id={name}
                      placeholder="Type and hit enter to send message"
                    />
                  )}
                />
              </Form>
            </FormProvider>
          </div>
        </div>
        <div style={{ paddingTop: 6 }}>
          <Button
            className={'gx-btn'}
            id="addMessage"
            type="default"
            loading={isSaving}
            disabled={!formMethods.formState.isDirty}
            icon={
              <i
                className="icon icon-sent mr-2"
                style={{ position: 'relative', top: 2 }}
              />
            }
            onClick={handleSubmit(onSubmit)}
          >
            <span>Send</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatCommunicationFooter;
