import { useGetPlayersProfileByIdsQuery } from '@api-3/endpoints/player';
import PlayerName from '@components-3/PlayerName';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { TablePlayers } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Select } from 'antd';
import React from 'react';
import { useController } from 'react-hook-form';

const DealerPlayerForm = ({
  formMethods,
  players
}: {
  formMethods: any;
  players: TablePlayers[];
}) => {
  const flopUser = players.filter(p => !p.seatId && p.flopId !== null);
  const nonFlopUser = players.filter(p => !p.seatId && p.flopId === null);
  const { data: playersProfile } = useGetPlayersProfileByIdsQuery({
    ids: flopUser.map(p => p.flopId)
  });
  const selectedPlayer = useController({
    control: formMethods.control,
    name: 'id'
  });
  const stack = useController({
    control: formMethods.control,
    name: 'stack'
  });

  const allPlayers = [
    ...nonFlopUser.map(p => ({
      id: p.id,
      name: p.name,
      firstName: undefined,
      lastName: undefined
    })),
    ...(playersProfile || []).map(p => ({
      name: undefined,
      id: flopUser
        .filter(player => p.id === player.flopId)
        .map(player => player.id)[0],
      firstName: p.firstName,
      lastName: p.lastName
    }))
  ];
  return (
    <>
      <ASelect
        controller={selectedPlayer}
        label={'Player'}
        hasFeedback
        // @ts-ignore
        placeholder={'Select a player'}
        mandatory
      >
        {allPlayers.map(p => (
          <Select.Option key={p.id} value={p.id}>
            <PlayerName
              player={{
                firstName: p.firstName!,
                lastName: p.lastName!,
                name: p.name
              }}
              showFullName={false}
              textSize={16}
            />
          </Select.Option>
        ))}
      </ASelect>
      <AInput controller={stack} label={'Stack'} type={'number'} />
    </>
  );
};

export default DealerPlayerForm;
