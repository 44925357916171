const GameVariant = [
  {
    id: '1',
    label: 'LH',
    shortName: 'LH'
  },
  {
    id: '2',
    label: 'NLH',
    shortName: 'NLH'
  },
  {
    id: '3',
    label: "Texas Hold'em - PL",
    shortName: "TexasHold'em-PL"
  },
  {
    id: '4',
    label: 'Omaha - NL',
    shortName: 'Omaha-NL'
  },
  {
    id: '5',
    label: 'Omaha - Limit',
    shortName: 'Omaha - Limit'
  },
  {
    id: '6',
    label: 'PLO',
    shortName: 'PLO'
  },
  {
    id: '61',
    label: 'Big O',
    shortName: 'BigO'
  },
  {
    id: '62',
    label: 'Mix',
    shortName: 'Mix'
  },
  {
    id: '7',
    label: 'Omaha Hi/ Lo - NL',
    shortName: 'OmahaHi/Lo-NL'
  },
  {
    id: '8',
    label: 'Omaha Hi/ Lo - Limit',
    shortName: 'OmahaHi/Lo-L'
  },
  {
    id: '9',
    label: 'Omaha Hi-Lo - PL',
    shortName: 'PLOHi-Lo'
  },
  {
    id: '10',
    label: 'Omaha 5 - PL',
    shortName: 'Omaha5-PL'
  },
  {
    id: '50',
    label: 'Omaha 5 - NL',
    shortName: 'Omaha5-NL'
  },
  {
    id: '11',
    label: '5 Card Omaha Hi/ Lo - Limit',
    shortName: 'Omaha5Hi/Lo-L'
  },
  {
    id: '12',
    label: 'Omaha 5 Hi/ Lo - PL',
    shortName: 'Omaha5Hi/Lo-PL'
  },
  {
    id: '13',
    label: 'Omaha 5 Hi/ Lo - NL',
    shortName: 'Omaha5Hi/Lo-NL'
  },
  {
    id: '14',
    label: 'Courchevel - NL',
    shortName: 'Courchevel-NL'
  },
  {
    id: '15',
    label: 'Courchevel - Limit',
    shortName: 'Courchevel-L'
  },
  {
    id: '16',
    label: 'Courchevel - PL',
    shortName: 'Courchevel-PL'
  },
  {
    id: '17',
    label: 'Courchevel Hi/ Lo - NL',
    shortName: 'CourchevelHi/Lo-NL'
  },
  {
    id: '18',
    label: 'Courchevel Hi/ Lo - Limit',
    shortName: 'CourchevelHi/Lo-L'
  },
  {
    id: '19',
    label: 'Courchevel Hi/ Lo - PL',
    shortName: 'CourchevelHi/Lo-PL'
  },
  {
    id: '20',
    label: '7 Card Stud - NL',
    shortName: '7CStud-NL'
  },
  {
    id: '21',
    label: '7 Card Stud - Limit',
    shortName: '7CStud-L'
  },
  {
    id: '22',
    label: '7 Card Stud - PL',
    shortName: '7CStud-PL'
  },
  {
    id: '23',
    label: '7 Card Stud Hi/ Lo - NL',
    shortName: '7CStudHi/Lo-NL'
  },
  {
    id: '24',
    label: '7 Card Stud Hi/ Lo - Limit',
    shortName: '7CStudHi/Lo-L'
  },
  {
    id: '25',
    label: '7 Card Stud Hi/ Lo - PL',
    shortName: '7CStudHi/Lo-PL'
  },
  {
    id: '26',
    label: 'Razz - NL',
    shortName: 'Razz-NL'
  },
  {
    id: '27',
    label: 'Razz - Limit',
    shortName: 'Razz-L'
  },
  {
    id: '28',
    label: 'Razz - PL',
    shortName: 'Razz-PL'
  },
  {
    id: '29',
    label: '5 Card Stud Sökö',
    shortName: '5CStudSökö'
  },
  {
    id: '30',
    label: '5 Card Canadian Stud',
    shortName: '5CCanadianStud'
  },
  {
    id: '31',
    label: 'Short deck',
    shortName: 'Shortdeck'
  },
  {
    id: '32',
    label: '5 Card Draw - NL',
    shortName: '5CDraw-NL'
  },
  {
    id: '33',
    label: '5 Card Draw - Limit',
    shortName: '5CDraw-L'
  },
  {
    id: '34',
    label: '5 Card Draw - PL',
    shortName: '5CDraw-PL'
  },
  {
    id: '35',
    label: 'Archie - NL',
    shortName: 'Archie-NL'
  },
  {
    id: '36',
    label: 'Archie - Limit',
    shortName: 'Archie-L'
  },
  {
    id: '37',
    label: 'Archie - PL',
    shortName: 'Archie-PL'
  },
  {
    id: '38',
    label: 'Sviten Special - NL',
    shortName: 'SvitenSpecial-NL'
  },
  {
    id: '39',
    label: 'Sviten Special - Limit',
    shortName: 'SvitenSpecial-L'
  },
  {
    id: '40',
    label: 'Sviten Special - PL',
    shortName: 'SvitenSpecial-PL'
  },
  {
    id: '41',
    label: '2-7 Triple Draw - NL',
    shortName: '2-7TD-NL'
  },
  {
    id: '42',
    label: '2-7 Triple Draw - Limit',
    shortName: '2-7TD-L'
  },
  {
    id: '43',
    label: '2-7 Triple Draw - PL',
    shortName: '2-7TD-PL'
  },
  {
    id: '44',
    label: '2-7 Single Draw - NL',
    shortName: '2-7SD-NL'
  },
  {
    id: '44',
    label: '2-7 Single Draw - Limit',
    shortName: '2-7SD-L'
  },
  {
    id: '45',
    label: '2-7 Single Draw - PL',
    shortName: '2-7SD-PL'
  },
  {
    id: '46',
    label: 'HORSE', //Differentvariantsofgames
    shortName: 'HORSE'
  },
  {
    id: '48',
    label: '8-Game',
    shortName: '8-Game'
  },
  {
    id: '51',
    label: 'Badugi', //OnlyLimit
    shortName: 'Badugi'
  },
  {
    id: '54',
    label: 'Badeucey', //OnlyLimit
    shortName: 'Badeucey'
  },
  {
    id: '57',
    label: 'Badacey', //OnlyLimit
    shortName: 'Badacey'
  },
  {
    id: '60',
    label: 'Open Face Chinese',
    shortName: 'OFC'
  }
];

export default GameVariant;
