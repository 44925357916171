import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { Card, Typography } from 'antd';
import React from 'react';

const { Title, Paragraph } = Typography;

const TermsAndConditionsPage = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Terms & Conditions'}
        pageIcon={
          <i
            className="icon icon-select"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
      />
      <Card
        className="gx-layouts-view mt-3"
        style={{ height: 'calc(100vh - 242px )', overflowY: 'scroll' }}
      >
        <Title className={'text-center'} level={3}>
          Terms and Conditions of Use for WeOpt LLC Solutions and Services
        </Title>
        <Paragraph style={{ color: '#ffffff' }}>
          These Terms and Conditions (the "Terms") govern your use of WeOpt
          LLC’s web and mobile applications, software-as-a-service (SaaS)
          platform, and related services (the "Services"). By using the
          Services, you agree to these Terms and enter into a binding contract
          with WeOpt LLC ("WeOpt").
        </Paragraph>
        <ol className="bold-numbers">
          <li>
            <Title level={4}>DEFINITIONS</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Content</b>: Text, images, photos, audio, video, location data,
              and all other forms of data or communication.
            </li>
            <li>
              <b>User Content</b>: Content that users, including you, submit or
              transmit through the Services.
            </li>
            <li>
              <b>WeOpt Content</b>: Content created and made available by WeOpt
              in connection with the Services.
            </li>
            <li>
              <b>Third-Party Content</b>: Content originating from parties other
              than WeOpt or its users, made available in connection with the
              Services.
            </li>
            <li>
              <b>Services Content</b>: All Content made available in connection
              with the Services, including User Content, WeOpt Content, and
              Third-Party Content.
            </li>
          </ol>
          <li>
            <Title level={4}>USE OF THE SERVICES</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Eligibility</b>: You must hold a valid license issued by the
              gaming regulator in your region to use the Services. You must be
              at least 18 years old and in good standing with WeOpt.
            </li>
            <li>
              <b>License</b>: WeOpt grants you a non-exclusive, non-transferable
              license to access and use the Services for your internal business
              operations. This license does not include the right to modify,
              copy, distribute, or create derivative works from the Services or
              any WeOpt Content.
            </li>
          </ol>
          <li>
            <Title level={4}>USER ACCOUNTS</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Account Creation</b>: To access certain Services, you must
              create an account and provide accurate information. You are
              responsible for maintaining the confidentiality of your account
              password and all activities that occur under your account.
            </li>
            <li>
              <b>Unauthorized Use</b>: You must notify WeOpt immediately of any
              unauthorized use of your account. WeOpt reserves the right to
              close your account at any time for any reason.
            </li>
          </ol>
          <li>
            <Title level={4}>SERVICES PROVIDED</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>User Admin Account</b>: You can create and manage a user admin
              account.
            </li>
            <li>
              <b>General Information</b>: You can provide WeOpt with general
              information about your poker room, including venue name, address,
              logo, number of tables, minimum age of allowed players, contact
              phone number, email contact address, offered games daily, and a
              text description of the poker room. This information will be
              listed on a mobile app for poker enthusiasts. The mobile app data
              is fully owned by WeOpt.
            </li>
            <li>
              <b>Real-Time Game Listings</b>: You can list all running poker
              games in real time, such as cash games and tournaments.
            </li>
            <li>
              <b>Waiting Lists</b>: You can list the status of waiting lists for
              every running game, and players can request to join waiting lists
              through the mobile app provided by WeOpt.
            </li>
            <li>
              <b>Performance Dashboard</b>: You can view a performance dashboard
              about the poker room. WeOpt uses all the provided data to offer
              KPIs. Without consent to access this data, WeOpt cannot perform
              these services and calculations.
            </li>
            <li>
              <b>Commercial Announcements</b>: You can publish commercial
              announcements or advertisements and authorize WeOpt to publish
              them to the mobile app audience.
            </li>
          </ol>
          <li>
            <Title level={4}>DATA USAGE AND PRIVACY</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Data Ownership</b>: WeOpt retains ownership of all data and
              Content provided through the Services. WeOpt may use this data to
              perform services, provide analytics, and offer insights.
            </li>
            <li>
              <b>Data Security</b>: WeOpt will implement reasonable security
              measures to protect your data. However, you acknowledge that data
              transmission over the internet is inherently insecure.
            </li>
            <li>
              <b>Privacy Policy</b>: Your use of the Services is also governed
              by our Privacy Policy, which is incorporated into these Terms by
              reference. WeOpt is committed to protecting your privacy and
              personal data, complying with applicable data protection
              regulations in the EU and North America.
            </li>
            <li>
              <b>Data Processing</b>: WeOpt processes your data in accordance
              with applicable data protection laws, including GDPR for users in
              the EU and CCPA for users in California.
            </li>
            <li>
              <b>Data Collection</b>: WeOpt collects and uses personal data only
              as necessary for the purposes of providing the Services, including
              creating user accounts, processing transactions, and providing
              customer support.
            </li>
            <li>
              <b>Data Sharing</b>: WeOpt does not sell your personal data to
              third parties. We may share data with third-party service
              providers who assist us in delivering the Services, subject to
              strict data protection and confidentiality obligations.
            </li>
            <li>
              <b>Data Retention</b>: WeOpt retains your data only as long as
              necessary to provide the Services or as required by applicable
              law.
            </li>
            <li>
              <b>Data Rights</b>: You have the right to access, correct, delete,
              and restrict the processing of your personal data. You can
              exercise these rights by contacting WeOpt at [contact
              information].
            </li>
          </ol>
          <li>
            <Title level={4}>COMMUNICATIONS</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Notifications</b>: By creating an account, you agree to receive
              communications from WeOpt related to the Services. You may opt out
              of these communications at any time.
            </li>
            <li>
              <b>Text Messages</b>: If you provide a cell phone number, you
              consent to receive SMS and other text messages from WeOpt. Your
              carrier may charge you for these messages.
            </li>
          </ol>
          <li>
            <Title level={4}>ADVERTISING AND ANNOUNCEMENTS</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Commercial Announcements</b>: You may publish commercial
              announcements and advertisements through the Services. WeOpt
              reserves the right to approve or disapprove any Content before
              publication.
            </li>
          </ol>
          <li>
            <Title level={4}>RESTRICTIONS</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Prohibited Uses</b>: You agree not to use the Services to
              violate any law, infringe on any intellectual property rights, or
              engage in any unlawful, harmful, or offensive conduct.
            </li>
            <li>
              <b>Account Limitations</b>: You may not impersonate others, create
              multiple accounts for yourself, or use the Services for any
              purpose other than as permitted by these Terms.
            </li>
          </ol>
          <li>
            <Title level={4}>INTELLECTUAL PROPERTY</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Ownership</b>: WeOpt owns all rights, title, and interest in
              the Services and WeOpt Content. You may not use, reproduce, or
              distribute any WeOpt Content without WeOpt’s express permission.
            </li>
            <li>
              <b>License to WeOpt</b>: By using the Services, you grant WeOpt a
              non-exclusive, royalty-free license to use, copy, modify, and
              distribute your Content as necessary to provide the Services.
            </li>
          </ol>
          <li>
            <Title level={4}>TERMINATION</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>Termination for Cause</b>: WeOpt may terminate or suspend your
              account and access to the Services at any time, without notice,
              for conduct that WeOpt believes violates these Terms, is harmful
              to other users of the Services, or causes harm or potential harm
              to WeOpt, its solutions, or its services.
            </li>
            <li>
              <b>Termination Events</b>: : These include Force Majeure
              situations, non-payment of service fees, violation of WeOpt’s
              intellectual property rights, and any intended damage to WeOpt’s
              solutions and services.
            </li>
            <li>
              <b>Effects of Termination</b>: Upon termination, your right to use
              the Services will immediately cease. WeOpt will not be liable to
              you or any third party for termination of your account or access
              to the Services.
            </li>
          </ol>
          <li>
            <Title level={4}>INDEMNIFICATION</Title>
          </li>
          <Paragraph style={{ color: '#ffffff' }}>
            You agree to indemnify, defend, and hold harmless WeOpt, its
            affiliates, and their respective officers, directors, employees, and
            agents from any claims, losses, damages, liabilities, including
            legal fees, arising out of your use of the Services, your violation
            of these Terms, or your violation of any third-party rights.
          </Paragraph>
          <li>
            <Title level={4}>DISCLAIMERS AND LIMITATIONS OF LIABILITY</Title>
          </li>
          <ol className={'mb-3'}>
            <li>
              <b>No Warranties</b>: The Services are provided "as is" and "as
              available" without warranties of any kind, either express or
              implied. WeOpt disclaims all warranties, including warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </li>
            <li>
              <b>Limitation of Liability</b>: WeOpt’s total liability to you for
              any claim arising out of or relating to the Services or these
              Terms is limited to the amount paid by you for the Services in the
              six months prior to the claim. WeOpt is not liable for any
              indirect, incidental, special, consequential, or punitive damages.
            </li>
          </ol>
          <li>
            <Title level={4}>GENERAL TERMS</Title>
          </li>
          <ol className={'mb-4'}>
            <li>
              <b>Governing Law</b>: These Terms are governed by the laws of the
              Principality of Monaco, without regard to its conflict of laws
              principles. Any disputes arising under these Terms will be
              resolved in the courts of Monaco.
            </li>
            <li>
              <b>Changes to Terms</b>: WeOpt reserves the right to modify these
              Terms at any time. Your continued use of the Services after any
              such changes constitutes your acceptance of the new Terms.
            </li>
            <li>
              <b>Entire Agreement</b>: These Terms constitute the entire
              agreement between you and WeOpt regarding the use of the Services
              and supersede any prior agreements between you and WeOpt.
            </li>
            <li>
              <b>Severability</b>: : If any provision of these Terms is found to
              be unenforceable or invalid, that provision will be limited or
              eliminated to the minimum extent necessary so that the Terms will
              otherwise remain in full force and effect.
            </li>
            <li>
              <b>No Waiver</b>: WeOpt’s failure to enforce any right or
              provision of these Terms will not be deemed a waiver of such right
              or provision.
            </li>
            <li>
              <b>Assignment</b>: These Terms and any rights and licenses granted
              hereunder may not be transferred or assigned by you, but may be
              assigned by WeOpt without restriction.
            </li>
            <li>
              <b>Notices</b>: All notices under these Terms will be in writing
              and will be deemed to have been duly given when received, if
              personally delivered ; when receipt is electronically confirmed,
              if transmitted by facsimile or e-mail; the day after it is sent,
              if sent for next day delivery by recognized overnight delivery
              service; and upon receipt, if sent by certified or registered
              mail, return receipt requested.
            </li>
          </ol>
        </ol>
        <Paragraph style={{ color: '#ffffff' }}>
          By using the Services, you acknowledge that you have read, understood,
          and agree to be bound by these Terms.
        </Paragraph>
      </Card>
    </LayoutContentContainer>
  );
};

export default TermsAndConditionsPage;
