import 'rc-slider/assets/index.css';

import { Input } from 'antd';
import Slider from 'rc-slider';
import React, { useState } from 'react';

const DistanceSlider = ({ min, max, onChange }) => {
  const [distance, setDistance] = useState(50);

  const onHandleChange = ({ target }) => {
    setDistance(target.value);
    onChange(target.value);
  };
  const onSliderChangeHandler = val => {
    setDistance(val);
    onChange(val);
  };
  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <Slider
          min={min}
          max={max}
          defaultValue={distance}
          value={distance}
          trackStyle={{ backgroundColor: '#4D8DCC', height: 8 }}
          style={{ marginTop: 9 }}
          handleStyle={{
            height: 24,
            width: 24,
            marginTop: -8,
            backgroundColor: '#4D8DCC',
            border: 'none'
          }}
          railStyle={{ backgroundColor: '#D2D9DF', height: 8 }}
          onChange={onSliderChangeHandler}
        />
        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
          <label style={{ fontSize: 12, marginBottom: 0 }}>{min}</label>
          <label style={{ fontSize: 12, marginBottom: 0 }}>{max}</label>
        </div>
      </div>

      <Input
        style={{
          height: 32,
          width: 55,
          marginLeft: 15
        }}
        value={distance}
        onChange={onHandleChange}
      />
    </div>
  );
};
export default DistanceSlider;
