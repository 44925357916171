import { ListAnnouncementItem } from '@api-3/endpoints/Announcements';
import {
  ADatePicker,
  AInput,
  ATextarea
} from '@components/AntdSimpleFieldForReactFormHooks';
import { Col, Row, Switch } from 'antd';
import React from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AnnouncementForm = ({
  announcement
}: {
  announcement?: ListAnnouncementItem;
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ListAnnouncementItem>();
  const titleController = useController({
    control,
    name: 'title',
    rules: { required: 'is required' },
    defaultValue: announcement ? announcement.title : ''
  });
  const descriptionController = useController({
    control,
    name: 'description',
    rules: { required: 'is required' },
    defaultValue: announcement ? announcement.description : ''
  });
  const startingDateController = useController({
    control,
    name: 'startingDate',
    defaultValue:
      announcement && announcement.startingDate
        ? new Date(announcement.startingDate)
        : undefined
  });
  const endingDateController = useController({
    control,
    name: 'endingDate',
    defaultValue:
      announcement && announcement.endingDate
        ? new Date(announcement.endingDate)
        : undefined
  });

  return (
    <Row
      className={'gx-flex-row'}
      style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
    >
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <AInput
          controller={titleController}
          label={'Title'}
          type={'text'}
          mandatory
        />
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <ATextarea
          label={'Description'}
          mandatory
          className={'d-flex flex-column mb-4'}
          style={{ minHeight: 100 }}
          hasFeedback
          controller={descriptionController}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <ADatePicker
          controller={startingDateController}
          label={t('INTEREST_STARTING_DATE_LABEL')}
          style={{ height: 32, width: '100%' }}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <ADatePicker
          controller={endingDateController}
          label={'Ending Date'}
          style={{ height: 32, width: '100%' }}
          disabledDate={d =>
            !d || d.isBefore(startingDateController.field.value)
          }
        />
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <span className={'mr-2'}>Publish</span>
        <Controller
          name="publish"
          control={control}
          defaultValue={announcement ? announcement.publish : false}
          render={({ field }) => (
            <Switch
              checked={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export default AnnouncementForm;
