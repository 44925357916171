import { Button } from 'antd';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BadRequestImage from '../../assets/images/400.png';
import UnAuthorizedImage from '../../assets/images/403.png';
import NotFoundImage from '../../assets/images/404.png';
import ServerErrorImage from '../../assets/images/500.png';
import ServerMaintenanceImage from '../../assets/images/ServerMaintenance.png';
import { getTimeRemainingFromEnd } from '../redux/AvailabilityRedux';

type ErrorPageProps = {
  image: any;
  altImage: string;
  title: any;
  text: any;
  variableText?: string;
  hideButton?: boolean;
};

function ErrorPage({
  image,
  altImage,
  title,
  text,
  hideButton
}: ErrorPageProps) {
  const { t } = useTranslation();

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content p-0 h-100">
            <img src={image} alt={altImage} height="300" />
          </div>
          <div className="gx-app-login-content">
            <h3 className="text-uppercase text-secondary gx-text-center gx-mb-4 gx-font-weight-bold">
              {title}
            </h3>
            <p className="text-greyDisable gx-text-center">{text}</p>
            {!hideButton && (
              <Link to="/">
                <Button className="text-uppercase mt-5" type="primary">
                  {t('BACK_TO_HOME_PAGE')}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
    // <Col className="d-flex flex-column justify-content-around align-items-center h-100">
    //   <Row>
    //     <img src={image} alt={altImage} height="400" />
    //   </Row>
    //   <Row className="justify-content-center text-center w-100">
    //     <Col>
    //       <h3 className="text-uppercase text-secondary">{title}</h3>
    //       <p className="text-greyDisable">{text}</p>
    //       {!hideButton && (
    //         <Link to="/">
    //           <Button className="text-uppercase mt-5" type="primary">
    //             {t('BACK_TO_HOME_PAGE')}
    //           </Button>
    //         </Link>
    //       )}
    //     </Col>
    //   </Row>
    // </Col>
  );
}

export function BadRequestPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={BadRequestImage}
      altImage="Bad Request"
      title={t('BAD_REQUEST_TITLE')}
      text={t('BAD_REQUEST_TEXT')}
    />
  );
}

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={NotFoundImage}
      altImage="Not Found"
      title={t('NOT_FOUND_TITLE')}
      text={t('NOT_FOUND_TEXT')}
    />
  );
}

export function UnAuthorizedPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={UnAuthorizedImage}
      altImage="UnAuthorized"
      title={t('UNAUTHORIZED_TITLE')}
      text={t('UNAUTHORIZED_TEXT')}
    />
  );
}

export function ServerErrorPage() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      image={ServerErrorImage}
      altImage="Server Error"
      title={t('SERVER_ERROR_TITLE')}
      text={t('SERVER_ERROR_TEXT')}
    />
  );
}

export function ServerMaintenancePage() {
  const { t } = useTranslation();
  const availableHoursLeft = useSelector(getTimeRemainingFromEnd);

  return (
    <ErrorPage
      image={ServerMaintenanceImage}
      altImage="Server Maintenance"
      title={t('SERVER_MAINTENANCE_TITLE')}
      text={t('SERVER_MAINTENANCE_TEXT', {
        backOnlineLabel: t('HOUR_NEXT', { count: availableHoursLeft })
      })
        .split('\n')
        .map((item, key) => (
          <Fragment key={key}>
            {item}
            <br />
          </Fragment>
        ))}
      hideButton
    />
  );
}
