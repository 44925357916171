import { useUpdateRunningGameMutation } from '@api-3/endpoints/games';
import { AInput } from '@components/AntdSimpleFieldForReactFormHooks';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { Form, message, Modal } from 'antd';
import React from 'react';
import { useController, useForm } from 'react-hook-form';

type Props = {
  onClose: () => void;
  expectedWaitingTime: string | undefined;
  gameId: string;
};

const ExpectedWaitingTimeModal = ({
  onClose,
  expectedWaitingTime,
  gameId
}: Props) => {
  const formMethods = useForm<{ waitingTime: string }>({
    mode: 'all',
    defaultValues: {
      waitingTime: expectedWaitingTime
    }
  });

  const rules = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    maxLength: 10
  };

  const [updateGame, { isLoading }] = useUpdateRunningGameMutation();
  const onSubmit = async (data: { waitingTime: string }) => {
    try {
      await updateGame({
        gameId,
        body: {
          expectedWaitingTime: data.waitingTime
        }
      }).unwrap();
      message.success('Expected Waiting time has been updated.');
      onClose();
    } catch (e) {
      message.error('An error has occurred');
    }
  };

  const waitingTime = useController({
    control: formMethods.control,
    name: 'waitingTime',
    rules
  });

  return (
    <Modal
      afterClose={() => formMethods.reset()}
      open={true}
      centered
      confirmLoading={isLoading}
      title={'Set expected waiting time for game'}
      onCancel={onClose}
      onOk={formMethods.handleSubmit(onSubmit)}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      okText={'Save'}
      closable={false}
      width={RunningCashGamesConstants.MODAL_WIDTH}
    >
      <Form layout="vertical">
        <div className="gx-mb-4">
          This is an estimated time (HH:MM) until the first 3 players can be
          seated.
        </div>
        <AInput
          controller={waitingTime}
          placeholder={'HH:MM'}
          label="Expected waiting time:"
        />
      </Form>
    </Modal>
  );
};

export default ExpectedWaitingTimeModal;
