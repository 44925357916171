import React from 'react';

export function getPlayerName(firstName?: string, lastName?: string) {
  const str1 = firstName
    ? firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
    : '';
  const str2 = lastName ? lastName?.charAt(0)?.toUpperCase() + '.' : '';
  return str1 + ' ' + str2;
}

export const PlayerName = ({
  player,
  textSize = 12,
  showFullName = false,
  textAlign = 'left'
}: {
  player: {
    firstName: string;
    lastName: string;
    name?: string;
  };
  textSize?: number;
  showFullName?: boolean;
  textAlign?: string;
}) => {
  return !player.firstName ? (
    // @ts-ignore
    <div style={{ textAlign: textAlign, fontSize: textSize }}>
      {player.name ? player.name : player.lastName}
    </div>
  ) : (
    // @ts-ignore
    <div style={{ textAlign: textAlign, fontSize: textSize }}>
      {showFullName
        ? player.firstName + ' ' + player.lastName
        : getPlayerName(player.firstName, player.lastName)}
    </div>
  );
};

export default PlayerName;
