import moment from 'moment';
// * Constant for default opening days and times
// * These data are store in redux for each casino  displayed in details screen
// * These constants are useful when we want to edit a casino
// * We can display default value is there is no opening days or custom opening days

const defaultOpeningDays = {
  openingTime: '090044',
  closingTime: '000044'
};

const defaultOpeningTime = moment()
  .hours(8)
  .minutes(0)
  .format('HHmmss');

const defaultClosingTime = moment()
  .hours(23)
  .minutes(0)
  .format('HHmmss');

const customTimeSection = {
  customOpeningTime: [
    {
      value: 0,
      label: 'Monday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 1,
      label: 'Tuesday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 2,
      label: 'Wednesday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 3,
      label: 'Thursday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 4,
      label: 'Friday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 5,
      label: 'Saturday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    },
    {
      value: 6,
      label: 'Sunday',
      checked: false,
      openingTime: defaultOpeningTime,
      closingTime: defaultClosingTime
    }
  ]
};
export { defaultOpeningDays, customTimeSection };
