import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import CasinoActions from '@old-world/features/casinos/redux/CasinosRedux';
import AboutUs from '@routes-3/main/myPokerRoom/generalInformation/components/AboutUs';
import Done from '@routes-3/main/myPokerRoom/generalInformation/components/Done';
import RulesAndContactInformation from '@routes-3/main/myPokerRoom/generalInformation/components/RulesAndContactInformation';
import useSettingsForm from '@routes-3/main/myPokerRoom/hooks/useSettingsForm';
import {
  ADMIN_CASH_GAME_RUNNING_GAMES,
  ADMIN_MY_POKER_ROOM_GAMES
} from '@routes-3/routes';
import { Modal, Steps as AntdSteps } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { useHistory } from 'react-router-dom';

import StandardInformation from '../generalInformation/components/StandardInformation';

const Steps = ({ current }: { current: number }) => {
  const steps = [
    {
      title: 'Standard Information',
      content: <StandardInformation />
    },
    {
      title: 'Rules & Contact Information',
      content: <RulesAndContactInformation />
    },
    {
      title: 'About Us',
      content: <AboutUs />
    },
    {
      title: 'Done',
      content: <Done />
    }
  ];

  return (
    <>
      <AntdSteps current={current} progressDot>
        {steps.map(item => (
          <AntdSteps.Step key={item.title} title={item.title} />
        ))}
      </AntdSteps>
      <div className="pt-4">{steps[current]?.content}</div>
    </>
  );
};

const STEPS = 3;

const OnboardingModal = ({ onClose }: { onClose: () => void }) => {
  const history = useHistory();
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const owner = useSelector(ownerSelector);

  useEffect(() => {
    owner &&
      dispatch(CasinoActions.fetchCasinoDetailsRequest(owner.managedCasinoId));
  }, [dispatch, owner]);

  const next = () => {
    setCurrent(Math.min(current + 1, STEPS));
  };

  const prev = () => {
    setCurrent(Math.max(current - 1, 0));
  };

  const okText = current == 2 ? 'Save' : current == 3 ? 'Continue' : 'Next';
  const isOnLastPage = current == 3;
  const { formMethods, onSubmitForm, stepsValidation } = useSettingsForm();
  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    setTimeout(() => {
      const width =
        document.getElementById('sidebar')?.getBoundingClientRect()?.width || 0;
      setOffset(width / 2);
    }, 10);
  }, []);

  const onClickNext = async () => {
    if (stepsValidation(current)) {
      if (current >= 0) {
        // @ts-ignore
        await onSubmitForm(formMethods.getValues()).then(() => {
          next();
        });
      } else {
        next();
      }
    } else if (isOnLastPage) {
      onClose();
      history.push(ADMIN_MY_POKER_ROOM_GAMES);
    }
  };

  return (
    // @ts-ignore
    <FormProvider {...formMethods}>
      <Modal
        style={{ left: offset }}
        width={800}
        open={true}
        destroyOnClose
        title={'General Information'}
        cancelText={current == 0 ? '' : current == 3 ? 'May be later' : 'Back'}
        onCancel={
          current == 0 || current == 3
            ? () => {
                onClose();
                history.push(ADMIN_CASH_GAME_RUNNING_GAMES);
              }
            : prev
        }
        closeIcon={null}
        onOk={onClickNext}
        centered
        okText={okText}
      >
        <Steps current={current} />
      </Modal>
    </FormProvider>
  );
};

export default OnboardingModal;
