import {
  InterestList,
  useGetRegisteredPlayersQuery,
  useStartInterestListMutation
} from '@api-3/endpoints/interestList';
import ModalTitleWithGameInfo from '@components-3/ModalTitleWithGameInfo';
import { ADMIN_CASH_GAME_RUNNING_GAMES } from '@routes-3/routes';
import { Form, Modal, notification, Spin } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useHistory } from 'react-router-dom';

import StartingInterestListForm, {
  FormShape
} from '../forms/StartingInterestListForm';

type Props = {
  onClose: () => void;
  interestList: InterestList;
};

const DEFAULT_FORM_VALUES = {
  tables: [
    {
      tableId: '1',
      maxPlayers: 8,
      players: []
    }
  ]
};

const StartingInterestListModal = ({ onClose, interestList }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: registeredPlayers, isLoading } = useGetRegisteredPlayersQuery(
    {
      id: interestList.id
    },
    { pollingInterval: 5000 }
  );

  const [
    startInterestList,
    { isLoading: isSaving }
  ] = useStartInterestListMutation();

  const formMethods = useForm<FormShape>({
    mode: 'onBlur',
    defaultValues: DEFAULT_FORM_VALUES
  });
  const { handleSubmit } = formMethods;

  const onSubmitForm = async (data: FormShape) => {
    const runningGameData: any = {
      flopGameId: interestList.id,
      table: data.tables.map((table: any) => ({
        buyIn: 0,
        gameSize: interestList.gameSize,
        buyInMin: interestList.buyInMin,
        buyInMax: interestList.buyInMax,
        gameVariant: interestList.gameVariant,
        ante: interestList.ante,
        maxPlayers: table.maxPlayers,
        tableId: table.tableId,
        players: table.players.map((playerId: string) => ({
          flopId: playerId
        }))
      })),
      waitingListPlayers: registeredPlayers?.map(player => {
        return { ...player, flopId: player.accountId };
      })
    };
    try {
      await startInterestList(runningGameData).unwrap();
      notification.success({
        message: 'Success',
        description: `Your game was successfully started.`,
        placement: 'topRight'
      });
      onClose();
      history.push(ADMIN_CASH_GAME_RUNNING_GAMES);
    } catch (e) {
      const { data, status } = e as {
        status: number;
        data: any;
      };
      if (status == 409) {
        formMethods.getValues().tables.map((table, index) => {
          if (data.find((t: any) => t.tableId === table.tableId)) {
            // @ts-ignore
            formMethods.setError(`tables[${index}].tableId`, {
              message: `Id '${table.tableId}' is already taken`
            });
          }
        });
      } else {
        console.error('An error has occurred: ', e);
        notification.error({
          message: 'Error',
          description: `An error occurred, while trying to start this interest list. Please Contact your administrator.`,
          placement: 'topRight'
        });
        onClose();
      }
    }
  };

  return (
    <Modal
      afterClose={() => {
        formMethods.reset();
      }}
      open={true}
      destroyOnClose
      centered
      title={
        <ModalTitleWithGameInfo
          game={interestList}
          title={t('STARTING_INTEREST_LIST')}
        />
      }
      onCancel={onClose}
      confirmLoading={isSaving}
      onOk={formMethods.handleSubmit(onSubmitForm)}
      okText={t('START_BUTTON')}
      okButtonProps={{ id: 'startGameButton', disabled: isLoading }}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        {isLoading ? (
          <Spin spinning={isLoading} size="large" />
        ) : (
          <Form onFinish={handleSubmit(onSubmitForm)} layout="vertical">
            <StartingInterestListForm
              availablePlayers={registeredPlayers?.length}
            />
          </Form>
        )}
      </FormProvider>
    </Modal>
  );
};

export default StartingInterestListModal;
