import { api } from '@api/api';
import { ListResponse } from '@api/types';

type AddMessagePayload = {
  content: string;
  entityId: string;
  entityType: 'GAME' | 'INTEREST_LIST';
};
export const messageApi = api
  .enhanceEndpoints({ addTagTypes: ['Message'] })
  .injectEndpoints({
    endpoints: builder => ({
      getMessages: builder.query<
        ListResponse<unknown>,
        {
          entityType: string;
          entityId: string;
          params: { page: number; size: number };
        }
      >({
        providesTags: ['Message'],
        query: ({ entityType, entityId, params }) =>
          `/messages/api/search/${entityType}/${entityId}?page=${params.page}&size=${params.size}`
      }),
      addMessage: builder.mutation<void, { body: AddMessagePayload }>({
        invalidatesTags: ['Message'],
        query: params => ({
          method: 'POST',
          url: '/messages/api/',
          body: params.body
        })
      })
    })
  });

export const { useGetMessagesQuery, useAddMessageMutation } = messageApi;
