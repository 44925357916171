import { ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { ADMIN_CASH_GAME_DEALER_DISPLAY } from '@routes-3/routes';
import { Form, Select } from 'antd';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
// @ts-ignore
import { useHistory } from 'react-router-dom';

type SelectTable = {
  tableId: string;
};
const DealerPageForm = ({
  allTables,
  setActiveTable
}: {
  allTables: Table[];
  setActiveTable: (table: Table) => void;
}) => {
  const history = useHistory();
  const formMethods = useForm<SelectTable>();
  function onSelectTable(id: string) {
    const selectedTable = allTables.find((table: Table) => table.id === id);
    if (selectedTable) {
      setActiveTable(selectedTable);
      history.push(`${ADMIN_CASH_GAME_DEALER_DISPLAY}/${id}`);
    } else {
      console.error(`Table with ID ${id} not found`);
    }
  }
  const selectedTable = useController({
    control: formMethods.control,
    name: 'tableId'
  });
  return (
    // @ts-ignore
    <FormProvider {...formMethods}>
      <Form layout="vertical" style={{ width: '50%' }}>
        <ASelect
          controller={selectedTable}
          hasFeedback
          // @ts-ignore
          onChange={onSelectTable}
          placeholder={'Select a table'}
        >
          {allTables.map(c => (
            <Select.Option key={c.id} value={c.id}>
              <div>
                <b># {c.tableId} - </b> {c.gameVariant} ({c.gameSize}
                {c.ante ? '/' + c.ante : ''})
              </div>
            </Select.Option>
          ))}
        </ASelect>
      </Form>
    </FormProvider>
  );
};

export default DealerPageForm;
