import { useGetCasinoNotificationsQuery } from '@api-3/endpoints/notifications';
import DealerCallImage from '@assets/images/notifications/dealer-call.png';
import DealerImage from '@assets/images/notifications/dealer.png';
import TableNotificationItem from '@old-world/flop-3.0/layout/components/TableNotificationItem';
import { Avatar, Dropdown, List, Spin } from 'antd';
import React, { useState } from 'react';

import styles from './CasinoNotification.module.less';

const TableNotification = () => {
  const [visible, setVisible] = useState(false);
  const { data: notifications, isLoading } = useGetCasinoNotificationsQuery(
    null,
    {
      pollingInterval: 5000
    }
  );
  const sortedNotifications = notifications
    ?.slice()
    .sort(
      (a, b) =>
        new Date(b.notificationDate).getTime() -
        new Date(a.notificationDate).getTime()
    );
  const hasUnreadNotifications = notifications?.some(
    notification => !notification.read
  );
  const handleVisibleChange = (visible: boolean): void => {
    setVisible(visible);
  };
  const getNotificationBox = () => {
    return (
      <>
        <div className="notifications-list-container d-flex flex-column justify-content-start align-items-center w-100">
          <div className="w-100 p-2 notification-header">
            <span className="notification-header-text font-weight-bold">
              TABLE NOTIFICATIONS
            </span>
          </div>
        </div>
        <div className="w-100" style={{ height: 300, overflowY: 'scroll' }}>
          <List
            className="w-100"
            dataSource={sortedNotifications || []}
            renderItem={(item, index) => (
              <TableNotificationItem key={index} notification={item} />
            )}
          >
            {isLoading && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </div>
      </>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Dropdown
        placement="bottomRight"
        overlay={getNotificationBox()}
        overlayClassName={styles.popover}
        trigger={['click']}
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Avatar
          className={`cursor-pointer ${
            hasUnreadNotifications ? 'blinking-dealer-avatar' : ''
          }`}
          src={hasUnreadNotifications ? DealerCallImage : DealerImage}
          size={46}
          style={{ opacity: 1 }}
          onClick={() => {
            return null;
          }}
        />
      </Dropdown>
    </div>
  );
};
export default TableNotification;
