import {
  ListEventItem,
  useGetAllTournamentsQuery
} from '@api-3/endpoints/festivals';
import { useModals } from '@providers/ModalProvider';
import TournamentsTable from '@routes-3/main/tournamentsManagement/common/TournamentsTable';
import { determineEventStatus } from '@routes-3/main/tournamentsManagement/events/components/EventCard';
import { Button, Card, Spin } from 'antd';
import React from 'react';

const EventDetailsPageBody = ({ event }: { event: ListEventItem }) => {
  const openModal = useModals();
  const {
    data: tournaments,
    isLoading: isTournamentsLoading
  } = useGetAllTournamentsQuery({ festivalId: event.id });

  return (
    <Card
      className="gx-card"
      title="Tournaments"
      extra={
        tournaments &&
        tournaments.length > 0 &&
        determineEventStatus(event.startDate, event.endDate) !== 'finished' && (
          <Button
            className={'gx-btn'}
            key="submit"
            type="default"
            style={{ position: 'relative', top: 14 }}
            onClick={() => {
              if (event) {
                openModal({
                  type: 'CREATE_TOURNAMENT',
                  props: {
                    type: 'EVENT_TOURNAMENTS',
                    event: event
                  }
                });
              } else {
                return null;
              }
            }}
          >
            Create an Tournament
          </Button>
        )
      }
    >
      {isTournamentsLoading ? (
        <Spin tip="Loading" size="large" />
      ) : (
        <div style={{ height: 'calc(100vh - 588px)', overflowY: 'scroll' }}>
          {tournaments && tournaments.length > 0 ? (
            <TournamentsTable
              tournaments={tournaments ? tournaments : []}
              type={'EVENT_TOURNAMENTS'}
              event={event}
            />
          ) : (
            <div
              className={'mt-3'}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <h2>
                {determineEventStatus(event.startDate, event.endDate) ===
                'finished'
                  ? 'This tournament has ended'
                  : 'No Tournaments Running Yet!'}
              </h2>
              {determineEventStatus(event.startDate, event.endDate) !==
                'finished' && (
                <>
                  <p>Here's your stage to create some action!</p>
                  <ol
                    style={{
                      textAlign: 'left',
                      marginBottom: '20px',
                      listStyleType: 'none'
                    }}
                  >
                    <li>
                      <b>Create A Tournament</b>: Create an tournament so Flop
                      players know when you offer events.
                    </li>
                  </ol>
                  <div>
                    <Button
                      className={'gx-btn'}
                      key="submit"
                      type="default"
                      onClick={() => {
                        if (event) {
                          openModal({
                            type: 'CREATE_TOURNAMENT',
                            props: {
                              type: 'EVENT_TOURNAMENTS',
                              event: event
                            }
                          });
                        } else {
                          return null;
                        }
                      }}
                    >
                      Create an Tournament
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default EventDetailsPageBody;
