import './Card.scss';

import { useGetPlayersCountQuery } from '@api-3/endpoints/interestList';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import CardPlayersList from '@routes-3/main/cashGameManagement/fullDisplay/components/cards/CardPlayersList';
import { GameObject } from '@routes-3/main/cashGameManagement/fullDisplay/components/FullDisplay';
import { Progress } from 'antd';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const DisplayCard = ({ game }: { game: GameObject }) => {
  const { t } = useTranslation();
  const [tableIds, setTableIds] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [playersCounter, setPlayersCounter] = useState(0);
  const [tableCapacityPercent, setTableCapacityPercent] = useState<number>(0);
  const MAX_LENGTH = 5;
  const [seatsAvailable, setSeatsAvailable] = useState(0);
  const [tableState, setTableState] = useState('success');
  const truncate = (str: string) => {
    return str.length > 16 ? str.substring(0, 15) + '...' : str;
  };

  const { data: upcomingPlayerCount } = useGetPlayersCountQuery(
    {
      id: game.content?.id
    },
    { pollingInterval: 50000, skip: game.gameType === 'RUNNING_GAME' }
  );

  useEffect(() => {
    if (game.gameType === 'RUNNING_GAME' && game.content.tables.length > 0) {
      const ids = getAvailableTablesId(game.content.tables);
      setTableIds(ids);
      setTotalSize(
        game.content.tables.reduce(
          (accumulator, currentItem) => accumulator + currentItem.maxPlayers,
          0
        )
      );
      setPlayersCounter(
        game.content.tables.reduce(
          (accumulator, currentItem) =>
            accumulator + currentItem.players.length,
          0
        )
      );
      const percentage = playersCounter
        ? Math.ceil((playersCounter * 100) / totalSize)
        : 0;

      setTableCapacityPercent(percentage);
      setSeatsAvailable(totalSize - playersCounter);

      if (percentage < 41) setTableState('success');
      if (percentage > 41 && percentage < 99) setTableState('warning');
      if (percentage > 99) setTableState('danger');
    }

    if (game.gameType === 'UPCOMING_GAME') {
      setTableState('primary-light');
    }
  }, [
    game,
    totalSize,
    playersCounter,
    tableCapacityPercent,
    tableState,
    seatsAvailable
  ]);

  const getAvailableTablesId = (tables: any) => {
    return tables
      .filter((table: any) => {
        const { maxPlayers, players } = table;
        return maxPlayers !== players;
      })
      .map((table: any) => table.tableId);
  };
  return (
    <div className="card">
      <div
        className={`card-header d-flex flex-column align-items-center justify-content-center bg-${tableState}`}
      >
        {game.gameType === 'RUNNING_GAME' && seatsAvailable === 0 && (
          <span
            className="gx-widget-badge fontWeight600"
            style={{
              fontWeight: 'bold',
              backgroundColor: '#c5520a',
              color: '#000000',
              borderRadius: '10px 0',
              left: -3,
              right: 'auto'
            }}
          >
            FULL
          </span>
        )}
        <div style={{ fontSize: 20 }}>
          <div>
            <strong>{truncate(game.content.gameVariant)}</strong>
          </div>
          <div>
            {game.content.gameSize}
            {game.content.ante ? `/${game.content.ante}` : null}
          </div>
        </div>
        <div className={'gx-fs-sm'}>
          Buy-In:{' '}
          {game.content.buyInMin ? formatBuyIn(game.content.buyInMin) : 'NO'} -{' '}
          {game.content.buyInMax ? formatBuyIn(game.content.buyInMax) : 'NO'}
        </div>
        {game.gameType === 'RUNNING_GAME' && (
          <div className={'max-players-status w-100'}>
            <div className="ant-row-flex mb-1">
              <span className="gx-mr-1">
                {game.content.tables[0].maxPlayers}-max per Table
              </span>
              <span className="mr-1">
                | {seatsAvailable}{' '}
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ fontSize: 12, position: 'relative', bottom: 1 }}
                />
              </span>
              <span>Free seats</span>
            </div>
            <Progress
              percent={tableCapacityPercent}
              status="active"
              strokeColor={{ '0%': '#038fde', '100%': '#87d068' }}
            />
          </div>
        )}
        {game.gameType === 'UPCOMING_GAME' && (
          <div className={'mt-2'}>
            <span
              className={'fontWeight600'}
              style={{ fontSize: 14, position: 'relative', bottom: 1 }}
            >
              <span>
                {game.content.date &&
                  t('DATE_GAME_SHORT', {
                    date: moment(game.content.date).parseZone()
                  })}
                {' at '}
                {game.content.date &&
                  t('TIME_GAME', {
                    time: moment(game.content.date).parseZone()
                  })}
              </span>
            </span>
          </div>
        )}
      </div>
      <div
        className={`card-tables d-flex flex-row align-items-center justify-content-center game-status-${tableState}`}
      >
        {game.gameType === 'RUNNING_GAME' && (
          <>
            <strong className={'mr-2'}>
              <u>Open tables:</u>
            </strong>
            <span
              className={'fontWeight600'}
              style={{ fontSize: 20, position: 'relative', bottom: 2 }}
            >
              {_.toString(tableIds)}
            </span>
          </>
        )}
        {game.gameType === 'UPCOMING_GAME' && (
          <strong>
            <u>Upcoming Game</u>
          </strong>
        )}
      </div>
      <div className="card-body">
        <div className={'d-flex flex-column w-100'}>
          <p>
            <strong>
              <u>
                {game.gameType === 'RUNNING_GAME' && 'Waiting List'}
                {game.gameType === 'UPCOMING_GAME' && 'Players'}
              </u>
            </strong>
          </p>
          {game.gameType == 'RUNNING_GAME' && game.content.expectedWaitingTime
            ? `Expected Waiting time for the first 3 players is: ${game.content.expectedWaitingTime}`
            : ''}
          <CardPlayersList game={game} maxLength={MAX_LENGTH} />
        </div>
        {game.gameType === 'RUNNING_GAME' &&
          game.content.waitingPlayers.length > MAX_LENGTH && <p>...</p>}
        {game.gameType === 'UPCOMING_GAME' &&
          upcomingPlayerCount !== undefined &&
          upcomingPlayerCount > MAX_LENGTH && <p>...</p>}
      </div>
      <div className="card-status">
        {game.gameType === 'RUNNING_GAME' && game.content.waitingPlayers && (
          <p>
            {t('WAITING_PLAYERS_NUMBER', {
              number: game.content.waitingPlayers.length
            })}
          </p>
        )}
        {game.gameType === 'UPCOMING_GAME' && (
          <p>{upcomingPlayerCount} Registered Players</p>
        )}
      </div>
    </div>
  );
};
export default DisplayCard;
