import LineIndicator from '@components-3/LineIndicator/LineIndicator';
import { Card } from 'antd';

type PopularGamesIndicatorProps = {
  gameVariant: string;
  bigBlind: number;
  smallBlind: number;
  ante: number;
  playerCounter: number;
  percentage: number;
}[];

const PopularGamesIndicator = ({
  popularGamesIndicator
}: {
  popularGamesIndicator: PopularGamesIndicatorProps;
}) => {
  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <Card
      className={'gx-card-widget gx-card-full'}
      title="Popular Games Indicator"
    >
      <div style={{ height: 300, overflow: 'auto', padding: '24px 20px' }}>
        <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
          {popularGamesIndicator.map((item, index) => (
            <li key={index}>
              <LineIndicator
                width={Math.round(item.percentage * 100) + '%'}
                title={
                  item.gameVariant +
                  ' ' +
                  item.smallBlind +
                  '/' +
                  item.bigBlind +
                  '/' +
                  item.ante
                }
                title2={item.playerCounter + ' Players'}
                color={getRandomColor()}
                value={Math.round(item.percentage * 100) + '%'}
              />
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default PopularGamesIndicator;
