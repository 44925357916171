const Done = () => {
  return (
    <div>
      <p className={'gx-text-center gx-fs-xl'}>Congratulations!</p>
      <p className={'gx-text-center'}>
        Your Poker Room Profile is now completed, and your Poker Room profile is
        listed in flop APP.
      </p>
      <p className={'gx-text-center'}>
        Do you want to complete your Games Information, to share with the flop
        APP community the offered games in daily basis?
      </p>
    </div>
  );
};

export default Done;
