import Form from '@components/forms/Form';
import CasinoContactSection from '@routes-3/main/myPokerRoom/generalInformation/components/CasinoContactSection';
import PokerRoomSection from '@routes-3/main/myPokerRoom/generalInformation/components/PokerRoomSection';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const RulesAndContactInformation = ({ disabled }: { disabled?: boolean }) => {
  const formMethods = useFormContext();

  return (
    <Form>
      {/*@ts-ignore*/}
      <PokerRoomSection control={formMethods.control} isEditing={!disabled} />
      <div className={'pt-4'} />
      <CasinoContactSection disabled={disabled} />
    </Form>
  );
};

export default RulesAndContactInformation;
