import { Row, Spin } from 'antd';
import React from 'react';

const PageLoading: React.FC = () => (
  // @ts-ignore
  <Row type="flex" justify="center" align="middle" className="vh100">
    <Spin size="large" />
  </Row>
);
export default PageLoading;
