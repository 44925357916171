import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import {
  DailyTournamentsPerDay,
  EventTournamentsPerDay,
  ListEventItem,
  useDeleteDailyTournamentMutation,
  useDeleteTournamentMutation
} from '@api-3/endpoints/festivals';
import { useModals } from '@providers/ModalProvider';
import { ColumnObjType } from '@routes-3/main/cashGameManagement/interestList/types';
import { tournamentsTableColumns } from '@routes-3/main/tournamentsManagement/eventDetails/constants';
import { TOURNAMENT_LIST_COLUMN } from '@routes-3/main/tournamentsManagement/eventDetails/types';
import { determineEventStatus } from '@routes-3/main/tournamentsManagement/events/components/EventCard';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment/moment';
import React from 'react';

const TournamentsTable = ({
  tournaments,
  type,
  event
}: {
  tournaments: EventTournamentsPerDay[] | DailyTournamentsPerDay[] | [];
  type: 'EVENT_TOURNAMENTS' | 'DAILY_TOURNAMENTS';
  event?: ListEventItem;
}) => {
  const openModal = useModals();
  const tournamentsColumns: ColumnsType<any> = [{ key: 'placeholder' }];
  const [deleteTournament] = useDeleteTournamentMutation();
  const [deleteDailyTournament] = useDeleteDailyTournamentMutation();
  const showActionButtons =
    type === 'DAILY_TOURNAMENTS'
      ? true
      : !!event &&
        determineEventStatus(event.startDate, event.endDate) !== 'finished';
  console.log('showActionButtons: ', showActionButtons);
  tournamentsTableColumns.map((column: any) => {
    tournamentsColumns.push({
      key: column.key,
      title: column.title,
      render: (tournamentListItem: any) => {
        return renderColumnObj(column.dataIndex, tournamentListItem);
      },
      onCell: record => {
        return {
          colSpan: record.children
            ? column.dataIndex === TOURNAMENT_LIST_COLUMN.DATE
              ? 11
              : 0
            : 1
        };
      }
    });
  });

  function renderColumnObj(column: any, tournamentListItem: any) {
    const columnObj: ColumnObjType = {
      children: <React.Fragment />,
      props: {}
    };
    if (tournamentListItem.children) {
      columnObj.children = (
        <div
          style={{
            width: '100%',
            position: 'relative'
          }}
        >
          <span
            style={{
              textAlign: 'center',
              fontSize: 20,
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              color: '#13c2c2'
            }}
          >
            {moment(tournamentListItem.date).format('dddd Do MMMM, YYYY')}
          </span>
          {showActionButtons && (
            <PlusCircleOutlined
              size={30}
              style={{
                fontSize: 20,
                color: '#13c2c2',
                position: 'absolute',
                right: 50,
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              key={'addTournament'}
              onClick={() => {
                openModal({
                  type: 'CREATE_TOURNAMENT',
                  props: {
                    type: type,
                    event: event ? event : undefined,
                    startDate: tournamentListItem.date
                  }
                });
              }}
            />
          )}
        </div>
      );
      return columnObj;
    }
    switch (column) {
      case TOURNAMENT_LIST_COLUMN.DATE:
        columnObj.children = (
          <span className={'gx-font-weight-bold'}>
            {moment.utc(tournamentListItem.date).format('HH:mm')}
          </span>
        );
        break;
      case TOURNAMENT_LIST_COLUMN.EVENT_NUMBER:
        columnObj.children = <span>{tournamentListItem.eventNumber}</span>;
        break;
      case TOURNAMENT_LIST_COLUMN.BUY_IN:
        columnObj.children = (
          <span>
            €{tournamentListItem.buyIn} + €{tournamentListItem.fee}
          </span>
        );
        break;
      case TOURNAMENT_LIST_COLUMN.GAME_VARIANT:
        columnObj.children = <span>{tournamentListItem.gameVariant}</span>;
        break;
      case TOURNAMENT_LIST_COLUMN.NAME:
        columnObj.children = (
          <span className={'gx-font-weight-bold'}>
            {tournamentListItem.name}
          </span>
        );
        break;
      case TOURNAMENT_LIST_COLUMN.STARTING_STACK:
        columnObj.children = <span>{tournamentListItem.startStack}</span>;
        break;
      case TOURNAMENT_LIST_COLUMN.ENTRIES:
        columnObj.children = <span>{tournamentListItem.entries}</span>;
        break;
      case TOURNAMENT_LIST_COLUMN.LEVEL_DURATION:
        columnObj.children = (
          <span>{tournamentListItem.levelDuration} min</span>
        );
        break;
      case TOURNAMENT_LIST_COLUMN.LATE_REGISTRATION_LEVEL:
        columnObj.children = (
          <span>{tournamentListItem.lateRegistrationLevel} Level</span>
        );
        break;
      case TOURNAMENT_LIST_COLUMN.ACTIONS:
        columnObj.children = (
          <>
            {showActionButtons && (
              <>
                <EditOutlined
                  className={'cursor-pointer mr-3'}
                  key="edit"
                  onClick={() => {
                    openModal({
                      type: 'CREATE_TOURNAMENT',
                      props: {
                        type: type,
                        event: event ? event : undefined,
                        tournament: tournamentListItem
                      }
                    });
                  }}
                />
                <DeleteOutlined
                  className={'gx-text-danger cursor-pointer mr-3'}
                  key="delete"
                  onClick={() => {
                    Modal.confirm({
                      title:
                        'Are you sure you want to remove this tournament? This action can’t be undone.',
                      okText: 'Remove',
                      okType: 'danger',
                      cancelText: 'No',
                      async onOk() {
                        if (type === 'EVENT_TOURNAMENTS' && event) {
                          await deleteTournament({
                            festivalId: event.id,
                            tournamentId: tournamentListItem.id
                          });
                        } else if (type === 'DAILY_TOURNAMENTS') {
                          await deleteDailyTournament({
                            tournamentId: tournamentListItem.id
                          });
                        }
                      }
                    });
                  }}
                />
              </>
            )}
          </>
        );
        break;
    }
    return columnObj;
  }
  return (
    <Table
      defaultExpandAllRows
      dataSource={tournaments}
      columns={tournamentsColumns}
      pagination={false}
      rowKey={(record: any) => (record.children ? record.date : record.id)}
    />
  );
};

export default TournamentsTable;
