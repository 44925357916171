import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { Section } from '@routes-3/main/myPokerRoom/generalInformation/GeneralInformationPage';
import GameSettings from '@routes-3/main/myPokerRoom/settings/GameSettings';
import NotificationTemplate from '@routes-3/main/myPokerRoom/settings/NotificationTemplate';
import { Card, Col, Row } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { FormProvider } from 'react-hook-form';

export type SettingsFormShape = {
  reserveAllowedTime: string | Moment;
  userMaxDistance: number;
  userMaxDistanceUnit: 'KILOMETERS' | 'MILES';
};
const SettingsPage = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Settings'}
      />
      <Row className="gx-layouts-view">
        <Col xl={6} lg={6} md={6} sm={6} xs={6}>
          <Card className="gx-card">
            <Section>
              {(formMethods, disabled) => (
                <FormProvider {...formMethods}>
                  <GameSettings disabled={disabled} />
                </FormProvider>
              )}
            </Section>
          </Card>
        </Col>
        <Col xl={18} lg={18} md={18} sm={18} xs={18}>
          <NotificationTemplate />
        </Col>
      </Row>
    </LayoutContentContainer>
  );
};

export default SettingsPage;
