import flopLogo from '@assets-3/images/flop-pm-logo-dark.png';
import slide1 from '@assets-3/images/slide1.png';
import slide2 from '@assets-3/images/slide2.png';
import slide3 from '@assets-3/images/slide3.png';
import SignUpForm from '@routes-3/userAuth/signUp/SignUpForm';
import { Carousel } from 'antd';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SignUpPage = () => {
  const [t] = useTranslation();
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: '900px' }}>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content p-0">
            <Carousel autoplay autoplaySpeed={7000} className={'gx-mb-0'}>
              <div className={'gx-position-relative'}>
                <img src={slide1} alt="slide1" height={680} />
                <p className="legend">
                  {t('AUTH_FIRST_SLIDE_TEXT')
                    .split('\n')
                    .map((item: string, key: number) => {
                      return (
                        <Fragment key={key}>
                          {item}
                          <br />
                        </Fragment>
                      );
                    })}
                </p>
              </div>
              <div className={'gx-position-relative'}>
                <img src={slide2} alt="slide2" height={680} />
                <p className="legend">
                  {t('AUTH_SECOND_SLIDE_TEXT')
                    .split('\n')
                    .map((item: string, key: number) => {
                      return (
                        <Fragment key={key}>
                          {item}
                          <br />
                        </Fragment>
                      );
                    })}
                </p>
              </div>
              <div className={'gx-position-relative'}>
                <img src={slide3} alt="slide3" height={680} />
                <p className="legend">
                  {t('AUTH_THIRD_SLIDE_TEXT')
                    .split('\n')
                    .map((item: string, key: number) => {
                      return (
                        <Fragment key={key}>
                          {item}
                          <br />
                        </Fragment>
                      );
                    })}
                </p>
              </div>
            </Carousel>
          </div>
          <div className="gx-app-login-content">
            <div className={'gx-text-center gx-mb-3'}>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
            </div>
            <h1 className="gx-login-title">Create account</h1>
            <h5 className={'gx-mb-2'}>
              The Poker Marketplace for Poker operators
            </h5>
            <SignUpForm />
            <div className={'gx-text-center gx-mb-2'}>
              Already have a account?
            </div>
            <h4 className={'gx-text-center'}>
              {/*@ts-ignore*/}
              <Link
                className="gx-text-underline"
                style={{ color: '#4F8DCA' }}
                to="/user-auth/sign-in"
              >
                Sign In
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
