import './RunningGamesMap.scss';

import { RightOutlined } from '@ant-design/icons';
import { useModals } from '@providers/ModalProvider';
import TablesMap from '@routes-3/main/cashGameManagement/runningGamesMap/components/TablesMap';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Button, Tabs } from 'antd';
import React from 'react';

const RunningGamesMap = ({
  runningGames,
  isLoading,
  onChange,
  selectedTab
}: {
  runningGames: RunningGame[];
  isLoading: boolean;
  onChange: (key: string) => void;
  selectedTab: string;
}) => {
  const openModal = useModals();
  return (
    <div style={{ position: 'relative' }}>
      <Button
        style={{ position: 'absolute', right: 0 }}
        className={'gx-btn'}
        key="submit"
        type="default"
        loading={isLoading}
        onClick={() => {
          openModal({
            type: 'CREATE_GAME',
            props: {}
          });
        }}
      >
        Add Game
      </Button>
      <Tabs
        className="running-game-tab"
        defaultActiveKey="ALL"
        size={'large'}
        moreIcon={<RightOutlined />}
        onChange={onChange}
        activeKey={selectedTab}
      >
        <Tabs.TabPane tab={'All Tables'} key="ALL">
          <TablesMap games={runningGames} />
        </Tabs.TabPane>
        {runningGames.map((game: RunningGame) => (
          <Tabs.TabPane
            key={
              game.gameVariant +
              '/' +
              game.gameSize +
              (game.ante !== null ? '/' + game.ante : '')
            }
            tab={
              game.gameVariant.toUpperCase() +
              ` (${game.gameSize}${game.ante !== null ? '/' + game.ante : ''})`
            }
          >
            <TablesMap game={game} onChange={onChange} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default RunningGamesMap;
