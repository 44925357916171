import { Filter, useGetInterestListQuery } from '@api-3/endpoints/interestList';
import {
  NOTIFICATION_TYPE,
  useGetNotificationsQuery
} from '@api-3/endpoints/notifications';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import {
  currentCasinoSelector,
  ownerSelector
} from '@old-world/features/authentication/redux/AuthenticationRedux';
import { INITIAL_GAME_STATES_FILTER } from '@routes-3/main/cashGameManagement/interestList/constants';
import ChatBox from '@routes-3/main/chat/components/ChatBox';
import ChatSideNav from '@routes-3/main/chat/components/ChatSideNav';
import { dataTestIds } from '@routes-3/main/contactUs/ContactUsPage';
import { Card, Spin } from 'antd';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ChatPage = () => {
  const casinoId = useSelector(currentCasinoSelector);
  const owner = useSelector(ownerSelector);
  const filter: Filter = {
    gameStates: INITIAL_GAME_STATES_FILTER,
    gameVariant: []
  };
  const [selectedInterestGameId, setSelectedInterestGameId] = useState<string>(
    ''
  );
  const {
    data: ListResponse,
    isLoading: isLoadingInterestList
  } = useGetInterestListQuery(
    {
      queryParams: {
        ...filter,
        page: 1,
        size: 200,
        casinoId: casinoId,
        sort: 'ASC',
        dateFrom: encodeURIComponent(
          moment()
            .startOf('day')
            .subtract(12, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
        )
      }
    },
    { pollingInterval: 10000 }
  );

  const { data: messageNotifications } = useGetNotificationsQuery(
    {
      userId: owner.id,
      notificationType: NOTIFICATION_TYPE.GAME_MESSAGE,
      page: 1,
      size: 200
    },
    { pollingInterval: 10000 }
  );

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Chat'}
        pageIcon={
          <i
            className="icon icon-chat-new"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
      />
      <Card
        data-testid={dataTestIds.root}
        className="gx-card card-without-padding"
      >
        <div className="gx-chat-module-box">
          {!isLoadingInterestList && ListResponse && messageNotifications ? (
            <>
              <ChatSideNav
                messageNotifications={messageNotifications.content}
                interestList={ListResponse.content}
                selectedInterestGameId={selectedInterestGameId}
                setSelectedInterestGameId={setSelectedInterestGameId}
              />
              <ChatBox
                selectedInterestGameId={selectedInterestGameId}
                interestList={ListResponse.content.find(
                  game => game.id === selectedInterestGameId
                )}
              />
            </>
          ) : (
            <Spin />
          )}
        </div>
      </Card>
    </LayoutContentContainer>
  );
};

export default ChatPage;
