import { api } from '@api/api';
import { ListResponse } from '@api/types';
import { GAME_VARIANT } from '@constants/type';
import { parseParams } from '@old-world/utils/ApiUtils';

export type GameInvitationsDTO = {
  accountId: number;
  id: string | number;
  inviterId: number;
  name: string;
  presenceStatus: string;
  status: string;
};

type StartInterestListPayload = {
  flopGameId: string;
  table: {
    buyIn: 0;
    gameSize: string;
    gameVariant: string;
    maxPlayers: number;
    tableId: string;
    players: {
      flopId: string;
    }[];
  }[];
  waitingListPlayers: {
    flopId: string;
  }[];
};

type CreateInterestListPayload = {
  gameVariant: string;
  casinoId: string;
  casino: string;
  country: string;
  city: string;
  ownerId: string;
  gameOrigin: 'CASINO';
  gameSize: string;
  date: string;
  bigBlind: number;
  smallBlind: number;
  ante?: number;
  buyInMin: number;
  buyInMax?: number;
};

export type InterestList = {
  id: string;
  gameOrigin: 'CASINO' | 'FLOP_USER';
  casino: string;
  casinoId: string;
  position: { lat: number; lon: number };
  gameSize: string;
  gameVariant: string;
  ante?: string;
  state: 'DECLINED' | 'ACCEPTED' | 'PENDING';
  date: string;
  maxPlayers: number;
  ownerId: number;
  playersNumber: number;
  tables: any[];
  gameId: string;
  buyInMin?: number;
  buyInMax?: number;
  templateId?: number;
  liveGameKey: string;
};

export type Filter = {
  gameVariant: typeof GAME_VARIANT[];
  gameStates: string[];
};

const interestListApi = api
  .enhanceEndpoints({ addTagTypes: ['InterestList'] })
  .injectEndpoints({
    endpoints: builder => ({
      getGameDetails: builder.query<InterestList, { gameId: string }>({
        providesTags: ['InterestList'],
        query: ({ gameId }) => `/games/api/${gameId}`
      }),
      declineInterestList: builder.mutation<void, { gameId: string }>({
        invalidatesTags: ['InterestList'],
        query: ({ gameId }) => ({
          method: 'PUT',
          url: `/games/api/${gameId}/decline`
        })
      }),
      cancelInterestList: builder.mutation<void, { gameId: string }>({
        invalidatesTags: ['InterestList'],
        query: ({ gameId }) => ({
          method: 'PUT',
          url: `/games/api/${gameId}/cancel`
        })
      }),
      convertInterestListToGame: builder.mutation<void, { gameId: string }>({
        invalidatesTags: ['InterestList'],
        query: ({ gameId }) => ({
          method: 'PUT',
          url: `/games/api/${gameId}/confirm`
        })
      }),
      createInterestList: builder.mutation<
        void,
        { body: CreateInterestListPayload }
      >({
        invalidatesTags: ['InterestList'],
        query: params => ({
          method: 'POST',
          url: `/games/api/`,
          body: params.body
        })
      }),
      startInterestList: builder.mutation<void, StartInterestListPayload>({
        invalidatesTags: ['InterestList'],
        query: payload => ({
          method: 'POST',
          url: `/games/api/live/cash-games/`,
          body: payload
        })
      }),
      getInterestList: builder.query<
        ListResponse<InterestList>,
        {
          queryParams: {
            page: number;
            size: number;
            dateFrom: string;
            casinoId: string;
            sort: 'ASC' | 'DESC';
          } & Filter;
        }
      >({
        providesTags: ['InterestList'],
        extraOptions: { maxRetries: 1 },
        query: ({ queryParams }) =>
          `/games/api/CASH_GAME/upcoming/search?${parseParams(queryParams)}`
      }),
      getRegisteredPlayers: builder.query<
        GameInvitationsDTO[],
        {
          id: string;
        }
      >({
        providesTags: ['InterestList'],
        extraOptions: { maxRetries: 1 },
        query: ({ id }) => `/games/api/${id}/members/confirmed`
        // transformResponse: (response: { accountId: number }[]) => {
        //   return response.map(r => r.accountId);
        // }
      }),
      getPlayersCount: builder.query<
        number,
        {
          id: string;
        }
      >({
        providesTags: [],
        extraOptions: { maxRetries: 1 },
        query: ({ id }) => `/games/api/${id}/members/confirmed/count`
      }),
      addPlayerToInterestList: builder.mutation<
        void,
        { gameId: string; accountId?: number; name?: string }
      >({
        invalidatesTags: ['InterestList'],
        query: ({ gameId, accountId, name }) => ({
          method: 'POST',
          body: { accountId, name },
          url: `/games/api/${gameId}/join`
        })
      }),
      removePlayerFromInterestList: builder.mutation<
        void,
        { accountId?: number; id?: string; gameId: string }
      >({
        invalidatesTags: ['InterestList'],
        query: ({ gameId, accountId, id }) => ({
          method: 'POST',
          body: { gameId, accountId, id },
          url: `/games/api/${gameId}/quit`
        })
      })
    })
  });

export const {
  useGetGameDetailsQuery,
  useGetInterestListQuery,
  useCreateInterestListMutation,
  useConvertInterestListToGameMutation,
  useCancelInterestListMutation,
  useDeclineInterestListMutation,
  useStartInterestListMutation,
  useGetRegisteredPlayersQuery,
  useGetPlayersCountQuery,
  useAddPlayerToInterestListMutation,
  useRemovePlayerFromInterestListMutation
} = interestListApi;
