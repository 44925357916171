import { ColumnFilterItem } from 'antd/lib/table/interface';

export const INITIAL_GAME_ORIGIN_FILTER = ['FLOP_USER', 'CASINO'];
export const INITIAL_GAME_STATES_FILTER = ['PENDING', 'ACCEPTED', 'DECLINED'];

export const GAME_ORIGIN_FILTER: ColumnFilterItem[] = [
  {
    text: 'Flop IL',
    value: 'FLOP_USER'
  },
  {
    text: 'Casino',
    value: 'CASINO'
  }
];

export const GAME_STATES_FILTER: ColumnFilterItem[] = [
  {
    text: 'Pending',
    value: 'PENDING'
  },
  {
    text: 'Accepted',
    value: 'ACCEPTED'
  },
  {
    text: 'Declined',
    value: 'DECLINED'
  }
];

export const INITIAL_GAME_VARIANT_FILTER = [
  'LH',
  'NLH',
  "Texas Hold'em - PL",
  'Omaha - NL',
  'Omaha - Limit',
  'PLO',
  'Omaha Hi/ Lo - NL',
  'Omaha Hi/ Lo - Limit',
  'Omaha Hi-Lo - Pot Limit',
  'Omaha 5 - Pot Limit',
  'Omaha 5 - NL',
  '5 Card Omaha Hi/ Lo - Limit',
  'Omaha 5 Hi/ Lo - Pot Limit',
  'Omaha 5 Hi/ Lo - NL',
  'Courchevel - NL',
  'Courchevel - Limit',
  'Courchevel - PL',
  'Courchevel Hi/ Lo - NL',
  'Courchevel Hi/ Lo - Limit',
  'Courchevel Hi/ Lo - Pot Limit',
  '7 Card Stud - NL',
  '7 Card Stud - Limit',
  '7 Card Stud - Pot Limit',
  '7 Card Stud Hi/ Lo - NL',
  '7 Card Stud Hi/ Lo - Limit',
  '7 Card Stud Hi/ Lo - Pot Limit',
  'Razz - NL',
  'Razz - Limit',
  'Razz - Pot Limit',
  '5 Card Stud Sökö',
  '5 Card Canadian Stud',
  'Short deck',
  '5 Card Draw - NL',
  '5 Card Draw - Limit',
  '5 Card Draw - Pot Limit',
  'Archie - NL',
  'Archie - Limit',
  'Archie - Pot Limit',
  'Sviten Special - NL',
  'Sviten Special - Limit',
  'Sviten Special - Pot Limit',
  '2-7 Triple Draw - NL',
  '2-7 Triple Draw - Limit',
  '2-7 Triple Draw - Pot Limit',
  '2-7 Single Draw - NL',
  '2-7 Single Draw - Limit',
  '2-7 Single Draw - Pot Limit',
  'HORSE',
  '8-Game',
  'Badugi',
  'Badeucey',
  'Badacey',
  'Open Face Chinese',
  'Big O'
];

export const MAIN_FOUR_GAME_VARIANTS = ['NLH', 'PLO', 'LH', 'Short deck'];
