import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import flopLogo from '@assets-3/images/flop-pm-logo.png';
import AuthenticationActions from '@old-world/features/authentication/redux/AuthenticationRedux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';

type MatchParam = {
  email: string;
};

const EmailUnverifiedPage = (props: any) => {
  const [t] = useTranslation();
  const email = props.match.params.email;
  const dispatch = useDispatch();
  const { match } = useReactRouter<MatchParam>();
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: '800px' }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div className={'gx-text-center'}>
                <MailOutlined style={{ fontSize: '100px' }} />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">Warning!</h1>
              <h4>
                Your email (<strong>{email}</strong>) address is not yet
                confirmed.
              </h4>
              <h4>
                Please check out your emails, and confirm your email address to
                be able to access flop PM.
              </h4>
              <h4 className={'gx-text-center'}>
                {/*@ts-ignore*/}
                <Link
                  className="gx-text-underline"
                  style={{ color: '#4F8DCA' }}
                  to="/"
                >
                  Go to Login
                </Link>
              </h4>
            </div>
            <p className="gx-mt-4">
              {t('RESEND_MAIL_TEXT')}{' '}
              <span
                id="resendEmailButton"
                className="text-decoration-underline text-primary cursor-pointer"
                onClick={() =>
                  dispatch(
                    AuthenticationActions.resendEmailRequest(match.params.email)
                  )
                }
              >
                {t('RESEND_MAIL_LINK')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailUnverifiedPage;
