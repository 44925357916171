import { LoadingContainer } from '@old-world/common/components/container';
import { IRootState } from '@old-world/common/models/StateModel';
import { createLoadingSelector } from '@old-world/common/redux/LoadingRedux';
import { Empty, List } from 'antd';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  renderItem: (playerId: number) => ReactNode;
  searchResults: any[];
};

const PlayersListComp = (props: Props) => {
  const { renderItem, searchResults } = props;

  const isFetchingPlayer = useSelector((state: IRootState) =>
    createLoadingSelector(['SEARCH_PLAYERS'])(state.loading)
  );

  return (
    <div
      className="d-flex flex-column justify-content-start align-items-center w-100 modal-overflow"
      style={{ height: 250 }}
    >
      {!isFetchingPlayer && searchResults.length === 0 ? (
        <Empty description="No Flop Users found" />
      ) : (
        <List
          dataSource={searchResults}
          renderItem={renderItem}
          className="w-100"
        />
      )}
    </div>
  );
};

export default LoadingContainer(['SEARCH_PLAYERS'])(PlayersListComp);
