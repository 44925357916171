import './OfferedGamesList.scss';

import { Button, List } from 'antd';
import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';

import OfferedGameRow from './OfferedGameRow';

export type OfferedGameFormShape = {
  variant: string;
  smallBlind: number | string;
  bigBlind: number | string;
  ante?: number | string;
  _editing: boolean;
  new?: boolean;
};

const OfferedGamesList = ({
  control,
  onSave
}: {
  control: Control<{ games: OfferedGameFormShape[] }>;
  onSave: () => void;
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<
    { games: OfferedGameFormShape[] },
    'games',
    'id'
  >({
    control,
    name: 'games'
  });

  return (
    <List
      className={'offeredGameList'}
      itemLayout="horizontal"
      dataSource={(fields as unknown) as OfferedGameFormShape[]}
      split={false}
      footer={
        <Button
          id="addGameButton"
          type="link"
          icon={<AiOutlinePlus />}
          onClick={() => {
            append({
              variant: '',
              smallBlind: '',
              bigBlind: '',
              ante: '',
              _editing: true,
              new: true
            });
          }}
        >
          {fields.length === 0
            ? t('SETTINGS_ADD_FIRST_GAME')
            : 'Add new game variant'}
        </Button>
      }
      renderItem={(_: OfferedGameFormShape, index: number) => {
        return (
          <OfferedGameRow
            index={index}
            key={index}
            onRemove={remove}
            showVariable={fields[index]?.variant !== fields[index - 1]?.variant}
            onSave={onSave}
          />
        );
      }}
    />
  );
};

export default OfferedGamesList;
