import { BellOutlined } from '@ant-design/icons';
import {
  CasinoNotification,
  useCasinoNotificationMutation,
  useGetCasinoNotificationsQuery
} from '@api-3/endpoints/notifications';
import { currentUserIdSelector } from '@redux-3/AuthenticationRedux';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Notification = ({ tableId }: { tableId: string }) => {
  const recipientId = useSelector(currentUserIdSelector);
  const { data: notifications } = useGetCasinoNotificationsQuery(null, {
    pollingInterval: 5000
  });
  const [casinoNotification] = useCasinoNotificationMutation();
  const [isRead, setIsRead] = useState<boolean>(true);
  const [notificationAlreadySent, setNotificationAlreadySent] = useState<
    CasinoNotification | undefined
  >();

  useEffect(() => {
    const relevantNotifications = notifications?.filter(
      n => n.tableId === tableId
    );
    if (relevantNotifications && relevantNotifications.length > 0) {
      const latestNotification = relevantNotifications.sort(
        (a, b) =>
          new Date(b.notificationDate).getTime() -
          new Date(a.notificationDate).getTime()
      )[0];

      if (latestNotification.read === false) {
        setNotificationAlreadySent(latestNotification);
        setIsRead(false);
      } else {
        setNotificationAlreadySent(undefined);
        setIsRead(true);
      }
    } else {
      setNotificationAlreadySent(undefined);
      setIsRead(false);
    }
  }, [notifications, tableId]);

  const handleClick = async () => {
    console.log('notificationAlreadySent: ', notificationAlreadySent);
    if (!notificationAlreadySent) {
      const params = {
        body: {
          tableId: tableId,
          recipientId: recipientId
        }
      };
      await casinoNotification(params).unwrap();
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 20,
        right: 20,
        textAlign: 'center',
        background: '#252b2f',
        padding: 5,
        borderRadius: 10,
        cursor: notificationAlreadySent ? 'not-allowed' : 'pointer'
      }}
      onClick={handleClick}
    >
      <BellOutlined
        className={`mb-2 ${
          notificationAlreadySent && !isRead ? 'bell-icon-read' : ''
        }`}
        style={{
          fontSize: 36,
          color: notificationAlreadySent && !isRead ? '#fa8c16' : '#ffffff'
        }}
      />
      <div style={{ color: '#ffff', fontSize: 11, width: 50 }}>
        {notificationAlreadySent && !isRead
          ? 'Floor manager is called'
          : 'Call floor manager'}
      </div>
    </div>
  );
};

export default Notification;
