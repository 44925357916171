import DistanceSlider from '@old-world/features/cashgames/sub-domains/running-games/components/items/DistanceSlider';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SetDistanceFormComponent({
  onChange
}: {
  onChange: (value: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <span>{t('POCKER_ROOM_PLAYER_MAXIMAL_DISTANCE')}</span>
      <DistanceSlider min={0} max={100} onChange={onChange} />
    </>
  );
}
