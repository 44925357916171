import { RightOutlined } from '@ant-design/icons';
import {
  TOURNAMENT_STATUS,
  useGetAllEventsQuery
} from '@api-3/endpoints/festivals';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { useModals } from '@providers/ModalProvider';
import EventsCards from '@routes-3/main/tournamentsManagement/events/components/EventsCards';
import { Button, Card, Spin, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const EventsPageContent = () => {
  const openModal = useModals();
  const [tournamentStatus, setTournamentStatus] = useState<TOURNAMENT_STATUS>(
    TOURNAMENT_STATUS.ALL
  );
  const tabs = [
    { name: 'All Events', key: TOURNAMENT_STATUS.ALL },
    { name: 'Running Events', key: TOURNAMENT_STATUS.RUNNING },
    { name: 'Upcoming Events', key: TOURNAMENT_STATUS.UPCOMING },
    { name: 'Finished Events', key: TOURNAMENT_STATUS.FINISHED }
  ];
  const [showButton, setShowButton] = useState<boolean>(false);
  const casinoId = useSelector(currentCasinoSelector);
  const isFirstRender = useRef(true);
  const { data, isLoading, refetch } = useGetAllEventsQuery({
    queryParams: {
      page: 1,
      size: 500,
      status: tournamentStatus === TOURNAMENT_STATUS.ALL ? '' : tournamentStatus
    },
    casinoId
  });

  useEffect(() => {
    refetch();
  }, [tournamentStatus, refetch]);

  useEffect(() => {
    if (data && data.content.length > 0 && isFirstRender.current) {
      setShowButton(true);
      isFirstRender.current = false;
    }
  }, [data]);

  const onChange = (key: TOURNAMENT_STATUS) => {
    setTournamentStatus(key);
  };

  return (
    <>
      <div className={'d-flex flex-row justify-content-between pr-3'}>
        <div className={'gx-font-weight-bold'}>Events</div>
        {showButton && (
          <Button
            className={'gx-btn'}
            key="submit"
            type="default"
            style={{ position: 'relative', top: -8, marginBottom: 0 }}
            onClick={() => {
              openModal({
                type: 'CREATE_EVENT',
                props: {}
              });
            }}
          >
            Create your Event
          </Button>
        )}
      </div>
      {isLoading ? (
        <Spin tip="Loading" size="large" />
      ) : tournamentStatus === TOURNAMENT_STATUS.ALL &&
        data &&
        data.content.length === 0 ? (
        <Card
          className={'mt-3'}
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            display: 'flex'
          }}
        >
          <h2>No Tournaments Running Yet!</h2>
          <p>Here's your stage to create some action!</p>
          <ol
            style={{
              textAlign: 'left',
              marginBottom: '20px',
              listStyleType: 'none'
            }}
          >
            <li>
              <b>Create A Tournament</b>: Create an event so Flop players know
              when you offer events.
            </li>
            <li>
              <b>Publish Your Tournament</b>: When ready to publish, your
              tournament will be live for all FLOP players.
            </li>
          </ol>
          <div>
            <Button
              className={'gx-btn'}
              key="submit"
              type="default"
              loading={isLoading}
              onClick={() => {
                openModal({
                  type: 'CREATE_EVENT',
                  props: {}
                });
              }}
            >
              Create your Event
            </Button>
          </div>
        </Card>
      ) : (
        <Tabs
          defaultActiveKey="ALL"
          size={'large'}
          moreIcon={<RightOutlined />}
          onChange={key => onChange(key as TOURNAMENT_STATUS)}
        >
          {tabs.map(tab => (
            <Tabs.TabPane key={tab.key} tab={tab.name}>
              <EventsCards events={data?.content} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default EventsPageContent;
