import { InfoCircleOutlined } from '@ant-design/icons';
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import {
  SignupForm as SignupFormType,
  useRegisterMutation
} from '@api-3/endpoints/account';
import { ApiError } from '@api/types';
import { Button, Checkbox, Form, Input, message as toast, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { Link, useHistory } from 'react-router-dom';

const FormItem = Form.Item;

type FormData = {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  term: boolean;
};

const SignUpForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [register] = useRegisterMutation();
  const [loading, setLoading] = useState(false);

  const onSignup = async (data: FormData) => {
    setLoading(true);
    const casinoData = {
      firstName: data.firstName,
      lastName: data.lastName,
      casinoName: data.name,
      emailAdminUser: data.email,
      password: data.password,
      type: 'CASINO'
    } as SignupFormType;
    try {
      await register(casinoData).unwrap();
      history.push(`/user-auth/email-check/${casinoData.emailAdminUser}`);
      return;
      // const response = await axios.post(`${baseURL}/accounts/api/login`, {
      //   email: casinoData.emailAdminUser,
      //   password: casinoData.password,
      //   loginOrigin: 'FLOP_AD'
      // });
      // if (response.status === 200) {
      //   const { email, id, customerId, managedCasinoId } = response.data;
      //   const { authorization } = response.headers;
      //   history.push(
      //     `/user-auth/signin-after-signup/${email}/${id}/${customerId}/${managedCasinoId}/${authorization}`
      //   );
      // }
    } catch (e) {
      console.log('Signup error: ', e);
      const { errorCode } = (e as ApiError).data;
      switch (errorCode) {
        case 'ERR_CUSTOMER_002':
          toast.error(t('ERR_CUSTOMER'));
          break;
        case 'ERR_CUSTOMER_011':
          toast.error(t('ERR_CUSTOMER'));
          break;
        default:
          toast.error(t('DEFAULT_ERROR'));
      }
      console.log('Signup error: ', errorCode);
    } finally {
      setLoading(false);
    }
  };
  const [checked, setChecked] = useState(false);
  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked);
    form.validateFields(['term']);
  };
  const validation = () => {
    return new Promise<void>((resolve, reject) => {
      if (checked) {
        resolve();
      } else {
        reject(new Error('Please accept the terms and conditions'));
      }
    });
  };
  return (
    <Form
      form={form}
      initialValues={{ remember: true }}
      name="basic"
      className="gx-signin-form gx-form-row0"
      onFinish={onSignup}
    >
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please input your Casino/Club name',
            whitespace: true
          }
        ]}
        name="name"
      >
        <Input
          prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={t('CASINO_CLUB')}
        />
      </FormItem>
      <div className={'gx-flex-row ant-row-space-between'}>
        <FormItem
          rules={[{ required: true, message: 'Please input your firstname' }]}
          name="firstName"
          className={'w-50 gx-pr-1'}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={'Firstname'}
          />
        </FormItem>
        <FormItem
          rules={[{ required: true, message: 'Please input your lastname' }]}
          name="lastName"
          className={'w-50 gx-pl-1'}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={'Lastname'}
          />
        </FormItem>
      </div>
      <FormItem
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!'
          },
          {
            required: true,
            message: 'Please input your E-mail!'
          }
        ]}
        name="email"
      >
        <Input
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          type={'email'}
        />
      </FormItem>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please input your Password!'
          },
          {
            message: '',
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S_]{6,}$/
          }
        ]}
        name="password"
      >
        <div className={'gx-position-relative password-input-wrapper'}>
          <Input.Password
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />
          <div
            className="gx-position-absolute tooltip-wrapper"
            style={{ top: '6px', right: '-21px' }}
          >
            <Tooltip
              title={
                <>
                  <div>
                    <b>{t('PASS_RULE')}</b>
                  </div>
                  <div>- {t('UPPER_CASE')}</div>
                  <div>- {t('LOWER_CASE')}</div>
                  <div>- {t('NUMBER')}</div>
                </>
              }
            >
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </div>
        </div>
      </FormItem>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S_]{6,}$/
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            }
          })
        ]}
        name="confirmPassword"
        dependencies={['password']}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Confirm password"
        />
      </FormItem>
      <FormItem rules={[{ validator: validation }]} name="term">
        <Checkbox checked={checked} onChange={onCheckboxChange}>
          {/*@ts-ignore*/}
          <Link className="gx-text-underline" to="/todo">
            I accept the terms & privacy policies
          </Link>
        </Checkbox>
      </FormItem>
      <FormItem className="gx-text-center gx-mt-2">
        <Button
          type="primary"
          htmlType="submit"
          className={'w-100'}
          loading={loading}
        >
          Sign Up
        </Button>
      </FormItem>
    </Form>
  );
};

export default SignUpForm;
