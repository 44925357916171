import { api } from '@api/api';
import { parseParams } from '@old-world/utils/ApiUtils';
export enum ANNOUNCEMENT_STATUS {
  ALL = 'ALL',
  UPCOMING = 'UPCOMING',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED'
}

export type ListAnnouncementResult = {
  content: ListAnnouncementItem[];
  empty: boolean;
  number: number;
  numberOfElements: number;
  totalElements: number;
};

export type ListAnnouncementItem = {
  id: string;
  title: string;
  description: string;
  startingDate?: Date;
  endingDate?: Date;
  publish: boolean;
};

export type CreateAnnouncementPayload = {
  title: string;
  description: string;
  startingDate?: Date;
  endingDate?: Date;
  publish: boolean;
};

const announcementApi = api
  .enhanceEndpoints({ addTagTypes: ['Announcements'] })
  .injectEndpoints({
    endpoints: builder => ({
      getAllAnnouncements: builder.query<
        ListAnnouncementResult,
        {
          queryParams: {
            page: number;
            size: number;
            publish?: boolean;
            status?: string;
          };
          casinoId: string;
        }
      >({
        providesTags: ['Announcements'],
        query: ({ queryParams, casinoId }) =>
          `/datas/api/announcement/casino/${casinoId}?${parseParams(
            queryParams
          )}`
      }),
      createAnnouncement: builder.mutation<
        void,
        { body: CreateAnnouncementPayload }
      >({
        invalidatesTags: ['Announcements'],
        query: params => ({
          method: 'POST',
          body: params.body,
          url: `/datas/api/announcement`
        })
      }),
      updateAnnouncement: builder.mutation<
        void,
        { body: ListAnnouncementItem; announcementId: string }
      >({
        invalidatesTags: ['Announcements'],
        query: params => ({
          method: 'PUT',
          body: params.body,
          url: `/datas/api/announcement/${params.announcementId}`
        })
      }),
      deleteAnnouncement: builder.mutation<void, { announcementId: string }>({
        invalidatesTags: ['Announcements'],
        query: ({ announcementId }) => ({
          method: 'DELETE',
          url: `/datas/api/announcement/${announcementId}`
        })
      })
    })
  });

export const {
  useGetAllAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation
} = announcementApi;
