import { useModals } from '@providers/ModalProvider';
import { currentUserIdSelector } from '@redux-3/AuthenticationRedux';
import ChatPage from '@routes-3/main/chat/ChatPage';
import ContactUsPage from '@routes-3/main/contactUs';
import AdsMonitoringPage from '@routes-3/main/myPokerRoom/adsMonitoring/AdsMonitoringPage';
import AnnouncementsPage from '@routes-3/main/myPokerRoom/announcements/AnnouncementsPage';
import Onboarding from '@routes-3/main/myPokerRoom/componenets/Onboarding';
import useGetProfileRedirectRoute from '@routes-3/main/myPokerRoom/componenets/useGetProfileRedirectRoute';
import DashboardPage from '@routes-3/main/myPokerRoom/dashboard/DashboardPage';
import GeneralInformationPage from '@routes-3/main/myPokerRoom/generalInformation/GeneralInformationPage';
import PokerGamesInformationPage from '@routes-3/main/myPokerRoom/pokerGamesInformation/PokerGamesInformationPage';
import SettingsPage from '@routes-3/main/myPokerRoom/settings/SettingsPage';
import MyPlayers from '@routes-3/main/playersDatabase/MyPlayers';
import TermsAndConditionsPage from '@routes-3/main/termsAndConditions/TermsAndConditionsPage';
import ClockPage from '@routes-3/main/tournamentsManagement/ClockPage';
import DailyTournamentsPage from '@routes-3/main/tournamentsManagement/dailyTournaments/DailyTournamentsPage';
import EventDetailsPage from '@routes-3/main/tournamentsManagement/eventDetails/EventDetailsPage';
import EventsPage from '@routes-3/main/tournamentsManagement/events/EventsPage';
import AccountSettingsPage from '@routes-3/main/userProfile/AccountSettingsPage';
import MyInformationPage from '@routes-3/main/userProfile/MyInformationPage';
import {
  ADMIN_BAD_REQUEST,
  ADMIN_CASH_GAME_INTEREST_LIST,
  ADMIN_CASH_GAME_RUNNING_GAMES,
  ADMIN_CASH_TEMPLATES,
  ADMIN_CHAT,
  ADMIN_CONTACT_US,
  ADMIN_DAILY_TOURNAMENTS,
  ADMIN_ERROR,
  ADMIN_EVENTS,
  ADMIN_EVENTS_CLOCK,
  ADMIN_EVENTS_TOURNAMENTS,
  ADMIN_MOBILE,
  ADMIN_MY_PLAYERS,
  ADMIN_MY_POKER_ROOM_ADS,
  ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS,
  ADMIN_MY_POKER_ROOM_DASHBOARD,
  ADMIN_MY_POKER_ROOM_GAMES,
  ADMIN_MY_POKER_ROOM_GENERAL,
  ADMIN_MY_POKER_ROOM_SETTINGS,
  ADMIN_PROFILE_ACCOUNT_SETTINGS,
  ADMIN_PROFILE_MY_INFORMATION,
  ADMIN_SUB_GROUPS,
  ADMIN_TERMS_AND_CONDITIONS,
  ADMIN_UNAUTHORIZED
} from '@routes-3/routes';
import { useBlocker } from '@utils-3/useBlocker';
import modal from 'antd/lib/modal';
import RunningGamesMapPage from 'flop-3.0/routes/main/cashGameManagement/runningGamesMap';
import TemplatePage from 'flop-3.0/routes/main/cashGameManagement/templates';
import SubGroupsPage from 'flop-3.0/routes/main/playersDatabase/sub-groups';
import * as H from 'history';
import React, { Suspense, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
// @ts-ignore
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import {
  BadRequestPage,
  NotFoundPage,
  ServerErrorPage,
  UnAuthorizedPage
} from '../../common/containers/ErrorPage';
import LoadingPage from '../../common/containers/LoadingPage';
import { CurrentUserContextProvider } from '../../components/CurrentUserContext';
import { authorizationTokenSelector } from '../../features/authentication/redux/AuthenticationRedux';
import { api } from '../../services/Api';
import InterestListPage from '../routes/main/cashGameManagement/interestList';
import DefaultLayout from './DefaultLayout';

export default function CoreLayout() {
  const authorizationToken = useSelector(authorizationTokenSelector);
  useEffect(() => {
    api.setHeader('Authorization', authorizationToken || '');
  }, [authorizationToken]);

  const { url: profileRedirectRoute, isLoading } = useGetProfileRedirectRoute();
  const currentUser = useSelector(currentUserIdSelector);
  const location = useLocation();
  const hasModal = useRef<boolean>(false);
  const openModal = useModals();

  const createOnboardingWarning = (location: H.Location) => {
    const isAllowed =
      location.pathname.includes('my-poker-room/settings') ||
      location.pathname == '' ||
      location.pathname == '/' ||
      location.pathname.includes(ADMIN_PROFILE_MY_INFORMATION);

    const isDisallowed = location.pathname.includes('/admin') && !isAllowed;

    if (!isLoading && isDisallowed && profileRedirectRoute !== null) {
      hasModal.current = true;
      modal.warn({
        okText: 'Complete the Information',
        onOk: () => {
          openModal({
            type: 'ONBOARDING',
            props: { onClose: () => (hasModal.current = false) }
          });
        },
        title:
          'You can’t access to this section if you don’t complete the Poker Room General Information'
      });
    }
    return !isDisallowed;
  };

  useBlocker(transition => {
    return createOnboardingWarning(transition);
  }, profileRedirectRoute != null && currentUser);

  useEffect(() => {
    if (!hasModal.current && location.pathname !== '/') {
      createOnboardingWarning(location);
    }
  }, [profileRedirectRoute, location]);

  useEffect(() => {
    if (
      profileRedirectRoute === null &&
      isMobile &&
      location.pathname !== ADMIN_MOBILE
    ) {
      window.location.replace(ADMIN_MOBILE);
    }
  }, [profileRedirectRoute, location.pathname]);

  if (isLoading) {
    return <LoadingPage />;
  }

  // @ts-ignore
  return (
    <CurrentUserContextProvider>
      <DefaultLayout>
        <Suspense fallback={<LoadingPage />}>
          {/*@ts-ignore*/}
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return profileRedirectRoute == null && !hasModal.current ? (
                  // @ts-ignore
                  <Redirect to={ADMIN_CASH_GAME_RUNNING_GAMES} />
                ) : (
                  <Onboarding
                    onOpen={() => (hasModal.current = true)}
                    onClose={() => (hasModal.current = false)}
                  />
                );
              }}
            />
            <Route
              path={ADMIN_PROFILE_MY_INFORMATION}
              component={MyInformationPage}
            />
            <Route
              path={ADMIN_PROFILE_ACCOUNT_SETTINGS}
              component={AccountSettingsPage}
            />
            <Route path={ADMIN_CHAT} component={ChatPage} />
            <Route
              path={ADMIN_CASH_GAME_RUNNING_GAMES}
              component={RunningGamesMapPage}
            />
            <Route path={ADMIN_CASH_TEMPLATES} component={TemplatePage} />
            <Route
              path={ADMIN_CASH_GAME_INTEREST_LIST}
              component={InterestListPage}
            />
            <Route
              path={`${ADMIN_EVENTS_TOURNAMENTS}/:festivalId`}
              component={EventDetailsPage}
            />
            <Route path={ADMIN_EVENTS} component={EventsPage} />
            <Route
              path={ADMIN_DAILY_TOURNAMENTS}
              component={DailyTournamentsPage}
            />
            <Route path={ADMIN_EVENTS_CLOCK} component={ClockPage} />
            <Route path={ADMIN_SUB_GROUPS} component={SubGroupsPage} />
            <Route path={ADMIN_MY_PLAYERS} component={MyPlayers} />
            <Route
              path={ADMIN_MY_POKER_ROOM_GENERAL}
              component={GeneralInformationPage}
            />
            <Route
              path={ADMIN_MY_POKER_ROOM_GAMES}
              component={PokerGamesInformationPage}
            />
            <Route
              path={ADMIN_MY_POKER_ROOM_SETTINGS}
              component={SettingsPage}
            />
            <Route
              path={ADMIN_MY_POKER_ROOM_DASHBOARD}
              component={DashboardPage}
            />
            <Route
              path={ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS}
              component={AnnouncementsPage}
            />
            <Route
              path={ADMIN_MY_POKER_ROOM_ADS}
              component={AdsMonitoringPage}
            />
            <Route path={ADMIN_CONTACT_US} component={ContactUsPage} />
            <Route
              path={ADMIN_TERMS_AND_CONDITIONS}
              component={TermsAndConditionsPage}
            />
            <Route path={ADMIN_UNAUTHORIZED} component={UnAuthorizedPage} />
            <Route path={ADMIN_BAD_REQUEST} component={BadRequestPage} />
            <Route path={ADMIN_ERROR} component={ServerErrorPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </DefaultLayout>
    </CurrentUserContextProvider>
  );
}
