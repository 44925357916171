import ChatCommunication from '@routes-3/main/chat/components/ChatCommunication';
import React from 'react';

const ChatBox = ({
  selectedInterestGameId,
  interestList
}: {
  selectedInterestGameId: string;
  interestList: any;
}) => {
  return (
    <div className="gx-chat-box" style={{ height: 'calc(100vh - 340px)' }}>
      {selectedInterestGameId === '' ? (
        <div className="gx-comment-box">
          <div className="gx-fs-80">
            <i className="icon icon-chat gx-text-muted" />
          </div>
          <h1 className="gx-text-muted">Select Interest List to start Chat</h1>
        </div>
      ) : (
        <ChatCommunication
          selectedInterestGameId={selectedInterestGameId}
          interestList={interestList}
        />
      )}
    </div>
  );
};

export default ChatBox;
