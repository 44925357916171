import { useOrderWaitingListMutation } from '@api-3/endpoints/games';
import { GamesApiDefinitions } from '@old-world/services/models/GamesApiModel';
import { useModals } from '@providers/ModalProvider';
import SeatedPlayers from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/SeatedPlayers';
import WaitingListPlayersDnD from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/WaitingListPlayersDnD';
import {
  RunningGame,
  Table,
  WaitingPlayer,
  WaitingPlayerOrder
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Card, Col, notification, Row, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type NoPlayer = {
  key: string;
};
export type SatPlayers = (GamesApiDefinitions.PlayerDTO | NoPlayer)[];

export interface GamePlayersProps {
  acceptedPlayers: any;
  game: RunningGame;
  table: Table;
  maxPlayers: number;
  waitingListPlayers: WaitingPlayer[];
  expectedWaitingTime: string | undefined;
}

const GamePlayers = ({
  acceptedPlayers,
  game,
  table,
  maxPlayers,
  waitingListPlayers,
  expectedWaitingTime
}: GamePlayersProps) => {
  const { t } = useTranslation();
  const openModal = useModals();
  const freeSeats: NoPlayer[] = _.times(
    maxPlayers - acceptedPlayers.length,
    idx => ({
      key: idx.toString()
    })
  );
  const hasFreeSeats = freeSeats.length > 0;
  const [isReordering, setIsReordering] = useState(false);
  const satPlayers: SatPlayers = ([] as SatPlayers).concat(
    acceptedPlayers,
    freeSeats
  );
  const [wListPlayers, setWLPlayers] = useState<WaitingPlayer[]>();

  const toggleReordering = () => {
    setWLPlayers(waitingListPlayers);
    setIsReordering(!isReordering);
  };

  const [
    reorderWaitingList,
    { isLoading: isLoadingReorderWL }
  ] = useOrderWaitingListMutation();

  const reorderWL = async () => {
    try {
      const wl: WaitingPlayerOrder[] = [];
      wListPlayers?.map((player, index) => {
        wl.push({
          casinoId: player.casinoId ? player.casinoId : null,
          casinoName: player.casinoName ? player.casinoName : null,
          currentLatitude: player.currentLatitude,
          currentLongitude: player.currentLongitude,
          flopId: player.flopId,
          gameSize: player.gameSize,
          gameVariant: player.gameVariant,
          name: player.name,
          order: index + 1
        } as WaitingPlayerOrder);
      });
      await reorderWaitingList({ gameId: game.id, payload: wl }).unwrap();
      setIsReordering(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to reorder Waiting List. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }
  };

  return (
    <Row>
      <Col md={16}>
        <Card
          title={t('PLAYERS')}
          extra={
            hasFreeSeats && (
              <Tooltip title="Sit Player to the table" placement="bottom">
                <i
                  className="icon icon-add-circle gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                  style={{ fontSize: 22 }}
                  onClick={event => {
                    event.stopPropagation();
                    openModal({
                      type: 'ADD_PLAYERS',
                      props: {
                        game,
                        table
                        // subGroupId: game?.subgroupId
                      }
                    });
                  }}
                />
              </Tooltip>
            )
          }
        >
          <SeatedPlayers data={satPlayers} />
        </Card>
      </Col>
      <Col md={8}>
        <Card
          title={t('WAITING_LIST') + ' (' + waitingListPlayers.length + ')'}
          extra={
            <>
              {waitingListPlayers.length > 0 &&
                (isReordering ? (
                  <>
                    <Tooltip title="Cancel" placement="bottom">
                      <i
                        className="icon icon-close-circle gx-text-danger cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                        style={{ fontSize: 22 }}
                        onClick={event => {
                          event.stopPropagation();
                          toggleReordering();
                        }}
                      />
                    </Tooltip>
                    {isLoadingReorderWL ? (
                      <Spin style={{ marginRight: 0, marginLeft: 8 }} />
                    ) : (
                      <Tooltip title="Save" placement="bottom">
                        <i
                          className="icon icon-check-circle-o gx-text-success cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                          style={{ fontSize: 22 }}
                          onClick={event => {
                            event.stopPropagation();
                            reorderWL();
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip title="Reorder Waiting List" placement="bottom">
                    <i
                      className="icon icon-collapse gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                      style={{ fontSize: 18 }}
                      onClick={event => {
                        event.stopPropagation();
                        toggleReordering();
                      }}
                    />
                  </Tooltip>
                ))}
              {!isReordering && (
                <>
                  <Tooltip
                    title="Set expected waiting time shown to the first 3 players"
                    placement="bottom"
                  >
                    <i
                      className="icon icon-timepicker gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                      style={{ fontSize: 19, position: 'relative', top: 1 }}
                      onClick={event => {
                        event.stopPropagation();
                        openModal({
                          type: 'EXPECTED_WAITING_TIME',
                          props: {
                            gameId: game.id,
                            expectedWaitingTime
                          }
                        });
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title="Add Player to Waiting List"
                    placement="bottom"
                  >
                    <i
                      className="icon icon-add-circle gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                      style={{ fontSize: 22 }}
                      onClick={event => {
                        event.stopPropagation();
                        openModal({
                          type: 'ADD_PLAYERS',
                          props: {
                            game
                            // We disable the table here to only set players to waiting list and not sit automatically!
                            // table
                            // subGroupId: game?.subgroupId
                          }
                        });
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          }
        >
          <div style={{ height: 'calc(100vh - 455px)', overflowY: 'scroll' }}>
            <WaitingListPlayersDnD
              tableId={table.id}
              waitingListPlayers={waitingListPlayers}
              reorderWaitingListPlayers={wListPlayers}
              setWaitingListPlayers={setWLPlayers}
              gameId={game.id}
              hasFreeSeats={hasFreeSeats}
              isReordering={isReordering}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default React.memo(GamePlayers);
