import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { IRootState } from '../../../common/models/StateModel.d';
import { createImmutableEqualSelector } from '../../../redux/selector/custom-selector';
import { generateFetchAction } from '../../../redux/util';
import { IAccountImmutableStateType } from '../models/AccountModel.d';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ...generateFetchAction(
    'saveCasinoGeneralInformation',
    ['data', 'callback', 'typeSection'],
    []
  ),
  ...generateFetchAction(
    'uploadCasinoBrandLogo',
    ['brandLogo', 'managedCasinoId'],
    []
  ),
  ...generateFetchAction(
    'uploadCasinoBrandBanner',
    ['brandBanner', 'managedCasinoId'],
    []
  ),
  saveCurrentStep: ['step']
});

export const AccountTypes = Types;
// Export Default
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: any = Immutable({
  step: 1 // step for the account creation (from 1 to 4 => casino information to casino contact)
});

/* ------------- Reducers ------------- */
/**
 * Fetch Casino Details Reducer
 */
export const saveStep = (
  state: IAccountImmutableStateType,
  { step }: { step: number }
) => {
  return state.merge({ step });
};

/* ------------- Hookup Reducers To Types ------------- */
// Login Reducer
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_CURRENT_STEP]: saveStep
});

/* ------------- Selectors ------------- */

const stepSelector = (state: IRootState) => state.account.step;

export const currentStep = createImmutableEqualSelector(
  [stepSelector],
  step => step
);
