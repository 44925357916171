import moment from 'moment';

import { customTimeSection } from './OpeningDaysAndTime';

/**
 * Construct a Custom Time Object
 * @param openingsDays
 * @param dayOfWeek
 * @param label
 */
const constructCustomTimeObject = (
  openingsDays: Array<DataApiDefinitions.CasinoOpeningDays>,
  dayOfWeek: number,
  label: string,
  timeZoneStr: string
) => {
  return {
    value: dayOfWeek,
    label,
    checked: openingsDays.find(o => o.dayOfWeek === dayOfWeek) !== undefined,
    openingTime:
      openingsDays.find(o => o.dayOfWeek === dayOfWeek) !== undefined
        ? moment
            .tz(
              openingsDays.find(o => o.dayOfWeek === dayOfWeek).openingTime,
              'HHmmssZZ',
              timeZoneStr
            )
            .format('HHmmss') || ''
        : '',
    closingTime:
      openingsDays.find(o => o.dayOfWeek === dayOfWeek) !== undefined
        ? moment
            .tz(
              openingsDays.find(o => o.dayOfWeek === dayOfWeek).closingTime,
              'HHmmssZZ',
              timeZoneStr
            )
            .format('HHmmss') || ''
        : ''
  };
};

/**
 * Manage Opening Days Format from API to Form
 */
const manageOpeningDaysFromApi = (
  alwaysOpen: boolean,
  openingsDays: Array<DataApiDefinitions.CasinoOpeningDays>,
  timeZoneStr: string
) => {
  // Identify if it's a SameTimeSection or CustomTimeSection
  const isSameTime = true;
  if (alwaysOpen || !openingsDays || openingsDays.length === 0) {
    return {
      openingDays: 'alwaysOpen',
      customTimeSection
    };
  }

  let openingTime: string, closingTime: string;
  // Loop on OpeningsDay to know if it's sametime or customtime
  openingsDays.forEach(o => {
    if (!openingTime) {
      openingTime = o.openingTime || '';
    }
    if (!closingTime) {
      closingTime = o.closingTime || '';
    }
  });
  const openingTimes: Array<any> = [];
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 0, 'Monday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 1, 'Tuesday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 2, 'Wednesday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 3, 'Thursday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 4, 'Friday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 5, 'Saturday', timeZoneStr)
  );
  openingTimes.push(
    constructCustomTimeObject(openingsDays, 6, 'Sunday', timeZoneStr)
  );
  return {
    // openingDays: 'customTime',
    customTimeSection: {
      customOpeningTime: openingTimes
    }
  };
};

export { manageOpeningDaysFromApi, constructCustomTimeObject };
