export enum THEME_TYPE {
  DARK = 'dark-theme',
  SEMI_DARK = 'semi-dark-theme',
  LITE = 'lite-theme'
}

export enum THEME_COLOR {
  LIGHT_PURPLE = 'light_purple',
  RED = 'red',
  BLUE = 'blue',
  DARK_BLUE = 'dark_blue',
  ORANGE = 'orange',
  LIGHT_BLUE = 'light_blue',
  DEEP_ORANGE = 'deep_orange',
  LIGHT_PURPLE_1 = 'light_purple_1',
  LIGHT_PURPLE_2 = 'light_purple_2'
}

const styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

export const setThemeType = (themeType: THEME_TYPE) => {
  if (themeType === THEME_TYPE.DARK) {
    document.body.classList.add('dark-theme');
    styleSheetLink.href = '/css/dark_theme.min.css';
  } else if (document.body.classList.contains('dark-theme')) {
    document.body.classList.remove('dark-theme');
    removeStyleSheet();
  }
  localStorage.setItem('themeType', themeType);
};

export const setThemeColor = (themeColor: THEME_COLOR) => {
  styleSheetLink.href = `/css/${themeColor}.min.css`;
  localStorage.setItem('themeColor', themeColor);
};

export const removeStyleSheet = () => {
  styleSheetLink.href = '';
};
