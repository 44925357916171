import { LoadingOutlined } from '@ant-design/icons';
import {
  useCallPlayerMutation,
  useRemovePlayerFromWaitingListMutation,
  useSitPlayerMutation
} from '@api-3/endpoints/games';
import { PlayerProfile } from '@api-3/endpoints/player';
import PlayerInfo from '@components-3/PlayerInfo';
import {
  faBullhorn,
  faPersonArrowDownToLine,
  faRotate,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModals } from '@providers/ModalProvider';
import Timer from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/Timer';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetGame';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PlayerInfoWithActionProps = {
  playerPosition: number;
  player: WaitingPlayer | PlayerProfile;
  gameId: string;
  tableId?: string;
  showCallPlayer?: boolean;
  showRemovePlayer?: boolean;
  hasFreeSeats?: boolean;
  isReordering?: boolean;
};

const Loader = () => <LoadingOutlined style={{ fontSize: 12 }} spin />;
const PlayerInfoWithAction = ({
  playerPosition,
  player,
  gameId,
  tableId,
  showCallPlayer = false,
  showRemovePlayer = false,
  hasFreeSeats,
  isReordering = false
}: PlayerInfoWithActionProps) => {
  const { t } = useTranslation();
  const { game } = useGetGame({ gameId });
  const openModal = useModals();
  const table = game?.tables?.find(t => t.id == tableId);
  const maxPlayers = table?.maxPlayers ?? 0;
  const currentPlayers = table?.players?.length ?? 0;
  const [
    callPlayer,
    { isLoading: isSavingCallPlayer }
  ] = useCallPlayerMutation();
  const [
    removePlayer,
    { isLoading: isSavingRemovePlayer }
  ] = useRemovePlayerFromWaitingListMutation();
  const [sitPlayer, { isLoading: isSavingSitPlayer }] = useSitPlayerMutation();
  return (
    <div
      className={''}
      style={{
        backgroundColor: '#323d46',
        padding: '6px 10px',
        display: 'flex',
        flexFlow: 'row',
        borderRadius: 4,
        width: '100%',
        border: isReordering ? '1px dashed white' : 'none'
      }}
    >
      <div
        style={{
          width: 50,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: 'relative'
        }}
        className={'gx-text-white'}
      >
        #{playerPosition + 1}
        {(player as WaitingPlayer).lastCallDate && (
          <div
            style={{
              position: 'absolute',
              bottom: 2,
              left: 43
            }}
          >
            <Timer
              date={(player as WaitingPlayer).lastCallDate}
              countDown={true}
              withBackground={true}
              withHours={false}
              size={'small'}
            />
          </div>
        )}
      </div>
      <div className={'w-100'}>
        <PlayerInfo player={player} width={'100%'} />
        <div
          className={'gx-flex-row'}
          style={{
            justifyContent: 'space-between',
            paddingLeft: 45
          }}
        >
          {player.flopId ? (
            showCallPlayer && (
              <Tooltip
                title={t('CALL_PLAYER')}
                placement="bottom"
                className={'ml-2'}
              >
                <div
                  className="cursor-pointer mr-3"
                  onClick={() => {
                    if (isReordering) return null;
                    callPlayer({ gameId, id: player.id });
                  }}
                >
                  {isSavingCallPlayer ? (
                    <Loader />
                  ) : (player as WaitingPlayer).lastCallDate ? (
                    <FontAwesomeIcon
                      style={{
                        fontSize: 16,
                        color: '#fa8c16',
                        opacity: isReordering ? 0.2 : 1,
                        cursor: isReordering ? 'no-drop' : 'pointer'
                      }}
                      icon={faRotate}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{
                        fontSize: 16,
                        color: '#4A7A8A',
                        opacity: isReordering ? 0.2 : 1,
                        cursor: isReordering ? 'no-drop' : 'pointer'
                      }}
                      icon={faBullhorn}
                    />
                  )}
                </div>
              </Tooltip>
            )
          ) : (
            <div style={{ width: 43 }} />
          )}
          {table ? (
            hasFreeSeats && (
              <Tooltip title={t('SIT_PLAYER')} placement="bottom">
                <div
                  className={`gx-text-white cursor-pointer mr-3`}
                  onClick={() => {
                    if (isReordering) return null;
                    maxPlayers > currentPlayers &&
                      sitPlayer({ gameId, id: player.id, tableId: table.id });
                  }}
                >
                  {isSavingSitPlayer ? (
                    <Loader />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPersonArrowDownToLine}
                      style={{
                        opacity: isReordering ? 0.2 : 1,
                        cursor: isReordering ? 'no-drop' : 'pointer'
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            )
          ) : (
            <Tooltip title={t('SIT_PLAYER')} placement="bottom">
              {isSavingSitPlayer ? (
                <Loader />
              ) : (
                <div
                  className={`gx-text-white cursor-pointer mr-3`}
                  onClick={() => {
                    if (isReordering) return null;
                    // @ts-ignore
                    openModal({
                      type: 'CHOSE_TABLE_AND_SIT_MODAL',
                      props: {
                        gameId,
                        player,
                        onChose: tableId =>
                          sitPlayer({ gameId, id: player.id, tableId })
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      fontSize: 16,
                      color: '#4A7A8A',
                      opacity: isReordering ? 0.2 : 1,
                      cursor: isReordering ? 'no-drop' : 'pointer'
                    }}
                    icon={faPersonArrowDownToLine}
                  />
                </div>
              )}
            </Tooltip>
          )}
          {showRemovePlayer && (
            <Tooltip title={t('REMOVE_PLAYER')} placement="bottom">
              <div
                className="cursor-pointer"
                style={{
                  color: 'red',
                  opacity: isReordering ? 0.2 : 1,
                  cursor: isReordering ? 'no-drop' : 'pointer'
                }}
                onClick={() => {
                  if (isReordering) return null;
                  removePlayer({ id: player.id, gameId });
                }}
              >
                {isSavingRemovePlayer ? (
                  <Loader />
                ) : (
                  <FontAwesomeIcon
                    style={{ fontSize: 16, color: '#4A7A8A' }}
                    icon={faTrash}
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerInfoWithAction;
