import React, { SyntheticEvent } from 'react';
const AddButton = ({
  value,
  onClick
}: {
  value: JSX.Element;
  onClick: (e: SyntheticEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div
      className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
      style={{ float: 'right', marginLeft: 'auto' }}
      onClick={onClick}
    >
      <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
      {value}
    </div>
  );
};
export default AddButton;
