import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';

const BuyInColumn = ({
  interestList
}: {
  interestList: { buyInMax?: number; buyInMin?: number };
}) => {
  return (
    <div>
      {interestList.buyInMin ? formatBuyIn(interestList.buyInMin) : 'NO'} -{' '}
      {interestList.buyInMax ? formatBuyIn(interestList.buyInMax) : 'NO'}
    </div>
  );
};

export default BuyInColumn;
