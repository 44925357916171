import {
  EventBannerType,
  ListEventItem,
  useCreateEventMutation,
  useUpdateEventBannerMutation,
  useUpdateEventMutation
} from '@api-3/endpoints/festivals';
import AntdFileUploadField from '@components/AntdFileUploadField';
import {
  ADatePicker,
  AInput
} from '@components/AntdSimpleFieldForReactFormHooks';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { Button, Col, Form, Modal, notification, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useController,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type AddEventModalProps = {
  onClose: () => void;
  event?: ListEventItem;
  eventBanner?: EventBannerType;
};

type EventCreationFormShape = {
  name: string;
  website: string;
  startingDate: Date;
  endingDate: Date;
  published: boolean;
  banner: any;
};

const DATE_SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const AddEventModal = ({ onClose, event, eventBanner }: AddEventModalProps) => {
  const { t } = useTranslation();
  const managedCasinoId = useSelector(currentCasinoSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formMethods = useForm<EventCreationFormShape>({
    defaultValues: {}
  });
  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [updateEventBanner] = useUpdateEventBannerMutation();
  const { handleSubmit, control } = formMethods;
  const nameController = useController({
    control,
    name: 'name',
    rules: { required: 'is required' },
    defaultValue: event ? event.name : ''
  });
  const websiteController = useController({
    control,
    name: 'website',
    rules: {
      required: 'is required',
      pattern: /(www|http:|https:)+[^\s]+[\w]/
    },
    defaultValue: event ? event.website : ''
  });
  const startingDateController = useController({
    control,
    name: 'startingDate',
    rules: { required: 'is required' },
    defaultValue: event ? new Date(event.startDate) : new Date()
  });
  const endingDateController = useController({
    control,
    name: 'endingDate',
    rules: { required: 'is required' },
    // @ts-ignore
    defaultValue: event ? new Date(event.endDate) : null
  });
  const bannerController = useController({
    control,
    name: 'banner',
    defaultValue: eventBanner ? eventBanner.resizedUrl : null
  });
  const onSubmit = async (data: EventCreationFormShape) => {
    setIsLoading(true);
    try {
      let createNewEventData = null;
      const params = {
        body: {
          casinoId: managedCasinoId,
          name: data.name,
          website: data.website,
          startDate: moment(data.startingDate).format(DATE_SERVER_FORMAT),
          endDate: moment(data.endingDate).format(DATE_SERVER_FORMAT),
          published: data.published
        }
      };
      if (event) {
        await updateEvent({
          ...params,
          festivalId: event.id
        }).unwrap();
      } else {
        createNewEventData = await createEvent(params).unwrap();
      }
      if (
        data.banner &&
        data.banner.file &&
        (createNewEventData || event?.id)
      ) {
        const formData = new FormData();
        formData.append(
          'image',
          data.banner.file,
          Math.random() * 100 + data.banner.file.name
        );
        await updateEventBanner({
          // @ts-ignore
          festivalId: event ? event.id : createNewEventData,
          data: formData
        }).finally(() => {
          setIsLoading(false);
          notification.success({
            message: 'Success',
            description: `This Event was successfully ${
              event ? 'updated' : 'created'
            }`,
            placement: 'topRight'
          });
          onClose();
        });
      } else {
        setIsLoading(false);
        notification.success({
          message: 'Success',
          description: `This Event was successfully ${
            event ? 'updated' : 'created'
          }`,
          placement: 'topRight'
        });
        onClose();
      }
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to ${
          event ? 'update' : 'create'
        } this event. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={event ? 'EDIT EVENT' : 'NEW EVENT'}
      onCancel={onClose}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      closable={false}
      footer={[
        <Button onClick={onClose} className="">
          {t('CANCEL_BUTTON')}
        </Button>,
        <Button
          onClick={handleSubmit(onSubmit)}
          className="gx-btn"
          loading={isLoading}
        >
          {event ? 'Update' : t('ADD_BUTTON')}
        </Button>
      ]}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Row
            className={'gx-flex-row'}
            style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
          >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <AInput
                controller={nameController}
                label={'Event Name'}
                type={'text'}
                mandatory
              />
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <AInput
                controller={websiteController}
                label={'Website'}
                type={'text'}
                mandatory
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ADatePicker
                controller={startingDateController}
                mandatory
                label={t('INTEREST_STARTING_DATE_LABEL')}
                style={{ height: 32, width: '100%' }}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ADatePicker
                controller={endingDateController}
                mandatory
                label={'Ending Date'}
                style={{ height: 32, width: '100%' }}
                disabledDate={d =>
                  !d || d.isBefore(startingDateController.field.value)
                }
              />
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {/*@ts-ignore*/}
              <AntdFileUploadField
                controller={bannerController}
                className={'event-banner-upload'}
                label={t('EVENT_BANNER_LABEL')}
                hasFeedback
                accept=".png,.jpg"
              />
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <span className={'mr-2'}>Publish</span>
              <Controller
                name="published"
                control={control}
                defaultValue={event ? event.published : false}
                render={({ field }) => (
                  <Switch
                    checked={field.value}
                    onChange={value => field.onChange(value)}
                  />
                )}
              />
              {/*<Switch defaultChecked={false} onChange={onChangePublish} />*/}
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddEventModal;
