import { SubGroup } from '@api-3/endpoints/subGroup';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const SubGroupPlayersColumn = ({ subGroup }: { subGroup: SubGroup }) => {
  return (
    <span className="gx-font-weight-heavy">
      {subGroup.players.length}
      <FontAwesomeIcon
        icon={faUser}
        className={'gx-text-primary ml-1'}
        style={{ fontSize: 12, position: 'relative', bottom: 1 }}
      />
    </span>
  );
};

export default SubGroupPlayersColumn;
