import { call, cancelled, put, select } from 'redux-saga/effects';

import MessagesAction from '../../../common/redux/SystemMessagesRedux';
import AuthenticationActions, {
  currentUserIdSelector
} from '../../authentication/redux/AuthenticationRedux';
import UserActions from '../../users/redux/UserRedux';
import { CustomersServiceType } from '../models/CustomersModel.d';
import CustomersAction from '../redux/CustomersRedux';

/**
 * Fetch customer details Middleware
 * @param {*} api
 * @param {*} action : - id of the customer
 */
export function* fetchCustomerDetails(
  api: CustomersServiceType,
  action: { id: number }
) {
  const { id } = action;
  try {
    const fetchCustomerData = yield call(api.fetchCustomerDetails, id);

    if (fetchCustomerData.status === 200) {
      // Fetch Success
      yield put(
        CustomersAction.fetchCustomerDetailsSuccessResponse(
          id,
          fetchCustomerData.data
        )
      );
    } else {
      // Put Failure Response to Stop loading
      yield put(CustomersAction.fetchCustomerDetailsFailureResponse(id));
      yield put(
        MessagesAction.addMessage(
          'FETCH_CUSTOMER_DETAILS_ERROR',
          'ERROR',
          `An error Occurred, while trying to fetch customer's data.`,
          '',
          'PANEL'
        )
      );

      // Logout
      yield put(AuthenticationActions.logoutRequest());
    }
  } finally {
    // Put Cancel Response to Stop Loading
    if (yield cancelled()) {
      // Put Failure Response to Stop loading
      yield put(CustomersAction.fetchCustomerDetailsCancelResponse(id));
    }
  }
}

/**
 * Fetch customer details Middleware
 * @param {*} api
 * @param {*} action : - id of the customer
 */
export function* updateCustomerProfile(
  api: CustomersServiceType,
  action: { firstname: string; lastname: string; phoneNumber: string }
) {
  const { firstname, lastname, phoneNumber } = action;
  const id = yield select(currentUserIdSelector);
  try {
    const updateProfileData = yield call(
      api.updateCustomerProfile,
      id,
      firstname,
      lastname,
      phoneNumber
    );

    if (updateProfileData.status === 200) {
      // Fetch Success
      yield put(
        CustomersAction.updateCustomerProfileSuccessResponse(
          firstname,
          lastname,
          phoneNumber
        )
      );
      yield put(UserActions.updateUser(id, firstname, lastname, phoneNumber));
    } else {
      // Put Failure Response to Stop loading
      yield put(CustomersAction.updateCustomerProfileFailureResponse(id));
      yield put(
        MessagesAction.addMessage(
          'FETCH_CUSTOMER_DETAILS_ERROR',
          'ERROR',
          `An error Occurred, while trying to fetch customer's data.`,
          '',
          'PANEL'
        )
      );

      // Logout
      yield put(AuthenticationActions.logoutRequest());
    }
  } finally {
    // Put Cancel Response to Stop Loading
    if (yield cancelled()) {
      // Put Failure Response to Stop loading
      yield put(CustomersAction.fetchCustomerDetailsCancelResponse(id));
    }
  }
}
