import RunningGameTableCard from '@routes-3/main/cashGameManagement/runningGamesMap/components/RunningGameTableCard';
import RunningGameTableCardLoader from '@routes-3/main/cashGameManagement/runningGamesMap/components/RunningGameTableCardLoader';
import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Card } from 'antd';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';

const TablesMap = ({
  games,
  game,
  onChange
}: {
  games?: RunningGame[];
  game?: RunningGame;
  onChange?: (key: string) => void;
}) => {
  const LOADER_CONTENT: Array<ReactNode> = [];
  _.times(4, i => {
    LOADER_CONTENT.push(<RunningGameTableCardLoader key={i} />);
  });
  const allTablesAndGames: { game: RunningGame; table: Table }[] = [];
  if (games && games.length > 0) {
    games.forEach((game: RunningGame) => {
      game.tables.forEach((table: Table) => {
        allTablesAndGames.push({
          game: {
            ...game,
            liveGameKey: game.liveGameKey
          },
          table: table
        });
      });
    });
  }

  return (
    <Card
      style={{
        height: isMobile ? '100%' : 'calc(100vh - 322px)',
        overflowY: 'scroll'
      }}
    >
      <div className="row">
        {games &&
          _.orderBy(
            allTablesAndGames,
            function(o) {
              return Number(o.table.tableId);
            },
            ['asc']
          ).map(
            (gameTable: { game: RunningGame; table: Table }, index: number) => (
              <div
                key={index}
                className={`mb-4 d-flex justify-content-center ${
                  window.innerWidth > 1560
                    ? 'col-xl-3'
                    : 'col-xl-3 col-md-6 col-sm-6'
                }`}
              >
                <RunningGameTableCard
                  table={gameTable.table}
                  game={gameTable.game}
                />
              </div>
            )
          )}
        {game &&
          game.tables.map((table: Table, index: number) => (
            <div
              key={index}
              className={`mb-4 d-flex justify-content-center ${
                window.innerWidth > 1560 ? 'col-xl-3' : 'col-xl-3'
              }`}
            >
              <RunningGameTableCard
                game={game}
                table={table}
                onChange={onChange}
              />
            </div>
          ))}
      </div>
    </Card>
  );
};

export default TablesMap;
