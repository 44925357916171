import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { GameVariantType } from '@old-world/common/redux/ParametersModel.d';
import GameVariant from '@old-world/common/sagas/static/GameVariant';
import { Col, Row, Select } from 'antd';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type TableGameCharacteristicsFieldFormShape = {
  gameLabel: string;
  gameVariant: string;
  smallBlind: number;
  bigBlind: number;
  ante: number;
  buyInMin: number;
  buyInMax: number;
  waitingListPlayers?: number[];
  sitPlayers?: number[];
  templateId?: number;
  subgroupId?: number;
  tables: {
    tableId: string;
    maxPlayers: number;
  }[];
};

const TableGameCharacteristics = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<TableGameCharacteristicsFieldFormShape>();
  const gameVariant = useController({
    control,
    name: 'gameVariant',
    rules: { required: 'is required' }
  });
  const smallBlind = useController({
    control,
    name: 'smallBlind',
    rules: {
      required: 'is required',
      pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const bigBlind = useController({
    control,
    name: 'bigBlind',
    rules: {
      required: 'is required',
      pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const ante = useController({
    control,
    name: 'ante',
    rules: {
      pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const min = useController({
    control,
    name: 'buyInMin',
    rules: {
      required: 'is required',
      pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
    }
  });
  const max = useController({
    control,
    name: 'buyInMax',
    rules: {
      pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
    }
  });

  return (
    <>
      <ASelect
        label={t('INTEREST_GAME_VARIANT_LABEL')}
        controller={gameVariant}
        hasFeedback
        mandatory
      >
        {GameVariant.map((c: GameVariantType, index: number) => (
          <Select.Option key={index} value={c.label}>
            {c.label}
          </Select.Option>
        ))}
      </ASelect>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput
            controller={smallBlind}
            label={'SB'}
            type={'text'}
            mandatory
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={bigBlind} label={'BB'} type={'text'} mandatory />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={ante} label={'Straddle'} type={'text'} />
        </Col>
      </Row>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput
            controller={min}
            label={'Min. Buy-In'}
            type={'text'}
            mandatory
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput controller={max} label={'Max. Buy-In'} type={'text'} />
        </Col>
      </Row>
    </>
  );
};

export default TableGameCharacteristics;
