import { RightOutlined } from '@ant-design/icons';
import {
  TOURNAMENT_STATUS,
  useGetDailyTournamentsListByStatusQuery
} from '@api-3/endpoints/festivals';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { useModals } from '@providers/ModalProvider';
import TournamentsTable from '@routes-3/main/tournamentsManagement/common/TournamentsTable';
import { Button, Card, Spin, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const DailyTournamentsPage = () => {
  const openModal = useModals();
  const [tournamentStatus, setTournamentStatus] = useState<TOURNAMENT_STATUS>(
    TOURNAMENT_STATUS.ALL
  );
  const tabs = [
    { name: 'All Events', key: TOURNAMENT_STATUS.ALL },
    { name: 'Running Events', key: TOURNAMENT_STATUS.RUNNING },
    { name: 'Upcoming Events', key: TOURNAMENT_STATUS.UPCOMING },
    { name: 'Finished Events', key: TOURNAMENT_STATUS.FINISHED }
  ];
  const [showButton, setShowButton] = useState<boolean>(false);
  const casinoId = useSelector(currentCasinoSelector);
  const isFirstRender = useRef(true);
  const {
    data: dailyTournaments,
    isLoading,
    refetch
  } = useGetDailyTournamentsListByStatusQuery({
    casinoId: casinoId,
    tournamentStatus: tournamentStatus
  });

  useEffect(() => {
    refetch();
  }, [tournamentStatus, refetch]);

  useEffect(() => {
    if (
      dailyTournaments &&
      dailyTournaments.length > 0 &&
      isFirstRender.current
    ) {
      setShowButton(true);
      isFirstRender.current = false;
    }
  }, [dailyTournaments]);

  const onChange = (key: TOURNAMENT_STATUS) => {
    setTournamentStatus(key);
  };

  return (
    <>
      <div className={'d-flex flex-row justify-content-between pr-3'}>
        <div className={'gx-font-weight-bold'}>Daily Tournaments</div>
        {showButton && (
          <Button
            className={'gx-btn'}
            key="submit"
            type="default"
            style={{ position: 'relative', top: -8, marginBottom: 0 }}
            onClick={() => {
              openModal({
                type: 'CREATE_TOURNAMENT',
                props: {
                  type: 'DAILY_TOURNAMENTS'
                }
              });
            }}
          >
            Create a Daily Tournament
          </Button>
        )}
      </div>
      {isLoading ? (
        <Spin tip="Loading" size="large" />
      ) : tournamentStatus === TOURNAMENT_STATUS.ALL &&
        dailyTournaments &&
        dailyTournaments.length === 0 ? (
        <Card
          className={'mt-3'}
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            display: 'flex'
          }}
        >
          <h2>No Daily Tournaments Running Yet!</h2>
          <p>Here's your stage to create some action!</p>
          <ol
            style={{
              textAlign: 'left',
              marginBottom: '20px',
              listStyleType: 'none'
            }}
          >
            <li>
              <b>Create A Daily Tournament</b>: Create an Daily Tournament so
              Flop players know when you offer events.
            </li>
          </ol>
          <div>
            <Button
              className={'gx-btn'}
              key="submit"
              type="default"
              onClick={() => {
                openModal({
                  type: 'CREATE_TOURNAMENT',
                  props: {
                    type: 'DAILY_TOURNAMENTS'
                  }
                });
              }}
            >
              Create a Daily Tournament
            </Button>
          </div>
        </Card>
      ) : (
        <Tabs
          defaultActiveKey="ALL"
          size={'large'}
          moreIcon={<RightOutlined />}
          onChange={key => onChange(key as TOURNAMENT_STATUS)}
        >
          {tabs.map(tab => (
            <Tabs.TabPane key={tab.key} tab={tab.name}>
              <Card
                className="gx-layouts-view"
                style={{ height: 'calc(100vh - 332px )', overflowY: 'scroll' }}
              >
                {dailyTournaments && dailyTournaments.length > 0 && (
                  <TournamentsTable
                    tournaments={dailyTournaments ? dailyTournaments : []}
                    type={'DAILY_TOURNAMENTS'}
                  />
                )}
              </Card>
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default DailyTournamentsPage;
