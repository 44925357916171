import { InfoCircleOutlined } from '@ant-design/icons';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import { useResetPasswordMutation } from '@api-3/endpoints/account';
import { ChangePasswordFormValuesType } from '@old-world/features/authentication/models/AuthenticationModel';
import { parseParam } from '@old-world/utils/LocationUtils';
import { SIGN_IN } from '@routes-3/routes';
import { Button, Form, Input, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';

const FormItem = Form.Item;

const NewPasswordForm = () => {
  const { t } = useTranslation();
  const { location } = useReactRouter();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [resetPassword] = useResetPasswordMutation();
  const onChangePassword = async (data: ChangePasswordFormValuesType) => {
    setLoading(true);
    const token = parseParam(location, 'forgotPasswordToken');
    try {
      await resetPassword({
        email: data.email,
        password: data.password,
        token
      }).unwrap();
      history.push(SIGN_IN);
    } catch (e) {
      history.push(`/user-auth/change_password_unauthorized`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      initialValues={{ remember: true }}
      name="basic"
      onFinish={onChangePassword}
      className="gx-signin-form gx-form-row0"
    >
      <FormItem
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!'
          },
          {
            required: true,
            message: 'Please input your E-mail!'
          }
        ]}
        name="email"
        initialValue={parseParam(location, 'customerEmail')}
      >
        <Input
          disabled
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          type={'email'}
        />
      </FormItem>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please input your Password!'
          },
          {
            message: '',
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S_]{6,}$/
          }
        ]}
        name="password"
      >
        <div className={'gx-position-relative password-input-wrapper'}>
          <Input.Password
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={t('NEW_PASSWORD_LABEL')}
          />
          <div
            className="gx-position-absolute tooltip-wrapper"
            style={{ top: '6px', right: '-21px' }}
          >
            <Tooltip
              title={
                <>
                  <div>
                    <b>{t('PASS_RULE')}</b>
                  </div>
                  <div>- {t('UPPER_CASE')}</div>
                  <div>- {t('LOWER_CASE')}</div>
                  <div>- {t('NUMBER')}</div>
                </>
              }
            >
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </div>
        </div>
      </FormItem>
      <FormItem
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\S_]{6,}$/
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            }
          })
        ]}
        name="password_check"
        dependencies={['password']}
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder={t('NEW_CONFIRM_PASSWORD_LABEL')}
        />
      </FormItem>
      <FormItem className="gx-text-center gx-mt-5">
        <Button
          type="primary"
          htmlType="submit"
          className={'w-100'}
          loading={loading}
        >
          {t('SET_NEW_PASSWORD_BUTTON')}
        </Button>
      </FormItem>
    </Form>
  );
};
export default NewPasswordForm;
