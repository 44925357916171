import { useGetEventQuery } from '@api-3/endpoints/festivals';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import EventDetailsPageBody from '@routes-3/main/tournamentsManagement/eventDetails/components/EventDetailsPageBody';
import EventDetailsPageHeader from '@routes-3/main/tournamentsManagement/eventDetails/components/EventDetailsPageHeader';
import React from 'react';
import { useParams } from 'react-router';

interface RouteParams {
  festivalId: string;
}

const EventDetailsPage = () => {
  const { festivalId } = useParams<RouteParams>();
  const { data: event } = useGetEventQuery({
    festivalId
  });
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Tournaments Management'}
        pageIcon={
          <i
            className="icon icon-crm"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Events'}
        subSubPage={'Tournaments'}
      />
      {event && (
        <>
          <EventDetailsPageHeader event={event} />
          <EventDetailsPageBody event={event} />
        </>
      )}
    </LayoutContentContainer>
  );
};

export default EventDetailsPage;
