import { LoadingOutlined } from '@ant-design/icons';
import {
  SubGroup,
  useRemovePlayerFromSubGroupMutation
} from '@api-3/endpoints/subGroup';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SubGroupPlayerAction = ({
  flopId,
  subGroup,
  setSubGroupPlayersIds
}: {
  flopId: number;
  subGroup?: SubGroup;
  setSubGroupPlayersIds?: (players: number[]) => void;
}) => {
  const { t } = useTranslation();
  const [
    removePlayer,
    { isLoading: isSavingRemovePlayer }
  ] = useRemovePlayerFromSubGroupMutation();
  const Loader = () => <LoadingOutlined style={{ fontSize: 12 }} spin />;

  return (
    <div className={'gx-flex-row'} style={{ marginLeft: 'auto' }}>
      <Tooltip title={t('REMOVE_PLAYER')} placement="bottom">
        <div
          className="cursor-pointer"
          style={{ color: 'red' }}
          onClick={() => {
            if (subGroup) {
              removePlayer({
                body: { flopId: flopId, subgroupId: subGroup.id }
              });
            }
            if (setSubGroupPlayersIds) {
              // @ts-ignore
              setSubGroupPlayersIds(flopId);
            }
          }}
        >
          {isSavingRemovePlayer ? (
            <Loader />
          ) : (
            <FontAwesomeIcon icon={faTrash} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default SubGroupPlayerAction;
