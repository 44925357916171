import { useGetRegisteredPlayersQuery } from '@api-3/endpoints/interestList';
import {
  PlayerProfile,
  PlayerProfileInit,
  useGetPlayersProfileByIdsQuery
} from '@api-3/endpoints/player';
import PlayerInfo from '@components-3/PlayerInfo';
import PlayerInfoLoader from '@components-3/PlayerInfoLoader';
import { GameObject } from '@routes-3/main/cashGameManagement/fullDisplay/components/FullDisplay';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetWaitingListForGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetWaitingListForGame';
import { List, Tag } from 'antd';
import React from 'react';

const PlayerList = ({
  waitingPlayer,
  registeredPlayers,
  isLoading,
  gameType
}: {
  waitingPlayer?: WaitingPlayer[];
  registeredPlayers?: any;
  isLoading: boolean;
  gameType: 'RUNNING_GAME' | 'UPCOMING_GAME';
}) => {
  return (
    <List
      className={'card-waiting-list-items'}
      itemLayout="horizontal"
      // @ts-ignore
      dataSource={
        isLoading
          ? []
          : gameType === 'RUNNING_GAME'
          ? waitingPlayer
          : gameType === 'UPCOMING_GAME'
          ? registeredPlayers
          : []
      }
      renderItem={(item, index) => (
        <List.Item className={'waiting-list-item'}>
          {isLoading ? (
            <PlayerInfoLoader />
          ) : (
            <div className={'d-flex gx-flex-row w-100'}>
              <div
                className={'gx-text-geekblue mr-2'}
                style={{ alignSelf: 'center' }}
              >
                {index + 1}
              </div>
              <PlayerInfo
                player={item}
                textColor={'geekblue'}
                showDistance={false}
                showCountry={false}
              />
              {/*@ts-ignore*/}
              {gameType === 'RUNNING_GAME' && item.lastCallDate && (
                <Tag
                  style={{
                    marginLeft: 'auto',
                    alignSelf: 'center',
                    marginTop: 9
                  }}
                  className={'blink_me'}
                  color="#13c2c2"
                >
                  Called
                </Tag>
              )}
            </div>
          )}
        </List.Item>
      )}
    />
  );
};
const WaitingListPlayers = ({
  gameId,
  maxLength
}: {
  gameId: string;
  maxLength: number;
}) => {
  const { isLoading, players } = useGetWaitingListForGame({
    gameId,
    size: maxLength
  });
  return (
    <PlayerList
      waitingPlayer={players}
      isLoading={isLoading}
      gameType={'RUNNING_GAME'}
    />
  );
};
const RegisteredPlayersList = ({
  gameId,
  maxLength
}: {
  gameId: string;
  maxLength: number;
}) => {
  const { data: registeredPlayersList } = useGetRegisteredPlayersQuery(
    {
      id: gameId
    },
    { pollingInterval: 50000 }
  );

  const { data, isLoading } = useGetPlayersProfileByIdsQuery(
    {
      ids: (registeredPlayersList ?? [])
        .map(p => p.accountId)
        .filter(Boolean)
        .slice(0, maxLength)
    },
    { skip: !registeredPlayersList || registeredPlayersList.length === 0 }
  );

  const insert = (arr: Array<any>, index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ];
  let profiles: PlayerProfile[] | undefined = Object.assign([], data);
  if (registeredPlayersList && profiles) {
    registeredPlayersList.map((player, index) => {
      const profile: PlayerProfile = Object.assign({}, PlayerProfileInit);
      if (!player.accountId) {
        profile.lastName = player.name ? player.name : 'Anonymous';
        profiles = insert(profiles as PlayerProfile[], index, profile);
      }
    });
  }
  return (
    <PlayerList
      registeredPlayers={profiles}
      isLoading={isLoading}
      gameType={'UPCOMING_GAME'}
    />
  );
};
const CardPlayersList = ({
  game,
  maxLength
}: {
  game: GameObject;
  maxLength: number;
}) => {
  if (game.gameType === 'RUNNING_GAME') {
    return (
      <WaitingListPlayers
        gameId={game.content.liveGameKey}
        maxLength={maxLength}
      />
    );
  } else if (game.gameType === 'UPCOMING_GAME') {
    return (
      <RegisteredPlayersList gameId={game.content.id} maxLength={maxLength} />
    );
  } else {
    return null;
  }
};

export default CardPlayersList;
