import React from 'react';

export interface LayoutContentContainerProps {
  children: any;
}

export default function LayoutContentContainer({
  children
}: LayoutContentContainerProps) {
  return (
    <div style={{ overflowY: 'scroll' }} className="p-4">
      {children}
    </div>
  );
}
