import { useUpdateTableMutation } from '@api-3/endpoints/runningGame';
import { GameTableProps } from '@features/runningGames/types';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import TableGameCharacteristics, {
  TableGameCharacteristicsFieldFormShape
} from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TableGameCharacteristics';
import { Form, message, Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  table: GameTableProps;
};

const OpenTableModal = ({ onClose, table }: Props) => {
  const formMethods = useForm<TableGameCharacteristicsFieldFormShape>({
    mode: 'all',
    defaultValues: {}
  });

  const casinoId = useSelector(currentCasinoSelector);
  const [updateTable, { isLoading }] = useUpdateTableMutation();
  const onSubmit = async (data: TableGameCharacteristicsFieldFormShape) => {
    try {
      await updateTable({
        //TODO: This needs to be fixed in the backend
        position: table.position,
        country: table.country,
        casino: table.casino,
        city: table.city,
        // @ts-ignore
        address: table.address,
        gameType: 'CASH_GAME',
        casinoId,
        // TODO END
        tableState: 'RUNNING',

        ...data,
        id: table.id,
        buyIn: data.buyInMin,
        gameSize: `${data.smallBlind}/${data.bigBlind}${
          data.ante ? '/' + data.ante : '' // TODO: The backend validation is broken
        }`
      }).unwrap();
      message.success('Table has been updated.');
      onClose();
    } catch (e) {
      message.error('An error has occurred');
    }
  };

  return (
    <Modal
      afterClose={() => formMethods.reset()}
      open={true}
      centered
      title={'Open Table'.toLocaleUpperCase() + ' - ID: ' + table.tableId}
      onCancel={onClose}
      onOk={formMethods.handleSubmit(onSubmit)}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      okText={'open'}
      closable={false}
      width={RunningCashGamesConstants.MODAL_WIDTH}
    >
      <FormProvider {...formMethods}>
        <Form onFinish={formMethods.handleSubmit(onSubmit)} layout="vertical">
          <TableGameCharacteristics />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default OpenTableModal;
