import './DealerPage.scss';

import { CaretLeftOutlined } from '@ant-design/icons';
import { useGetRunningGamesQuery } from '@api-3/endpoints/games';
import rotateIcon from '@assets/images/retation.gif';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import DealerPageForm from '@routes-3/main/cashGameManagement/dealerDisplay/components/DealerPageForm';
import DealerTable from '@routes-3/main/cashGameManagement/dealerDisplay/components/DealerTable';
import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import MobileHeader from '@routes-3/main/mobileView/components/MobileHeader';
import { ADMIN_CASH_GAME_DEALER_DISPLAY, ADMIN_MOBILE } from '@routes-3/routes';
import { Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const DealerPage = () => {
  const history = useHistory();
  const { id: tableIdFromUrl } = useParams<{ id?: string }>();
  const casinoId = useSelector(currentCasinoSelector);
  const [activeTable, setActiveTable] = useState<Table>();
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );
  const { data, isLoading } = useGetRunningGamesQuery(
    {
      queryParams: {
        page: 1,
        size: 40,
        casinoId
      }
    },
    { pollingInterval: 5000 }
  );
  const allTables: Table[] = [];

  if (data && data.content.length > 0) {
    data.content.forEach((game: RunningGame) => {
      game.tables.forEach((table: Table) => {
        allTables.push(table);
      });
    });
  }

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (tableIdFromUrl) {
      const selectedTable = allTables.find(
        (table: Table) => table.id === tableIdFromUrl
      );
      if (selectedTable) {
        setActiveTable(selectedTable);
      }
    }
  }, [tableIdFromUrl, allTables, data]);

  function handleBack() {
    setActiveTable(undefined);
    history.push(ADMIN_CASH_GAME_DEALER_DISPLAY);
  }

  return (
    <Layout className="dealer-page-wrapper">
      <Layout.Header
        className="dealer-page-header"
        style={{ height: isMobile ? 50 : 80 }}
      >
        <MobileHeader />
      </Layout.Header>
      <Layout.Content className="dealer-page-body">
        {isPortrait && isMobile ? (
          <div style={{ textAlign: 'center', margin: '0 30px' }}>
            <img src={rotateIcon} alt={'rotate your device'} />
            <div>
              Please rotate your device to landscape orientation to use this
              site.
            </div>
          </div>
        ) : isLoading ? (
          <Spin />
        ) : activeTable ? (
          <DealerTable table={activeTable} onBack={handleBack} />
        ) : (
          <>
            {isMobile && (
              <div
                style={{ position: 'absolute', top: 60, left: 20 }}
                className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
                onClick={() => history.push(ADMIN_MOBILE)}
              >
                <CaretLeftOutlined className={'mr-1'} />
                <b style={{ position: 'relative', top: 2 }}>back</b>
              </div>
            )}
            <DealerPageForm
              allTables={allTables}
              setActiveTable={setActiveTable}
            />
          </>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default DealerPage;
