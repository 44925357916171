import { takeLatest } from 'redux-saga/effects';

/* ------------- Types ------------- */
/* ------------- Sagas ------------- */
import DebugConfig from '../../../config/DebugConfig';
import { AccountTypes } from '../redux/AccountRedux';
import SettingsApi from '../services/AccountApi';
import FixtureSettingsApi from '../services/FixtureAccountApi';
import {
  saveCasinoBanner,
  saveCasinoGeneralInformation,
  saveCasinoLogo
} from './AccountSagas';

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = DebugConfig.useFixtures ? FixtureSettingsApi : SettingsApi.create();

// Settings Saga
const settingsSaga = [
  takeLatest(
    AccountTypes.SAVE_CASINO_GENERAL_INFORMATION_REQUEST,
    saveCasinoGeneralInformation,
    api
  ),
  takeLatest(
    AccountTypes.UPLOAD_CASINO_BRAND_LOGO_REQUEST,
    saveCasinoLogo,
    api
  ),
  takeLatest(
    AccountTypes.UPLOAD_CASINO_BRAND_BANNER_REQUEST,
    saveCasinoBanner,
    api
  )
];

// Export Default
export default settingsSaga;
