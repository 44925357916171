import React, { createContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import ApiClient from '../ApiClient';
import { IRootState } from '../common/models/StateModel';
import { ownerSelector } from '../features/authentication/redux/AuthenticationRedux';
import CasinosActions from '../features/casinos/redux/CasinosRedux';
import CustomersActions from '../features/customers/redux/CustomersRedux';
import UsersActions from '../features/users/redux/UserRedux';

export const CurrentUserContext = createContext<any>({
  id: '',
  profilePicture: ''
});

function Provider({ children, owner }: { children: any; owner: any }) {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState<any>();

  useEffect(() => {
    async function setup() {
      if (owner) {
        const userResponse = await ApiClient.fetchProfile(
          owner.customerId,
          owner.id
        );

        try {
          const pictureResponse = await ApiClient.fetchProfilePicture(owner.id);

          setCurrentUser({
            data: {
              ...userResponse.data
            },
            profilePicture: pictureResponse.data.resizedUrl
          });
        } catch (e) {
          console.debug('Profile picture does not exist');
          setCurrentUser({
            data: userResponse.data
          });
        }

        dispatch(UsersActions.fetchUserRequest(owner.id));
        dispatch(
          CustomersActions.fetchCustomerDetailsRequest(owner.customerId)
        );
        dispatch(
          CasinosActions.fetchCasinoDetailsRequest(owner.managedCasinoId)
        );
      }
    }

    setup();
  }, [owner]);

  if (!currentUser) {
    return <span />;
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export const CurrentUserContextProvider = connect<any, any, any, IRootState>(
  state => ({ owner: ownerSelector(state) }),
  {
    fetchUser: UsersActions.fetchUserRequest,
    fetchCustomerDetails: CustomersActions.fetchCustomerDetailsRequest,
    fetchCasinoDetails: CasinosActions.fetchCasinoDetailsRequest
  }
)(Provider);
