import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

import CommonConstants from '../../../common/constants/CommonConstants';
import { ApiResponseType } from '../../../common/models';
import MessagesAction from '../../../common/redux/SystemMessagesRedux';
import i18n from '../../../i18n';
import { ownerSelector } from '../../authentication/redux/AuthenticationRedux';
import AccountActions from '../redux/AccountRedux';

function handleSameOpeningTime(data, timeZone) {
  // We try to add this format -0200[Europe/Madrid]
  const timeStrZoneForOpeningDay = `${moment()
    .tz(timeZone)
    .format('ZZ')}[${timeZone}]`;

  // Custom Time Section
  const openingDays: any = [];
  data.customTimeSection.customOpeningTime.forEach(day => {
    if (day.checked) {
      openingDays.push({
        dayOfWeek: day.value,
        openingTime: `${day.openingTime}${timeStrZoneForOpeningDay}`,
        closingTime: `${day.closingTime}${timeStrZoneForOpeningDay}`
      });
    }
  });
  // Add To DataService
  return openingDays;
}

export function* saveCasinoLogo(api: any, action: any) {
  const { brandLogo, managedCasinoId } = action;

  if (brandLogo) {
    const formData = new FormData();
    formData.append('image', brandLogo);
    // We Try to upload Logo
    const uploadLogoResponse: ApiResponseType<any> = yield call(
      api.uploadCasinoLogo,
      managedCasinoId,
      formData
    );
  }
}

export function* saveCasinoBanner(api: any, action: any) {
  const { brandBanner, managedCasinoId } = action;
  // Upload Logo
  if (brandBanner) {
    const formData = new FormData();
    formData.append('image', brandBanner);
    // We Try to upload Logo
    const uploadLogoResponse: ApiResponseType<any> = yield call(
      api.uploadCasinoBanner,
      managedCasinoId,
      formData
    );
  }
}
/**
 * Save General Information
 */
export function* saveCasinoGeneralInformation(api: any, action: any) {
  const { data, callback, typeSection } = action;

  let dataToSend = null;
  // Get Data of Games from Store

  // Prepare Data for the Service
  if (typeSection === 'general_information') {
    dataToSend = {
      name: data.casinoName,
      establishmentType: data.establishmentType,
      telephoneNumber: data.telephoneNumber,
      mainCurrency: data.mainCurrency,
      entryFee: data.entryFee,
      description: data.description
    };
  } else if (typeSection === 'address') {
    dataToSend = {
      address: data.address,
      city: data.city,
      postalCode: data.postalCode,
      country: data.country,
      countryCode: data.countryCode,
      region: data.region
    };
  } else if (typeSection === 'poker_room') {
    const openingDays = handleSameOpeningTime(data, data.timeZoneStr);
    dataToSend = {
      openingDays,
      alwaysOpen: data.openingDays === 'alwaysOpen',
      tablesNumber: data.pokerTablesNumber,
      minimumAgeEntrance: data.minAge
    };
  } else if (typeSection === 'contact') {
    dataToSend = {
      mailContact: data.mailContact,
      webSite: data.webSite,
      instagramUrl: data.instagramUrl,
      facebookUrl: data.facebookUrl,
      pokerRoom: true
    };
  }
  // Select casino Id
  const owner = yield select(ownerSelector);
  const { managedCasinoId } = owner;
  let saveGeneralInformationResponse: ApiResponseType<any>;
  if (typeSection === 'address') {
    saveGeneralInformationResponse = yield call(
      api.updateCasinoAddress,
      managedCasinoId,
      dataToSend
    );
  } else {
    saveGeneralInformationResponse = yield call(
      api.updateCasino,
      managedCasinoId,
      dataToSend
    );
  }

  if (saveGeneralInformationResponse.status === 204) {
    // Save General Information Success
    yield put(AccountActions.saveCasinoGeneralInformationSuccessResponse());
    if (typeSection === 'general_information') {
      yield put(
        AccountActions.uploadCasinoBrandLogoRequest(data.logo, managedCasinoId)
      );
      yield put(
        AccountActions.uploadCasinoBrandBannerRequest(
          data.banner,
          managedCasinoId
        )
      );
    }
    if (callback) {
      callback(true);
      // if (typeSection === 'contact') {
      //   yield put(AccountActions.saveCurrentStep(1));
      // }
    }
  } else {
    /// Display An Error
    yield put(
      MessagesAction.addMessage(
        'SAVE_CASINO_GENERAL_INFORMATION_ERROR',
        CommonConstants.ERROR,
        i18n.t('GLOBAL_ERROR_MESSAGE', {
          action: i18n.t('SAVE_GENERAL_INFORMATION_ACTION_ERROR')
        }),
        '',
        CommonConstants.PANEL
      )
    );
    // Set Error in Error Store
    yield put(AccountActions.saveCasinoGeneralInformationFailureResponse());
  }
}
