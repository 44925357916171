import { GameInvitationsDTO } from '@api-3/endpoints/interestList';
import PlayerInfo from '@components-3/PlayerInfo';
import usePlayerProfile from '@features/players/usePlayerProfile';
import React from 'react';

const RegisteredPlayerItem = ({
  gameInvitation
}: {
  gameInvitation: GameInvitationsDTO;
}) => {
  const { data: player } = usePlayerProfile(
    gameInvitation.accountId?.toString()
  );

  return (
    <PlayerInfo
      player={
        Object.keys(player.data).length !== 0 ? player.data : gameInvitation
      }
    />
  );
};

export default RegisteredPlayerItem;
