import _ from 'lodash';
import moment, { Moment } from 'moment';

export const isOutstideRange = (
  day: Moment,
  minDate?: Moment,
  maxDate?: Moment
) => {
  if (minDate && maxDate) {
    return !day.isBetween(minDate, maxDate);
  } else if (minDate) {
    return !day.isSameOrAfter(minDate);
  } else if (maxDate) {
    return !day.isSameOrBefore(maxDate);
  } else {
    return false;
  }
};

// * Get the number of days between two dates
export const numberOfDays = (dateFrom: string, dateTo: string) => {
  // * DATE FROM
  const dayF = moment(dateFrom).format('DD');
  const monthF = moment(dateFrom).format('MM');
  const yearF = moment(dateFrom).format('YYYY');
  const FROM = moment([yearF, monthF, dayF]);
  // * DATE To
  const dayT = moment(dateTo).format('DD');
  const monthT = moment(dateTo).format('MM');
  const yearT = moment(dateTo).format('YYYY');
  const TO = moment([yearT, monthT, dayT]);
  return TO.diff(FROM, 'days') - 1; // 1
};

// * Get the number of days between two dates
export const formatDate = (date: Moment) => {
  return `${date.format('DD')}${date.format('MM')}${date.format('YYYY')}`;
};

// * Format duration in seconds into standar format
export const formatDuration = (duration: number) => {
  // Calculate if more than 1day
  const dur = Math.abs(duration);
  let days = 0;
  if (dur >= 84000) {
    days = parseInt(String(dur / 84000), 10);
  }
  const seconds = dur - 84000;
  const date = moment(moment.duration(seconds, 'seconds').asSeconds()).format(
    'HH:mm'
  );

  const totalDuration = days ? days + ' days ' + date : date;
  return totalDuration;
};

export const _formatDuration = (duration: number) => {
  if (duration < 1) {
    return 'less than 1 hour';
  }

  return `${_.floor(duration)}h`;
};

export const convertMinutes = (inputMinutes: number) => {
  const days = Math.floor(inputMinutes / 1440);
  const hours = Math.floor((inputMinutes % 1440) / 60);
  const minutes = inputMinutes % 60;
  let result = '';
  if (days > 0) {
    result += days + 'd ';
  }
  if (hours > 0) {
    result += hours + 'h ';
  }
  if (minutes > 0) {
    result += minutes + 'm';
  }

  return result.trim();
};
