import { InterestList } from '@api-3/endpoints/interestList';
import { useGetMessagesQuery } from '@api-3/endpoints/message';
import { useModals } from '@providers/ModalProvider';
import { Spin } from 'antd';
import React from 'react';

const GameMessagesColumn = ({
  interestList
}: {
  interestList: InterestList;
}) => {
  const { data: messageResponse, isLoading } = useGetMessagesQuery(
    {
      entityType: 'GAME',
      entityId: interestList.id,
      params: { page: 1, size: 1 }
    },
    { pollingInterval: 5000 }
  );
  const openModal = useModals();
  return (
    <span
      className="cursor-pointer text-decoration-underline gx-font-weight-heavy"
      onClick={() =>
        openModal({
          type: 'MESSAGE_LIST',
          props: {
            interestList: interestList
          }
        })
      }
    >
      {!isLoading ? messageResponse?.totalElements || 0 : <Spin />}
    </span>
  );
};

export default GameMessagesColumn;
