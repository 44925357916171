import flopLogo from '@assets-3/images/flop-pm-logo.png';
import React from 'react';
import { Link } from 'react-router-dom';

const ChangePasswordUnauthorized = () => {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content" style={{ height: 426 }}>
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div>Welcome on Flop PM</div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h4>
                The Change Password link sent is valid only once. If you forget
                your password please click on the button below
              </h4>
            </div>
            <h4 className={'gx-text-center mt-3'}>
              {/*@ts-ignore*/}
              <Link
                className="gx-text-underline"
                style={{ color: '#4F8DCA' }}
                to="/user-auth/reset-password"
              >
                Forget Password
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordUnauthorized;
