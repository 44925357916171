import { BellOutlined } from '@ant-design/icons';
import {
  CasinoNotification,
  useReadCasinoNotificationMutation
} from '@api-3/endpoints/notifications';
import moment from 'moment';
import React from 'react';

const TableNotificationItem = ({
  notification
}: {
  notification: CasinoNotification;
}) => {
  const [readNotification] = useReadCasinoNotificationMutation();
  return (
    <div
      className={'p-4 d-flex justify-content-between'}
      style={{ borderBottom: '1px dashed #fff' }}
    >
      <div style={{ width: '30%' }}>
        <b>#{notification.tableId}</b>
      </div>
      <div>{moment(notification.notificationDate).format('h:mm a')}</div>
      <div>
        {!notification.read && (
          <BellOutlined
            className={'bell-icon-read'}
            style={{
              fontSize: 20,
              color: '#4a7a8a',
              cursor: 'pointer'
            }}
            onClick={async () =>
              await readNotification({
                notificationId: notification.id
              }).unwrap()
            }
          />
        )}
      </div>
    </div>
  );
};

export default TableNotificationItem;
