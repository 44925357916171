import { Template } from '@api-3/endpoints/templates';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ExistingGameListItem = ({
  game,
  index,
  selectedGameIndex,
  setSelectedGameIndex
}: {
  game: RunningGame;
  index: number;
  selectedGameIndex: number | undefined;
  setSelectedGameIndex: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<{
    [key: string]: string | number;
  }>();

  const tableId = useController({
    control,
    name: `tableId_${index}`,
    rules:
      index === selectedGameIndex
        ? {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            required: t('VALIDATOR_REQUIRED')! as string,
            minLength: 1,
            maxLength: 10
          }
        : {},
    defaultValue: ''
  });
  const tableCapacity = useController({
    control,
    name: `maxPlayers_${index}`,
    rules:
      index === selectedGameIndex
        ? {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            required: t('VALIDATOR_REQUIRED')! as string
          }
        : {},
    defaultValue: 8
  });
  const truncate = (str: string) => {
    return str.length > 17 ? str.substring(0, 16) + '...' : str;
  };

  useEffect(() => {
    if (index === selectedGameIndex) {
      setValue('bigBlind', parseInt(String(game.bigBlind)));
      setValue('smallBlind', parseInt(String(game.smallBlind)));
      setValue('gameVariant', game.gameVariant);
      setValue('ante', game.ante);
      setValue('buyInMin', game.buyInMin);
      setValue('buyInMax', game.buyInMax);
      setValue('selectedExistingGame', index);
    }
  }, [
    game.ante,
    game.bigBlind,
    game.buyInMax,
    game.buyInMin,
    game.gameVariant,
    game.smallBlind,
    index,
    selectedGameIndex,
    setValue
  ]);
  return (
    <div
      className="d-flex flex-row justify-content-between"
      style={{
        cursor: 'pointer',
        backgroundColor: '#323d46',
        padding: '6px 10px',
        display: 'flex',
        flexFlow: 'row',
        borderRadius: 4,
        width: '100%',
        border: selectedGameIndex === index ? '1px solid #4a7a8a' : 'none',
        borderLeftWidth: selectedGameIndex === index ? 6 : 0
      }}
      onClick={() => setSelectedGameIndex(index)}
    >
      <div style={{ color: 'white' }}>
        <div style={{ fontSize: 16, marginBottom: 5 }}>
          <b>{truncate(game.gameVariant)}</b>
        </div>
        <div style={{ fontSize: 12 }}>
          Size:{' '}
          <b>
            {game.gameSize}
            {game.ante ? `/${game.ante}` : null}
          </b>
        </div>
        <div style={{ fontSize: 12 }}>
          Buy-In:{' '}
          <b>
            {game.buyInMin ? formatBuyIn(game.buyInMin) : 'NO'} -
            {game.buyInMax ? formatBuyIn(game.buyInMax) : 'NO'}
          </b>
        </div>
      </div>
      <div className="d-flex flex-row">
        <AInput
          style={{ width: 100, marginRight: 15 }}
          controller={tableId}
          label={'Table Id'}
          type="text"
          maxLength={10}
        />
        <ASelect
          style={{ width: 100 }}
          controller={tableCapacity}
          label={'Capacity'}
        >
          {[6, 7, 8, 9, 10].map((num: number) => (
            <Select.Option key={num} value={num}>
              {num}
            </Select.Option>
          ))}
        </ASelect>
      </div>
    </div>
  );
};

export default ExistingGameListItem;
