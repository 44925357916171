import { CurrentUserContextProvider } from '@old-world/components/CurrentUserContext';
import { api } from '@old-world/services/Api';
import DealerPage from '@routes-3/main/cashGameManagement/dealerDisplay/components/DealerPage';
import React, { Suspense, useEffect } from 'react';

import LoadingPage from '../../../../../common/containers/LoadingPage';
export default function DealerDisplayPage() {
  const authorizationToken = localStorage.token;
  useEffect(() => {
    api.setHeader('Authorization', authorizationToken || '');
  }, [authorizationToken]);
  return (
    <CurrentUserContextProvider>
      <Suspense fallback={<LoadingPage />}>
        <DealerPage />
      </Suspense>
    </CurrentUserContextProvider>
  );
}
