import ChatIcon from '@components-3/notifications/ChatIcon';
import NoticeIcon from '@components-3/notifications/NoticeIcon';
import React from 'react';

const AppsNavigation = () => {
  return (
    <ul className="gx-app-nav">
      <li>
        <NoticeIcon />
      </li>
      <li>
        <ChatIcon />
      </li>
    </ul>
  );
};

export default AppsNavigation;
