import './styles/FormCheckBoxRFStyles.css';

import { Form } from 'antd';
import React, { InputHTMLAttributes } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormCheckbox } from 'shards-react';

const FormItem = Form.Item;
/* ****************** */
/*      CheckBox     */
/* ****************** */

type FormCheckBoxProps = {
  labelText: string; // Label Text Of Checkbox
  disabled: boolean;
  containerClassName?: string;
} & WrappedFieldProps &
  InputHTMLAttributes<HTMLInputElement>;
/**
 * Checkbox Component
 * Simple Checkbox Link with Redux Form
 */
const FormCheckBoxRF = ({
  input: { value, onChange },
  labelText,
  id,
  containerClassName
}: FormCheckBoxProps) => (
  <FormItem className={`labelCheckBox ${containerClassName}`}>
    <FormCheckbox
      id={id}
      onChange={() => {
        onChange(!value);
      }}
      checked={!!value}
      type="checkbox"
      className="checkboxInput"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: 0,
        border: 1,
        borderColor: '#FFF'
      }}
    >
      {labelText}
    </FormCheckbox>
  </FormItem>
);

export default FormCheckBoxRF;
