import { InterestList } from '@api-3/endpoints/interestList';
import PlayerInfo from '@components-3/PlayerInfo';
import usePlayerProfile from '@features/players/usePlayerProfile';
import CasinoAvatar from '@old-world/common/layouts/DefaultLayout/components/CasinoAvatar';
import { Spin } from 'antd';
import React from 'react';

const CreatedByColumn = ({ interestList }: { interestList: InterestList }) => {
  const { data: profile, isLoading } = usePlayerProfile(
    (interestList.ownerId as unknown) as string
  );

  return interestList.gameOrigin === 'FLOP_USER' ? (
    isLoading ? (
      <Spin />
    ) : profile ? (
      <PlayerInfo player={profile.data} />
    ) : (
      <span>'NO DATA'</span>
    )
  ) : (
    <CasinoAvatar
      height={30}
      textSize={12}
      fontWeight={'normal'}
      borderRadius={15}
    />
  );
};

export default CreatedByColumn;
