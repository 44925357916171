import { api } from '@api/api';

export type SubGroup = {
  id: number;
  gameVariant: string;
  smallBlind: number;
  bigBlind: number;
  ante?: number;
  maxBuyIn: number;
  minBuyIn: number;
  players: number[];
};

export type CreateSubGroupPayload = {
  gameVariant: string;
  smallBlind: number;
  bigBlind: number;
  ante?: number;
  maxBuyIn: number;
  minBuyIn: number;
  players: number[];
};

export type AddPlayerPayload = {
  flopId: number;
  subgroupId: number;
};

const subGroupsApi = api
  .enhanceEndpoints({ addTagTypes: ['SubGroups', 'SubGroup'] })
  .injectEndpoints({
    endpoints: builder => ({
      getSubGroups: builder.query<SubGroup[], void>({
        providesTags: ['SubGroups'],
        extraOptions: { maxRetries: 1 },
        query: () => '/games/api/games/sub-group'
      }),
      getSubGroup: builder.query<SubGroup, { subGroupId: string | number }>({
        providesTags: ['SubGroup'],
        extraOptions: { maxRetries: 1 },
        query: ({ subGroupId }) => `/games/api/games/sub-group/${subGroupId}`
      }),
      createSubGroup: builder.mutation<void, { body: CreateSubGroupPayload }>({
        invalidatesTags: ['SubGroups', 'SubGroup'],
        query: params => ({
          method: 'POST',
          body: params.body,
          url: '/games/api/games/sub-group'
        })
      }),
      updateSubGroup: builder.mutation<
        void,
        { body: SubGroup; subGroupId: string | number }
      >({
        invalidatesTags: ['SubGroups', 'SubGroup'],
        query: params => ({
          method: 'PUT',
          body: params.body,
          url: `/games/api/games/sub-group/${params.subGroupId}` //TODO add Id in url
        })
      }),
      deleteSubGroup: builder.mutation<void, { subGroupId: string }>({
        invalidatesTags: ['SubGroups', 'SubGroup'],
        query: ({ subGroupId }) => ({
          method: 'DELETE',
          url: `/games/api/games/sub-group/${subGroupId}`
        })
      }),
      addPlayerToSubGroup: builder.mutation<void, { body: AddPlayerPayload }>({
        invalidatesTags: ['SubGroups', 'SubGroup'],
        query: params => ({
          method: 'POST',
          body: params.body,
          url: '/games/api/games/sub-group/join'
        })
      }),
      removePlayerFromSubGroup: builder.mutation<
        void,
        { body: AddPlayerPayload }
      >({
        invalidatesTags: ['SubGroups', 'SubGroup'],
        query: params => ({
          method: 'POST',
          body: params.body,
          url: '/games/api/games/sub-group/quit'
        })
      })
    })
  });

export const {
  useGetSubGroupsQuery,
  useGetSubGroupQuery,
  useCreateSubGroupMutation,
  useUpdateSubGroupMutation,
  useDeleteSubGroupMutation,
  useAddPlayerToSubGroupMutation,
  useRemovePlayerFromSubGroupMutation
} = subGroupsApi;
