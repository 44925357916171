import {
  InterestList,
  useGetPlayersCountQuery
} from '@api-3/endpoints/interestList';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModals } from '@providers/ModalProvider';
import { Spin } from 'antd';
import React from 'react';

const GamePlayersColumn = ({
  interestList
}: {
  interestList: InterestList;
}) => {
  const openModal = useModals();
  const { data: count, isLoading } = useGetPlayersCountQuery(
    {
      id: interestList.id
    },
    { pollingInterval: 5000 }
  );
  let tableState = 'danger';
  if (count && count > 5) {
    tableState = 'warning';
  }
  if (count && count > 8) {
    tableState = 'primary';
  }
  return interestList ? (
    <span
      className="cursor-pointer text-decoration-underline gx-font-weight-heavy"
      onClick={() =>
        openModal({
          type: 'REGISTERED_PLAYERS_LIST',
          props: { game: interestList }
        })
      }
    >
      {isLoading ? <Spin /> : count || 0}
      <FontAwesomeIcon
        icon={faUser}
        className={`gx-text-${tableState} ml-1`}
        style={{ fontSize: 12, position: 'relative', bottom: 1 }}
      />
    </span>
  ) : (
    <span>NO DATA</span>
  );
};

export default GamePlayersColumn;
