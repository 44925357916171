export const COLORS = {
  ERROR: '#fe5f55',
  PRIMARY: '#4d8dcc',
  SECONDARY1: '#1a2025',
  SECONDARY_BACKGROUND_1: '#d2d9df',
  TEXT_PRIMARY: '#323f48',
  TEXT_SECONDARY: '#8A9BA8',
  VALIDATION_GREEN: '#7cc194',
  WHITE: '#ffffff',
  WARNING: '#F18B2D'
};

export const CHART_COLORS = {
  GAMES_AND_TABLES: [
    '#2E6BA7',
    '#3979A6',
    '#4283A5',
    '#4A8DA4',
    '#5397A3',
    '#5CA2A2',
    '#65ACA1',
    '#6EB6A0',
    '#76C09F',
    '#83CE9D'
  ]
};
