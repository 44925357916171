export default {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  INFO: 'INFO',

  PANEL: 'PANEL',
  MODAL: 'MODAL',

  ADD_MESSAGE_FORM: 'add-message-form'
};
