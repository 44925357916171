import { ListAnnouncementItem } from '@api-3/endpoints/Announcements';
import React from 'react';
import Marquee from 'react-fast-marquee';

const AnnouncementMarquee = ({
  announcements
}: {
  announcements: ListAnnouncementItem[];
}) => (
  <div
    className="d-flex align-items-center"
    style={{ backgroundColor: 'white', height: 70, color: 'black' }}
  >
    <Marquee>
      {announcements.map((announcement: ListAnnouncementItem, i: number) => (
        <div className="d-flex ">
          <div key={i} className="d-flex align-items-center">
            <h1 className="gx-mr-2 gx-mb-0" style={{ color: 'black' }}>
              <u>{announcement.title}</u>:
            </h1>
            <p className="gx-mb-0" style={{ fontSize: 20 }}>
              {announcement.description}
            </p>
          </div>
          <div className="gx-mx-5" style={{ fontSize: 24 }}>
            +++
          </div>
        </div>
      ))}
    </Marquee>
  </div>
);

export default AnnouncementMarquee;
