import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { IRootState } from '../../../common/models/StateModel';
import { generateFetchAction } from '../../../redux/util';
import { ICustomersImmutableStateType } from '../models/CustomersModel.d';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ...generateFetchAction(
    'fetchCustomerDetails',
    ['id'],
    ['id', 'data'],
    ['id'],
    ['id']
  ),
  changeCustomerEmailAdmin: ['newEmail'],
  ...generateFetchAction(
    'updateCustomerProfile',
    ['firstname', 'lastname', 'phoneNumber'],
    ['firstname', 'lastname', 'phoneNumber'],
    ['payload']
  )
});

export const CustomersTypes = Types;
// Export Default
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ICustomersImmutableStateType = Immutable({
  account: {}
});

/* ------------- Reducers ------------- */

/**
 * Fetch customers Details Reducer
 */
export const fetchCustomerDetails = (
  state: ICustomersImmutableStateType,
  {
    data
  }: {
    data: CustomerApiDefinitions.Customer;
  }
) => {
  return state.merge({ account: data });
};

export const changeCustomerEmail = (
  state: ICustomersImmutableStateType,
  { newEmail }
) => {
  return state.setIn(['account', 'emailAdminUser'], newEmail);
};

export const succesUpdateCustomerProfile = (
  state: ICustomersImmutableStateType,
  { firstname, lastname, phoneNumber }
) => {
  return state.setIn(['account', 'phoneNumber'], phoneNumber);
};

/* ------------- Hookup Reducers To Types ------------- */
// Customer Reducer
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CUSTOMER_DETAILS_SUCCESS_RESPONSE]: fetchCustomerDetails,
  [Types.CHANGE_CUSTOMER_EMAIL_ADMIN]: changeCustomerEmail,
  [Types.UPDATE_CUSTOMER_PROFILE_SUCCESS_RESPONSE]: succesUpdateCustomerProfile
});

/* ------------- Selectors ------------- */
// Get Customer
export const customerSelector = (state: IRootState) => state.customers.account;
