export default {
  signin: () => ({
    status: 200,
    data: {
      email: 's.paquis@we-opt.com',
      id: 1096,
      managedCasinoId: '254654556',
      customerId: 23
    },
    headers: {
      authorization: 'Bearer Xdfsdfsfxfwfedfdsfds'
    }
  }),

  // Signup Call
  signup: () => ({
    status: 201,
    data: {}
  }),

  // Validate Email Call
  validateMail: () => ({ status: 200 }),

  // Reset Password Call
  resetPassword: () => ({ status: 204 }),

  // Resend Email Call
  resendEmail: () => ({ status: 204 }),

  /**
   * Check Access to New Password interface
   * @param email
   */
  checkAccessForChangePassword: (email: string) => {
    if (email === 'test@we-opt.com') {
      return { status: 403 };
    }
    return { status: 204 };
  },

  /**
   * Check Access to Setting New Password interface
   * @param email
   */
  checkAccessForSettingNewPassword: (email: string) => {
    if (email === 'test@we-opt.com') {
      return { status: 403 };
    }
    return { status: 204 };
  },

  /**
   * Change The password
   */
  changePassword: () => ({ status: 204 })
};
