import {
  CheckCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { StatisticsType } from '@api-3/endpoints/reporting';
import { COLORS } from '@constants/colors';
import { Card, Col, Row } from 'antd';
import React, { ReactNode } from 'react';
import { RxCrossCircled } from 'react-icons/rx';

const Divider = ({ className }: { className?: string }) => (
  <div
    className={`section-spacer ${className} d-none d-sm-block`}
    style={{
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      borderLeft: '1px solid currentColor',
      height: '30%'
    }}
  />
);

const Section = ({
  statistic,
  icon,
  name,
  color
}: {
  icon: ReactNode;
  statistic: number;
  name: string;
  color: string;
}) => {
  return (
    <section className={'text-center position-relative'}>
      <div className="d-flex align-items-center font24 justify-content-center mb-2">
        <span
          className="d-flex align-items-center justify-content-center"
          style={{ color }}
        >
          {icon}
        </span>
        <span className="ml-2">{statistic}</span>
      </div>
      <span className="font18 font-weight-light">{name}</span>
    </section>
  );
};

const WaitingListStatistics = ({
  waitingListData
}: {
  waitingListData: StatisticsType['waitingListRegistrations'];
}) => {
  return (
    <Card className={'gx-card-widget'} title={'Waiting List Registrations'}>
      <Row gutter={16}>
        <Col sm={6} xs={24}>
          <Section
            icon={<DownloadOutlined />}
            statistic={waitingListData.received}
            name="Received"
            color={COLORS.PRIMARY}
          />
          <Divider />
        </Col>
        <Col sm={6} xs={24}>
          <Section
            icon={<CheckCircleOutlined />}
            statistic={waitingListData.seated}
            name="Seated"
            color={COLORS.VALIDATION_GREEN}
          />
          <Divider />
        </Col>
        <Col sm={6} xs={24}>
          <Section
            icon={<RxCrossCircled />}
            statistic={waitingListData.notShowed}
            name="No shows"
            color={COLORS.WARNING}
          />
          <Divider />
        </Col>

        <Col sm={6} xs={24}>
          <Section
            icon={<InfoCircleOutlined />}
            statistic={waitingListData.notCalled}
            name="Not called"
            color={COLORS.ERROR}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default WaitingListStatistics;
