import {
  ListAnnouncementItem,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation
} from '@api-3/endpoints/Announcements';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import AnnouncementForm from '@routes-3/main/myPokerRoom/announcements/components/forms/AnnouncementForm';
import { Button, Form, Modal, notification } from 'antd';
import moment from 'moment/moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  announcement?: ListAnnouncementItem;
};

const DATE_SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const CreateUpdateAnnouncementModal = ({ onClose, announcement }: Props) => {
  const { t } = useTranslation();
  const formMethods = useForm<ListAnnouncementItem>({
    defaultValues: {
      ...announcement
    }
  });
  const { handleSubmit } = formMethods;
  const [
    createAnnouncement,
    { isLoading: isCreateAnnouncementLoading }
  ] = useCreateAnnouncementMutation();
  const [
    updateAnnouncement,
    { isLoading: isUpdateAnnouncementLoading }
  ] = useUpdateAnnouncementMutation();
  const onSubmit = async (data: ListAnnouncementItem) => {
    try {
      const params: { body: any } = {
        body: {
          title: data.title,
          description: data.description,
          startingDate: data.startingDate
            ? moment(data.startingDate).format(DATE_SERVER_FORMAT)
            : undefined,
          endingDate: data.endingDate
            ? moment(data.endingDate).format(DATE_SERVER_FORMAT)
            : undefined,
          publish: data.publish
        }
      };
      if (announcement) {
        params.body.id = announcement.id;
        await updateAnnouncement({
          ...params,
          announcementId: announcement.id
        }).unwrap();
      } else {
        await createAnnouncement(params).unwrap();
      }
      onClose();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to ${
          announcement ? 'update' : 'create'
        } this announcement. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={announcement ? 'EDIT ANNOUNCEMENT' : 'NEW ANNOUNCEMENT'}
      onCancel={onClose}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      closable={false}
      footer={[
        <Button onClick={onClose} className="">
          {t('CANCEL_BUTTON')}
        </Button>,
        <Button
          onClick={handleSubmit(onSubmit)}
          className="gx-btn"
          loading={isCreateAnnouncementLoading || isUpdateAnnouncementLoading}
        >
          {announcement ? 'Update' : t('ADD_BUTTON')}
        </Button>
      ]}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <AnnouncementForm announcement={announcement} />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default CreateUpdateAnnouncementModal;
