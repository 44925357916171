import './TableSettingModal.scss';

import { useUpdateTableMutation } from '@api-3/endpoints/runningGame';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import TableGameCharacteristics, {
  TableGameCharacteristicsFieldFormShape
} from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TableGameCharacteristics';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Col, Form, message, Modal, Row, Select } from 'antd';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  table: Table;
};

type TableSettingFormShape = {
  tableId: string;
  maxPlayers: number;
} & TableGameCharacteristicsFieldFormShape;

const TableSettingModal = ({ onClose, table }: Props) => {
  const { t } = useTranslation();
  const casinoId = useSelector(currentCasinoSelector);
  const [updateTable, { isLoading }] = useUpdateTableMutation();
  const formMethods = useForm<TableSettingFormShape>({
    mode: 'all',
    defaultValues: {
      tableId: table.tableId,
      maxPlayers: table.maxPlayers,
      gameLabel: table.gameLabel,
      gameVariant: table.gameVariant,
      smallBlind: table.gameSize
        ? parseInt(table.gameSize.split('/')[0])
        : undefined,
      bigBlind: table.gameSize
        ? parseInt(table.gameSize.split('/')[1])
        : undefined,
      // ante: table.ante ? table.ante : undefined,
      buyInMin: table.buyInMin,
      buyInMax: table.buyInMax
    }
  });
  const rules = {
    required: t('VALIDATOR_REQUIRED')!
  };
  const tableId = useController({
    control: formMethods.control,
    name: 'tableId',
    rules
  });
  const maxPlayers = useController({
    control: formMethods.control,
    name: 'maxPlayers',
    rules
  });
  const onSubmit = async (data: TableSettingFormShape) => {
    try {
      await updateTable({
        position: table.position,
        country: table.country,
        casino: table.casino,
        city: table.city,
        // @ts-ignore
        address: table.address,
        gameType: 'CASH_GAME',
        casinoId,
        ...data,
        id: table.id,
        buyIn: data.buyInMin,
        gameSize: `${data.smallBlind}/${data.bigBlind}${
          data.ante ? '/' + data.ante : ''
        }`
      }).unwrap();
      message.success('Table has been updated.');
      onClose();
    } catch (e) {
      const { status } = e as { status: number };
      // we have a conflict with the table id
      if (status == 409) {
        formMethods.setError('tableId', {
          message: 'Id is already taken'
        });
      } else {
        message.error('An error has occurred');
      }
    }
  };

  return (
    <Modal
      className={'table-setting-modal'}
      afterClose={() => formMethods.reset()}
      open={true}
      centered
      title={'Edit Table'.toLocaleUpperCase() + ' - ID: ' + table.tableId}
      onCancel={onClose}
      onOk={formMethods.handleSubmit(onSubmit)}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      confirmLoading={isLoading}
      okText={'save'}
      closable={false}
      width={RunningCashGamesConstants.MODAL_WIDTH}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={formMethods.handleSubmit(onSubmit)} layout="vertical">
          <Row
            className={'gx-flex-row'}
            style={{ marginLeft: -16, position: 'relative' }}
          >
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <AInput
                controller={tableId}
                label={t('TABLE_ID')}
                type="text"
                className={'d-flex flex-column'}
                maxLength={4}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <ASelect
                controller={maxPlayers}
                label={t('TABLE_CAPACITY')}
                className="d-flex flex-column"
              >
                {[6, 7, 8, 9, 10].map((num: number) => (
                  <Select.Option key={num} value={num}>
                    {num}
                  </Select.Option>
                ))}
              </ASelect>
            </Col>
          </Row>
          {table.gameVariant && (
            <>
              <hr />
              <TableGameCharacteristics />
            </>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default TableSettingModal;
