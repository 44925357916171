import { InterestList } from '@api-3/endpoints/interestList';
import { PlayerSearchProfile } from '@api-3/endpoints/player';
import { useGetSubGroupQuery } from '@api-3/endpoints/subGroup';
import FlopUserForm from '@components-3/addPlayerModal/FlopUserForm';
import NonFlopUserForm from '@components-3/addPlayerModal/NonFlopUserForm';
import ModalTitleWithGameInfo from '@components-3/ModalTitleWithGameInfo';
import SubGroupPlayersList from '@components-3/SubGroupPlayerTable/SubGroupPlayersList';
import {
  RunningGame,
  Table
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetGame';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AddPlayerModalProps = {
  onClose: () => void;
  game?: RunningGame | InterestList;
  table?: Table;
  isInterestList?: boolean;
  showNonFopUser?: boolean;
  subGroupId?: number;
  setSubGroupPlayersIds?: (players: number[]) => void;
  addText?: string;
  removeText?: string;
  onChange?: (player: PlayerSearchProfile) => void;
  checkIsSelected?: (player: PlayerSearchProfile) => boolean;
  open?: boolean;
  showSubGroupForm?: boolean;
  addToSubGroup?: boolean;
};

const SubGroupList = ({ subGroupId }: { subGroupId: number }) => {
  const { data: subGroup } = useGetSubGroupQuery(
    {
      // @ts-ignore
      subGroupId: subGroupId
    },
    { skip: !subGroupId }
  );

  const [players, setPlayers] = useState<number[]>([]);

  return <SubGroupPlayersList subGroup={subGroup} />;
};

const AddPlayerModal = ({
  onClose,
  game: tmpGame,
  table: tmpTable,
  isInterestList = false,
  showNonFopUser = true,
  setSubGroupPlayersIds,
  addText = 'Add to WL',
  removeText = 'Remove from WL',
  onChange,
  checkIsSelected,
  open = true,
  subGroupId,
  addToSubGroup
}: AddPlayerModalProps) => {
  const { t } = useTranslation();

  const { game } = useGetGame({ gameId: tmpGame?.id });
  const table = game?.tables.find(t => t.tableId === tmpTable?.tableId);

  return (
    <Modal
      open={open}
      centered
      bodyStyle={{ height: 500 }}
      title={
        <ModalTitleWithGameInfo
          game={game || tmpGame}
          tableId={table && table.tableId}
          title={t('ADD_NEW_PLAYER_HEADER')}
        />
      }
      cancelText={'Close'}
      onCancel={onClose}
      okButtonProps={{ style: { display: 'none' } }}
      zIndex={10000}
    >
      {/*{subGroupId && <SubGroupList subGroupId={subGroupId} />}*/}

      {showNonFopUser && (
        <NonFlopUserForm
          isInterestList={isInterestList}
          table={table}
          isWaitingList={false}
          gameId={tmpGame?.id}
        />
      )}
      <FlopUserForm
        isInterestList={isInterestList}
        addText={addText}
        addToSubGroup={addToSubGroup}
        removeText={removeText}
        table={table}
        gameId={tmpGame?.id}
        subGroupId={subGroupId}
        setSubGroupPlayersIds={setSubGroupPlayersIds}
        onChange={onChange}
        checkIsSelected={checkIsSelected}
      />
    </Modal>
  );
};

export default AddPlayerModal;
