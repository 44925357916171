// Export All Settings Fixture API
// Export Default
export default {
  // Update Casino
  updateCasino: () => ({
    status: 204
  }),

  uploadCustomerLogo: () => ({
    status: 200,
    headers: {
      location: 'http://fdskfkds/dsfsdfdsf/image1'
    }
  })
};
