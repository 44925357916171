import PropTypes from 'prop-types';
import React from 'react';

type LineIndicatorProps = {
  title: string;
  title2: string;
  width: string;
  value: string;
  color: string;
};
const LineIndicator = ({
  title,
  title2,
  width,
  value,
  color
}: LineIndicatorProps) => {
  return (
    <React.Fragment>
      <div className="ant-row-flex">
        <p className="gx-mr-1 gx-font-weight-bold">{title}</p>
        <p className={'gx-mr-1'}>|</p>
        <p className="gx-text-grey gx-font-italic">{title2}</p>
      </div>
      <div className="gx-line-indi-info">
        <div
          className={`gx-line-indi`}
          style={{
            backgroundColor: color,
            width: Number.parseInt(width, 10) * 3.5,
            height: 6
          }}
        />
        <span className="gx-line-indi-count gx-ml-2">{value}</span>
      </div>
    </React.Fragment>
  );
};

export default LineIndicator;

LineIndicator.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};
