import { ApiError } from '@api/types';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry
} from '@reduxjs/toolkit/query/react';

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as any).authentication.authorizationToken;
        headers.set('authorization', token);
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Cache-Control', 'no-cache');

        return headers;
      },
      baseUrl: process.env.REACT_APP_API_URL
    })(args, api, extraOptions);

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    const status = result.error?.status;
    if (status === 401 || status === 400 || status === 500) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 1
  }
);

export const api = createApi({
  reducerPath: 'apiSlice',
  tagTypes: [],
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: () => ({})
});

export function isFetchBaseQueryError(error: unknown): error is ApiError {
  return typeof error === 'object' && error != null && 'data' in error;
}
