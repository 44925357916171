import {
  makeField,
  Props as makeFieldProps
} from '@components/AntdSimpleFieldForReactFormHooks';
import { Select, SelectProps } from 'antd';
import React from 'react';

export type AutoCompleteFieldProps = SelectProps<string> & makeFieldProps;

function AutoCompleteField(props: AutoCompleteFieldProps) {
  const {
    children,
    onSearch,
    onChange,
    placeholder,
    disabled,
    loading = false,
    showArrow,
    filterOption = (
      inputValue: string,
      option?: { key?: string | number | undefined } | undefined
    ) => {
      const key = option?.key;
      return (
        String(key)
          .toUpperCase()
          .indexOf(inputValue.toUpperCase()) !== -1
      );
    },
    value
  } = props;
  return (
    <Select
      showSearch
      value={value}
      showArrow={showArrow}
      placeholder={placeholder}
      notFoundContent={null}
      onSearch={onSearch}
      filterOption={filterOption}
      onChange={onChange}
      style={{ width: '100%' }}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </Select>
  );
}

// @ts-ignore
export default makeField(AutoCompleteField);
