import ContentLoader from 'react-content-loader';

const PlayerInfoLoader = () => {
  return (
    <ContentLoader
      speed={1}
      width={300}
      height={48}
      viewBox="0 0 300 48"
      backgroundColor="#ffffff"
      foregroundColor="#b8b8b8"
    >
      <rect x="48" y="8" rx="3" ry="3" width="120" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="60" height="6" />
      <circle cx="16" cy="16" r="16" />
    </ContentLoader>
  );
};

export default PlayerInfoLoader;
