import {
  NOTIFICATION_TYPE,
  useGetNotificationCountQuery
} from '@api-3/endpoints/notifications';
import styles from '@components-3/notifications/NoticeIcon.module.less';
import NotificationList from '@components-3/notifications/NotificationList';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { Badge, Dropdown } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export interface NoticeIconProps {
  className?: string;
  onPopupVisibleChange?: (visible: boolean) => void;
}

function NoticeIcon({ className, onPopupVisibleChange }: NoticeIconProps) {
  const [visible, setVisible] = useState(false);
  const owner = useSelector(ownerSelector);

  const { data: notificationCount } = useGetNotificationCountQuery(
    { userId: owner.id, notificationType: NOTIFICATION_TYPE.GAME },
    { pollingInterval: 10000 }
  );

  const getNotificationBox = () => {
    return <NotificationList onHide={() => setVisible(false)} />;
  };

  const handleVisibleChange = (visible: boolean): void => {
    setVisible(visible);
    if (onPopupVisibleChange) {
      onPopupVisibleChange(visible);
    }
  };

  const noticeButtonClass = `${className} ${styles.noticeButton}`;
  const notificationBox = getNotificationBox();
  const trigger = (
    <span className={`${noticeButtonClass} ${visible && 'opened'}`}>
      <Badge size="small" count={notificationCount}>
        <i className="icon icon-notification" style={{ fontSize: 20 }} />
      </Badge>
    </span>
  );

  if (!notificationBox) {
    return trigger;
  }

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationBox}
      overlayClassName={styles.popover}
      trigger={['click']}
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      {trigger}
    </Dropdown>
  );
}

NoticeIcon.defaultProps = {
  onPopupVisibleChange: (): void => {},
  loading: false,
  clearClose: false
};

export default NoticeIcon;
