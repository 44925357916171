import React from 'react';

const Form: React.FunctionComponent<React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>> = ({ children, ...rest }) => {
  return (
    <form
      className="ant-form ant-form-vertical form d-flex flex-column flex-fill"
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;
