import RegisteredPlayerAction from '@routes-3/main/cashGameManagement/interestList/modals/RegisteredPlayerAction';
import RegisteredPlayerItem from '@routes-3/main/cashGameManagement/interestList/modals/RegisteredPlayerItem';
import { Table } from 'antd';
import React from 'react';

const RegisteredPlayersList = ({
  players,
  gameId
}: {
  players: any;
  gameId: string;
}) => {
  const columns = [
    {
      title: '',
      dataIndex: 'index',
      render: (value: string, item: any, index: number) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Player',
      dataIndex: 'player',
      render: (text: string, record: any) => {
        return <RegisteredPlayerItem gameInvitation={record} />;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: string, record: any) => {
        return (
          <RegisteredPlayerAction gameInvitation={record} gameId={gameId} />
        );
      }
    }
  ];

  return (
    <Table
      className="gx-table-no-bordered"
      columns={columns}
      dataSource={players}
      pagination={false}
      size="small"
      rowKey={(record: any) => record.id}
    />
  );
};

export default RegisteredPlayersList;
