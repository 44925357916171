import AddPlayerModal from '@components-3/addPlayerModal/AddPlayerModal';
import NotifyPlayersModal from '@components-3/modals/NotifyPlayersModal/NotifyPlayersModal';
import InviteUserModal from '@features/userManagement/modals/InviteUserModal';
import ThemeSettingsModal from '@old-world/flop-3.0/layout/components/ThemeSettingsModal';
import DealerActionModal from '@routes-3/main/cashGameManagement/dealerDisplay/modal/DealerActionModal';
import InterestListCreationModal from '@routes-3/main/cashGameManagement/interestList/modals/InterestListCreationModal';
import MessagesModal from '@routes-3/main/cashGameManagement/interestList/modals/MessagesModal';
import RegisteredPlayersModal from '@routes-3/main/cashGameManagement/interestList/modals/RegisteredPlayersModal';
import StartingInterestListModal from '@routes-3/main/cashGameManagement/interestList/modals/StartingInterestListModal';
import AddGameToMapModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/AddGameMapModal';
import AddTableToMapModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/AddTableToMapModal';
import ChoseTableAndSitModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/ChoseTableAndSitModal';
import ExpectedWaitingTimeModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/ExpectedWaitingTimeModal';
import OpenTableModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/OpenTableModal';
import TablePlayersModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/TablePlayersModal';
import TableSettingModal from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tableSettingModal/TableSettingModal';
import CreateUpdateTemplateModal from '@routes-3/main/cashGameManagement/templates/modals/CreateUpdateTemplateModal';
import CreateUpdateAnnouncementModal from '@routes-3/main/myPokerRoom/announcements/components/modals/CreateUpdateAnnouncementModal';
import OnboardingModal from '@routes-3/main/myPokerRoom/modals/OnboardingModal';
import EditSubGroupModal from '@routes-3/main/playersDatabase/sub-groups/components/modals/EditSubGroupModal';
import AddTournamentModal from '@routes-3/main/tournamentsManagement/common/AddTournamentModal';
import AddEventModal from '@routes-3/main/tournamentsManagement/events/modals/AddEventModal';
import React from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';

const modals = {
  STARTING_INTEREST_LIST: {
    type: 'STARTING_INTEREST_LIST',
    content: StartingInterestListModal
  },
  INTEREST_LIST_CREATION: {
    type: 'INTEREST_LIST_CREATION',
    content: InterestListCreationModal
  },
  NOTIFY_PLAYERS: { type: 'NOTIFY_PLAYERS', content: NotifyPlayersModal },
  EXPECTED_WAITING_TIME: {
    type: 'EXPECTED_WAITING_TIME',
    content: ExpectedWaitingTimeModal
  },
  MESSAGE_LIST: { type: 'MESSAGE_LIST', content: MessagesModal },
  ADD_PLAYERS: {
    type: 'ADD_PLAYERS',
    content: AddPlayerModal
  },
  REGISTERED_PLAYERS_LIST: {
    type: 'REGISTERED_PLAYERS_LIST',
    content: RegisteredPlayersModal
  },
  INVITE_USER: {
    type: 'INVITE_USER',
    content: InviteUserModal
  },
  TABLE_PLAYER: {
    type: 'TABLE_PLAYER',
    content: TablePlayersModal
  },
  CREATE_GAME: {
    type: 'CREATE_GAME',
    content: AddGameToMapModal
  },
  CREATE_TABLE: {
    type: 'CREATE_TABLE',
    content: AddTableToMapModal
  },
  CREATE_UPDATE_TEMPLATE: {
    type: 'CREATE_UPDATE_TEMPLATE',
    content: CreateUpdateTemplateModal
  },
  OPEN_TABLE: {
    type: 'OPEN_TABLE',
    content: OpenTableModal
  },
  EDIT_TABLE: {
    type: 'EDIT_TABLE',
    content: TableSettingModal
  },
  ONBOARDING: {
    type: 'ONBOARDING',
    content: OnboardingModal
  },
  CHOSE_TABLE_AND_SIT_MODAL: {
    type: 'CHOSE_TABLE_AND_SIT_MODAL',
    content: ChoseTableAndSitModal
  },
  THEME_SETTINGS_MODAL: {
    type: 'THEME_SETTINGS_MODAL',
    content: ThemeSettingsModal
  },
  CREATE_EVENT: {
    type: 'CREATE_EVENT',
    content: AddEventModal
  },
  CREATE_TOURNAMENT: {
    type: 'CREATE_TOURNAMENT',
    content: AddTournamentModal
  },
  CREATE_UPDATE_ANNOUNCEMENT: {
    type: 'CREATE_UPDATE_ANNOUNCEMENT',
    content: CreateUpdateAnnouncementModal
  },
  EDIT_SUB_GROUP: {
    type: 'EDIT_SUB_GROUP',
    content: EditSubGroupModal
  },
  DEALER_ACTION_MODAL: {
    type: 'DEALER_ACTION_MODAL',
    content: DealerActionModal
  }
} as const;

// We map the modals array to types.
// Explanation can be found here: https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
type Modals = {
  [Property in keyof typeof modals]: {
    type: typeof modals[Property]['type'];
    props: Omit<
      Parameters<typeof modals[Property]['content']>[0],
      'onClose' | 'onCancel'
    >;
  };
};

type OpenProps = Modals[keyof Modals];

type ContextType = {
  open: (props: OpenProps) => Promise<unknown>;
};

const ModalContext = createContext<ContextType>({
  open: () => Promise.reject()
});

type Modal = { key: string; content: JSX.Element };

const findModal = (type: keyof typeof modals) => (props: any): JSX.Element => {
  const M = modals[type].content;
  return <M key={type} {...props} />;
};

export default function ModalProvider({ children }: { children: ReactNode }) {
  const [openModal, setOpenModals] = useState<Modal[]>([]);
  const open = ({ type, props }: OpenProps) => {
    return new Promise((resolve, reject) => {
      const key = Math.random().toString();
      setOpenModals(notifications => [
        ...notifications,
        {
          key,
          content: findModal(type)({
            ...(props as any), // TODO: types are any but there is no difference
            onCancel: () => {
              reject();
              setOpenModals(notifications =>
                notifications.filter(notification => notification.key != key)
              );
            },
            onClose: () => {
              resolve(null);
              setOpenModals(notifications =>
                notifications.filter(notification => notification.key != key)
              );
            }
          })
        }
      ]);
    });
  };

  return (
    <ModalContext.Provider value={{ open }}>
      {openModal.map(n => n.content)}
      {children}
    </ModalContext.Provider>
  );
}

export const useModals = () => {
  const { open } = useContext(ModalContext);
  return open;
};
