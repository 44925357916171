import { useGetMessagesQuery } from '@api-3/endpoints/message';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import ReceivedMessageCell from '@routes-3/main/chat/components/ReceivedMessageCell';
import SentMessageCell from '@routes-3/main/chat/components/SentMessageCell';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const Conversation = ({
  selectedInterestGameId
}: {
  selectedInterestGameId: string;
}) => {
  const casinoId = useSelector(currentCasinoSelector);
  const { data: messageResponse } = useGetMessagesQuery(
    {
      entityType: 'GAME',
      entityId: selectedInterestGameId,
      params: { page: 1, size: 50 }
    },
    { pollingInterval: 5000 }
  );
  const bottomRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageResponse]);

  return (
    <>
      <div className="gx-chat-main-content">
        {messageResponse && messageResponse.totalElements !== 0 ? (
          messageResponse?.content
            .slice(0)
            .reverse()
            .map((message: any, index: number) =>
              message.authorRepresentativeId === casinoId ? (
                <SentMessageCell key={index} message={message} />
              ) : (
                <ReceivedMessageCell key={index} message={message} />
              )
            )
        ) : (
          <div
            className="gx-comment-box"
            style={{ height: 'calc(100vh - 464px)' }}
          >
            <div className="gx-fs-80">
              <i className="icon icon-chat gx-text-muted" />
            </div>
            <h1 className="gx-text-muted">No Message</h1>
          </div>
        )}
      </div>
      <div ref={bottomRef} />
    </>
  );
};

export default Conversation;
