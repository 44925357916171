import { useStatisticsQuery } from '@api-3/endpoints/reporting';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { DateRangePicker } from '@components/DateRangePicker';
import Spinner from '@routes-3/main/myPokerRoom/componenets/Spinner';
import AverageTablesFillingRate from '@routes-3/main/myPokerRoom/dashboard/components/AverageTablesFillingRate';
import NumberOfPlayersSeated from '@routes-3/main/myPokerRoom/dashboard/components/NumberOfPlayersSeated';
import PlayersLeaderboard from '@routes-3/main/myPokerRoom/dashboard/components/PlayersLeaderboard';
import PopularGamesIndicator from '@routes-3/main/myPokerRoom/dashboard/components/PopularGamesIndicator';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import WaitingListStatistics from './components/WaitingListStatistics';

const DashboardPage = () => {
  const [{ start, end }, setRange] = useState({
    start: moment()
      .subtract(7, 'days')
      .toDate(),
    end: moment().toDate()
  });
  const { data, isLoading, error, refetch } = useStatisticsQuery({
    start: moment(start).format('YYYY-MM-DD'),
    end: moment(end).format('YYYY-MM-DD')
  });

  useEffect(() => {
    refetch();
  }, [start, end, refetch]);

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Dashboard'}
      />
      <div className="align-items-center end-align d-flex justify-content-end mb-4">
        <DateRangePicker
          disabledDate={current => {
            // Can not select days before today and today
            return (
              current &&
              current < moment('2023-08-01', 'YYYY-MM-DD').endOf('day')
            );
          }}
          start={start}
          end={end}
          onChange={value => setRange({ start: value[0], end: value[1] })}
        />
      </div>
      <div style={{ maxHeight: 'calc(100vh - 246px)', overflowY: 'auto' }}>
        {error ? (
          <div>error</div>
        ) : (
          <Row className={''}>
            <Col xl={6} lg={12} md={12} sm={12} xs={24}>
              {isLoading ? (
                <Spinner />
              ) : (
                <NumberOfPlayersSeated
                  numberOfPlayersSeated={data!.numberOfPlayersSeated}
                />
              )}
            </Col>
            <Col xl={9} lg={12} md={12} sm={12} xs={24}>
              {isLoading ? (
                <Spinner />
              ) : (
                <AverageTablesFillingRate
                  averageTablesFillingRateData={data!.averageTablesFillingRate}
                />
              )}
            </Col>
            <Col xl={9} lg={24} md={24} sm={24} xs={24}>
              {isLoading ? (
                <Spinner />
              ) : (
                <WaitingListStatistics
                  waitingListData={data!.waitingListRegistrations}
                />
              )}
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              {isLoading ? (
                <Spinner />
              ) : (
                <PopularGamesIndicator
                  popularGamesIndicator={data!.popularGamesIndicator}
                />
              )}
            </Col>
            {/*<Col xl={12} lg={24} md={24} sm={24} xs={24}>*/}
            {/*  {isLoading ? (*/}
            {/*    <Spinner />*/}
            {/*  ) : (*/}
            {/*    <AverageSessionPerGame*/}
            {/*      averageSessionPerGameData={data!.averageSessionPerGame}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</Col>*/}
            <Col xl={16} lg={12} md={12} sm={24} xs={24}>
              {isLoading ? (
                <Spinner />
              ) : (
                <PlayersLeaderboard
                  playersLeaderboardData={data!.playersLeaderboard}
                />
              )}
            </Col>
          </Row>
        )}
      </div>
    </LayoutContentContainer>
  );
};

export default DashboardPage;
