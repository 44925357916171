import moment from 'moment/moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AnnouncementDateColumn = ({ date }: { date: Date | undefined }) => {
  const { t } = useTranslation();
  return date ? (
    <span>{t('DATE_GAME', { date: moment(date).parseZone() })}</span>
  ) : (
    <span style={{ opacity: 0.3, fontSize: 12 }}>NO DATA</span>
  );
};

export default AnnouncementDateColumn;
