import {
  authorizationTokenSelector as authorizationTokenSelectorNew,
  currentCasinoSelector as currentCasinoSelectorNew,
  currentCustomerIdSelector as currentCustomerIdSelectorNew,
  currentUserIdSelector as currentUserIdSelectorNew,
  getCurrentUserId as getCurrentUserIdNew,
  ownerSelector as ownerSelectorNew
} from '@redux-3/AuthenticationRedux';
import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { IRootState } from '../../../common/models/StateModel.d';
import { generateFetchAction } from '../../../redux/util';
import { AuthenticationImmutableStateType } from '../models/AuthenticationModel.d';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ...generateFetchAction(
    'signIn',
    ['email', 'password'],
    ['signin', 'authorizationToken']
  ),
  ...generateFetchAction('signUp', ['accountData'], []),
  ...generateFetchAction('resendEmail', ['email'], []),
  ...generateFetchAction('changeEmail', ['oldEmail', 'newEmail'], ['newEmail']),
  ...generateFetchAction('validateEmail', ['email', 'activationToken'], []),
  ...generateFetchAction('resetPassword', ['email'], []),
  ...generateFetchAction(
    'changePasswordGrant',
    ['email', 'activationToken'],
    []
  ),
  ...generateFetchAction('newPasswordGrant', ['email', 'activationToken'], []),
  ...generateFetchAction(
    'changePassword',
    [
      'email',
      'firstName',
      'lastName',
      'password',
      'password_check',
      'token',
      'actionType'
    ],
    []
  ),
  storeAuth: ['signin', 'authorizationToken'], // Set the userId and the auth token when we come from signup flop website
  logoutRequest: [] // Logout Request
});

export const AuthenticationTypes = Types;
// Export Default
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: AuthenticationImmutableStateType = Immutable({});

/* ------------- Reducers ------------- */

// we've successfully logged in
export const success = (
  state: AuthenticationImmutableStateType,
  { signin, authorizationToken }: AnyAction
) => {
  localStorage.setItem('token', authorizationToken);

  return state.merge({ signin, authorizationToken });
};

export const successChangeEmail = (
  state: AuthenticationImmutableStateType,
  { newEmail }: AnyAction
) => state.setIn(['signin', 'email'], newEmail);

// we've logged out
export const resetState = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
// Login Reducer
export const reducer = createReducer(INITIAL_STATE, {
  // [Types.SIGN_IN_SUCCESS_RESPONSE]: success,
  [Types.CHANGE_EMAIL_SUCCESS_RESPONSE]: successChangeEmail
  // [Types.STORE_AUTH]: success,
  // [Types.LOGOUT_REQUEST]: resetState
});

/* ------------- Selectors ------------- */
export const getCurrentUserId = getCurrentUserIdNew;
export const ownerSelector = ownerSelectorNew;
export const authorizationTokenSelector = authorizationTokenSelectorNew;
export const currentCasinoSelector = currentCasinoSelectorNew;
export const currentCustomerIdSelector = currentCustomerIdSelectorNew;
export const currentUserIdSelector = currentUserIdSelectorNew;
