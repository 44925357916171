import { ListAnnouncementItem } from '@api-3/endpoints/Announcements';
import AnnouncementsTable from '@routes-3/main/myPokerRoom/announcements/components/AnnouncementsTable';
import { Card } from 'antd';

const AnnouncementsTab = ({
  announcements
}: {
  announcements?: ListAnnouncementItem[];
}) => {
  return (
    <Card
      className="gx-layouts-view"
      style={{ height: 'calc(100vh - 332px )', overflowY: 'scroll' }}
    >
      {announcements && (
        <div className="row">
          <AnnouncementsTable announcements={announcements} />
        </div>
      )}
    </Card>
  );
};

export default AnnouncementsTab;
