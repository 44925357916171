import './WaitingList.scss';

import WaitingListPanel from '@routes-3/main/cashGameManagement/runningGamesMap/components/WaitingListPanel';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Card, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';

const WaitingList = ({
  runningGames,
  selectedTab
}: {
  runningGames: RunningGame[];
  selectedTab: string;
}) => {
  const [filteredGames, setFilteredGames] = useState(runningGames);
  const [activeKey, setActiveKey] = useState<string | string[]>([
    runningGames.findIndex(game => game.waitingPlayers.length !== 0).toString()
  ]);

  const parseFilterString = (str: string) => {
    const parts = str.split('/');
    const gameVariant = parts[0];
    const smallBlind = parseFloat(parts[1]);
    const bigBlind = parseFloat(parts[2]);
    const ante = parts[3] ? parseFloat(parts[3]) : null;
    return {
      gameVariant,
      smallBlind,
      bigBlind,
      ante
    };
  };
  useEffect(() => {
    if (selectedTab !== 'ALL') {
      const filterObj = parseFilterString(selectedTab);
      console.log('filterObj: ', filterObj);
      console.log('runningGames: ', runningGames);
      const filtered = runningGames.filter(
        game =>
          game.gameVariant === filterObj.gameVariant &&
          game.smallBlind === filterObj.smallBlind &&
          game.bigBlind === filterObj.bigBlind &&
          game.ante === filterObj.ante
      );
      console.log('filtered: ', filtered);
      setFilteredGames(filtered);
    } else {
      setFilteredGames(runningGames);
    }
  }, [runningGames, selectedTab]);

  return (
    <Card
      className={`${
        filteredGames && filteredGames.length > 0 ? 'waiting-list-card' : ''
      }`}
    >
      {filteredGames.map((game, index) => (
        <Collapse
          activeKey={activeKey}
          key={index}
          onChange={keys => {
            console.log('keys: ', keys);
            setActiveKey(keys as string[]);
          }}
          className={'waiting-list-collapse'}
        >
          <WaitingListPanel
            index={index}
            key={index}
            game={game}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            header={null}
          />
        </Collapse>
      ))}
    </Card>
  );
};

export default WaitingList;
