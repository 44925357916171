import { InfoCircleFilled } from '@ant-design/icons';
import ApiClient from '@old-world/ApiClient';
import { PasswordStrengthMeter } from '@old-world/common/components/form';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import { PASSWORD_REGEX } from '@old-world/constants/user';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Tooltip,
  Typography
} from 'antd';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GrUpdate } from 'react-icons/gr';

export default function ChangePasswordForm() {
  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const [validationErrors, setValidationErrors] = useState({
    newPassword: '',
    newPasswordConfirmation: ''
  });

  function resetForm() {
    setIsEditing(false);
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirmation('');
    setValidationErrors({ newPassword: '', newPasswordConfirmation: '' });
  }

  async function handleSubmit() {
    try {
      setSubmitting(true);

      const errors = {
        newPassword: !new RegExp(PASSWORD_REGEX).test(newPassword)
          ? t('ChangePasswordForm.errors.recommendation_mismatch')
          : '',
        newPasswordConfirmation:
          newPassword !== newPasswordConfirmation
            ? t('ChangePasswordForm.errors.passwords_mismatch')
            : ''
      };

      setValidationErrors(errors);

      if (errors.newPassword || errors.newPasswordConfirmation) {
        return;
      }

      await ApiClient.changePassword({
        currentPassword,
        email: currentUser?.data?.email,
        newPassword,
        password_check: newPasswordConfirmation
      });

      setIsEditing(false);
      message.success(t('notifications.password_updated_successfully'));

      resetForm();
    } catch (e) {
      message.error((e as any)?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  const passwordsAreTheSame =
    currentPassword && newPassword && currentPassword === newPassword;

  return (
    <Form className="form d-flex flex-column flex-fill" layout="vertical">
      <Row justify="start">
        <Col className="w-100 pb-2">
          <Form.Item
            label={t('ChangePasswordForm.current_password')}
            className="mb-2"
          >
            <Input.Password
              placeholder={t('ChangePasswordForm.type_your_current_password')}
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              required
              disabled={!isEditing}
            />
          </Form.Item>

          <Form.Item
            label={
              <>
                {t('ChangePasswordForm.new_password')}
                <Tooltip
                  title={
                    <>
                      The password must contain at least 8 characters:
                      <ul>
                        <li>Minimum one upper case letter</li>
                        <li>Minimum one lower case letter</li>
                        <li>Minimum one number</li>
                      </ul>
                    </>
                  }
                >
                  <InfoCircleFilled className="ml-2" />
                </Tooltip>
              </>
            }
            className="mb-2 flex-column"
          >
            <Input.Password
              placeholder={t('ChangePasswordForm.type_your_new_password')}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              disabled={!currentPassword}
              required
            />
          </Form.Item>
          {validationErrors.newPassword && (
            <Typography.Text type="danger">
              {validationErrors.newPassword}
            </Typography.Text>
          )}
          <div className="mb-2">
            {passwordsAreTheSame ? (
              <Typography.Text type="danger">
                {t(
                  'ChangePasswordForm.errors.old_and_new_password_are_the_same'
                )}
              </Typography.Text>
            ) : (
              <PasswordStrengthMeter password={newPassword} />
            )}
          </div>
          <Form.Item
            label={t('ChangePasswordForm.new_password_confirmation')}
            className="mb-4 flex-column pb-0"
          >
            <Input.Password
              placeholder={t('ChangePasswordForm.type_your_new_password_again')}
              value={newPasswordConfirmation}
              onChange={e => setNewPasswordConfirmation(e.target.value)}
              disabled={!currentPassword}
            />
          </Form.Item>
          {validationErrors.newPasswordConfirmation && (
            <Typography.Text type="danger" className="pb-2">
              {validationErrors.newPasswordConfirmation}
            </Typography.Text>
          )}
        </Col>

        <Row justify="center" className="w-100">
          <Fragment>
            {!isEditing ? (
              <Button
                type="primary"
                onClick={() => setIsEditing(true)}
                loading={isSubmitting}
              >
                {t('EDIT_BUTTON')}
              </Button>
            ) : (
              <div className="d-flex">
                <Button className={'w-50'} type="default" onClick={resetForm}>
                  {t('CANCEL_BUTTON')}
                </Button>
                <Button
                  style={{ flex: 1 }}
                  type="primary"
                  icon={<GrUpdate />}
                  disabled={Boolean(
                    !currentPassword ||
                      !newPassword ||
                      !newPasswordConfirmation ||
                      passwordsAreTheSame
                  )}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Update password
                </Button>
              </div>
            )}
          </Fragment>
        </Row>
      </Row>
    </Form>
  );
}
