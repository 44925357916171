import React from 'react';

const formatDistanceValue = (distanceValue: number) => {
  if (distanceValue > 100) {
    return '> 100';
  } else if (distanceValue < 1) {
    return '< 1';
  }
  return Math.floor(distanceValue);
};

const convertDistanceUnit = (distanceUnit: 'KILOMETERS' | 'FEET') => {
  if (distanceUnit === 'KILOMETERS') {
    return 'km';
  } else if (distanceUnit === 'FEET') {
    return 'ft';
  }
  return distanceUnit;
};

export default function DistanceWithUnit({
  value,
  unit
}: {
  value: number;
  unit: 'KILOMETERS' | 'FEET';
}) {
  return (
    <span>{`${formatDistanceValue(value)} ${convertDistanceUnit(unit)}`}</span>
  );
}
