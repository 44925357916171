import { useDeleteCasinoMutation } from '@api-3/endpoints/casino';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { CurrentUserContext } from '@old-world/components/CurrentUserContext';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { logout } from '@redux-3/AuthenticationRedux';
import ChangePasswordForm from '@routes-3/main/userProfile/components/ChangePasswordForm';
import { Button, Card, Modal } from 'antd';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AccountSettingsPage = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(CurrentUserContext);
  const owner = useSelector(ownerSelector);
  const casinoId = owner.managedCasinoId;
  const [deleteCasino] = useDeleteCasinoMutation();
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={`${currentUser.data.firstName} ${currentUser.data.lastName}`}
        pageIcon={
          <i
            className="icon icon-avatar"
            style={{ fontSize: 14, position: 'absolute', top: 1 }}
          />
        }
        subPage={'Account Settings'}
      />
      <Card className="gx-card w-50" title="Password & Security">
        <div className="w-100">
          <ChangePasswordForm />
          <Button
            onClick={() => {
              Modal.confirm({
                title:
                  'By deleting the Casino Account, you won’t be anymore able to :',
                content: (
                  <ul>
                    <li>List running games and Interest Lists</li>
                    <li>Getting access to flop App players community</li>
                  </ul>
                ),
                okText: 'Confirm',
                okType: 'danger',
                cancelText: 'Cancel',
                async onOk() {
                  await deleteCasino({ casinoId: casinoId }).then(() =>
                    dispatch(logout())
                  );
                }
              });
            }}
            type="link"
            size={'small'}
            danger
          >
            Delete Casino Account
          </Button>
        </div>
      </Card>
    </LayoutContentContainer>
  );
};

export default React.memo(AccountSettingsPage);
