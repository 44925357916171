import {
  InterestList,
  useConvertInterestListToGameMutation
} from '@api-3/endpoints/interestList';
import {
  faBullhorn,
  faCheck,
  faPlay,
  faTrashCan,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModals } from '@providers/ModalProvider';
import useCancelInterestList from '@routes-3/main/cashGameManagement/interestList/hooks/cancelInterestList';
import useDeclineInterestList from '@routes-3/main/cashGameManagement/interestList/hooks/declineInterestList';
import { Modal, notification, Row, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GameActionsColumn = ({
  interestList
}: {
  interestList: InterestList;
}) => {
  const { t } = useTranslation();
  const openModal = useModals();
  const [convertInterestListToGame] = useConvertInterestListToGameMutation();
  const declineInterestList = useDeclineInterestList();
  const cancelInterestList = useCancelInterestList();
  const interestListId = interestList.id;
  return interestList?.state ? (
    <>
      <Row>
        {interestList.state && interestList.state === 'ACCEPTED' && (
          <>
            <Tooltip title={t('NOTIFY_TABLE')} placement="bottom">
              <FontAwesomeIcon
                style={{ fontSize: 18 }}
                className={'gx-text-warning cursor-pointer mr-3'}
                icon={faBullhorn}
                onClick={() =>
                  openModal({
                    type: 'NOTIFY_PLAYERS',
                    props: { game: interestList }
                  })
                }
              />
            </Tooltip>
            <Tooltip title={t('START_CASH_GAMES')} placement="bottom">
              <FontAwesomeIcon
                style={{ fontSize: 18 }}
                className={'gx-text-success cursor-pointer mr-3'}
                icon={faPlay}
                onClick={() =>
                  openModal({
                    type: 'STARTING_INTEREST_LIST',
                    props: { interestList }
                  })
                }
              />
            </Tooltip>
          </>
        )}
        {(interestList.gameOrigin === 'CASINO' ||
          interestList.state === 'DECLINED' ||
          interestList.state === 'ACCEPTED') && (
          <Tooltip title={t('REMOVE_CASH_GAMES')} placement="bottom">
            <FontAwesomeIcon
              style={{ fontSize: 18 }}
              className={'gx-text-danger cursor-pointer mr-3'}
              icon={faTrashCan}
              onClick={() => {
                Modal.confirm({
                  title: t('REMOVE_CONFIRMATION_TEXT'),
                  okText: t('REMOVE_BUTTON'),
                  okType: 'danger',
                  cancelText: t('NO_BUTTON'),
                  async onOk() {
                    await cancelInterestList(interestListId);
                  }
                });
              }}
            />
          </Tooltip>
        )}
        {interestList.gameOrigin === 'FLOP_USER' &&
          interestList.state &&
          interestList.state === 'PENDING' && (
            <>
              <Tooltip title={t('ACCEPT_CASH_GAMES')} placement="bottom">
                <FontAwesomeIcon
                  style={{ fontSize: 20 }}
                  className={'gx-text-success cursor-pointer mr-3'}
                  icon={faCheck}
                  onClick={async () => {
                    try {
                      await convertInterestListToGame({
                        gameId: interestListId
                      }).unwrap();
                      notification.success({
                        message: 'Success',
                        description: t('ACCEPT_GAME_ACTION_SUCCESS'),
                        placement: 'topRight'
                      });
                    } catch (e) {
                      notification.error({
                        message: 'Error',
                        description: t('GLOBAL_ERROR_MESSAGE', {
                          action: t('ACCEPT_GAME_ACTION_ERROR')
                        }),
                        placement: 'topRight'
                      });
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title={t('DECLINE_CASH_GAMES')} placement="bottom">
                <FontAwesomeIcon
                  style={{ fontSize: 22 }}
                  className={'gx-text-danger cursor-pointer mr-3'}
                  icon={faXmark}
                  onClick={() => {
                    Modal.confirm({
                      title: t('DECLINE_CONFIRMATION_TEXT'),
                      okText: t('DECLINE_BUTTON'),
                      okType: 'danger',
                      cancelText: t('NO_BUTTON'),
                      async onOk() {
                        await declineInterestList(interestListId);
                      }
                    });
                  }}
                />
              </Tooltip>
            </>
          )}
      </Row>
    </>
  ) : (
    <span>NO DATA</span>
  );
};

export default GameActionsColumn;
