import {
  AInput,
  APhoneInput
} from '@components/AntdSimpleFieldForReactFormHooks';
import React, { Fragment } from 'react';
import { useController } from 'react-hook-form';

/**
 * Casino Contact Section
 */
const CasinoContactSection = ({ disabled }: { disabled?: boolean }) => {
  const websiteController = useController({
    name: 'webSite',
    rules: { required: true, pattern: /(www|http:|https:)+[^\s]+[\w]/ }
  });

  const mailContactController = useController({
    name: 'mailContact',
    rules: { required: true }
  });

  const telephoneNumberController = useController({
    name: 'telephoneNumber',
    rules: { required: true }
  });

  return (
    <Fragment>
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Contact Information
      </p>
      <div className={'row'}>
        <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
          <AInput
            disabled={disabled}
            className={'d-flex flex-column mb-4'}
            label={'Contact Email Address'}
            controller={mailContactController}
            hasFeedback
            mandatory
          />
          <APhoneInput
            disabled={disabled}
            className={'d-flex flex-column w-100'}
            label={'Phone Number'}
            controller={telephoneNumberController}
            mandatory
          />
        </div>
        <div className={'col-sm-12 col-md-12 col-lg-6 col-xl-6'}>
          <AInput
            disabled={disabled}
            className={'d-flex flex-column mb-4'}
            controller={websiteController}
            label={'Website'}
            hasFeedback
            mandatory
          />
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(CasinoContactSection);
