import { useCasinoQuery } from '@api-3/endpoints/casino';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import Spinner from '@routes-3/main/myPokerRoom/componenets/Spinner';
import useSettingsForm from '@routes-3/main/myPokerRoom/hooks/useSettingsForm';
import OfferedGamesList from '@routes-3/main/myPokerRoom/pokerGamesInformation/componenets/OfferedGamesList';
import { Card, Form } from 'antd';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function PokerGamesInformationPage() {
  const owner = useSelector(ownerSelector);
  const { isLoading } = useCasinoQuery({ id: owner.managedCasinoId });
  const { formMethods, onSubmitForm } = useSettingsForm();
  // @ts-ignore*
  const onSave = formMethods.handleSubmit(onSubmitForm);

  const handleSubmit = formMethods.handleSubmit;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Poker Games Information'}
      />
      <Card className="gx-card w-50" title="Offered Games">
        <p>
          You can list the in flop APP the offered Poker Games that runs in
          regular basis to let the flop APP community knows more about your
          offer.
        </p>
        {/*@ts-ignore*/}
        <FormProvider {...formMethods}>
          {/*@ts-ignore*/}
          <Form onFinish={handleSubmit(onSubmitForm)} layout="vertical">
            {/*@ts-ignore*/}
            <OfferedGamesList control={formMethods.control} onSave={onSave} />
          </Form>
        </FormProvider>
      </Card>
    </LayoutContentContainer>
  );
}
