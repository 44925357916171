import { useGetSubGroupsQuery } from '@api-3/endpoints/subGroup';
import SubGroupsTable from '@routes-3/main/playersDatabase/sub-groups/components/SubGroupsTable';
import { Card, Spin } from 'antd';
import React from 'react';

const SubGroupsPageContent = () => {
  const { data, isLoading } = useGetSubGroupsQuery();

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading" size="large" />
      ) : data && data.length > 0 ? (
        <Card
          className="gx-layouts-view mt-3"
          style={{ height: 'calc(100vh - 294 )', overflowY: 'scroll' }}
        >
          <SubGroupsTable subGroups={data} />
        </Card>
      ) : (
        <Card
          className={'mt-3'}
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            display: 'flex'
          }}
        >
          <h2>No Sub-Groups yet!</h2>
          <p>Here's your stage to create some action!</p>
          Sub groups appear here when you create a template. For each template
          has a corresponding sub-group that allows you to manage users and
          quickly onboard them.
          <div></div>
        </Card>
      )}
    </>
  );
};

export default SubGroupsPageContent;
