import AppsNavigation from '@old-world/flop-3.0/layout/components/AppsNavigation';
import SidebarLogo from '@old-world/flop-3.0/layout/components/SidebarLogo';
import TableNotification from '@old-world/flop-3.0/layout/components/TableNotification';
import UserProfile from '@old-world/flop-3.0/layout/components/UserProfile';
import {
  ADMIN_CASH_GAME,
  ADMIN_CASH_GAME_DEALER_DISPLAY,
  ADMIN_CASH_GAME_FULL_DISPLAY,
  ADMIN_CASH_GAME_INTEREST_LIST,
  ADMIN_CASH_GAME_RUNNING_GAMES,
  ADMIN_CASH_TEMPLATES,
  ADMIN_CONTACT_US,
  ADMIN_DAILY_TOURNAMENTS,
  ADMIN_EVENTS,
  ADMIN_EVENTS_CLOCK,
  ADMIN_MY_PLAYERS,
  ADMIN_MY_POKER_ROOM,
  ADMIN_MY_POKER_ROOM_ADS,
  ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS,
  ADMIN_MY_POKER_ROOM_DASHBOARD,
  ADMIN_MY_POKER_ROOM_GAMES,
  ADMIN_MY_POKER_ROOM_GENERAL,
  ADMIN_MY_POKER_ROOM_SETTINGS,
  ADMIN_PLAYERS,
  ADMIN_SUB_GROUPS,
  ADMIN_TERMS_AND_CONDITIONS
} from '@routes-3/routes';
import { Layout, Menu } from 'antd';
import React, { useMemo, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CasinoAvatar from '../../common/layouts/DefaultLayout/components/CasinoAvatar';
import CustomScrollbars from '../../utils/CustomScrollbars';

export interface DefaultLayoutProps {
  children: any;
}

function ComingSoon() {
  return (
    <small
      style={{
        color: 'var(--gray)',
        position: 'absolute',
        left: 0,
        top: '3px'
      }}
    >
      {' '}
      (coming soon){' '}
    </small>
  );
}

function DefaultLayout({ children }: DefaultLayoutProps) {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(isMobile || isTablet);
  const location = useLocation();
  // const openModal = useModals();
  const openKeys = useMemo(
    () =>
      location.pathname
        .replace('/', '')
        .split('/')
        .reduce((acc: any, r: any) => {
          const last = acc[acc.length - 1];
          return last ? [...acc, `${last}/${r}`] : [`/${r}`];
        }, [] as string[]),
    [location]
  );

  return (
    <Layout className="h-100">
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        theme={'dark'}
        className="gx-app-sidebar gx-layout-sider-dark"
      >
        <SidebarLogo isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        <div className="gx-sidebar-content">
          <div className={'gx-sidebar-notifications'}>
            <UserProfile />
            <AppsNavigation />
          </div>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={openKeys}
            selectedKeys={openKeys}
            theme={'dark'}
            mode="inline"
          >
            <Menu.SubMenu
              key={ADMIN_CASH_GAME}
              title={
                <span>
                  <i className="icon icon-dasbhoard" />
                  <span>Cash Game Management</span>
                </span>
              }
            >
              <Menu.Item key={ADMIN_CASH_GAME_RUNNING_GAMES}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_CASH_GAME_RUNNING_GAMES}>
                  <span>Running Games</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_CASH_GAME_INTEREST_LIST}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_CASH_GAME_INTEREST_LIST}>
                  <span>Interest Lists</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_CASH_TEMPLATES}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_CASH_TEMPLATES}>
                  <span>Templates</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_CASH_GAME_DEALER_DISPLAY}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_CASH_GAME_DEALER_DISPLAY} target={'_blank'}>
                  <span>Dealer</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_CASH_GAME_FULL_DISPLAY}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_CASH_GAME_FULL_DISPLAY} target={'_blank'}>
                  <span>External Display</span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key={'EVENTS'}
              title={
                <span>
                  <i className="icon icon-crm" />
                  <span>Tournaments Management</span>
                </span>
              }
            >
              <Menu.Item key={ADMIN_EVENTS}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_EVENTS} className={'gx-position-relative'}>
                  <span>Events & Tournaments</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_DAILY_TOURNAMENTS}>
                {/*@ts-ignore*/}
                <Link
                  to={ADMIN_DAILY_TOURNAMENTS}
                  className={'gx-position-relative'}
                >
                  <span>Daily Tournaments</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_EVENTS_CLOCK}>
                {/*@ts-ignore*/}
                <Link
                  to={ADMIN_EVENTS_CLOCK}
                  className={'gx-position-relative'}
                >
                  <span
                    style={{
                      color: 'var(--gray)'
                    }}
                  >
                    Clock
                  </span>
                  <ComingSoon />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key={ADMIN_PLAYERS}
              title={
                <span>
                  <i className="icon icon-contacts" />
                  <span>Players Database</span>
                </span>
              }
            >
              <Menu.Item key={ADMIN_SUB_GROUPS}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_SUB_GROUPS}>
                  <span>Sub-groups</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_PLAYERS}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_MY_PLAYERS} className={'gx-position-relative'}>
                  <span
                    style={{
                      color: 'var(--gray)'
                    }}
                  >
                    My Players
                  </span>
                  <ComingSoon />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key={ADMIN_MY_POKER_ROOM}
              title={
                <span>
                  <i className="icon icon-data-display" />
                  <span>My Poker Room</span>
                </span>
              }
            >
              <Menu.Item key={ADMIN_MY_POKER_ROOM_GENERAL}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_MY_POKER_ROOM_GENERAL}>
                  <span>General Information</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_POKER_ROOM_GAMES}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_MY_POKER_ROOM_GAMES}>
                  <span>Poker Games Information</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_POKER_ROOM_SETTINGS}>
                {/*@ts-ignore*/}
                <Link to={ADMIN_MY_POKER_ROOM_SETTINGS}>
                  <span>Settings</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_POKER_ROOM_DASHBOARD}>
                {/*@ts-ignore*/}
                <Link
                  to={ADMIN_MY_POKER_ROOM_DASHBOARD}
                  className={'gx-position-relative'}
                >
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS}>
                {/*@ts-ignore*/}
                <Link
                  to={ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS}
                  className={'gx-position-relative'}
                >
                  <span>Announcements</span>
                </Link>
              </Menu.Item>
              <Menu.Item key={ADMIN_MY_POKER_ROOM_ADS}>
                {/*@ts-ignore*/}
                <Link
                  to={ADMIN_MY_POKER_ROOM_ADS}
                  className={'gx-position-relative'}
                >
                  <span
                    style={{
                      color: 'var(--gray)'
                    }}
                  >
                    Ads Monitoring
                  </span>
                  <ComingSoon />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={ADMIN_CONTACT_US}>
              {/*@ts-ignore*/}
              <Link to={ADMIN_CONTACT_US}>
                <i className="icon icon-compose" />
                <span>{t('CONTACT_US_MENU')}</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <div className={'gx-position-absolute w-100 '} style={{ bottom: 0 }}>
          <Menu
            defaultOpenKeys={openKeys}
            selectedKeys={openKeys}
            theme={'dark'}
            mode="inline"
          >
            <Menu.Item key={ADMIN_TERMS_AND_CONDITIONS}>
              {/*@ts-ignore*/}
              <Link to={ADMIN_TERMS_AND_CONDITIONS}>
                <i className="icon icon-select" />
                <span>Terms & Conditions</span>
              </Link>
            </Menu.Item>
          </Menu>
          <div
            className={
              'gx-text-center gx-align-content-center gx-justify-content-center gx-align-items-center gx-d-flex'
            }
            style={{
              color: 'var(--gray)',
              backgroundColor: '#343d45',
              height: 47
            }}
          >
            V 2.0.0
          </div>
        </div>
      </Layout.Sider>
      <Layout>
        <Layout.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: `${isMobile ? '' : 'center'}`
            }}
          >
            <div />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: `${isMobile ? '' : 'center'}`
              }}
            >
              <CasinoAvatar />
            </div>
            <TableNotification />
          </div>
        </Layout.Header>
        <Layout.Content className={`gx-layout-content`}>
          <div className={'h-100'}>{children}</div>
          <Layout.Footer>
            <div className="gx-layout-footer-content">
              © {new Date().getFullYear()} WeOpt, All Rights Reserved
            </div>
          </Layout.Footer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(DefaultLayout);
