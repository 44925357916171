const AnnouncementPublishColumn = ({ publish }: { publish: boolean }) => {
  return (
    <div
      style={{
        writingMode: 'vertical-lr',
        fontSize: 11,
        height: '100%',
        fontWeight: 'bold',
        backgroundColor: publish ? '#13C2C2' : '#8A9BA8',
        letterSpacing: 2,
        textAlign: 'center',
        paddingRight: 2
      }}
    >
      {publish ? 'PUBLISHED' : 'UNPUBLISHED'}
    </div>
  );
};

export default AnnouncementPublishColumn;
