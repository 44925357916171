import React from 'react';

const GameVariantColumn = ({
  interestList
}: {
  interestList: { gameVariant: string };
}) => {
  return interestList ? (
    <span className={'ellipsis'}>{interestList.gameVariant}</span>
  ) : (
    <span>NO DATA</span>
  );
};

export default GameVariantColumn;
