import { api } from '@api/api';
import { ListResponse } from '@api/types';

export type Template = {
  ante?: number;
  casinoId: string;
  gameVariant: string;
  id: number;
  maxBuyIn: number;
  minBuyIn: number;
  bigBlind: number;
  smallBlind: number;
  maxPlayers?: number;
  tableId?: string;
  players?: number[];
  subGroupId: number;
};

const templateApi = api
  .enhanceEndpoints({ addTagTypes: ['Template', 'SubGroup', 'SubGroups'] })
  .injectEndpoints({
    endpoints: builder => ({
      deleteTemplate: builder.mutation<void, { id: number }>({
        invalidatesTags: ['Template', 'SubGroup', 'SubGroups'],
        query: ({ id }) => ({
          method: 'DELETE',
          url: `/games/api/regular/template/${id}`
        })
      }),

      updateTemplate: builder.mutation<void, { body: Template }>({
        invalidatesTags: ['Template', 'SubGroup', 'SubGroups'],
        query: ({ body }) => ({
          method: 'PUT',
          url: `/games/api/regular/template/${body.id}`,
          body: body
        })
      }),
      createTemplate: builder.mutation<void, { body: Omit<Template, 'id'> }>({
        invalidatesTags: ['Template', 'SubGroup', 'SubGroups'],
        query: params => ({
          method: 'POST',
          url: `/games/api/regular/template/`,
          body: params.body
        })
      }),
      getTemplate: builder.query<
        Template,
        {
          id: number;
        }
      >({
        providesTags: ['Template'],
        extraOptions: { maxRetries: 1 },
        query: ({ id }) => `/games/api/regular/template/${id}`
      }),
      getTemplates: builder.query<
        ListResponse<Template>,
        {
          casinoId: string;
        }
      >({
        providesTags: ['Template'],
        extraOptions: { maxRetries: 1 },
        query: ({ casinoId }) =>
          `/games/api/regular/template/casino/${casinoId}`
      })
    })
  });

export const {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useUpdateTemplateMutation
} = templateApi;
