export enum TOURNAMENT_LIST_COLUMN {
  DATE = 'date',
  EVENT_NUMBER = 'eventNumber',
  BUY_IN = 'buyIn',
  GAME_VARIANT = 'gameVariant',
  NAME = 'name',
  STARTING_STACK = 'startingStack',
  ENTRIES = 'entries',
  LEVEL_DURATION = 'levelDuration',
  LATE_REGISTRATION_LEVEL = 'lateRegistrationLevel',
  ACTIONS = 'actions'
}
