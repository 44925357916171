import PlayerInfoWithAction from '@components-3/PlayerInfoWithAction';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Identifier, XYCoord } from 'dnd-core';
import React, { CSSProperties, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const ITEM_TYPE = 'player';

interface DragItem {
  index: number;
  id: string;
  type: string;
}
const DraggablePlayer = ({
  index,
  id,
  player,
  movePlayer,
  gameId,
  tableId,
  hasFreeSeats,
  isReordering
}: {
  index: number;
  id: string;
  player: WaitingPlayer;
  movePlayer: (dragIndex: any, hoverIndex: number, isDropEnd?: boolean) => void;
  gameId: string;
  tableId?: string;
  hasFreeSeats?: boolean;
  isReordering: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(player: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = player.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      movePlayer(dragIndex, hoverIndex);
      player.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_TYPE,
    item: () => {
      return { id, index };
    },
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        movePlayer(index, index, true);
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: isReordering
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0 : 1,
    cursor: isReordering ? 'move' : 'default',
    borderBottomStyle: 'solid',
    borderBottomColor: '#495762',
    padding: '12px 0'
  };

  drop(drag(ref));

  return (
    <div ref={ref} style={style} data-handler-id={handlerId}>
      <div
        ref={preview}
        style={{
          borderBottomWidth: 0,
          padding: 0
        }}
      >
        <PlayerInfoWithAction
          playerPosition={index}
          player={player}
          gameId={gameId}
          showCallPlayer={true}
          showRemovePlayer={true}
          tableId={tableId}
          hasFreeSeats={hasFreeSeats}
          isReordering={isReordering}
        />
      </div>
    </div>
  );
};

export default DraggablePlayer;
