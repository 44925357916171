import './RunningGameTableCard.scss';

import trash from '@assets-3/icons/trash.svg';
import userSettings from '@assets-3/icons/user_settings.svg';
import { Card } from 'antd';
import React from 'react';
import ContentLoader from 'react-content-loader';

const RunningGameTableCardLoader = () => {
  const loaderProps = {
    backgroundColor: '#ffffff',
    foregroundColor: '#b8b8b8',
    speed: 1
  };
  return (
    <Card
      className={`gx-card-widget gx-card-full gx-py-4 gx-px-2 w-100 table-card`}
    >
      <span className="gx-widget-badge fontWeight600">
        <ContentLoader width={15} height={15} {...loaderProps}>
          <rect x="0" y="0" rx="3" ry="3" width="15" height="15" />
        </ContentLoader>
      </span>
      <div className={'action-container ml-auto d-flex flex-row pt-2'}>
        <img
          src={userSettings}
          alt={'userSettings'}
          className={'mr-2'}
          height={20}
        />
        <div className={'gx-pointer mr-2'}>
          <img src={trash} alt={'delete'} className={'mr-2'} height={20} />
        </div>
      </div>
      <div className="gx-justify-content-center gx-text-center gx-mb-3 gx-mb-sm-4 gx-mt-2">
        <div>
          <ContentLoader
            width={120}
            height={15}
            viewBox="0 0 120 15"
            {...loaderProps}
          >
            <rect x="0" y="0" rx="3" ry="3" width="120" height="15" />
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            width={90}
            height={10}
            viewBox="0 0 90 10"
            {...loaderProps}
          >
            <rect x="0" y="0" rx="3" ry="3" width="90" height="10" />
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            width={110}
            height={5}
            viewBox="0 0 110 5"
            {...loaderProps}
          >
            <rect x="0" y="0" rx="3" ry="3" width="110" height="5" />
          </ContentLoader>
        </div>
      </div>
      <div className={'max-players-status w-100'}>
        <div className="ant-row-flex">
          <ContentLoader
            width={400}
            height={15}
            viewBox="0 0 400 15"
            {...loaderProps}
          >
            <rect x="0" y="0" rx="3" ry="3" width="400" height="15" />
          </ContentLoader>
        </div>
        <div className="gx-line-indi-info">
          <ContentLoader
            width={400}
            height={5}
            viewBox="0 0 400 5"
            {...loaderProps}
          >
            <rect x="0" y="0" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    </Card>
  );
};

export default RunningGameTableCardLoader;
