import LocationSearchInput from '@routes-3/main/myPokerRoom/generalInformation/components/locationSearch';
import { Form } from 'antd';
import modal from 'antd/lib/modal';
import * as React from 'react';
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const FormItem = Form.Item;
const AddressSection = ({ disabled }: { disabled?: boolean }) => {
  const [showLatLongNotDefinedModal, setShowLatLongNotDefinedModal] = useState(
    false
  );
  const streetAddressController = useController({
    name: 'address.streetAddress'
  });
  const addressLabelController = useController({
    name: 'address.addressLabel'
  });
  const streetNumberController = useController({
    name: 'address.streetNumber'
  });
  const cityController = useController({
    name: 'address.city'
  });

  const postalCodeController = useController({
    name: 'address.postalCode'
  });
  const stateController = useController({
    name: 'address.state'
  });
  const countryController = useController({
    name: 'address.country'
  });
  const latController = useController({
    name: 'lat',
    rules: { required: true }
  });
  const lonController = useController({
    name: 'lon',
    rules: { required: true }
  });
  const [location, setLocation] = useState<string>(
    addressLabelController.field.value
  );
  const countryCode = useWatch({ name: 'address.countryCode' });
  const { setValue } = useFormContext();
  const resetAddressForm = () => {
    setValue('address.addressLabel', '');
    setValue('address.streetNumber', '');
    setValue('address.streetAddress', '');
    setValue('address.postalCode', '');
    setValue('address.countryCode', '');
    setValue('address.country', '');
    setValue('address.city', '');
    setValue('address.state', '');
    setValue('address.lat', 0);
    setValue('address.lon', 0);
  };
  const handleAddressSelect = (address: string, placeID: string): void => {
    geocodeByAddress(address)
      .then(async (results: google.maps.GeocoderResult[]) => {
        setLocation(results[0].formatted_address);
        resetAddressForm();
        results[0].address_components.map(item => {
          switch (item.types[0]) {
            case 'street_number':
              setValue('address.streetNumber', item.long_name, {
                shouldDirty: true
              });
              break;
            case 'route':
              setValue('address.streetAddress', item.long_name, {
                shouldDirty: true
              });
              break;
            case 'postal_code':
              setValue('address.postalCode', item.long_name, {
                shouldDirty: true
              });
              break;
            case 'country':
              setValue('address.countryCode', item.short_name, {
                shouldDirty: true
              });
              setValue('address.country', item.long_name, {
                shouldDirty: true
              });
              break;
            case 'administrative_area_level_1':
              setValue('address.state', item.long_name, {
                shouldDirty: true
              });
              break;
            case 'postal_town':
            case 'locality':
              setValue('address.city', item.long_name, {
                shouldDirty: true
              });
              break;
            default:
              return null;
          }
        });
        setValue('address.addressLabel', results[0].formatted_address);
        return getLatLng(results[0]);
      })
      .then((latLng: google.maps.LatLngLiteral) => {
        setShowLatLongNotDefinedModal(!latLng.lat && !latLng.lng);
        setValue('address.lat', latLng.lat);
        setValue('address.lon', latLng.lng);
      })
      .catch((error: any) => {
        console.error('Error', error);
      });
  };

  if (showLatLongNotDefinedModal) {
    modal.warn({
      okText: 'Close',
      onOk: () => setShowLatLongNotDefinedModal(false),
      title:
        'We apologize for the inconvenience, but we are unable to define the geolocation of the casino using this address. Our system may be experiencing technical difficulties or the casino location may not be available in our database. Please try again with different address format or select from the suggestions in the location field.'
    });
  }

  return (
    <>
      <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
        Venue Address
      </p>
      <small className={'mb-3'}>
        To help the players to find you, and get geo-located around your venue,
        please fill-in the exact address of your Poker Room
      </small>
      <div className="row">
        <div className="col-12">
          <FormItem>
            <span className={'ant-form-item-label mr-2'}>Location</span>
            <LocationSearchInput
              address={location}
              onAddressSelect={handleAddressSelect}
              disabled={disabled}
              placeholder={'Type casino name or address'}
              setAddress={setLocation}
              error={addressLabelController.formState.errors}
            />
          </FormItem>
        </div>
      </div>
      <div className="row mb-2">
        {streetNumberController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>Street Number:</small>
            <span className={'gx-text-white fontWeightBold'}>
              {streetNumberController.field.value}
            </span>
          </div>
        )}
        {streetAddressController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>Street Name:</small>
            <span className={'gx-text-white fontWeightBold'}>
              {streetAddressController.field.value}
            </span>
          </div>
        )}
        {cityController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>City:</small>
            <span className={'gx-text-white fontWeightBold'}>
              {cityController.field.value}
            </span>
          </div>
        )}
        {postalCodeController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>Zip / Postal Code:</small>
            <span className={'gx-text-white fontWeightBold'}>
              {postalCodeController.field.value}
            </span>
          </div>
        )}
        {stateController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>
              State / Province / Region:
            </small>
            <span className={'gx-text-white fontWeightBold'}>
              {stateController.field.value}
            </span>
          </div>
        )}
        {countryController.field.value && (
          <div className="col-6 mb-2">
            <small className={'gx-text-muted mr-2'}>Country:</small>
            <ReactCountryFlag
              styleProps={{
                width: '20px',
                height: '20px',
                marginRight: '10px',
                marginLeft: '3px'
              }}
              code={countryCode}
              svg
            />
            <span className={'gx-text-white fontWeightBold'}>
              {countryController.field.value}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddressSection;
