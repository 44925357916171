import flopLogo from '@assets-3/images/flop-pm-logo.png';
import SignInForm from '@routes-3/userAuth/signIn/SignInForm';
import React from 'react';
import { Link } from 'react-router-dom';

const SignInPage = () => {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div>Welcome on Flop PM</div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">Login</h1>
              <h6>Sign in to your account.</h6>
            </div>
            <SignInForm />
            <div className={'gx-text-center gx-mb-2'}>
              Don't have an account yet?
            </div>
            <h4 className={'gx-text-center'}>
              {/*@ts-ignore*/}
              <Link
                className="gx-text-underline"
                style={{ color: '#4F8DCA' }}
                to="/user-auth/sign-up"
              >
                Create one
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
