import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import DailyTournamentsContent from '@routes-3/main/tournamentsManagement/dailyTournaments/DailyTournamentsContent';
import React from 'react';

const DailyTournamentsPage = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Daily Tournaments'}
        pageIcon={
          <i
            className="icon icon-crm"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Daily Tournaments'}
      />
      <DailyTournamentsContent />
    </LayoutContentContainer>
  );
};

export default DailyTournamentsPage;
