import './index.css';
import './App.scss';
import 'antd/es/style/themes/default.less';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Validators from 'redux-form-validators';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import App from './App';
import CacheBuster from './cache/CacheBuster';
import { persistConfig } from './config/ReduxPersistConfig';
import AuthenticationActions from './features/authentication/redux/AuthenticationRedux';
import i18n from './i18n';
import createStore from './redux';
import * as serviceWorker from './serviceWorker';
const { store, persistor, history } = createStore();

// #26 : i18n in Validation Messages
Validators.formatMessage = (msg: any) => i18n.t(msg.defaultMessage);

// try to i18n Error Message
Object.assign(Validators.messages, {
  email: 'VALIDATOR_EMAIL',
  presence: 'VALIDATOR_REQUIRED',
  url: 'VALIDATOR_URL'
});

/**
 * Before Run the App We Check The Reducers Version
 */
const onBeforeLift = async () => {
  const reducerVersion = persistConfig.reducerVersion;

  // Check to ensure latest reducer version
  await storage.getItem('reducerVersion').then(async localVersion => {
    if (localVersion !== reducerVersion) {
      // Force To Logout
      store.dispatch(AuthenticationActions.logoutRequest());
      await storage.setItem('reducerVersion', reducerVersion);
    }
  });
};

ReactDOM.render(
  <CacheBuster>
    {({
      loading,
      isLatestVersion,
      refreshCacheAndReload
    }: {
      loading: boolean;
      isLatestVersion: boolean;
      refreshCacheAndReload: () => void;
    }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }
      return (
        // @ts-ignore
        <Provider store={store}>
          {/*@ts-ignore*/}
          <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={onBeforeLift}
          >
            {/*@ts-ignore*/}
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      );
    }}
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

// welldone
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

export default store;
