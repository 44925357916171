import { useCasinoQuery } from '@api-3/endpoints/casino';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import Spinner from '@routes-3/main/myPokerRoom/componenets/Spinner';
import AboutUs from '@routes-3/main/myPokerRoom/generalInformation/components/AboutUs';
import RulesAndContactInformation from '@routes-3/main/myPokerRoom/generalInformation/components/RulesAndContactInformation';
import StandardInformation from '@routes-3/main/myPokerRoom/generalInformation/components/StandardInformation';
import useSettingsForm from '@routes-3/main/myPokerRoom/hooks/useSettingsForm';
import { Button, Card, Tabs } from 'antd';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineSave } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

export const Section = ({
  children
}: {
  children: (formMethods: any, disabled: boolean) => any;
}) => {
  const { t } = useTranslation();
  const { formMethods, onSubmitForm, defaultValues } = useSettingsForm();
  const [isEditing, setIsEditing] = useState(false);
  // @ts-ignore
  const onSave = formMethods.handleSubmit(onSubmitForm);
  return (
    <div className="pt-4">
      {children(formMethods, !isEditing)}
      <div className="d-flex w-100">
        <Fragment>
          {!isEditing ? (
            <Button
              type="primary"
              className={'w-100'}
              onClick={() => setIsEditing(true)}
            >
              {t('EDIT_BUTTON')}
            </Button>
          ) : (
            <div className="d-flex w-100">
              <Button
                className={'w-50'}
                type="default"
                onClick={() => {
                  formMethods.reset(defaultValues);
                  setIsEditing(false);
                }}
              >
                {t('CANCEL_BUTTON')}
              </Button>
              <Button
                className={'w-50'}
                key="3"
                type="primary"
                disabled={!formMethods.formState.isDirty}
                icon={<AiOutlineSave />}
                onClick={() => {
                  onSave().then(() => {
                    if (_.isEmpty(formMethods.formState.errors)) {
                      setIsEditing(false);
                    }
                  });
                }}
              >
                {t('SAVE_BUTTON')}
              </Button>
            </div>
          )}
        </Fragment>
      </div>
    </div>
  );
};

export default function GeneralInformationPage() {
  const owner = useSelector(ownerSelector);
  const { isLoading } = useCasinoQuery({ id: owner.managedCasinoId });

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'My Poker Room'}
        pageIcon={
          <i
            className="icon icon-data-display"
            style={{ fontSize: 14, position: 'absolute', top: 0 }}
          />
        }
        subPage={'General Information'}
      />
      <Card className="gx-card">
        <Tabs defaultActiveKey="1" size={'large'}>
          <TabPane tab="Standard Information" key="1">
            {isLoading ? (
              <Spinner />
            ) : (
              <Section>
                {(formMethods, disabled) => (
                  <FormProvider {...formMethods}>
                    <StandardInformation disabled={disabled} />
                  </FormProvider>
                )}
              </Section>
            )}
          </TabPane>
          <TabPane tab="Rules & Contact Information" key="2">
            {isLoading ? (
              <Spinner />
            ) : (
              <Section>
                {(formMethods, disabled) => (
                  <FormProvider {...formMethods}>
                    <RulesAndContactInformation disabled={disabled} />
                  </FormProvider>
                )}
              </Section>
            )}
          </TabPane>
          <TabPane tab="About the Venue" key="3">
            {isLoading ? (
              <Spinner />
            ) : (
              <Section>
                {(formMethods, disabled) => (
                  <FormProvider {...formMethods}>
                    <AboutUs disabled={disabled} />
                  </FormProvider>
                )}
              </Section>
            )}
          </TabPane>
        </Tabs>
      </Card>
    </LayoutContentContainer>
  );
}
