import { InterestList } from '@api-3/endpoints/interestList';
import PlayerName from '@components-3/PlayerName';
import usePlayerProfile from '@features/players/usePlayerProfile';
import {
  faCheck,
  faHourglassHalf,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CasinoAvatar from '@old-world/common/layouts/DefaultLayout/components/CasinoAvatar';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import React from 'react';

const ChatCommunicationHeader = ({
  interestList,
  withoutBorder = false
}: {
  interestList: InterestList;
  withoutBorder?: boolean;
}) => {
  const { data: profile } = usePlayerProfile(
    (interestList.ownerId as unknown) as string
  );
  return (
    <div
      className={`gx-chat-main-header ${withoutBorder ? 'without-border' : ''}`}
    >
      <div className="gx-chat-main-header-info">
        <div className="gx-chat-user-row">
          <div className="gx-chat-avatar">
            {interestList.gameOrigin === 'FLOP_USER' && (
              <div
                className="gx-status-pos"
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: 30,
                  fontSize: 12,
                  fontWeight: 'bold',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  backgroundColor:
                    interestList.state && interestList.state === 'ACCEPTED'
                      ? '#7CC194'
                      : interestList.state === 'DECLINED'
                      ? '#8A9BA8'
                      : '#F18B2D'
                }}
              >
                {interestList.state === 'ACCEPTED' && (
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCheck} />
                )}
                {interestList.state === 'PENDING' && (
                  <FontAwesomeIcon
                    style={{ fontSize: 20 }}
                    icon={faHourglassHalf}
                  />
                )}
                {interestList.state === 'DECLINED' && (
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faXmark} />
                )}
              </div>
            )}
            {interestList.gameOrigin === 'CASINO' && (
              <CasinoAvatar height={60} showText={false} borderRadius={30} />
            )}
          </div>
          <div className="gx-chat-info" style={{ width: 500 }}>
            <div className="gx-chat-contact-name">
              <span className="gx-name h4 gx-font-weight-heavy gx-text-white">
                {interestList.gameVariant}
              </span>
            </div>
            <div className="gx-chat-info-des gx-text-truncate">
              {interestList.gameSize}
              {interestList.ante ? '/' + interestList.ante : ''}
              {' -  '}
              {interestList.buyInMin
                ? formatBuyIn(interestList.buyInMin)
                : 'NO'}{' '}
              -{' '}
              {interestList.buyInMax
                ? formatBuyIn(interestList.buyInMax)
                : 'NO'}
            </div>
            <div className="gx-last-message-time">
              Created by:{' '}
              <strong className={'gx-text-white'}>
                {interestList.gameOrigin === 'CASINO' && interestList.casino}
                {interestList.gameOrigin === 'FLOP_USER' && (
                  <>
                    <PlayerName
                      player={{
                        firstName: profile.data.firstName!,
                        lastName: profile.data.lastName!
                      }}
                    />
                  </>
                )}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatCommunicationHeader;
