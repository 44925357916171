import '@components-3/notifications/NotificationsList.scss';

import {
  NOTIFICATION_TYPE,
  useDismissAllNotificationsMutation,
  useGetNotificationsQuery
} from '@api-3/endpoints/notifications';
import NotificationConstants from '@components-3/notifications/NotificationConstants';
import NotificationItem from '@components-3/notifications/NotificationItem';
import { faCrown, faEnvelope, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { List, Row, Spin } from 'antd';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';

export const NotificationMetData = [
  {
    type: NotificationConstants.NEW_GAME_REQUEST,
    title: 'A new game has been submitted',
    image: (
      <FontAwesomeIcon
        icon={faCrown}
        className={'gx-text-success'}
        style={{ fontSize: 22 }}
      />
    )
  },
  {
    type: NotificationConstants.GAME_NEW_MESSAGE_FROM_PLAYER,
    title: 'You have a new player’s message',
    image: (
      <FontAwesomeIcon
        icon={faEnvelope}
        className={'gx-text-primary'}
        style={{ fontSize: 22 }}
      />
    )
  },
  {
    type: NotificationConstants.GAME_SEAT_REQUEST_SENT,
    title: 'Seat Request',
    image: (
      <FontAwesomeIcon
        icon={faInfo}
        className={'gx-text-warning'}
        style={{ fontSize: 22 }}
      />
    )
  }
];
const NotificationsList = ({ onHide }: { onHide: () => void }) => {
  const [page, setPage] = useState<number>(1);
  const owner = useSelector(ownerSelector);
  const [dismissAllNotifications] = useDismissAllNotificationsMutation();

  const { data, isLoading } = useGetNotificationsQuery({
    userId: owner.id,
    notificationType: NOTIFICATION_TYPE.GAME,
    page: page,
    size: 200
  });

  const notifications = data?.content || [];

  const isFetchingNotifications = isLoading;

  const handleInfiniteOnLoad = () => {
    setPage(page + 1);
  };

  const onDismissAllNotification = () => dismissAllNotifications();

  const hasNotifications = Boolean(notifications.length && !isLoading);

  return (
    <div className="notifications-list-container d-flex flex-column justify-content-start align-items-center w-100">
      <Row justify="space-between" className="w-100 p-2 notification-header">
        <span className="notification-header-text font-weight-bold">
          NOTIFICATIONS
        </span>
        {hasNotifications && (
          <span
            onClick={onDismissAllNotification}
            className="text-decoration-underline cursor-pointer gx-text-primary"
          >
            Clear all
          </span>
        )}
      </Row>
      <Row className="notification-list w-100">
        {/*@ts-ignore*/}
        <InfiniteScroll
          className="w-100"
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!isFetchingNotifications && !data?.last}
          useWindow={false}
          threshold={250}
        >
          <List
            className="w-100"
            dataSource={notifications || []}
            renderItem={(item, index) => (
              <NotificationItem item={item} index={index} onHide={onHide} />
            )}
          >
            {isFetchingNotifications && !data?.last && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </Row>
    </div>
  );
};

export default NotificationsList;
