import './RunningGamesPage.scss';

import { CaretLeftOutlined } from '@ant-design/icons';
import { useGetRunningGamesQuery } from '@api-3/endpoints/games';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import TablesMap from '@routes-3/main/cashGameManagement/runningGamesMap/components/TablesMap';
import MobileHeader from '@routes-3/main/mobileView/components/MobileHeader';
import { ADMIN_MOBILE } from '@routes-3/routes';
import { Card, Layout, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const RunningGamesMobilePage = () => {
  const casinoId = useSelector(currentCasinoSelector);
  const history = useHistory();
  const { data, isLoading } = useGetRunningGamesQuery(
    {
      queryParams: {
        page: 1,
        size: 40,
        casinoId
      }
    },
    { pollingInterval: 5000 }
  );
  return (
    <Layout className="table-page-wrapper">
      <Layout.Header className="table-page-header" style={{ height: 50 }}>
        <MobileHeader />
      </Layout.Header>
      <Layout.Content className="table-page-body">
        <div
          style={{ position: 'absolute', top: 60, left: 20 }}
          className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
          onClick={() => history.push(ADMIN_MOBILE)}
        >
          <CaretLeftOutlined className={'mr-1'} />
          <b style={{ position: 'relative', top: 2 }}>back</b>
        </div>
        {data && data.content.length > 0 ? (
          <TablesMap games={data.content} />
        ) : (
          <Card
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              display: 'flex'
            }}
          >
            {isLoading ? (
              <Spin tip="Loading" size="large" />
            ) : (
              <>
                <h2>No Poker Cash Games Running Yet!</h2>
                <p>Here's your stage to create some action!</p>
                <ol
                  style={{
                    textAlign: 'left',
                    marginBottom: '20px'
                  }}
                >
                  <li>
                    <b>Set Up Tables</b>: Open your table and decide on the
                    number of players.
                  </li>
                  <li>
                    <b>Choose Your Games</b>: Whether it's Texas Hold'em, Omaha,
                    or any other variation, set the rules and let the games
                    begin.
                  </li>
                </ol>
                <div>
                  <i
                    className="icon icon-sweet-alert text-warning"
                    style={{ position: 'relative', top: 3 }}
                  />{' '}
                  <strong>Every Table needs to have a Unique ID.</strong>
                </div>
              </>
            )}
          </Card>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default RunningGamesMobilePage;
