import { api } from '@api/api';
import { ListResponse } from '@api/types';

export enum NOTIFICATION_TYPE {
  POST = 'POST',
  FRIEND_REQUEST = 'FRIEND_REQUEST',
  TABLE = 'TABLE',
  GAME = 'GAME',
  GAME_MESSAGE = 'GAME_MESSAGE',
  ACCOUNT = 'ACCOUNT',
  NEWS = 'NEWS',
  SESSIONS = 'SESSIONS',
  AD = 'AD',
  CONVERSATION_MESSAGE = 'CONVERSATION_MESSAGE'
}

export type CustomNotification = {
  casinoId: string;
  content: string;
  id: number;
  type: string;
};

export type Notification = {
  creationDate: string;
  entityId: string;
  entityType: string;
  id: string;
  message: string;
  notificationType: string;
  read: boolean;
  sourceId: number[];
  sourceRepresentativeId: null | string;
  targetId: number;
};

export type CasinoNotification = {
  casinoId: string;
  id: number;
  notificationDate: string;
  read: boolean;
  recipientId: string;
  tableId: string;
};

export type CasinoNotificationPayload = {
  tableId: string;
  recipientId: string;
};
export const notificationApi = api
  .enhanceEndpoints({
    addTagTypes: [
      'NotificationCount',
      'Notifications',
      'CustomNotification',
      'CasinoNotification'
    ]
  })
  .injectEndpoints({
    endpoints: builder => ({
      dismissAllNotifications: builder.mutation<void, void>({
        invalidatesTags: ['Notifications', 'NotificationCount'],
        query: () => ({
          method: 'PUT',
          url: `notifications/api/dismiss/all`
        })
      }),
      readNotification: builder.mutation<void, string>({
        invalidatesTags: ['Notifications', 'NotificationCount'],
        query: notificationId => ({
          method: 'PUT',
          url: `notifications/api/${notificationId}/read`
        })
      }),
      dismissNotification: builder.mutation<void, string>({
        invalidatesTags: ['Notifications', 'NotificationCount'],
        query: notificationId => ({
          method: 'PUT',
          url: `notifications/api/${notificationId}/dismiss`
        })
      }),
      getNotifications: builder.query<
        ListResponse<Notification>,
        {
          userId: string;
          notificationType: NOTIFICATION_TYPE;
          page: number;
          size: number;
        }
      >({
        providesTags: ['Notifications'],
        query: ({ userId, notificationType, page, size }) =>
          `notifications/api/${notificationType}/${userId}?page=${page}&size=${size}`
      }),
      getNotificationCount: builder.query<
        { counter: number },
        { userId: string; notificationType: NOTIFICATION_TYPE }
      >({
        providesTags: ['NotificationCount'],
        query: ({ userId, notificationType }) =>
          `notifications/api/${notificationType}/${userId}/count`
      }),
      getCustomNotifications: builder.query<CustomNotification[], void>({
        providesTags: ['CustomNotification'],
        query: () => 'notifications/api/custom'
      }),
      customNotification: builder.mutation<void, CustomNotification>({
        invalidatesTags: ['CustomNotification'],
        query: notification => ({
          method: 'PUT',
          url: `notifications/api/custom/${notification.id}`,
          body: notification
        })
      }),
      getCasinoNotifications: builder.query<CasinoNotification[], void | null>({
        providesTags: ['CasinoNotification'],
        query: () => 'notifications/api/casino'
      }),
      casinoNotification: builder.mutation<
        void,
        { body: CasinoNotificationPayload }
      >({
        invalidatesTags: ['CasinoNotification'],
        query: params => ({
          method: 'POST',
          url: '/notifications/api/casino',
          body: params.body
        })
      }),
      readCasinoNotification: builder.mutation<
        void,
        { notificationId: number }
      >({
        invalidatesTags: ['CasinoNotification'],
        query: ({ notificationId }) => ({
          method: 'PUT',
          url: `/notifications/api/casino/${notificationId}/isRead`
        })
      })
    })
  });

export const {
  useDismissAllNotificationsMutation,
  useReadNotificationMutation,
  useDismissNotificationMutation,
  useGetNotificationsQuery,
  useGetNotificationCountQuery,
  useGetCustomNotificationsQuery,
  useCustomNotificationMutation,
  useGetCasinoNotificationsQuery,
  useCasinoNotificationMutation,
  useReadCasinoNotificationMutation
} = notificationApi;
