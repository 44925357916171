import {
  Template,
  useCreateTemplateMutation,
  useUpdateTemplateMutation
} from '@api-3/endpoints/templates';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import TemplateForm from '@routes-3/main/cashGameManagement/templates/forms/TemplateForm';
import { Button, Form, Modal, notification } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  template?: Template;
};

const CreateUpdateTemplateModal = ({ onClose, template }: Props) => {
  const { t } = useTranslation();
  const formMethods = useForm<Template>({
    defaultValues: {
      ...template
    }
  });
  const { handleSubmit } = formMethods;
  const [
    createTemplate,
    { isLoading: createTemplateIsLoading }
  ] = useCreateTemplateMutation();
  const [
    updateTemplate,
    { isLoading: updateTemplateIsLoading }
  ] = useUpdateTemplateMutation();

  const casinoId = useSelector(currentCasinoSelector);
  const onSubmit = async (data: Template) => {
    try {
      if (template) {
        await updateTemplate({
          body: { ...data, casinoId }
        }).unwrap();
      } else {
        await createTemplate({
          body: { ...data, casinoId }
        }).unwrap();
      }

      onClose();
    } catch (e) {
      console.error('An error has occurred: ', e);
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to ${
          template ? 'update' : 'create'
        } the template. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={template ? 'EDIT TEMPLATE' : 'NEW TEMPLATE'}
      onCancel={onClose}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      closable={false}
      footer={[
        <Button onClick={onClose} className="">
          {t('CANCEL_BUTTON')}
        </Button>,
        <Button
          onClick={handleSubmit(onSubmit)}
          className="gx-btn"
          loading={updateTemplateIsLoading || createTemplateIsLoading}
        >
          {template ? 'Update' : t('ADD_BUTTON')}
        </Button>
      ]}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <TemplateForm template={template} />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default CreateUpdateTemplateModal;
