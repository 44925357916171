import SlimModal from '@components/SlimModal';
import { Button, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddGameSizeItem from './AddGameSizeItem';

type GameSizeType = {
  smallBlind: number;
  bigBlind: number;
  active?: boolean;
};
type PickGameSizesModalProps = {
  visible: boolean;
  gameSize: GameSizeType;
  initialGameSizes: GameSizeType[];
  setVisible: (visible: boolean) => void;
  onAdd: (gameSizes: GameSizeType[]) => void;
};

const compareGameSize = (sizeA: GameSizeType, sizeB: GameSizeType) => {
  return sizeA.smallBlind - sizeB.smallBlind;
};

const PickGameSizesModal = ({
  initialGameSizes,
  gameSize,
  onAdd,
  visible,
  setVisible
}: PickGameSizesModalProps) => {
  const { t } = useTranslation();
  const [addedSizes, setAddedSizes] = useState<GameSizeType[]>([]);
  const [visibleOtherSizes, setVisibleOtherSizes] = useState(false);

  useEffect(() => {
    if (gameSize) {
      const defaultSizes: any[] = [];
      initialGameSizes.forEach((sizeItem: GameSizeType) => {
        if (
          sizeItem.smallBlind != gameSize.smallBlind ||
          sizeItem.bigBlind != gameSize.bigBlind
        )
          defaultSizes.push(sizeItem);
      });
      setAddedSizes(defaultSizes);
    }
  }, [initialGameSizes, gameSize]);

  const onSave = () => {
    onAdd(addedSizes);
    setVisible(false);
  };

  const handleAddSize = (smallBlind: number, bigBlind: number) => {
    addedSizes.push({ smallBlind, bigBlind, active: true });
    setAddedSizes([...addedSizes]);
  };
  const toggleBtn = useCallback(
    (index: number) => {
      addedSizes[index]['active'] = !addedSizes[index]['active'];
      setAddedSizes([...addedSizes]);
    },
    [addedSizes]
  );
  return (
    <SlimModal
      open={visible}
      centered
      title={t('PICK_ADDITIONAL_GAME_SIZES').toLocaleUpperCase()}
      cancelText={t('CANCEL_BUTTON')}
      okText={t('ADD_BUTTON')}
      onCancel={() => setVisible(false)}
      okButtonProps={{
        className: 'gx-btn',
        type: 'default'
      }}
      onOk={onSave}
      closable={false}
    >
      <Row className="mb-3">
        {gameSize && (
          <Button className="gamesize-btn" style={{ margin: 5 }}>
            {gameSize.smallBlind}/{gameSize.bigBlind}
          </Button>
        )}
        {addedSizes.sort(compareGameSize).map((addedSize, index) => (
          <Button
            key={index}
            className={`selectable-btn ${
              addedSize.active ? 'active gx-btn-secondary' : ''
            }`}
            style={{ margin: 5 }}
            onClick={() => toggleBtn(index)}
          >
            {addedSize.smallBlind}/{addedSize.bigBlind}
          </Button>
        ))}
        <Button
          className={`selectable-btn ${visibleOtherSizes ? 'active' : ''}`}
          style={{ margin: 5 }}
          onClick={() => setVisibleOtherSizes(!visibleOtherSizes)}
        >
          {t('OTHER')}
        </Button>
      </Row>
      {visibleOtherSizes && (
        <AddGameSizeItem
          sizeList={addedSizes.concat({
            smallBlind: gameSize.smallBlind,
            bigBlind: gameSize.bigBlind
          })}
          onAddSize={handleAddSize}
          onHide={() => setVisibleOtherSizes(false)}
        />
      )}
    </SlimModal>
  );
};

export default React.memo(PickGameSizesModal);
