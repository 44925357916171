import { ReactComponent as NotificationIcon } from '@assets/svgs/notification_blue.svg';
import AddButton from '@components/AddButton';
import AdditionButton from '@components/AdditionButton';
import GameSizeDefaults from '@constants/gamesizes';
import Description from '@features/notifyPlayers/components/Description';
import { Button, Popover } from 'antd';
import uniqWith from 'lodash/uniqWith';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PickGameSizesModal from '../PickGameSizeModal/PickGameSizesModal';
import { GameSize } from './NotifyPlayersForm';

const isSameGameSize = (a: GameSize, b: GameSize) =>
  a.bigBlind == b.bigBlind && a.smallBlind == b.smallBlind;

export default function ModifyGameSizes(props: {
  defaults: { gameVariant: string; bigBlind: number; smallBlind: number };
  gameSizes: GameSize[];
  onChange: (gameSizes: GameSize[]) => void;
}) {
  const { t } = useTranslation();
  const [sizePickVisible, setSizePickVisible] = useState(false);

  const availableGameSizesWithActiveStatus = useMemo(
    () =>
      uniqWith([...GameSizeDefaults, ...props.gameSizes], isSameGameSize).map(
        gz => ({
          ...gz,
          active: props.gameSizes.some(checkedGameSize =>
            isSameGameSize(checkedGameSize, gz)
          )
        })
      ),
    [props.gameSizes]
  );

  function AdditionalSizeLabel() {
    return (
      <div className="d-flex flex-row align-items-center justify-content-flex-start mb-3">
        <div>
          <span className="mb-0" style={{ fontSize: 14, fontWeight: 400 }}>
            {t('PICK_ADDITIONAL_GAME_SIZES')}
          </span>
          <Popover
            placement="bottom"
            content={Description(
              'FLOP users with game preferences that match these additional game sizes will also be notified.'
            )}
          >
            <NotificationIcon style={{ marginLeft: 8 }} />
          </Popover>
        </div>
        <AddButton
          value={t('ADD_GAME_SIZES')}
          onClick={() => setSizePickVisible(true)}
        />
      </div>
    );
  }

  return (
    <>
      <AdditionalSizeLabel />
      <div className="d-flex flex-wrap align-items-center justify-content-flex-start">
        <Button className="gamesize-btn" style={{ margin: 5 }}>
          {props.defaults.smallBlind}/{props.defaults.bigBlind}
        </Button>
        {props.gameSizes.map(gameSize => (
          <AdditionButton
            key={`${gameSize.smallBlind}/${gameSize.bigBlind}`}
            text={`${gameSize.smallBlind}/${gameSize.bigBlind}`}
            onClickCross={() =>
              props.onChange(
                props.gameSizes.filter(gz => !isSameGameSize(gz, gameSize))
              )
            }
          />
        ))}
        <PickGameSizesModal
          gameSize={{
            smallBlind: props.defaults.smallBlind,
            bigBlind: props.defaults.bigBlind
          }}
          initialGameSizes={availableGameSizesWithActiveStatus}
          onAdd={gameSizes => {
            props.onChange(gameSizes.filter(gz => gz.active));
          }}
          visible={sizePickVisible}
          setVisible={setSizePickVisible}
        />
      </div>
    </>
  );
}
