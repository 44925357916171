import { RightOutlined } from '@ant-design/icons';
import {
  useAddNewPlayerToTableMutation,
  useCreateRunningGameMutation
} from '@api-3/endpoints/games';
import TableFieldArray, {
  TableFormShape
} from '@components/forms/TableFieldArray';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import ExistingGamesList from '@routes-3/main/cashGameManagement/runningGamesMap/forms/ExistingGamesList';
import TableGameCharacteristics, {
  TableGameCharacteristicsFieldFormShape
} from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TableGameCharacteristics';
import TemplateGamesForm from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TemplateGamesForm';
import { CreateRunningGamePayload } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Form, Modal, notification, Tabs } from 'antd';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type GameCreateFormShape = {
  selectedExistingGame?: string;
  selectedTemplateGame?: string;
  waitingListPlayers: number[];
  sitPlayers: number[];
  subgroupId?: number;
  tables: TableFormShape[];
} & TableGameCharacteristicsFieldFormShape;

type Props = {
  onClose: () => void;
  templateId?: number;
  onSuccess?: () => void;
  defaults?: Partial<GameCreateFormShape>;
};

export enum GAME_TYPE {
  CUSTOM = 'CUSTOM',
  EXISTING = 'EXISTING',
  TEMPLATE = 'TEMPLATE'
}

const AddGameToMapModal = ({
  onClose,
  templateId,
  defaults = {
    tables: [
      {
        tableId: '',
        maxPlayers: 8
      }
    ]
  },
  onSuccess = () => {}
}: Props) => {
  const { t } = useTranslation();
  const isTemplateCreation = templateId != undefined;
  const tabs = isTemplateCreation
    ? [{ name: 'Template', key: GAME_TYPE.TEMPLATE }]
    : [
        { name: 'Custom', key: GAME_TYPE.CUSTOM },
        { name: 'Existing', key: GAME_TYPE.EXISTING },
        { name: 'Template', key: GAME_TYPE.TEMPLATE }
      ];
  const [gameType, setGameType] = useState<GAME_TYPE>(
    isTemplateCreation ? GAME_TYPE.TEMPLATE : GAME_TYPE.CUSTOM
  );

  const [
    addNewPlayer,
    { isLoading: isLoadingAddNewPlayer }
  ] = useAddNewPlayerToTableMutation();
  const [createRunningGame] = useCreateRunningGameMutation();
  const formMethods = useForm<GameCreateFormShape>({
    defaultValues: defaults
  });
  const { handleSubmit, control, reset } = formMethods;
  const [selectedExistingGameIndex, setSelectedExistingGameIndex] = useState<
    number
  >();

  const onSubmit = async (data: GameCreateFormShape) => {
    if (
      gameType === GAME_TYPE.EXISTING &&
      selectedExistingGameIndex === undefined
    ) {
      formMethods.setError('selectedExistingGame', {
        type: 'manual',
        message:
          'Please select a game from the existing games list and ensure Table ID is filled out.'
      });
      return;
    }

    const runningGame: CreateRunningGamePayload = {
      subgroupId: data.subgroupId,
      waitingListPlayers:
        data.waitingListPlayers?.map(p => ({ flopId: p })) ??
        ([] as { flopId: number }[]),
      table: data.tables.map((table: any) => ({
        buyIn: 0,
        gameSize: `${data.smallBlind}/${data.bigBlind}`,
        gameVariant: data.gameVariant,
        subgroupId: data.subgroupId,
        maxPlayers:
          selectedExistingGameIndex !== undefined
            ? // @ts-ignore
              data[`maxPlayers_${selectedExistingGameIndex}`]
            : table.maxPlayers,
        tableId:
          selectedExistingGameIndex !== undefined
            ? // @ts-ignore
              data[`tableId_${selectedExistingGameIndex}`]
            : table.tableId,
        ante: data.ante,
        buyInMin: data.buyInMin,
        buyInMax: data.buyInMax
      }))
    };
    try {
      await createRunningGame({
        body: runningGame
      }).unwrap();
      onClose();
      onSuccess();
    } catch (e) {
      const { data, status } = e as {
        status: number;
        data: any;
      };
      if (status == 409) {
        if (
          gameType === GAME_TYPE.EXISTING &&
          selectedExistingGameIndex !== undefined
        ) {
          // @ts-ignore
          formMethods.setError(`tableId_${selectedExistingGameIndex}`, {
            message: `Id '${runningGame.table[0].tableId}' is already taken`
          });
          return;
        }
        formMethods.getValues().tables.map((table, index) => {
          if (data.find((t: any) => t.tableId === table.tableId)) {
            // @ts-ignore
            formMethods.setError(`tables[${index}].tableId`, {
              message: `Id '${table.tableId}' is already taken`
            });
          }
        });
      } else {
        console.error('An error has occurred: ', e);
        notification.error({
          message: 'Error',
          description: `An error occurred, while trying to start this interest list. Please Contact your administrator.`,
          placement: 'topRight'
        });
      }
    }
  };
  const onChange = (key: GAME_TYPE) => {
    reset({
      gameLabel: undefined,
      gameVariant: undefined,
      smallBlind: undefined,
      bigBlind: undefined,
      ante: undefined,
      buyInMin: undefined,
      buyInMax: undefined,
      subgroupId: undefined,
      sitPlayers: [],
      waitingListPlayers: [],
      tables: [
        {
          tableId: '',
          maxPlayers: 8
        }
      ]
    });
    setSelectedExistingGameIndex(undefined);
    setGameType(key);
  };
  // @ts-ignore
  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={'NEW GAME'}
      onCancel={onClose}
      cancelText={t('CANCEL_BUTTON')}
      okText="Create Game"
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      onOk={handleSubmit(onSubmit)}
      closable={false}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <Tabs
            defaultActiveKey={
              templateId !== undefined ? GAME_TYPE.TEMPLATE : GAME_TYPE.CUSTOM
            }
            size={'large'}
            moreIcon={<RightOutlined />}
            onChange={key => onChange(key as GAME_TYPE)}
            centered
          >
            {tabs.map(tab => (
              <Tabs.TabPane key={tab.key} tab={tab.name}>
                {gameType === GAME_TYPE.CUSTOM && (
                  <>
                    <TableGameCharacteristics />
                    <hr />
                    {/*@ts-ignore*/}
                    <TableFieldArray control={control} totalTableCount={1} />
                  </>
                )}
                {gameType === GAME_TYPE.EXISTING && (
                  <ExistingGamesList
                    selectedExistingGameIndex={selectedExistingGameIndex}
                    setSelectedExistingGameIndex={setSelectedExistingGameIndex}
                  />
                )}
                {gameType === GAME_TYPE.TEMPLATE && (
                  <TemplateGamesForm templateId={templateId} />
                )}
              </Tabs.TabPane>
            ))}
          </Tabs>
          <input
            type="hidden"
            {...formMethods.register('selectedExistingGame')}
          />
          <input
            type="hidden"
            {...formMethods.register('selectedTemplateGame')}
          />
          <div style={{ fontSize: 12, paddingTop: 5 }}>
            {formMethods.formState.errors.selectedExistingGame && (
              <p style={{ color: 'red' }}>
                {formMethods.formState.errors.selectedExistingGame.message}
              </p>
            )}
            {formMethods.formState.errors.selectedTemplateGame && (
              <p style={{ color: 'red' }}>
                {formMethods.formState.errors.selectedTemplateGame.message}
              </p>
            )}
          </div>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddGameToMapModal;
