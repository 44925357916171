import { api } from '@api/api';
import { GameTableProps } from '@features/runningGames/types';

const runningGameApi = api
  .enhanceEndpoints({ addTagTypes: ['Table', 'RunningGame'] })
  .injectEndpoints({
    endpoints: builder => ({
      table: builder.query<GameTableProps[], { casinoId: string }>({
        providesTags: ['Table'],
        query: ({ casinoId }) => `/games/api/live/table/casinoId/${casinoId}`
      }),
      // deleteTable: builder.mutation<void, { id: string }>({
      //   invalidatesTags: ['Table'],
      //   query: ({ id }) => ({
      //     method: 'DELETE',
      //     url: `/games/api/live/table/${id}`
      //   })
      // }),
      updateTable: builder.mutation<void, Partial<GameTableProps>>({
        invalidatesTags: ['Table'],
        query: params => ({
          method: 'PATCH',
          url: `/games/api/live/table`,
          body: params
        })
      }),
      createTable: builder.mutation<void, unknown>({
        invalidatesTags: ['Table'],
        query: params => ({
          method: 'POST',
          url: `/games/api/live/table/`,
          body: params
        })
      })
      // getRunningGames: builder.query<
      //   ListResponse<RunningGame>,
      //   {
      //     queryParams: {
      //       page: number;
      //       size: number;
      //       // dateFrom: string;
      //       casinoId: string;
      //       // sort: 'ASC' | 'DESC';
      //     };
      //   }
      // >({
      //   providesTags: ['RunningGame'],
      //   extraOptions: { maxRetries: 1 },
      //   transformResponse: (
      //     response: ListResponse<
      //       Omit<RunningGame, 'id'> & { liveGameKey: string }
      //     >
      //   ) => {
      //     return {
      //       ...response,
      //       content: response.content.map(r => ({ ...r, id: r.liveGameKey }))
      //     };
      //   },
      //   query: ({ queryParams }) =>
      //     `/games/api/live/cash-games/search?${parseParams(queryParams)}`
      // })
    })
  });

export const {
  useTableQuery,
  useCreateTableMutation,
  // useDeleteTableMutation,
  useUpdateTableMutation
} = runningGameApi;
