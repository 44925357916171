import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  UploadOutlined,
  UserDeleteOutlined
} from '@ant-design/icons';
import {
  useBreakPlayerMutation,
  useRemovePlayerFromGameMutation,
  useRemovePlayerSeatMutation,
  useResumePlayerMutation
} from '@api-3/endpoints/games';
import { TablePlayers } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React, { useState } from 'react';

type Props = {
  onClose: () => void;
  seatedPlayer: TablePlayers;
};
const DealerActionButton = ({ onClose, seatedPlayer }: Props) => {
  const [removePlayer] = useRemovePlayerFromGameMutation();
  const [resumePlayer] = useResumePlayerMutation();
  const [breakPlayer] = useBreakPlayerMutation();
  const [removeSeat] = useRemovePlayerSeatMutation();
  const [isPlayerBreak, setIsPlayerBreak] = useState<boolean>(
    seatedPlayer?.requestState === 'PAUSED'
  );
  return (
    <div className={'justify-content-center d-flex flex-row'}>
      {isPlayerBreak && (
        <div className={'d-flex flex-column'}>
          <PlayCircleOutlined
            className={'gx-text-success mr-4'}
            style={{ fontSize: 50 }}
            onClick={async () =>
              await resumePlayer({ playerId: seatedPlayer.id }).then(() =>
                setIsPlayerBreak(false)
              )
            }
          />
          <div className={'gx-text-center gx-fs-11 mt-1 mr-4 gx-text-success'}>
            <b>Resume</b>
          </div>
        </div>
      )}
      {!isPlayerBreak && (
        <div className={'d-flex flex-column'}>
          <PauseCircleOutlined
            className={'gx-text-cyan mr-4'}
            style={{ fontSize: 50 }}
            onClick={async () =>
              await breakPlayer({ playerId: seatedPlayer.id }).then(() =>
                setIsPlayerBreak(true)
              )
            }
          />
          <div className={'gx-text-center gx-fs-11 mt-1 mr-4 gx-text-cyan'}>
            <b>Break</b>
          </div>
        </div>
      )}
      <div className={'d-flex flex-column'}>
        <UploadOutlined
          className={'gx-text-warning mr-4'}
          style={{ fontSize: 50 }}
          onClick={async () => {
            await removeSeat({ playerId: seatedPlayer.id }).then(() =>
              onClose()
            );
          }}
        />
        <div className={'gx-text-center gx-fs-11 mt-1 mr-4 gx-text-warning'}>
          <b>Stand out</b>
        </div>
      </div>
      <div className={'d-flex flex-column'}>
        <UserDeleteOutlined
          className={'gx-text-danger'}
          style={{ fontSize: 50 }}
          onClick={async () =>
            await removePlayer({ playerId: seatedPlayer.id }).then(() =>
              onClose()
            )
          }
        />
        <div className={'gx-text-center gx-fs-11 mt-1 gx-text-danger'}>
          <b>Leave table</b>
        </div>
      </div>
    </div>
  );
};

export default DealerActionButton;
