import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ComingSoonPage = () => {
  const { t } = useTranslation();

  return (
    <div
      className="gx-app-login-wrap"
      style={{ height: 'calc(100vh - 223px)' }}
    >
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content w-100 gx-text-center gx-align-content-center">
            <div
              className={'mb-4 '}
              style={{
                position: 'relative',
                height: 46,
                width: 400,
                borderColor: '#ffffff',
                borderStyle: 'solid',
                borderRadius: 20,
                padding: 5,
                margin: 'auto'
              }}
            >
              <h3
                style={{ position: 'absolute', left: 132, top: 11 }}
                className="text-uppercase gx-text-center text-secondary gx-mb-4 gx-font-weight-bold"
              >
                Coming So
              </h3>
              <h3
                style={{ position: 'absolute', left: 238, top: 11 }}
                className="text-uppercase gx-text-center gx-mb-4 gx-font-weight-bold"
              >
                on!
              </h3>
              <div
                style={{
                  height: 30,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                  width: '60%',
                  backgroundColor: '#ffffff'
                }}
              ></div>
            </div>

            <p className="text-greyDisable gx-text-center">
              Get ready for a fantastic new website experience! We're busy
              crafting the perfect blend of design, content, and usability for
              you.
            </p>
            <p className="text-greyDisable gx-text-center">
              Stay tuned, and thanks for your patience!
            </p>
            {/*@ts-ignore*/}
            <Link to="/">
              <Button className="text-uppercase mt-3" type="default">
                {t('BACK_TO_HOME_PAGE')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
