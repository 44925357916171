import { LogoutOutlined } from '@ant-design/icons';
import CasinoAvatar from '@old-world/common/layouts/DefaultLayout/components/CasinoAvatar';
import { logout } from '@redux-3/AuthenticationRedux';
import { Tooltip } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

const MobileHeader = () => {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(logout());
  }
  return (
    <>
      <div />
      <CasinoAvatar height={40} textSize={16} />
      <NavLink onClick={handleLogout} to={'/'}>
        <Tooltip title={'Logout'} placement="bottom">
          <LogoutOutlined
            style={{ color: 'red', fontSize: 30 }}
            onClick={() => handleLogout()}
          />
        </Tooltip>
      </NavLink>
    </>
  );
};

export default MobileHeader;
