import { useCasinoQuery } from '@api-3/endpoints/casino';
import { ownerSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { ADMIN_MY_POKER_ROOM_GENERAL } from '@routes-3/routes';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const branding = 'resizedUrl establishmentType name'.split(' ');
const address = 'position.lat position.lon'.split(' ');
const entryRules = 'dressCode mainCurrency entryFee minimumAgeEntrance'.split(
  ' '
);
const contact = 'telephoneNumber mailContact webSite'.split(' ');
const about = ['description'];

const isSectionEmpty = (section: string[], casino: unknown) =>
  section.map(s => get(casino, s)).filter(v => v == null || v === '');

const findRedirect = ({
  casino,
  isLoading
}: {
  casino: unknown;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return null;
  }
  if (isSectionEmpty(about, casino).length) {
    return ADMIN_MY_POKER_ROOM_GENERAL;
  }

  if (isSectionEmpty(contact, casino).length) {
    return ADMIN_MY_POKER_ROOM_GENERAL;
  }

  if (isSectionEmpty(entryRules, casino).length) {
    return ADMIN_MY_POKER_ROOM_GENERAL;
  }

  if (isSectionEmpty(address, casino).length) {
    return ADMIN_MY_POKER_ROOM_GENERAL;
  }

  if (isSectionEmpty(branding, casino).length) {
    return ADMIN_MY_POKER_ROOM_GENERAL;
  }

  return null;
};

export default function useGetProfileRedirectRoute() {
  const owner = useSelector(ownerSelector);
  const { data: casino, isLoading } = useCasinoQuery({
    id: owner.managedCasinoId
  });

  return { url: findRedirect({ casino, isLoading }), isLoading };
}
