import React from 'react';
import { Field } from 'redux-form';
import { FormFeedback, FormRadio } from 'shards-react';

import GreenCheck from '../../../assets/images/GreenCheck.png';

/* RadioElement Props */
type RadioElementProps = {
  id: string; // Id of Input
  label: string; // Label Text
  value: string; // Value of Input
  subComponent?: JSX.Element; // A component to show under Input when it's checked
};

/* RadioElement Props */
type RadioButtonsProps = {
  name: string;
  options: Array<RadioElementProps>;
  disabled: boolean;
  validate: Array<any>;
  className: string;
  label: string;
  mandatory?: boolean;
};

/**
 * FormRadioRF Component
 * Simple RadioButtons Link with Redux Form
 */
const FormRadioRF = ({
  name,
  options,
  disabled,
  validate,
  className,
  label,
  mandatory
}: RadioButtonsProps) => (
  <div className="radio-options">
    <Field
      disabled={disabled}
      validate={validate}
      component={({ input, meta, options }) => {
        const hasError = meta.touched && meta.invalid;
        return (
          <div>
            <div className="d-flex flex-row align-items-center mb-3">
              {label && (
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    color: hasError ? 'red' : '#FFF'
                  }}
                >
                  {label} {mandatory ? ' *' : ''}
                </div>
              )}
              {meta.valid && (
                <img className="ml-2" src={GreenCheck} width={10} height={7} />
              )}
            </div>
            <div className={className}>
              {options.map(option => {
                const isChecked: boolean = option.value === input.value;
                const hasSubComponent: boolean =
                  option.subComponent !== undefined;
                return (
                  <div key={option.id}>
                    <div className="custom-control custom-radio mb-3 text-white">
                      <FormRadio
                        name="option.id"
                        checked={input.value === option.value}
                        onChange={() => {
                          !disabled && input.onChange(option.value);
                        }}
                        disabled={disabled}
                      >
                        {option.label}
                      </FormRadio>
                    </div>
                    {hasSubComponent && isChecked && (
                      <div className="m-1 ml-5 p-2">{option.subComponent}</div>
                    )}
                  </div>
                );
              })}
            </div>
            {meta.touched && Boolean(meta.error) && (
              <FormFeedback className="d-block">{meta.error}</FormFeedback>
            )}
          </div>
        );
      }}
      name={name}
      options={options}
    />
  </div>
);

export default FormRadioRF;
