import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { useRequestPasswordResetMutation } from '@api-3/endpoints/account';
import { ResetPasswordFormValuesType } from '@old-world/features/authentication/models/AuthenticationModel';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

const FormItem = Form.Item;

const ResetPasswordForm = () => {
  const [t] = useTranslation();
  const { history } = useReactRouter();
  const [resetRequest] = useRequestPasswordResetMutation();
  const [loading, setLoading] = useState(false);
  const cancelForm = () => {
    history.goBack();
  };
  const onResetPassword = async (data: ResetPasswordFormValuesType) => {
    setLoading(true);
    try {
      await resetRequest(data.email).unwrap();
      history.push(`/user-auth/reset-password-succeed?email=${data.email}`);
      return;
    } catch (e) {
      console.log(e);
      message.error(
        t('GLOBAL_ERROR_MESSAGE', {
          action: t('RESET_PASSWORD_ACTION_ERROR')
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{ remember: true }}
      name="basic"
      onFinish={onResetPassword}
      className="gx-signin-form gx-form-row0"
    >
      <FormItem
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!'
          },
          {
            required: true,
            message: 'Please input your E-mail!'
          }
        ]}
        name="email"
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          type={'email'}
        />
      </FormItem>
      <div className={'gx-flex-row ant-row-space-between gx-mt-5'}>
        <FormItem className="gx-text-center w-50 gx-pr-1">
          <Button onClick={cancelForm} className={'w-100'}>
            {t('CANCEL_BUTTON')}
          </Button>
        </FormItem>
        <FormItem className="gx-text-center w-50 gx-pl-1">
          <Button
            type="primary"
            htmlType="submit"
            className={'w-100'}
            loading={loading}
          >
            {t('SEND_EMAIL_BUTTON')}
          </Button>
        </FormItem>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
