import './PickGameVariantsModal.scss';

import AdditionButton from '@components/AdditionButton';
import SlimModal from '@components/SlimModal';
import GameVariant from '@constants/gamevariants';
import { MAIN_FOUR_GAME_VARIANTS } from '@features/notifyPlayers/constants';
import { Button, Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PickGameVariantsItem from './PickGameVariantsItem';

type Props = {
  visible: boolean;
  gameVariant: string;
  setVisible: (visible: boolean) => void;
  onAdd: (gameVariants: string[]) => void;
  initialGameVariants: string[];
};

const CheckboxGroup = Checkbox.Group;
const _GameVariant = GameVariant.filter(
  variant => !MAIN_FOUR_GAME_VARIANTS.includes(variant.label)
).map(item => item.label);

const PickGameVariantsModal = ({
  gameVariant,
  onAdd,
  visible,
  setVisible,
  initialGameVariants
}: Props) => {
  const { t } = useTranslation();
  const [visibleOtherVariants, setVisibleOtherVariants] = useState(false);
  const [mostPopularGamesVariants, setCheckedList] = useState<string[]>([]);
  const [othersCheckedList, setOthersCheckedList] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  function removeOtherVariants() {
    setSelectedItems(
      selectedItems.filter(item => MAIN_FOUR_GAME_VARIANTS.includes(item))
    );
  }

  useEffect(() => {
    setCheckedList([
      ...MAIN_FOUR_GAME_VARIANTS.filter(item =>
        initialGameVariants.includes(item)
      )
    ]);
    setOthersCheckedList([
      ..._GameVariant.filter(variantItem =>
        initialGameVariants.includes(variantItem)
      )
    ]);
  }, [initialGameVariants]);

  useEffect(() => {
    setSelectedItems([...mostPopularGamesVariants.concat(othersCheckedList)]);
  }, [mostPopularGamesVariants, othersCheckedList]);

  const removeAdditionalGameSize = (gameSize: string) => {
    setCheckedList(mostPopularGamesVariants.filter(item => item !== gameSize));
    setOthersCheckedList(othersCheckedList.filter(item => item !== gameSize));
  };

  return (
    <SlimModal
      className="pickModal"
      open={visible}
      centered
      title={t('PICK_ADDITIONAL_GAME_VARIANTS').toLocaleUpperCase()}
      cancelText={t('CANCEL_BUTTON')}
      okText={t('ADD_BUTTON')}
      onCancel={() => setVisible(false)}
      onOk={() => {
        onAdd(selectedItems);
        setVisible(false);
      }}
      okButtonProps={{
        className: 'gx-btn',
        type: 'default'
      }}
      closable={false}
    >
      <Col className="pick-game-variants-content">
        <p>{t('SELECTED_GAME_VARIANTS')}</p>
        <Row style={{ marginBottom: 24 }}>
          {gameVariant && (
            <Button
              className="gamesize-btn"
              style={{ marginTop: 5, marginRight: 5 }}
            >
              {gameVariant}
            </Button>
          )}
          {selectedItems.map(item => (
            <AdditionButton
              text={item}
              onClickCross={() => removeAdditionalGameSize(item)}
            />
          ))}
        </Row>
        <p>{t('PICK_GAME_VARIANTS')}</p>
        {gameVariant && (
          <div className="checkbox-list">
            <Checkbox disabled={true} checked={true}>
              {gameVariant}
            </Checkbox>
            <CheckboxGroup
              className="checkbox-list"
              options={MAIN_FOUR_GAME_VARIANTS.filter(
                item => item !== gameVariant
              )}
              value={mostPopularGamesVariants}
              onChange={value => setCheckedList(value as string[])}
            />
            <Checkbox
              onChange={({ target }) => {
                setVisibleOtherVariants(target.checked);
                if (!target.checked) removeOtherVariants();
              }}
              checked={visibleOtherVariants}
            >
              {t('OTHER_VARIANTS')}
            </Checkbox>
          </div>
        )}
        {visibleOtherVariants && (
          <PickGameVariantsItem
            onPick={variants => setOthersCheckedList([...variants])}
            initialOtherVariants={othersCheckedList}
            gameVariant={gameVariant}
          />
        )}
      </Col>
    </SlimModal>
  );
};

export default React.memo(PickGameVariantsModal);
