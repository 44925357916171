import CasinoAvatar from '@old-world/common/layouts/DefaultLayout/components/CasinoAvatar';
import moment from 'moment/moment';
import React from 'react';

const SentMessageCell = ({ message }: { message: any }) => {
  const timeAgo = moment(message.creationDate)
    .parseZone()
    .fromNow();
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <CasinoAvatar height={40} showText={false} borderRadius={20} />
      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{message.content}</div>
          <div
            className="gx-time gx-text-muted gx-text-right gx-mt-2"
            style={{ fontSize: 10 }}
          >
            {timeAgo}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentMessageCell;
