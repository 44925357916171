import { InterestList } from '@api-3/endpoints/interestList';
import {
  Notification,
  useReadNotificationMutation
} from '@api-3/endpoints/notifications';
import PlayerName from '@components-3/PlayerName';
import usePlayerProfile from '@features/players/usePlayerProfile';
import {
  faCheck,
  faHourglassHalf,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CasinoAvatar from '@old-world/common/layouts/DefaultLayout/components/CasinoAvatar';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import React from 'react';

const ChatItem = ({
  messageNotifications,
  interestList,
  selectedInterestGameId,
  setSelectedInterestGameId
}: {
  messageNotifications: Notification[];
  interestList: InterestList;
  selectedInterestGameId: string;
  setSelectedInterestGameId: (selectedInterestGameId: string) => void;
}) => {
  const [readNotification] = useReadNotificationMutation();
  const { data: profile } = usePlayerProfile(
    (interestList.ownerId as unknown) as string
  );
  const unreadMessage = messageNotifications.filter(m => !m.read);

  const setReadNotification = async () => {
    for (const msg of unreadMessage) {
      await readNotification(msg.id).unwrap();
    }
  };

  return (
    <div
      className={`gx-chat-user-item ${
        selectedInterestGameId === interestList.id ? 'active' : ''
      }`}
      onClick={() => {
        if (unreadMessage.length > 0) setReadNotification();
        setSelectedInterestGameId(interestList.id);
      }}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          {interestList.gameOrigin === 'FLOP_USER' && (
            <div
              className="gx-status-pos"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                fontSize: 10,
                fontWeight: 'bold',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                backgroundColor:
                  interestList.state && interestList.state === 'ACCEPTED'
                    ? '#7CC194'
                    : interestList.state === 'DECLINED'
                    ? '#8A9BA8'
                    : '#F18B2D'
              }}
            >
              {interestList.state === 'ACCEPTED' && (
                <FontAwesomeIcon style={{ fontSize: 20 }} icon={faCheck} />
              )}
              {interestList.state === 'PENDING' && (
                <FontAwesomeIcon
                  style={{ fontSize: 20 }}
                  icon={faHourglassHalf}
                />
              )}
              {interestList.state === 'DECLINED' && (
                <FontAwesomeIcon style={{ fontSize: 20 }} icon={faXmark} />
              )}
            </div>
          )}
          {interestList.gameOrigin === 'CASINO' && (
            <CasinoAvatar height={40} showText={false} borderRadius={20} />
          )}
        </div>
        <div className="gx-chat-info">
          <span className="gx-name h4" style={{ fontWeight: 'bold' }}>
            {interestList.gameVariant}
          </span>
          <div className="gx-chat-info-des gx-text-truncate">
            {interestList.gameSize}
            {interestList.ante ? '/' + interestList.ante : ''}
            {' -  '}
            {interestList.buyInMin
              ? formatBuyIn(interestList.buyInMin)
              : 'NO'}{' '}
            -{' '}
            {interestList.buyInMax ? formatBuyIn(interestList.buyInMax) : 'NO'}
          </div>
          <div className="gx-last-message-time">
            Created by:{' '}
            <strong className={'gx-text-white'}>
              {interestList.gameOrigin === 'CASINO' && interestList.casino}
              {interestList.gameOrigin === 'FLOP_USER' && (
                <>
                  <PlayerName
                    // @ts-ignore
                    player={{
                      firstName: profile.data.firstName!,
                      lastName: profile.data.lastName!
                    }}
                  />
                </>
              )}
            </strong>
          </div>
        </div>
        {messageNotifications.length && unreadMessage.length > 0 && (
          <div
            className="gx-chat-date"
            style={{ position: 'absolute', right: 7 }}
          >
            <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {unreadMessage.length}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatItem;
