import { Col, message, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import FormItem from 'antd/lib/form/FormItem';
import { UploadProps } from 'antd/lib/upload/interface';
import React, { Fragment } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FormState
} from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';

const getBase64 = (
  img: File,
  callback: (s: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const AntdFileUploadField = (
  props: UploadProps & {
    size?: number;
    mandatory?: boolean;
    className: string;
    hasFeedback: boolean;
    label: JSX.Element;
    controller: {
      fieldState: ControllerFieldState;
      field: ControllerRenderProps;
      formState: FormState<any>;
    };
  }
) => {
  const {
    label,
    controller,
    className,
    disabled,
    accept,
    mandatory,
    size = 104
  } = props;
  const { fieldState } = controller;
  const hasError = fieldState.invalid;
  function beforeUpload(file: File) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    if (!isJPG && !isPNG) {
      message.error('You can only upload JPG or PNG file!');
    }
    const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : undefined;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    console.log('before upload');
    console.log(file);
    getBase64(file, (imageUrl: any) => {
      controller.field.onChange({
        file,
        imageUrl
      });
    }); // Return false for manually upload
    return false;
  }

  // fix: error handling
  const labelToDisplay = mandatory ? `${label} *` : label;

  return (
    <FormItem
      label={labelToDisplay}
      validateStatus={hasError ? 'error' : 'success'}
      help={hasError && fieldState.error?.message}
      className={className}
    >
      <ImgCrop rotationSlider>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader cursor-pointer"
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept={accept}
        >
          <Fragment>
            {controller.field.value?.imageUrl ? (
              <img
                width={size}
                src={controller.field.value?.imageUrl}
                alt="avatar"
              />
            ) : controller.field.value &&
              typeof controller.field.value !== 'object' ? (
              <img
                width={size}
                src={controller.field.value}
                alt={controller.field.name}
              />
            ) : (
              <Row align="middle" justify="center" style={{ width: size }}>
                <Col>
                  <AiOutlinePlus />
                  <div className="ant-upload-text">Upload</div>
                </Col>
              </Row>
            )}
          </Fragment>
        </Upload>
      </ImgCrop>
    </FormItem>
  );
};

export default AntdFileUploadField;
