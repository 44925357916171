import { SubGroup } from '@api-3/endpoints/subGroup';
import SubGroupPlayerAction from '@components-3/SubGroupPlayerTable/SubGroupPlayerAction';
import SubGroupPlayerItem from '@components-3/SubGroupPlayerTable/SubGroupPlayerItem';
import { Table } from 'antd';
import React, { ReactNode } from 'react';

const SubGroupPlayersList = ({
  subGroup,
  subGroupPlayersIds,
  setSubGroupPlayersIds,
  customAction
}: {
  subGroup?: SubGroup;
  subGroupPlayersIds?: any[];
  setSubGroupPlayersIds?: (players: number[]) => void;
  customAction?: (player: number) => ReactNode;
}) => {
  const columns = [
    {
      title: '',
      dataIndex: 'index',
      render: (value: string, item: any, index: number) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'Player',
      dataIndex: 'player',
      render: (text: string, record: any) => {
        return <SubGroupPlayerItem flopId={record} />;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: string, record: any) => {
        if (customAction) {
          return customAction(record);
        }
        return (
          <SubGroupPlayerAction
            flopId={record}
            subGroup={subGroup}
            setSubGroupPlayersIds={flopId => {
              if (setSubGroupPlayersIds === undefined) return;
              setSubGroupPlayersIds(
                subGroupPlayersIds?.filter(id => id !== flopId) ?? []
              );
            }}
          />
        );
      }
    }
  ];

  return (
    <Table
      className="gx-table-no-bordered"
      columns={columns}
      pagination={false}
      dataSource={
        subGroupPlayersIds
          ? subGroupPlayersIds
          : subGroup
          ? subGroup.players
          : []
      }
      size="small"
      rowKey={(record: any) => record.id}
    />
  );
};

export default SubGroupPlayersList;
