import { EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

export interface EditableImageProps {
  children: (props: { src: any; size: number }) => any;
  circle?: boolean;
  editable?: boolean;
  size?: number;
  src?: string;
}

function EditableImage({
  children,
  size = 64,
  src,
  editable
}: EditableImageProps) {
  const [showOverlay, setShowOverlay] = useState(true);

  return (
    <div
      onMouseEnter={() => editable && setShowOverlay(true)}
      onMouseLeave={() => editable && setShowOverlay(false)}
      className="position-relative"
    >
      {children({ src, size })}
      {showOverlay && (
        <div
          className="position-absolute h-100 w-100 d-flex"
          style={{
            top: 0,
            left: 0,
            pointerEvents: 'none',
            background: 'rgba(0, 0, 0, 0.65)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <EditOutlined className="text-white" style={{ fontSize: 40 }} />
        </div>
      )}
    </div>
  );
}

export default React.memo(EditableImage);
