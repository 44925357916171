import { CurrentUserContextProvider } from '@old-world/components/CurrentUserContext';
import { api } from '@old-world/services/Api';
import React, { Suspense, useEffect } from 'react';

import LoadingPage from '../../../../../common/containers/LoadingPage';
import FullDisplay from './components/FullDisplay';
export default function FullDisplayPage() {
  const authorizationToken = localStorage.token;
  useEffect(() => {
    api.setHeader('Authorization', authorizationToken || '');
  }, [authorizationToken]);
  return (
    <CurrentUserContextProvider>
      <Suspense fallback={<LoadingPage />}>
        <FullDisplay />
      </Suspense>
    </CurrentUserContextProvider>
  );
}
