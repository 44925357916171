import DraggablePlayer from '@routes-3/main/cashGameManagement/runningGamesMap/modals/tablePlayersModal/DraggablePlayer';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
// eslint-disable-next-line prettier/prettier
import update from 'immutability-helper';
import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const WaitingListPlayersDnD = ({
  waitingListPlayers,
  setWaitingListPlayers,
  reorderWaitingListPlayers,
  gameId,
  tableId,
  hasFreeSeats,
  isReordering
}: {
  waitingListPlayers: WaitingPlayer[];
  setWaitingListPlayers: (WaitingListPlayer: any) => void;
  reorderWaitingListPlayers: WaitingPlayer[] | undefined;
  gameId: string;
  tableId?: string;
  hasFreeSeats?: boolean;
  isReordering: boolean;
}) => {
  const movePlayer = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!isReordering) return;
      setWaitingListPlayers((prevPlayers: WaitingPlayer[]) => {
        return update(prevPlayers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevPlayers[dragIndex] as WaitingPlayer]
          ]
        });
      });
    },
    [isReordering, setWaitingListPlayers]
  );

  const renderPlayer = useCallback(
    (player: WaitingPlayer, index: number) => {
      return (
        <DraggablePlayer
          key={player.id}
          index={index}
          id={player.id}
          player={player}
          movePlayer={movePlayer}
          gameId={gameId}
          tableId={tableId}
          hasFreeSeats={hasFreeSeats}
          isReordering={isReordering}
        />
      );
    },
    [isReordering, movePlayer]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {isReordering && reorderWaitingListPlayers
          ? reorderWaitingListPlayers.map((player, i) =>
              renderPlayer(player, i)
            )
          : waitingListPlayers.map((player, i) => renderPlayer(player, i))}
      </div>
    </DndProvider>
  );
};

export default WaitingListPlayersDnD;
