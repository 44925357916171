import LoadingPage from '@old-world/common/containers/LoadingPage';
import { CurrentUserContextProvider } from '@old-world/components/CurrentUserContext';
import { api } from '@old-world/services/Api';
import MobileViewPage from '@routes-3/main/mobileView/components/MobileViewPage';
import React, { Suspense, useEffect } from 'react';

const MobileView = () => {
  const authorizationToken = localStorage.token;
  useEffect(() => {
    api.setHeader('Authorization', authorizationToken || '');
  }, [authorizationToken]);
  return (
    <CurrentUserContextProvider>
      <Suspense fallback={<LoadingPage />}>
        <MobileViewPage />
      </Suspense>
    </CurrentUserContextProvider>
  );
};

export default MobileView;
