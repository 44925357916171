import { PlayerSearchProfile } from '@api-3/endpoints/player';
import { useGetSubGroupQuery } from '@api-3/endpoints/subGroup';
import AddPlayerModal from '@components-3/addPlayerModal/AddPlayerModal';
import SubGroupPlayersList from '@components-3/SubGroupPlayerTable/SubGroupPlayersList';
import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { GameVariantType } from '@old-world/common/redux/ParametersModel.d';
import GameVariant from '@old-world/common/sagas/static/GameVariant';
import { Template } from '@old-world/flop-3.0/api/endpoints/templates';
import { Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TemplateForm = ({ template }: { template?: Template }) => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<Template>();

  const { data: subGroup } = useGetSubGroupQuery(
    {
      // @ts-ignore
      subGroupId: template?.subGroupId
    },
    { skip: !template?.subGroupId }
  );

  const rules = {
    required: 'is required',
    pattern: { value: /\d+/, message: t('VALIDATOR_NUMBER') }
  };

  const gameVariant = useController({
    control,
    name: 'gameVariant',
    rules: { required: 'is required' },
    defaultValue: template ? template.gameVariant : ''
  });
  const smallBlind = useController({
    control,
    name: 'smallBlind',
    rules,
    defaultValue: template ? template.smallBlind : undefined
  });
  const bigBlind = useController({
    control,
    name: 'bigBlind',
    rules: { required: 'is required' },
    defaultValue: template ? template.bigBlind : undefined
  });
  const ante = useController({
    control,
    name: 'ante'
  });
  const min = useController({
    control,
    name: 'minBuyIn',
    rules: { required: 'is required' },
    defaultValue: template ? template.minBuyIn : undefined
  });
  const max = useController({
    control,
    name: 'maxBuyIn',
    defaultValue: template ? template.maxBuyIn : undefined
  });

  const capacity = useController({
    control,
    name: 'maxPlayers',
    rules: { pattern: /\d+/ },
    defaultValue: template ? template.maxPlayers : undefined
  });
  const tableId = useController({
    control,
    name: 'tableId',
    rules: {
      minLength: 1,
      maxLength: 10
    },
    defaultValue: template ? template.tableId : undefined
  });

  const selectedPlayers = useController({
    control,
    name: 'players',
    rules: {
      minLength: 1,
      maxLength: 10
    },
    defaultValue: []
  });

  const [addPlayerModalOpen, setAddPlayerModalOpen] = useState<boolean>(false);

  return (
    <>
      <ASelect
        label={t('INTEREST_GAME_VARIANT_LABEL') + '*'}
        controller={gameVariant}
        hasFeedback
      >
        {GameVariant.map((c: GameVariantType, index: number) => (
          <Select.Option key={index} value={c.label}>
            {c.label}
          </Select.Option>
        ))}
      </ASelect>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={smallBlind} label={'SB*'} type={'text'} />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={bigBlind} label={'BB*'} type={'text'} />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <AInput controller={ante} label={'Straddle'} type={'text'} />
        </Col>
      </Row>
      <Row
        className={'gx-flex-row'}
        style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput controller={min} label={'Min. Buy-In*'} type={'text'} />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput controller={max} label={'Max. Buy-In'} type={'text'} />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <AInput
            controller={tableId}
            label={t('TABLE_ID')}
            type="text"
            maxLength={10}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <ASelect controller={capacity} label={t('TABLE_CAPACITY')}>
            {[6, 7, 8, 9, 10].map((num: number) => (
              <Select.Option key={num} value={num}>
                {num}
              </Select.Option>
            ))}
          </ASelect>
        </Col>
        <AddPlayerModal
          addText="Add Player"
          removeText="Remove Player"
          addToSubGroup
          showNonFopUser={false}
          subGroupId={template?.subGroupId}
          onChange={(player: PlayerSearchProfile) => {
            const currentlySelectedPlayers = getValues('players') ?? [];

            selectedPlayers.field.onChange(
              currentlySelectedPlayers.includes(player.id)
                ? currentlySelectedPlayers.filter(p => p !== player.id)
                : [...currentlySelectedPlayers, player.id]
            );
          }}
          onClose={() => setAddPlayerModalOpen(false)}
          open={addPlayerModalOpen}
        />
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className={'gx-mb-3'}>
          <div
            className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
            onClick={() => setAddPlayerModalOpen(true)}
          >
            <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1" />
            Add Player
          </div>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div style={{ maxHeight: 220, overflowY: 'scroll' }}>
            <SubGroupPlayersList
              subGroup={subGroup}
              setSubGroupPlayersIds={selectedPlayers.field.onChange}
              subGroupPlayersIds={getValues('players') ?? []}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TemplateForm;
