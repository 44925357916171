import { useModals } from '@providers/ModalProvider';
import { useEffect } from 'react';

export default function Onboarding({
  onOpen,
  onClose
}: {
  onOpen: () => void;
  onClose: () => void;
}) {
  const openModal = useModals();
  useEffect(() => {
    onOpen();
    openModal({ type: 'ONBOARDING', props: { onClose: onClose } });
  }, []);
  return null;
}
