import { useCasinoQuery } from '@api-3/endpoints/casino';
import {
  PlayerProfile,
  PlayerSearchProfile,
  useGetPlayerLocationQuery
} from '@api-3/endpoints/player';
import DefaultProfileImage from '@assets-3/images/default_profile_picture.png';
import DistanceWithUnit from '@components-3/DistanceWithUnit';
import PlayerName from '@components-3/PlayerName';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { Avatar, Badge, Tag } from 'antd';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';

enum PLAYER_STATUS {
  IS_PLAYING = 'isPlaying',
  HAS_BREAK = 'hasBreak',
  ON_THE_WAY = 'onTheWay'
}
const PlayerInfo = ({
  player,
  textColor = 'white',
  showDistance = true,
  showCountry = true,
  showFullName = false,
  width = 'fit-content'
}: {
  player:
    | (PlayerSearchProfile & Omit<PlayerProfile, 'id'>)
    | (WaitingPlayer & PlayerProfile)
    | any;
  textColor?: string;
  showDistance?: boolean;
  showCountry?: boolean;
  showFullName?: boolean;
  width?: string;
}) => {
  const casinoId = useSelector(currentCasinoSelector);
  const { data: casino } = useCasinoQuery({ id: casinoId });
  let playerStatus = null;

  if (player.breakDate && player.requestState === 'PAUSED') {
    playerStatus = PLAYER_STATUS.HAS_BREAK;
  } else if (player.requestState === 'SITTED' && !player.seatId) {
    playerStatus = PLAYER_STATUS.ON_THE_WAY;
  } else if (player.requestState === 'SITTED') {
    playerStatus = PLAYER_STATUS.IS_PLAYING;
  }

  const { data: playerPosition } = useGetPlayerLocationQuery(
    {
      userId: player.flopId
        ? player.flopId
        : player.onlinePlayer
        ? player.id
        : null,
      casinoLat: casino?.position?.lat || 0,
      distanceUnit: 'KILOMETERS',
      casinoLon: casino?.position?.lon || 0
    },
    { pollingInterval: 10000, skip: !casino?.position || !player.flopId }
  );

  return (
    <div
      className="gx-flex-row gx-justify-content-between"
      style={{ width: width }}
    >
      <div className={'gx-flex-row gx-align-items-lg-center mr-2'}>
        <Badge offset={[-4, 12]}>
          <Avatar
            src={
              player.profilePictureUrl
                ? player.profilePictureUrl
                : DefaultProfileImage
            }
            size={35}
            className={'mr-1'}
            style={{
              borderColor: '#508cca',
              borderWidth: 2,
              borderStyle: 'solid',
              marginTop: 8
            }}
          />
        </Badge>
        <div className={`gx-mb-0 gx-text-${textColor}`}>
          <PlayerName
            player={{
              firstName: player.firstName!,
              lastName: player.lastName!,
              name: player!.name
            }}
            showFullName={showFullName}
          />
          {showCountry && (
            <div style={{ fontSize: 11 }} className={'gx-flex-row'}>
              {player.country && (
                <span className={'mr-1'}>{player.country}</span>
              )}
              {showDistance && playerPosition && (
                <span>
                  <DistanceWithUnit
                    value={playerPosition.distance.value}
                    unit={playerPosition.distance.metric}
                  />
                </span>
              )}
            </div>
          )}
          {playerStatus === PLAYER_STATUS.HAS_BREAK && (
            <Badge size="small" count={'break'} />
          )}
          {playerStatus === PLAYER_STATUS.IS_PLAYING && (
            <Badge size="small" count={'playing'} color={'#52c41a'} />
          )}
          {playerStatus === PLAYER_STATUS.ON_THE_WAY && (
            <Badge
              size="small"
              count={'on the way'}
              color={'#faad14'}
              style={{
                animation: 'blink 3s infinite'
              }}
            />
          )}
        </div>
      </div>
      <div style={{ paddingTop: 10 }}>
        <Tag
          style={{
            padding: 0,
            margin: 0,
            fontSize: 7,
            fontWeight: 600,
            height: 14,
            borderRadius: 0,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center'
          }}
          className={`${player.flopId || player.onlinePlayer ? '' : 'd-none'}`}
          color="#4f8dca"
        >
          FLOP
        </Tag>
        {showCountry && player.countryCode ? (
          <div>
            <ReactCountryFlag
              className="country-flag"
              code={player.countryCode}
              svg
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlayerInfo;
