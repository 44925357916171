import {
  ListEventItem,
  useGetEventBannerQuery,
  useGetTournamentsCountQuery
} from '@api-3/endpoints/festivals';
import DummyImage from '@assets/images/dummy-image.png';
import { useModals } from '@providers/ModalProvider';
import { determineEventStatus } from '@routes-3/main/tournamentsManagement/events/components/EventCard';
import { Button, Card } from 'antd';
import moment from 'moment';
import React from 'react';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const EventDetailsPageHeader = ({ event }: { event: ListEventItem }) => {
  const history = useHistory();
  const openModal = useModals();
  const { data: eventBanner } = useGetEventBannerQuery({
    festivalId: event.id
  });
  const { data: tournamentsCount } = useGetTournamentsCountQuery({
    festivalId: event.id
  });

  return (
    <>
      <p
        className="gx-text-primary gx-mb-3 gx-pointer gx-d-none gx-d-sm-block"
        onClick={() => {
          history.goBack();
        }}
      >
        <i
          className="icon icon-chevron-left gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1"
          style={{ position: 'relative', bottom: 2 }}
        />
        Back
      </p>
      <Card className="gx-card-widget gx-card-full gx-dot-arrow-hover">
        <div className="gx-user-wid-row">
          <div
            className="gx-user-wid gx-mr-3"
            style={{
              backgroundColor: '#6c7177',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {eventBanner ? (
              <img
                alt="..."
                src={eventBanner?.resizedUrl}
                width={200}
                height={200}
                className="gx-object-cover"
              />
            ) : (
              <img
                className={'dummy-image'}
                alt={event?.name}
                src={DummyImage}
                style={{ justifyContent: 'center' }}
              />
            )}
          </div>
          <div className="gx-user-wid-body gx-py-3 gx-pr-3">
            <div className="ant-row-flex">
              <div className={'gx-fs-xl gx-font-weight-bold mb-2 mr-4'}>
                {event?.name}
              </div>
              <div
                className={'gx-fs-sm gx-font-weight-semi-bold'}
                style={{ position: 'relative', top: 3 }}
              >
                {determineEventStatus(event.startDate, event.endDate) ===
                'finished' ? (
                  <span style={{ color: '#A09E9E' }}>
                    This tournament has ended
                  </span>
                ) : event?.published ? (
                  <span style={{ color: '#61AD7B' }}>
                    PUBLISHED to FLOP APP
                  </span>
                ) : (
                  <span style={{ color: '#C48420' }}>UNPUBLISHED</span>
                )}
              </div>
            </div>
            <div className={'gx-fs-md mb-2'}>
              <span>{moment(event?.startDate).format('Do MMM')}</span>
              <span> - </span>
              <span>{moment(event?.endDate).format('Do MMM, YYYY')}</span>
            </div>
            {tournamentsCount !== undefined && (
              <div
                className={'gx-fs-sm gx-font-weight-bold mb-3'}
                style={{
                  color: '#A6C8E6',
                  opacity: tournamentsCount === 0 ? 0.65 : 1
                }}
              >
                {tournamentsCount}{' '}
                {tournamentsCount === 1 ? 'Tournament' : 'Tournaments'}
              </div>
            )}

            <div className="gx-fs-sm" style={{ color: '#A09E9E' }}>
              {event?.website}
            </div>
          </div>
          {determineEventStatus(event.startDate, event.endDate) !==
            'finished' && (
            <Button
              className="gx-btn"
              style={{ position: 'absolute', top: 30, right: 30 }}
              key="submit"
              type="default"
              onClick={() => {
                openModal({
                  type: 'CREATE_EVENT',
                  props: { event, eventBanner }
                });
              }}
            >
              Edit Event
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};
export default EventDetailsPageHeader;
