import { useGetSubGroupQuery } from '@api-3/endpoints/subGroup';
import { Template, useGetTemplatesQuery } from '@api-3/endpoints/templates';
import SubGroupPlayersList from '@components-3/SubGroupPlayerTable/SubGroupPlayersList';
import { ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import TableFieldArray from '@components/forms/TableFieldArray';
import {
  faCheck,
  faPersonArrowDownToLine,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import { TableGameCharacteristicsFieldFormShape } from '@routes-3/main/cashGameManagement/runningGamesMap/forms/TableGameCharacteristics';
import { Button, Col, Row, Select } from 'antd';
import { Tooltip } from 'antd/es';
import React, { useEffect, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TemplateGamesForm = ({ templateId }: { templateId?: number }) => {
  const { t } = useTranslation();
  const casinoId = useSelector(currentCasinoSelector);
  const [selectedTemplateId, setIndex] = useState<number | undefined>(
    undefined
  );
  const { data } = useGetTemplatesQuery({ casinoId });
  const templateGames: Template[] = data?.content ?? [];
  const { control, setValue } = useFormContext<
    TableGameCharacteristicsFieldFormShape
  >();
  // @ts-ignore
  const templateMap = templateGames.reduce((acc, template) => {
    return { ...acc, [template.id]: template };
  }, {});
  // @ts-ignore
  const selectedTemplate = templateMap[selectedTemplateId];
  const { data: subGroup } = useGetSubGroupQuery(
    { subGroupId: selectedTemplate?.subGroupId ?? 0 },
    { skip: !selectedTemplate?.subGroupId }
  );
  const templateController = useController({
    control,
    defaultValue: templateId,
    name: 'templateId'
  });
  const waitingList = useWatch({ control, name: 'waitingListPlayers' }) ?? [];
  const sitPlayers = useWatch({ control, name: 'sitPlayers' }) ?? [];

  function getOnChange(id: number) {
    // @ts-ignore
    const template = templateMap[id] as Template;
    setValue('templateId', template.id as any);
    setValue('bigBlind', template.bigBlind);
    setValue('smallBlind', template.smallBlind);
    setValue('ante', template.ante!);
    setValue('buyInMax', template.maxBuyIn);
    setValue('buyInMin', template.minBuyIn);
    setValue('gameVariant', template.gameVariant);
    setValue('subgroupId', template.subGroupId);
    setValue('tables.0.tableId', template.tableId?.toString() ?? '');
    setValue('tables.0.maxPlayers', template.maxPlayers ?? 8);
    setIndex(id);
    return null;
  }

  useEffect(() => {
    if (templateId) {
      getOnChange(templateId);
    }
  }, [templateId]);

  return (
    <div style={{ height: 378 }}>
      {templateGames.length > 0 && (
        <>
          <ASelect
            controller={templateController}
            hasFeedback
            // @ts-ignore
            onChange={getOnChange}
          >
            {templateGames.map(c => (
              <Select.Option key={c.id} value={c.id}>
                <div>
                  <b>{c.gameVariant}</b> - {c.smallBlind}/{c.bigBlind}
                  {c.ante ? `/${c.ante}` : null}
                </div>
                <div>
                  Buy-In: {c.minBuyIn ? formatBuyIn(c.minBuyIn) : 'NO'} -{' '}
                  {c.maxBuyIn ? formatBuyIn(c.maxBuyIn) : 'NO'}
                </div>
              </Select.Option>
            ))}
          </ASelect>
          <Row className={'gx-flex-row'}>
            {/*// @ts-ignore*/}
            <TableFieldArray control={control} totalTableCount={1} />
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div
                style={{
                  maxHeight: 220,
                  overflowY: 'scroll',
                  marginLeft: -16,
                  marginRight: -16,
                  paddingTop: 20
                }}
              >
                <SubGroupPlayersList
                  customAction={player => {
                    const isInWL = waitingList.some(p => p == player);
                    const isInSit = sitPlayers.some(p => p == player);
                    const onClick = (type: 'sit' | 'wl') => {
                      if (type == 'wl') {
                        setValue(
                          'sitPlayers',
                          sitPlayers.filter(p => p != player)
                        );
                        if (isInWL) {
                          setValue(
                            'waitingListPlayers',
                            waitingList.filter(p => p != player)
                          );
                        } else {
                          setValue('waitingListPlayers', [
                            ...waitingList,
                            player
                          ]);
                        }
                      }

                      if (type == 'sit') {
                        setValue(
                          'waitingListPlayers',
                          waitingList.filter(p => p != player)
                        );
                        if (isInSit) {
                          setValue(
                            'sitPlayers',
                            sitPlayers.filter(p => p != player)
                          );
                        } else {
                          setValue('sitPlayers', [...sitPlayers, player]);
                        }
                      }
                    };
                    const activeStyles = {
                      position: 'absolute',
                      bottom: -11,
                      left: 23,
                      color: 'green',
                      fontSize: 19
                    };

                    return (
                      <>
                        {/*TODO: Backend implementation is messing*/}
                        {/*<Tooltip title={t('SIT_PLAYER')} placement="bottom">*/}
                        {/*  <Button*/}
                        {/*    id={`sit-player-${player}`}*/}
                        {/*    disabled={false}*/}
                        {/*    onClick={() => onClick('sit')}*/}
                        {/*    icon={*/}
                        {/*      <div>*/}
                        {/*        <FontAwesomeIcon*/}
                        {/*          icon={faPersonArrowDownToLine}*/}
                        {/*        />*/}
                        {/*        <small*/}
                        {/*          //@ts-ignore*/}
                        {/*          style={{*/}
                        {/*            ...activeStyles,*/}
                        {/*            display: isInSit ? 'block' : 'none'*/}
                        {/*          }}*/}
                        {/*        >*/}
                        {/*          <FontAwesomeIcon icon={faCheck} />*/}
                        {/*        </small>*/}
                        {/*      </div>*/}
                        {/*    }*/}
                        {/*  />*/}
                        {/*</Tooltip>*/}
                        <Tooltip
                          title={'Add to Waiting List'}
                          placement="bottom"
                        >
                          <Button
                            id={`wl-player-${player}`}
                            type="primary"
                            disabled={false}
                            onClick={() => onClick('wl')}
                            icon={
                              <div>
                                <FontAwesomeIcon icon={faPlusCircle} />
                                <small
                                  //@ts-ignore
                                  style={{
                                    ...activeStyles,
                                    display: isInWL ? 'block' : 'none'
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                </small>
                              </div>
                            }
                          />
                        </Tooltip>
                      </>
                    );
                  }}
                  subGroupPlayersIds={subGroup?.players ?? []}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TemplateGamesForm;
