import DefaultProfileImage from '@assets-3/images/default_profile_picture.png';
import PlayerName from '@components-3/PlayerName';
import usePlayerProfile from '@features/players/usePlayerProfile';
import { Avatar } from 'antd';
import moment from 'moment';
import React from 'react';

const ReceivedMessageCell = ({ message }: { message: any }) => {
  const { data: profile } = usePlayerProfile(
    (message.authorId as unknown) as string
  );
  const timeAgo = moment(message.creationDate)
    .parseZone()
    .fromNow();
  return (
    <div className="gx-chat-item">
      <div>
        <Avatar
          src={
            profile.data.profilePictureUrl
              ? profile.data.profilePictureUrl
              : DefaultProfileImage
          }
          size={35}
          className={'mr-1'}
          style={{
            borderColor: '#508cca',
            borderWidth: 2,
            borderStyle: 'solid',
            marginTop: 8
          }}
        />
        <div className={'mt-1'}>
          <PlayerName
            textSize={10}
            player={{
              firstName: profile.data.firstName!,
              lastName: profile.data.lastName!
            }}
          />
        </div>
      </div>
      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{message.content}</div>
          <div
            className="gx-time gx-text-muted gx-text-right gx-mt-2"
            style={{ fontSize: 10 }}
          >
            {timeAgo}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessageCell;
