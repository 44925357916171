import PlayerInfo from '@components-3/PlayerInfo';
import { ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import { convertMinutes } from '@old-world/utils/DateUtils';
import { Card, Form, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

type PlayersLeaderboardProps = {
  gameVariant: string;
  bigBlind: number;
  smallBlind: number;
  ante: number | null;
  players: PlayersType[];
}[];

type PlayersType = {
  flopId: number | null;
  profilePictureUrl: string | null;
  firstName: string;
  lastName: string;
  countryCode: string | null;
  playedGame: string;
  numberOfSessions: number;
  totalPlayTime: number;
};
const PlayersLeaderboard = ({
  playersLeaderboardData
}: {
  playersLeaderboardData: PlayersLeaderboardProps;
}) => {
  const { control } = useForm<any>();
  const games = useController({
    control,
    name: 'games'
  });
  const [players, setPlayers] = useState<PlayersType[]>(
    playersLeaderboardData.length > 0 ? playersLeaderboardData[0].players : []
  );
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'index',
      width: 60,
      render: (value: string, item: any, index: number) => {
        return <span>#{index + 1}</span>;
      }
    },
    {
      title: 'Player',
      dataIndex: 'player',
      render: (text: string, record: any) => {
        return <PlayerInfo player={record} />;
      }
    },
    // {
    //   title: 'Preferred game',
    //   dataIndex: 'preferred_game',
    //   render: (text: string, record: any) => {
    //     return <div>{record.playedGame}</div>;
    //   }
    // },
    {
      title: 'Nb. of sessions',
      dataIndex: 'nb_of_sessions',
      render: (text: string, record: any) => {
        return <div>{record.numberOfSessions}</div>;
      }
    },
    {
      title: 'Total play time',
      dataIndex: 'total_play_time',
      render: (text: string, record: any) => {
        return <div>{convertMinutes(record.totalPlayTime)}</div>;
      }
    }
  ];

  useEffect(() => {
    setPlayers(
      playersLeaderboardData.length > 0 ? playersLeaderboardData[0].players : []
    );
  }, [playersLeaderboardData]);
  return (
    <Card
      className={'gx-card-widget gx-card-full'}
      title={
        <>
          <div className={'ant-card-head-title'}>Players Leaderboard</div>
          <div style={{ fontSize: 10 }}>
            (based only on players that use FLOP App)
          </div>
        </>
      }
      extra={
        playersLeaderboardData.length > 0 && (
          <Form style={{ width: 200 }}>
            <ASelect
              hasFeedback
              controller={games}
              // @ts-ignore
              onChange={index => {
                setPlayers(playersLeaderboardData[index].players);
                return null;
              }}
              defaultValue={0}
            >
              {playersLeaderboardData.map((c, index) => (
                <Select.Option key={index} value={index}>
                  {c.gameVariant} - {c.smallBlind}/{c.bigBlind}
                  {c.ante ? `/${c.ante}` : null}
                </Select.Option>
              ))}
            </ASelect>
          </Form>
        )
      }
    >
      <div className={'p-4'}>
        {players && (
          <>
            <Table
              className="gx-table-no-bordered"
              columns={columns}
              dataSource={players}
              pagination={false}
              size="small"
              scroll={{ y: 180 }}
            />
            {players.length > 10 && (
              <div className={'text-center mt-3'}>
                <a href={'#'}>show more</a>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default PlayersLeaderboard;
