import axios, { AxiosInstance } from 'axios';
import { Moment } from 'moment';

import { ReportingResponse } from './types';

class ApiClient {
  endpoint = '';
  token = '';
  client: AxiosInstance;

  constructor() {
    if (localStorage.token) {
      this.token = localStorage.token;
    }

    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });

    this.client.interceptors.request.use(config => {
      config.headers.Authorization = this.token;

      return config;
    });
  }

  changePassword({
    email,
    ...values
  }: {
    currentPassword: string;
    email: string;
    newPassword: string;
    password_check: string;
  }) {
    return this.client.put(
      `/accounts/api/customer/${email}/change_password`,
      values
    );
  }

  fetchFlopProfile(userId: string) {
    return this.client.get(`/profiles/api/${userId}`);
  }

  fetchProfile(customerId: string, customerAccountId: string) {
    return this.client.get(
      `/accounts/api/customer/${customerId}/customer_account/${customerAccountId}`
    );
  }

  fetchProfilePicture(customerAccountId: string) {
    return this.client.get(`/gallery/api/customer/${customerAccountId}`);
  }

  fetchReporting(from: Moment, to: Moment) {
    return this.client.get<ReportingResponse>('/games/api/reporting', {
      params: {
        dateFrom: from.toISOString(true),
        dateTo: to.toISOString(true)
      }
    });
  }

  async login(email: string, password: string) {
    const response = await this.client.post('/accounts/api/login', {
      email,
      password,
      loginOrigin: 'FLOP_AD'
    });

    this.token = response.headers?.authorization;

    return response;
  }

  sendContactForm(subject: string, content: string) {
    return this.client.post('/accounts/api/contact-us', {
      content,
      subject
    });
  }

  uploadCustomerProfilePicture(customerId: string, data: any) {
    const formData = new FormData();

    formData.append('image', data);

    return this.client.post(`/gallery/api/customer/${customerId}`, formData, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'multipart/form-data;'
      }
    });
  }

  updateCustomerProfile({ firstName, id, lastName }: any) {
    return this.client.put(`/accounts/api/customer/${id}/profile`, {
      firstName,
      lastName
    });
  }
}

export default new ApiClient();
