import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import ComingSoonPage from '@old-world/common/containers/ComingSoonPage';
import React from 'react';

const MyPlayers = () => {
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Players Database'}
        pageIcon={
          <i
            className="icon icon-contacts"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'My Players'}
      />
      <ComingSoonPage />
    </LayoutContentContainer>
  );
};

export default MyPlayers;
