import './InterestListDataTable.scss';

import { InterestList as InterestListItem } from '@api-3/endpoints/interestList';
import BuyInColumn from '@routes-3/main/cashGameManagement/columns/BuyInColumn';
import CreatedByColumn from '@routes-3/main/cashGameManagement/columns/CreatedByColumn';
import DateColumn from '@routes-3/main/cashGameManagement/columns/DateColumn';
import GameActionsColumn from '@routes-3/main/cashGameManagement/columns/GameActionsColumn';
import GameMessagesColumn from '@routes-3/main/cashGameManagement/columns/GameMessagesColumn';
import GamePlayersColumn from '@routes-3/main/cashGameManagement/columns/GamePlayersColumn';
import GameSizeColumn from '@routes-3/main/cashGameManagement/columns/GameSizeColumn';
import GameVariantColumn from '@routes-3/main/cashGameManagement/columns/GameVariantColumn';
import StatusColumn from '@routes-3/main/cashGameManagement/columns/StatusColumn';
import {
  GAME_ORIGIN_FILTER,
  GAME_STATES_FILTER,
  INITIAL_GAME_VARIANT_FILTER
} from '@routes-3/main/cashGameManagement/interestList/constants';
import {
  ColumnObjType,
  INTEREST_LIST_COLUMN,
  InterestListDataTableProps
} from '@routes-3/main/cashGameManagement/interestList/types';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GAME_VARIANT_FILTERS = INITIAL_GAME_VARIANT_FILTER.map(variant => ({
  text: variant,
  value: variant
}));

const InterestListDataTable = ({
  data,
  totalElements,
  size,
  handleTableChange
}: InterestListDataTableProps) => {
  const { t } = useTranslation();
  const interestListColumns: ColumnProps<any>[] = [
    {
      key: INTEREST_LIST_COLUMN.STATE,
      filters: GAME_STATES_FILTER,
      title: '',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(INTEREST_LIST_COLUMN.STATE, interestListItem);
      }
    },
    {
      key: INTEREST_LIST_COLUMN.CREATED_BY,
      filters: GAME_ORIGIN_FILTER,
      title: 'Created by',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.CREATED_BY,
          interestListItem
        );
      }
    },
    {
      key: INTEREST_LIST_COLUMN.GAME_VARIANT,
      filters: GAME_VARIANT_FILTERS,
      title: 'Variant',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.GAME_VARIANT,
          interestListItem
        );
      }
    },
    {
      key: INTEREST_LIST_COLUMN.GAME_SIZE,
      title: 'Size',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.GAME_SIZE,
          interestListItem
        );
      }
    },
    {
      key: INTEREST_LIST_COLUMN.BUY_IN,
      title: 'Buy In (Min - Max)',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(INTEREST_LIST_COLUMN.BUY_IN, interestListItem);
      }
    },
    {
      key: INTEREST_LIST_COLUMN.DATE,
      title: 'Suggested Time',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(INTEREST_LIST_COLUMN.DATE, interestListItem);
      },
      sorter: true,
      defaultSortOrder: 'ascend'
    },
    {
      key: INTEREST_LIST_COLUMN.GAME_PLAYERS,
      title: t('CASH_GAMES_PLAYERS_LABEL'),
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.GAME_PLAYERS,
          interestListItem
        );
      }
    },
    {
      key: INTEREST_LIST_COLUMN.GAME_MESSAGES,
      title: 'Chat',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.GAME_MESSAGES,
          interestListItem
        );
      }
    },
    {
      key: INTEREST_LIST_COLUMN.GAME_ACTION,
      title: '',
      className: 'text-right action-buttons-column',
      render: (interestListItem: InterestListItem) => {
        return renderColumnObj(
          INTEREST_LIST_COLUMN.GAME_ACTION,
          interestListItem
        );
      }
    }
  ];

  return (
    <Table
      className="gx-table w-100 interest-list-table"
      onChange={(pagination, filters, sorter) => {
        handleTableChange(pagination, filters, sorter);
      }}
      dataSource={data}
      columns={interestListColumns}
      size={'large'}
      rowKey={(record: any) => record.id}
      pagination={{
        defaultPageSize: size,
        total: totalElements,
        showSizeChanger: false
      }}
    />
  );

  function renderColumnObj(
    column: INTEREST_LIST_COLUMN,
    interestListItem: InterestListItem
  ) {
    const columnObj: ColumnObjType = {
      children: <React.Fragment />,
      props: {}
    };

    switch (column) {
      case INTEREST_LIST_COLUMN.STATE:
        columnObj.children = <StatusColumn interestList={interestListItem} />;
        break;
      case INTEREST_LIST_COLUMN.CREATED_BY:
        columnObj.children = (
          <CreatedByColumn interestList={interestListItem} />
        );
        break;
      case INTEREST_LIST_COLUMN.GAME_VARIANT:
        columnObj.children = (
          <GameVariantColumn interestList={interestListItem} />
        );
        break;
      case INTEREST_LIST_COLUMN.GAME_SIZE:
        columnObj.children = <GameSizeColumn interestList={interestListItem} />;
        break;
      case INTEREST_LIST_COLUMN.BUY_IN:
        columnObj.children = <BuyInColumn interestList={interestListItem} />;
        break;
      case INTEREST_LIST_COLUMN.DATE:
        columnObj.children = <DateColumn interestList={interestListItem} />;
        break;
      case INTEREST_LIST_COLUMN.GAME_PLAYERS:
        columnObj.children = (
          <GamePlayersColumn interestList={interestListItem} />
        );
        break;
      case INTEREST_LIST_COLUMN.GAME_MESSAGES:
        columnObj.children = (
          <GameMessagesColumn interestList={interestListItem} />
        );
        break;
      case INTEREST_LIST_COLUMN.GAME_ACTION:
        columnObj.children = (
          <GameActionsColumn interestList={interestListItem} />
        );
        break;
    }

    return columnObj;
  }
};

export default InterestListDataTable;
