import { InterestList } from '@api-3/endpoints/interestList';
import CustomScrollbars from '@old-world/utils/CustomScrollbars';
import ChatCommunicationFooter from '@routes-3/main/chat/components/ChatCommunicationFooter';
import ChatCommunicationHeader from '@routes-3/main/chat/components/ChatCommunicationHeader';
import Conversation from '@routes-3/main/chat/components/Conversation';
import { Modal } from 'antd';
import React from 'react';

type Props = {
  interestList: InterestList;
  onClose: () => void;
};

const MessagesModal = ({ onClose, interestList }: Props) => {
  return (
    <Modal
      destroyOnClose
      open={true}
      title={
        <ChatCommunicationHeader
          interestList={interestList}
          withoutBorder={true}
        />
      }
      centered
      onCancel={onClose}
      footer={
        <ChatCommunicationFooter selectedInterestGameId={interestList.id} />
      }
    >
      <CustomScrollbars className="gx-chat-list-scroll scroll-modal">
        <Conversation selectedInterestGameId={interestList.id} />
      </CustomScrollbars>
    </Modal>
  );
};

export default MessagesModal;
