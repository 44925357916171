import { ListEventItem } from '@api-3/endpoints/festivals';
import EventCard from '@routes-3/main/tournamentsManagement/events/components/EventCard';
import { Card, Col } from 'antd';

const EventsCards = ({ events }: { events?: ListEventItem[] }) => {
  return (
    <Card
      className="gx-layouts-view"
      style={{ height: 'calc(100vh - 347px )', overflowY: 'scroll' }}
    >
      <div className="row">
        {events &&
          events.map((event, index) => (
            <Col key={index} xl={6} lg={8} md={8} sm={12} xs={24}>
              <EventCard event={event} />
            </Col>
          ))}
      </div>
    </Card>
  );
};

export default EventsCards;
