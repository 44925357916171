import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import moment from 'moment';

// EN
import translationCommonEng from './common/locales/en/translation.json';
// FR
import translationCommonFr from './common/locales/fr/translation.json';
import translationAccountEng from './features/account/locales/en/translation.json';
import translationAccountFr from './features/account/locales/fr/translation.json';
import translationAuthenticationEng from './features/authentication/locales/en/translation.json';
import translationAuthenticationFr from './features/authentication/locales/fr/translation.json';
import translationCashGamesEng from './features/cashgames/locales/en/translation.json';
import translationCashGamesFr from './features/cashgames/locales/fr/translation.json';
import translationEventsEng from './features/events/locales/en/translation.json';
import translationEventsFr from './features/events/locales/fr/translation.json';
import translationTournamentsEng from './features/events/sub-domains/locales/en/translation.json';
import translationTournamentsFr from './features/events/sub-domains/locales/fr/translation.json';
import translationSettingsEng from './features/settings/locales/en/translation.json';
import translationSettingsFr from './features/settings/locales/fr/translation.json';
import translationUsersEng from './features/users/locales/en/translation.json';
import translationUsersFr from './features/users/locales/fr/translation.json';
import enLocale from './i18n/locales/en.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        if (value instanceof moment) return moment(value).format(format);
        return value;
      }
    },

    resources: {
      en: {
        translations: {
          ...translationCommonEng,
          ...translationAuthenticationEng,
          ...translationSettingsEng,
          ...translationEventsEng,
          ...translationTournamentsEng,
          ...translationUsersEng,
          ...translationCashGamesEng,
          ...translationAccountEng,
          ...enLocale
        }
      },
      fr: {
        translations: {
          ...translationCommonFr,
          ...translationAuthenticationFr,
          ...translationSettingsFr,
          ...translationEventsFr,
          ...translationTournamentsFr,
          ...translationUsersFr,
          ...translationCashGamesFr,
          ...translationAccountFr
        }
      }
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations'
  });

export default i18n;
