import { useCreateTableMutation } from '@api-3/endpoints/runningGame';
import TableFieldArray, {
  TableFormShape
} from '@components/forms/TableFieldArray';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import { Button, Form, message, Modal } from 'antd';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
};

const AddTableToMapModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const casinoId = useSelector(currentCasinoSelector);
  const [createTable, { isLoading, isError }] = useCreateTableMutation();

  const formMethods = useForm<{
    tables: TableFormShape[];
  }>({
    defaultValues: {
      tables: [
        {
          tableId: '1',
          maxPlayers: 8
        }
      ]
    }
  });

  const handleSubmit = formMethods.handleSubmit;
  const onSubmit = async (data: { tables: TableFormShape[] }) => {
    try {
      for (const table of data.tables) {
        await createTable({
          casinoId,
          ...table
        }).unwrap();
      }
      onClose();
    } catch (e) {
      const { status } = e as { status: number };
      // we have a conflict with the table id
      if (status == 409) {
        const {
          data: { tableId }
        } = e as { data: { tableId: string } };
        const index = data.tables.findIndex(t => t.tableId == tableId);
        // @ts-ignore
        formMethods.setError(`tables[${index}].tableId`, {
          message: 'Id is already taken'
        });
      } else {
        message.error('An error has occurred');
      }
    }
  };

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={t('ADD_NEW_TABLE_HEADER').toLocaleUpperCase()}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('CANCEL_BUTTON')}
        </Button>,
        <Button
          key="submit"
          className={'gx-btn'}
          type="default"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('ADD_BUTTON')}
        </Button>
      ]}
      onCancel={onClose}
      cancelText={t('CANCEL_BUTTON')}
      okText={t('ADD_BUTTON')}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      onOk={handleSubmit(onSubmit)}
      closable={false}
    >
      {/* @ts-ignore */}
      <FormProvider {...formMethods}>
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
          <TableFieldArray control={formMethods.control} totalTableCount={1} />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddTableToMapModal;
