import TableForm from '@components/forms/TableForm';
import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';

export type TableFormShape = {
  tableId: string;
  maxPlayers: number;
};

function TablesArray({
  fields,
  totalTableCount,
  remove,
  append
}: {
  fields: TableFormShape[];
  totalTableCount: number;
  remove: (idx: number) => void;
  append: (values: TableFormShape) => void;
}) {
  return (
    <div>
      {fields.map((table: TableFormShape, index: number) => {
        return (
          <TableForm
            key={index}
            index={index}
            fields={fields}
            remove={remove}
          />
        );
      })}
      <p
        className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
        onClick={() => {
          append({
            tableId: (fields.length + 1 + totalTableCount).toString(),
            maxPlayers: 8
          });
        }}
      >
        <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle mr-1 py-2" />
        Add another table
      </p>
    </div>
  );
}

const TableFieldArray = ({
  control,
  totalTableCount
}: {
  control: Control<{ tables: TableFormShape[] }>;
  totalTableCount: number;
}) => {
  const { fields, append, remove } = useFieldArray<{
    tables: TableFormShape[];
  }>({ control, name: 'tables' });
  return (
    <TablesArray
      totalTableCount={totalTableCount}
      fields={(fields as unknown) as TableFormShape[]}
      append={append}
      remove={remove}
    />
  );
};

export default React.memo(TableFieldArray);
