import { InterestList } from '@api-3/endpoints/interestList';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusColumn = ({ interestList }: { interestList: InterestList }) => {
  const { t } = useTranslation();
  return interestList ? (
    <div
      style={{
        writingMode: 'vertical-lr',
        backgroundColor:
          interestList.state && interestList.gameOrigin === 'CASINO'
            ? '#4D8DCC'
            : interestList.state === 'ACCEPTED'
            ? '#7CC194'
            : interestList.state === 'DECLINED'
            ? '#8A9BA8'
            : '#F18B2D',
        fontSize: 11,
        height: '100%',
        fontWeight: 'bold',
        letterSpacing: 2,
        textAlign: 'center',
        paddingRight: 2
      }}
    >
      {t(
        `${
          interestList.gameOrigin === 'CASINO' ? 'OWNED' : interestList.state
        }_IL`
      ).toUpperCase()}
    </div>
  ) : (
    <span>NO DATA</span>
  );
};

export default StatusColumn;
