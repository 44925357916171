import { useOrderWaitingListMutation } from '@api-3/endpoints/games';
import { useModals } from '@providers/ModalProvider';
import WaitingListItems from '@routes-3/main/cashGameManagement/runningGamesMap/components/WaitingListItems';
import {
  RunningGame,
  WaitingPlayer,
  WaitingPlayerOrder
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetWaitingListForGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetWaitingListForGame';
import {
  Collapse,
  CollapsePanelProps,
  notification,
  Spin,
  Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';

const { Panel } = Collapse;

type PanelWrapperProps = {
  index: number;
  game: RunningGame;
  activeKey: string | string[];
  setActiveKey: (activeKey: string | string[]) => void;
} & CollapsePanelProps;
const WaitingListPanel = (props: PanelWrapperProps) => {
  const openModal = useModals();
  const [isReordering, setIsReordering] = useState(false);
  const truncate = (str: string) => {
    return str.length > 19 ? str.substring(0, 18) + '...' : str;
  };
  const { players: waitingListPlayers } = useGetWaitingListForGame({
    gameId: props.game.liveGameKey
  });
  const [wListPlayers, setWLPlayers] = useState<WaitingPlayer[]>();
  const handleClosePanel = (key: string) => {
    if (Array.isArray(props.activeKey)) {
      const newActiveKeys = props.activeKey.filter(k => k !== key);
      props.setActiveKey(newActiveKeys);
    } else {
      if (props.activeKey === key) props.setActiveKey([]);
    }
  };

  const [
    reorderWaitingList,
    { isLoading: isLoadingReorderWL }
  ] = useOrderWaitingListMutation();

  const reorderWL = async () => {
    try {
      const wl: WaitingPlayerOrder[] = [];
      wListPlayers?.map((player, index) => {
        wl.push({
          casinoId: player.casinoId ? player.casinoId : null,
          casinoName: player.casinoName ? player.casinoName : null,
          currentLatitude: player.currentLatitude,
          currentLongitude: player.currentLongitude,
          flopId: player.flopId,
          gameSize: player.gameSize,
          gameVariant: player.gameVariant,
          name: player.name,
          order: index + 1
        } as WaitingPlayerOrder);
      });
      await reorderWaitingList({
        gameId: props.game.liveGameKey,
        payload: wl
      }).unwrap();
      setIsReordering(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: `An error occurred, while trying to reorder Waiting List. Please Contact your administrator.`,
        placement: 'topRight'
      });
    }
  };

  useEffect(() => {
    if (props.game.waitingPlayers.length === 0) {
      handleClosePanel(props.index.toString());
      if (Array.isArray(props.activeKey)) {
        const newActiveKeys = props.activeKey.filter(
          k => k !== props.index.toString()
        );
        props.setActiveKey(newActiveKeys);
      } else {
        if (props.activeKey === props.index.toString()) props.setActiveKey([]);
      }
    }
  }, [props.game]);
  const toggleReordering = () => {
    setWLPlayers(waitingListPlayers);
    setIsReordering(!isReordering);
  };
  return (
    <Panel
      {...props}
      collapsible={props.game.waitingPlayers.length === 0 ? 'disabled' : 'icon'}
      header={
        <div className={'gx-flex-row w-100'} style={{ fontSize: 12 }}>
          <div>
            <div>
              <strong>{truncate(props.game.gameVariant)}</strong> (
              {props.game.gameSize}
              {props.game.ante ? `/${props.game.ante}` : null})
            </div>
            <div style={{ fontSize: 11, opacity: 0.65 }}>
              {props.game.waitingPlayers.length} waiting
            </div>
            <div style={{ fontSize: 11, opacity: 0.65 }}>
              Expected waiting time: {props.game.expectedWaitingTime ?? 'N/A'}
            </div>
          </div>
          <div className={'gx-flex-row'} style={{ marginLeft: 'auto' }}>
            {props.game.waitingPlayers.length > 0 &&
              (isReordering ? (
                <>
                  <Tooltip title="Cancel" placement="bottom">
                    <i
                      className="icon icon-close-circle gx-text-danger cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                      style={{ fontSize: 22 }}
                      onClick={event => {
                        event.stopPropagation();
                        toggleReordering();
                      }}
                    />
                  </Tooltip>
                  {isLoadingReorderWL ? (
                    <Spin style={{ marginRight: 0, marginLeft: 8 }} />
                  ) : (
                    <Tooltip title="Save" placement="bottom">
                      <i
                        className="icon icon-check-circle-o gx-text-success cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                        style={{ fontSize: 22 }}
                        onClick={event => {
                          event.stopPropagation();
                          reorderWL();
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              ) : (
                <Tooltip title="Reorder Waiting List" placement="bottom">
                  <i
                    className="icon icon-collapse gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                    style={{ fontSize: 18 }}
                    onClick={event => {
                      event.stopPropagation();
                      toggleReordering();
                    }}
                  />
                </Tooltip>
              ))}
            {!isReordering && (
              <>
                <Tooltip
                  title="Set expected waiting time shown to the first 3 players"
                  placement="bottom"
                >
                  <i
                    className="icon icon-timepicker gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                    style={{ fontSize: 19, position: 'relative', top: 1 }}
                    onClick={event => {
                      event.stopPropagation();
                      openModal({
                        type: 'EXPECTED_WAITING_TIME',
                        props: {
                          gameId: props.game.liveGameKey,
                          expectedWaitingTime: props.game.expectedWaitingTime
                        }
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Add Player to Waiting List" placement="bottom">
                  <i
                    className="icon icon-add-circle gx-text-primary cursor-pointer gx-fs-lg gx-d-inline-flex gx-vertical-align-middle ml-2"
                    style={{ fontSize: 22 }}
                    onClick={event => {
                      event.stopPropagation();
                      openModal({
                        type: 'ADD_PLAYERS',
                        props: {
                          game: props.game
                          // subGroupId: props.game?.subgroupId
                        }
                      });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      }
      className={'waiting-list-panel'}
    >
      <WaitingListItems
        gameId={props.game.liveGameKey}
        isReordering={isReordering}
        waitingListPlayers={waitingListPlayers}
        wListPlayers={wListPlayers}
        setWLPlayers={setWLPlayers}
      />
    </Panel>
  );
};

export default WaitingListPanel;
