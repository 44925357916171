import { ReactComponent as NotificationIcon } from '@assets/svgs/notification_blue.svg';
import AddButton from '@components/AddButton';
import AdditionButton from '@components/AdditionButton';
import Description from '@features/notifyPlayers/components/Description';
import { Button, Popover } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PickGameVariantsModal from '../PickGameVariantsModal/PickGameVariantsModal';

export default function ModifyGameVariants(props: {
  defaults: { gameVariant: string };
  gameVariants: string[];
  onChange: (gameVariant: string[]) => void;
}) {
  const [variantPickVisible, setVariantPickVisible] = useState(false);
  const { t } = useTranslation();

  function AdditionalGameVariantsLabel() {
    return (
      <div className="d-flex flex-row align-items-center justify-content-flex-start mb-3">
        <div>
          <span className="mb-0" style={{ fontSize: 14, fontWeight: 400 }}>
            {t('PICK_ADDITIONAL_GAME_VARIANTS')}
          </span>
          <Popover
            placement="bottom"
            content={Description(
              'FLOP users with game preferences that match these additional game variants will also be notified.'
            )}
          >
            <NotificationIcon style={{ marginLeft: 8 }} />
          </Popover>
        </div>
        <AddButton
          value={t('ADD_GAME_VARIANTS')}
          onClick={() => {
            setVariantPickVisible(true);
          }}
        />
      </div>
    );
  }

  return (
    <>
      <AdditionalGameVariantsLabel />
      <div className="d-flex flex-wrap align-items-center justify-content-flex-start">
        <Button className="gamesize-btn" style={{ margin: 5 }}>
          {props.defaults.gameVariant}
        </Button>
        {props.gameVariants.map(gameVariant => (
          <AdditionButton
            text={gameVariant}
            onClickCross={() => {
              props.onChange(
                props.gameVariants.filter(
                  variantItem => variantItem !== gameVariant
                )
              );
            }}
          />
        ))}
        <PickGameVariantsModal
          gameVariant={props.defaults.gameVariant}
          initialGameVariants={props.gameVariants}
          onAdd={props.onChange}
          visible={variantPickVisible}
          setVisible={setVariantPickVisible}
        />
      </div>
    </>
  );
}
