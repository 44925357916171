import { PlayerProfile } from '@api-3/endpoints/player';
import PlayerName, { getPlayerName } from '@components-3/PlayerName';
import { WaitingPlayer } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import useGetGame from '@routes-3/main/cashGameManagement/runningGamesMap/useGetGame';
import { Modal, Space } from 'antd';
import { Radio } from 'antd';
import React, { useState } from 'react';

type Props = {
  gameId: string;
  player: WaitingPlayer & PlayerProfile;
  onClose: () => void;
  onChose: (tableId: string) => void;
};

const ChoseTableAndSitModal = ({ onClose, gameId, onChose, player }: Props) => {
  const { game } = useGetGame({ gameId });
  const firstTableWithSpace = game?.tables.find(table => {
    const maxPlayers = table.maxPlayers;
    const currentPlayers = table.players.length;
    return maxPlayers - currentPlayers;
  });
  const [tableId, setTableId] = useState<string | undefined>(
    firstTableWithSpace?.id
  );

  return (
    <Modal
      open={true}
      centered
      title={`Seat Player - ${game?.gameVariant} ${game?.gameSize}${
        game?.ante ? `/${game.ante}` : ``
      }  -  ${getPlayerName(player.firstName, player.lastName)}`}
      onCancel={onClose}
      onOk={() => {
        tableId && onChose(tableId);
        onClose();
      }}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      okText={'Seat'}
      closable={true}
    >
      <Radio.Group
        defaultValue={tableId}
        size="large"
        className={'m-0 p-0 d-flex align-items-center flex-column'}
      >
        <Space direction="vertical">
          {game?.tables?.map(table => {
            const maxPlayers = table.maxPlayers;
            const currentPlayers = table.players.length;
            const freeSeats = maxPlayers - currentPlayers;
            const isFull = freeSeats <= 0;
            return (
              <Radio.Button
                disabled={isFull}
                onClick={() => setTableId(table.id)}
                value={table.id}
                className="w-100"
              >
                <span className="font-weight-bold" style={{ fontSize: 20 }}>
                  Table {table.tableId}
                </span>{' '}
                ({isFull ? 'full' : freeSeats})
              </Radio.Button>
            );
          })}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default ChoseTableAndSitModal;
