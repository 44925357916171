import TableFieldArray, {
  TableFormShape
} from '@components/forms/TableFieldArray';
import { Row } from 'antd';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type FormShape = {
  tables: TableFormShape[];
};

const StartingInterestListComp = ({
  availablePlayers
}: {
  availablePlayers: number | undefined;
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<{
    tables: TableFormShape[];
  }>();

  return (
    <>
      <Row
        justify="space-between"
        className="mb-2 text-accent3 align-items-center"
      >
        <strong>{t('AVAILABLE_PLAYERS_NO_SEAT')}</strong>{' '}
        <span className="font20">{availablePlayers}</span>
      </Row>
      <p className="mb-4">{t('FILL_TABLE_TEXT')}</p>
      <TableFieldArray control={control} totalTableCount={0} />
    </>
  );
};

export default React.memo(StartingInterestListComp);
