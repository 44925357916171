export function convertToNumber<T>(
  value: string | undefined,
  defaultReturn: T
) {
  if (!value) {
    return value || defaultReturn;
  }
  const unit = value[value.length - 1];
  const parseValue = parseInt(value);
  if (unit == 'k') {
    return parseValue * 1_000;
  }

  if (unit == 'm') {
    return parseValue * 1000_000;
  }

  return parseValue;
}

export function formatBuyIn(number: number) {
  const size = Math.floor(Math.log10(Math.abs(number)));
  let adjustedSize = 2;
  let unit = 'k';
  if (size <= 2) {
    return number;
  }
  if (size >= 6) {
    adjustedSize = 5;
    unit = 'm';
  }
  const multiplier = 10 ** adjustedSize;
  return Math.floor(number / multiplier) / 10 + unit;
}
