import { useGetRunningGamesQuery } from '@api-3/endpoints/games';
import { currentCasinoSelector } from '@redux-3/AuthenticationRedux';
import ExistingGameListItem from '@routes-3/main/cashGameManagement/runningGamesMap/forms/ExistingGameListItem';
import { RunningGame } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import { List } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

const ExistingGamesList = ({
  selectedExistingGameIndex,
  setSelectedExistingGameIndex
}: {
  selectedExistingGameIndex: number | undefined;
  setSelectedExistingGameIndex: (index: number) => void;
}) => {
  const casinoId = useSelector(currentCasinoSelector);
  const { data } = useGetRunningGamesQuery({
    queryParams: {
      page: 1,
      size: 40,
      casinoId
    }
  });
  const runningGames: RunningGame[] = data?.content ?? [];

  return (
    <List
      style={{ height: 378, overflowY: 'scroll' }}
      className="game-list-items"
      itemLayout="horizontal"
      dataSource={runningGames ? runningGames : []}
      locale={{ emptyText: <div /> }}
      renderItem={(item, index) =>
        runningGames &&
        runningGames.length > 0 && (
          <List.Item>
            <ExistingGameListItem
              game={item}
              index={index}
              selectedGameIndex={selectedExistingGameIndex}
              setSelectedGameIndex={setSelectedExistingGameIndex}
            />
          </List.Item>
        )
      }
    />
  );
};

export default ExistingGamesList;
