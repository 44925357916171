import './AnnouncementDataTable.scss';

import { ListAnnouncementItem } from '@api-3/endpoints/Announcements';
import { ColumnObjType } from '@routes-3/main/cashGameManagement/interestList/types';
import AnnouncementActionColumn from '@routes-3/main/myPokerRoom/announcements/components/column/AnnouncementActionColumn';
import AnnouncementDateColumn from '@routes-3/main/myPokerRoom/announcements/components/column/AnnouncementDateColumn';
import AnnouncementPublishColumn from '@routes-3/main/myPokerRoom/announcements/components/column/AnnouncementPublishColumn';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React from 'react';

enum ANNOUNCEMENT_LIST_COLUMNS {
  PUBLISH = 'publish',
  TITLE = 'title',
  DESCRIPTION = 'description',
  STARTING_DATE = 'startingDate',
  ENDING_DATE = 'endingDate',
  ACTION = 'action'
}

const AnnouncementsTable = ({
  announcements
}: {
  announcements: ListAnnouncementItem[];
}) => {
  const announcementColumns: ColumnProps<any>[] = [
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.PUBLISH,
      title: '',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(ANNOUNCEMENT_LIST_COLUMNS.PUBLISH, announcement);
      }
    },
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.TITLE,
      title: 'Title',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(ANNOUNCEMENT_LIST_COLUMNS.TITLE, announcement);
      }
    },
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.DESCRIPTION,
      title: 'Description',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(
          ANNOUNCEMENT_LIST_COLUMNS.DESCRIPTION,
          announcement
        );
      }
    },
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.STARTING_DATE,
      title: 'Starting Date',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(
          ANNOUNCEMENT_LIST_COLUMNS.STARTING_DATE,
          announcement
        );
      }
    },
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.ENDING_DATE,
      title: 'Ending Date',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(
          ANNOUNCEMENT_LIST_COLUMNS.ENDING_DATE,
          announcement
        );
      }
    },
    {
      key: ANNOUNCEMENT_LIST_COLUMNS.ACTION,
      title: '',
      render: (announcement: ListAnnouncementItem) => {
        return renderColumnObj(ANNOUNCEMENT_LIST_COLUMNS.ACTION, announcement);
      }
    }
  ];
  return (
    <Table
      className="gx-table w-100 announcements-list-table"
      dataSource={announcements}
      size={'large'}
      columns={announcementColumns}
      rowKey={(record: any) => record.id}
    />
  );

  function renderColumnObj(
    column: ANNOUNCEMENT_LIST_COLUMNS,
    announcement: ListAnnouncementItem
  ) {
    const columnObj: ColumnObjType = {
      children: <React.Fragment />,
      props: {}
    };
    switch (column) {
      case ANNOUNCEMENT_LIST_COLUMNS.PUBLISH:
        columnObj.children = (
          <AnnouncementPublishColumn publish={announcement.publish} />
        );
        break;
      case ANNOUNCEMENT_LIST_COLUMNS.TITLE:
        columnObj.children = <div>{announcement.title}</div>;
        break;
      case ANNOUNCEMENT_LIST_COLUMNS.DESCRIPTION:
        columnObj.children = (
          <div style={{ maxWidth: 400 }}>{announcement.description}</div>
        );
        break;
      case ANNOUNCEMENT_LIST_COLUMNS.STARTING_DATE:
        columnObj.children = (
          <AnnouncementDateColumn date={announcement.startingDate} />
        );
        break;
      case ANNOUNCEMENT_LIST_COLUMNS.ENDING_DATE:
        columnObj.children = (
          <AnnouncementDateColumn date={announcement.endingDate} />
        );
        break;
      case ANNOUNCEMENT_LIST_COLUMNS.ACTION:
        columnObj.children = (
          <AnnouncementActionColumn announcement={announcement} />
        );
        break;
    }

    return columnObj;
  }
};

export default AnnouncementsTable;
