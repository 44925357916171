import _ from 'lodash';

export default function useMapProfileResponses<T>({
  secondary,
  primary,
  key = 'flopId'
}: {
  secondary: { id: number }[];
  primary: { flopId?: number; id: string | number }[];
  key?: string;
}) {
  const playerMap = _.groupBy(secondary ?? [], 'id');
  return ((primary?.map(profile => ({
    ...profile,
    // @ts-ignore
    ...(profile[key] ? playerMap?.[profile[key]]?.[0] : {}),
    id: profile.id
  })) || []) as unknown) as T;
}
