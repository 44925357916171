import '@components-3/Editor/editorStyles.less';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import createMentionEntities from '@components-3/Editor/mentions';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton
} from '@draft-js-plugins/buttons';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import createMentionPlugin, {
  defaultSuggestionsFilter
} from '@draft-js-plugins/mention';
import { MentionData } from '@draft-js-plugins/mention';
import createStaticToolbarPlugin, {
  Separator
} from '@draft-js-plugins/static-toolbar';
import React, { Component } from 'react';

import buttonStyles from './buttonStyles.module.css';
import Entry from './Entry';
import mentionStyles from './mention..module.css';
import toolbarStyles from './toolbarStyles.module.css';

interface SimpleEditorProps {
  defaultText: string;
  disabled: boolean;
  onSend: (htmlContent: string) => void;
  onChange: (htmlContent: string) => void;
  mentions: MentionData[];
}

interface SimpleEditorState {
  editorState: any;
  mentionOpen: boolean;
  suggestions: MentionData[];
}

class SimpleEditor extends Component<SimpleEditorProps, SimpleEditorState> {
  private editor: Editor | null = null;
  private mentionPlugin = createMentionPlugin({
    theme: mentionStyles
  });

  private emojiPlugin = createEmojiPlugin();

  private staticToolbarPlugin = createStaticToolbarPlugin({
    theme: { buttonStyles, toolbarStyles }
  });

  private MentionSuggestions = this.mentionPlugin.MentionSuggestions;
  private Toolbar = this.staticToolbarPlugin.Toolbar;
  private Emoji = this.emojiPlugin.EmojiSelect;

  constructor(props: SimpleEditorProps) {
    super(props);

    this.state = {
      editorState: createMentionEntities(props.defaultText, props.mentions),
      mentionOpen: false,
      suggestions: props.mentions
    };
  }

  componentDidUpdate(prevProps: SimpleEditorProps) {
    const contentState = this.state.editorState.getCurrentContent();
    const htmlContent = contentState.getPlainText();
    if (htmlContent !== this.props.defaultText && this.props.disabled) {
      this.setState({
        editorState: createEditorStateWithText(this.props.defaultText)
      });
    }
  }

  onChange = (editorState: any) => {
    this.setState({ editorState });
    const contentState = editorState.getCurrentContent();
    const htmlContent = contentState.getPlainText();
    this.props.onChange(htmlContent);
  };

  focus = () => {
    if (this.editor) {
      this.editor.focus();
    }
  };

  handleSend = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const htmlContent = contentState.getPlainText();
    this.props.onSend(htmlContent);
  };

  handleBeforeInput = (chars: string) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    if (currentText.length + chars.length > 200) {
      return 'handled';
    }
    return 'not-handled';
  };

  handleReturn = () => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    let lineBreaks = 0;
    blockMap.forEach((block: any) => {
      if (block.getType() === 'unstyled') {
        lineBreaks++;
      }
    });
    if (lineBreaks >= 3) {
      return 'handled';
    }

    return 'not-handled';
  };

  render() {
    const MentionSuggestions = this.MentionSuggestions;
    const Toolbar = this.Toolbar;
    const Emoji = this.Emoji;
    return (
      <div>
        <div className="editor" onClick={this.focus}>
          {/*@ts-ignore*/}
          <Editor
            readOnly={this.props.disabled}
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={[
              this.staticToolbarPlugin,
              this.emojiPlugin,
              this.mentionPlugin
            ]}
            ref={(element: Editor) => {
              this.editor = element;
            }}
            handleBeforeInput={this.handleBeforeInput}
            handleReturn={this.handleReturn}
          />
          {!this.props.disabled ? (
            // @ts-ignore
            <MentionSuggestions
              open={this.state.mentionOpen}
              onOpenChange={open => {
                this.setState({ mentionOpen: open });
              }}
              entryComponent={Entry}
              suggestions={this.state.suggestions}
              onSearchChange={({ value }) =>
                this.setState({
                  suggestions: defaultSuggestionsFilter(
                    value,
                    this.props.mentions
                  )
                })
              }
            />
          ) : null}
          {/*@ts-ignore*/}
          <Toolbar>
            {externalProps => (
              <div style={{ minHeight: 30 }}>
                {/*@ts-ignore*/}
                {/*@ts-ignore*/}
                <Emoji closeOnEmojiSelect={true} {...externalProps} />
              </div>
            )}
          </Toolbar>
        </div>
      </div>
    );
  }
}

export default SimpleEditor;
