import FreeChair from '@routes-3/main/cashGameManagement/dealerDisplay/components/FreeChair';
import OccupiedChair from '@routes-3/main/cashGameManagement/dealerDisplay/components/OccupiedChair';
import {
  Table,
  TablePlayers
} from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React, { useEffect, useState } from 'react';

type props = {
  pos: any;
  seatNr: number;
  table: Table;
};
const Chair = ({ pos, seatNr, table }: props) => {
  const [player, setPlayer] = useState<TablePlayers | undefined>(
    table.players.find((p: TablePlayers) => seatNr === p.seatId)
  );
  useEffect(() => {
    setPlayer(table.players.find((p: TablePlayers) => seatNr === p.seatId));
  }, [table, seatNr]);

  return (
    <div
      className={'chair-wrapper d-flex flex-column align-items-center'}
      style={{ left: pos.left, top: pos.top }}
    >
      {player ? (
        <OccupiedChair player={player} seatNr={seatNr} table={table} />
      ) : (
        <FreeChair seatNr={seatNr} table={table} />
      )}
    </div>
  );
};

export default Chair;
