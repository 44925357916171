import tableSvg from '@assets/svgs/tables.svg';
import { Card } from 'antd';
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

type AverageTablesFillingRateProps = {
  average: number;
  segments: {
    date: string;
    playerCounter: number;
  }[];
};
const AverageTablesFillingRate = ({
  averageTablesFillingRateData
}: {
  averageTablesFillingRateData: AverageTablesFillingRateProps;
}) => {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const originalDateStr = payload[0].payload.date;
      const date = new Date(originalDateStr);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0, daher addieren wir 1
      const year = date.getUTCFullYear();
      return (
        <div
          style={{
            margin: 0,
            padding: 10,
            borderRadius: 4,
            backgroundColor: 'rgba(255,255,255,0.8)',
            border: '1px solid rgb(204, 204, 204)',
            whiteSpace: 'nowrap'
          }}
        >
          <div style={{ color: '#000000', fontSize: 14 }}>
            {`players: ${payload[0].payload.playerCounter}`}
          </div>
          <div style={{ color: '#fa8c15', fontSize: 12 }}>
            {day}-{month}-{year}
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <Card className={'gx-card-widget gx-card-full'}>
      <div className="gx-actchart gx-px-3 gx-pt-3">
        <div className="ant-row-flex">
          <h2 className="gx-mb-0 gx-fs-xxl gx-font-weight-medium">
            {averageTablesFillingRateData.average}%
          </h2>
          <img src={tableSvg} className={'gx-fs-xl gx-ml-auto gx-fs-xxxl'} />
        </div>
        <p className="gx-mb-0 gx-fs-sm gx-text-grey gx-mb-3">
          Average Tables Filling Rate
        </p>
      </div>
      <ResponsiveContainer width="100%" height={75}>
        <AreaChart
          data={averageTablesFillingRateData.segments}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: 'transparent' }}
          />
          <defs>
            <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
              <stop offset="5%" stopColor="#163469" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#FE9E15" stopOpacity={0.9} />
            </linearGradient>
          </defs>
          <Area
            dataKey="playerCounter"
            strokeWidth={0}
            stackId="2"
            stroke="#4D95F3"
            fill="url(#color3)"
            fillOpacity={1}
            xAxisId={'name'}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default AverageTablesFillingRate;
