import { AInput, ASelect } from '@components/AntdSimpleFieldForReactFormHooks';
import GameVariant from '@constants/gamevariants';
import { GameVariantType } from '@old-world/common/redux/ParametersModel.d';
import { Button, List, Modal, Select } from 'antd';
import React from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';

export default function OfferedGameRow({
  index,
  onRemove,
  showVariable,
  onSave
}: {
  index: number;
  onRemove: (index: number) => void;
  showVariable: boolean;
  onSave: () => void;
}) {
  const { t } = useTranslation();
  const isEditing = useWatch({
    name: `games.${index}._editing`,
    defaultValue: false
  });
  const { setValue } = useFormContext();
  const setIsEditing = (v: boolean) => {
    setValue(`games.${index}._editing`, v);
  };
  const setIsNew = (v: boolean) => {
    setValue(`games.${index}.new`, v);
  };
  const variantController = useController({
    name: `games.${index}.variant`,
    rules: { required: true }
  });
  const smallBlindController = useController({
    name: `games.${index}.smallBlind`,
    rules: { required: true }
  });
  const bigBlindController = useController({
    name: `games.${index}.bigBlind`,
    rules: { required: true }
  });
  const anteController = useController({
    name: `games.${index}.ante`
  });

  const bigBlind = useWatch({ name: `games.${index}.bigBlind` });
  const smallBlind = useWatch({ name: `games.${index}.smallBlind` });
  const ante = useWatch({ name: `games.${index}.ante` });
  const variant = useWatch({ name: `games.${index}.variant` });
  const isNew = useWatch({ name: `games.${index}.new` });

  return (
    <List.Item
      style={{
        borderTop: isEditing && isNew ? '1px solid #ffffff' : '',
        marginTop: isEditing && isNew ? 20 : 0
      }}
      className={`${showVariable ? 'hasVariable' : 'hasNoVariable'}`}
      actions={
        isEditing
          ? [
              <Button
                className={'text-error border-error'}
                shape="circle"
                icon={<AiOutlineClose />}
                onClick={() => {
                  if (bigBlind && smallBlind && variant) {
                    setIsEditing(false);
                  } else {
                    onRemove(index);
                  }
                }}
              />,
              <Button
                className={'text-green border-green'}
                shape="circle"
                icon={<AiOutlineCheck />}
                onClick={() => {
                  setIsEditing(false);
                  setIsNew(false);
                  onSave();
                }}
              />
            ]
          : [
              <Button
                className={'gx-text-white'}
                id="editGameButton"
                shape="circle"
                type="link"
                icon={<AiOutlineEdit />}
                onClick={() => setIsEditing(true)}
              />,
              <Button
                className={'gx-text-white'}
                id="deleteGameButton"
                shape="circle"
                type="link"
                icon={<RiDeleteBinLine />}
                onClick={() =>
                  Modal.confirm({
                    title: t('SETTINGS_REMOVE_GAME_CONFIRMATION_TEXT'),
                    okText: t('YES_BUTTON'),
                    okType: 'danger',
                    cancelText: t('NO_BUTTON'),
                    onOk: () => onRemove(index)
                  })
                }
              />
            ]
      }
    >
      <div className={`w-100 gx-text-white`}>
        {showVariable && !isNew && (
          <div className={'gx-text-bold mb-3'}>{`${variant}`}:</div>
        )}
        {isEditing ? (
          <>
            {isNew && (
              <div
                className={'mb-1'}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                <ASelect
                  controller={variantController}
                  className={'mb-1'}
                  label={'Game Variant'}
                >
                  {GameVariant.map((c: GameVariantType, index: number) => (
                    <Select.Option key={index} value={c.shortName}>
                      {c.label}
                    </Select.Option>
                  ))}
                </ASelect>
              </div>
            )}
            <div
              className={'row game-size-wrapper'}
              style={{
                marginLeft: 5,
                marginRight: 5,
                paddingTop: variant ? '17px' : '0px'
              }}
            >
              <div className={'col-4'}>
                <AInput
                  className={'w-100'}
                  label={''}
                  id="smallBlind"
                  name="smallBlind"
                  placeholder={'SB'}
                  hasFeedback
                  controller={smallBlindController}
                />
              </div>
              <div className={'col-4'}>
                <AInput
                  className={'w-100'}
                  label={''}
                  id="bigBlind"
                  name="bigBlind"
                  placeholder={'BB'}
                  hasFeedback
                  controller={bigBlindController}
                />
              </div>
              <div className={'col-4'}>
                <AInput
                  className={'w-100'}
                  label={''}
                  id="ante"
                  name="ante"
                  placeholder={'Ante'}
                  hasFeedback
                  controller={anteController}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={'ml-4'}>
            {`${smallBlind}/${bigBlind}`}
            {ante && `/${ante}`}
          </div>
        )}
        {/*{variant && !isEditing && showAddNewSize && (*/}
        {/*  <Button*/}
        {/*    id="addGameButton"*/}
        {/*    type="link"*/}
        {/*    icon={<AiOutlinePlus />}*/}
        {/*    onClick={() => {*/}
        {/*      onAppend({*/}
        {/*        variant: variant,*/}
        {/*        smallBlind: '',*/}
        {/*        bigBlind: '',*/}
        {/*        ante: '',*/}
        {/*        _editing: true,*/}
        {/*        new: false*/}
        {/*      });*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Add new game size*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    </List.Item>
  );
}
