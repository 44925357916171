import { SubGroup } from '@api-3/endpoints/subGroup';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import { ColumnObjType } from '@routes-3/main/cashGameManagement/interestList/types';
import SubGroupActionColumn from '@routes-3/main/playersDatabase/sub-groups/components/columns/SubGroupActionColumn';
import SubGroupPlayersColumn from '@routes-3/main/playersDatabase/sub-groups/components/columns/SubGroupPlayersColumn';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import React from 'react';

enum SUB_GROUPS_LIST_COLUMNS {
  VARIANT = 'variant',
  SIZE = 'size',
  BUY_IN = 'buyIn',
  PLAYERS = 'players',
  ACTION = 'action'
}

const SubGroupsTable = ({ subGroups }: { subGroups: SubGroup[] }) => {
  const subGroupsListColumns: ColumnProps<any>[] = [
    {
      key: SUB_GROUPS_LIST_COLUMNS.VARIANT,
      title: 'Game Variant',
      render: (subGroup: SubGroup) => {
        return renderColumnObj(SUB_GROUPS_LIST_COLUMNS.VARIANT, subGroup);
      }
    },
    {
      key: SUB_GROUPS_LIST_COLUMNS.SIZE,
      title: 'Game Size',
      render: (subGroup: SubGroup) => {
        return renderColumnObj(SUB_GROUPS_LIST_COLUMNS.SIZE, subGroup);
      }
    },
    {
      key: SUB_GROUPS_LIST_COLUMNS.BUY_IN,
      title: 'Buy-In (Min - Max)',
      render: (subGroup: SubGroup) => {
        return renderColumnObj(SUB_GROUPS_LIST_COLUMNS.BUY_IN, subGroup);
      }
    },
    {
      key: SUB_GROUPS_LIST_COLUMNS.PLAYERS,
      title: 'Players',
      render: (subGroup: SubGroup) => {
        return renderColumnObj(SUB_GROUPS_LIST_COLUMNS.PLAYERS, subGroup);
      }
    },
    {
      key: SUB_GROUPS_LIST_COLUMNS.ACTION,
      title: '',
      render: (subGroup: SubGroup) => {
        return renderColumnObj(SUB_GROUPS_LIST_COLUMNS.ACTION, subGroup);
      }
    }
  ];

  function renderColumnObj(
    column: SUB_GROUPS_LIST_COLUMNS,
    subGroup: SubGroup
  ) {
    const columnObj: ColumnObjType = {
      children: <React.Fragment />,
      props: {}
    };
    switch (column) {
      case SUB_GROUPS_LIST_COLUMNS.VARIANT:
        columnObj.children = (
          <span className={'ellipsis'}>
            {subGroup.gameVariant ? subGroup.gameVariant : 'NO DATA'}
          </span>
        );
        break;
      case SUB_GROUPS_LIST_COLUMNS.SIZE:
        columnObj.children = (
          <span>
            {subGroup.smallBlind} / {subGroup.bigBlind}{' '}
            {subGroup.ante && `/${subGroup.ante}`}
          </span>
        );
        break;
      case SUB_GROUPS_LIST_COLUMNS.BUY_IN:
        columnObj.children = (
          <div>
            {subGroup.minBuyIn ? formatBuyIn(subGroup.minBuyIn) : 'NO'} -{' '}
            {subGroup.maxBuyIn ? formatBuyIn(subGroup.maxBuyIn) : 'NO'}
          </div>
        );
        break;
      case SUB_GROUPS_LIST_COLUMNS.PLAYERS:
        columnObj.children = <SubGroupPlayersColumn subGroup={subGroup} />;
        break;
      case SUB_GROUPS_LIST_COLUMNS.ACTION:
        columnObj.children = <SubGroupActionColumn subGroup={subGroup} />;
        break;
    }

    return columnObj;
  }

  return (
    <Table
      className="gx-table w-100 "
      dataSource={subGroups}
      columns={subGroupsListColumns}
      size={'large'}
      rowKey={(record: any) => record.id}
    />
  );
};

export default SubGroupsTable;
