import flopLogo from '@assets-3/images/flop-pm-logo.png';
import NewPasswordForm from '@routes-3/userAuth/newPassword/NewPasswordForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NewPasswordPage = () => {
  const [t] = useTranslation();
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div>Welcome on Flop PM</div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">
                {t('NEW_PASSWORD_MAIN_TITLE')}
              </h1>
              <h6>{t('NEW_PASSWORD_TITLE')}</h6>
            </div>
            <NewPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewPasswordPage;
