import { SubGroup, useDeleteSubGroupMutation } from '@api-3/endpoints/subGroup';
import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModals } from '@providers/ModalProvider';
import { Modal, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const SubGroupActionColumn = ({ subGroup }: { subGroup: SubGroup }) => {
  const { t } = useTranslation();
  const openModal = useModals();
  const [deleteSubGroup] = useDeleteSubGroupMutation();
  return (
    <Row>
      <Tooltip title={'Edit Sub-Group'} placement="bottom">
        <FontAwesomeIcon
          style={{ fontSize: 18 }}
          className={'gx-text-primary cursor-pointer mr-3'}
          icon={faPen}
          onClick={() =>
            openModal({
              type: 'EDIT_SUB_GROUP',
              props: { subGroupId: subGroup.id }
            })
          }
        />
      </Tooltip>
      {/*// sub group deletion not working!*/}
      {/*<Tooltip title={'Remove Sub-Group'} placement="bottom">*/}
      {/*  <FontAwesomeIcon*/}
      {/*    style={{ fontSize: 18 }}*/}
      {/*    className={'gx-text-danger cursor-pointer'}*/}
      {/*    icon={faTrashCan}*/}
      {/*    onClick={() => {*/}
      {/*      Modal.confirm({*/}
      {/*        title:*/}
      {/*          'Are you sure you want to remove this announcement? This action can’t be undone.',*/}
      {/*        okText: t('REMOVE_BUTTON'),*/}
      {/*        okType: 'danger',*/}
      {/*        cancelText: t('NO_BUTTON'),*/}
      {/*        async onOk() {*/}
      {/*          await deleteSubGroup({ subGroupId: subGroup.id });*/}
      {/*        }*/}
      {/*      });*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Tooltip>*/}
    </Row>
  );
};

export default SubGroupActionColumn;
