import {
  useAddNewPlayerToTableMutation,
  useAddPlayerToWaitingListMutation,
  useGetWaitingListQuery,
  useRemovePlayerFromWaitingListMutation
} from '@api-3/endpoints/games';
import { useAddPlayerToInterestListMutation } from '@api-3/endpoints/interestList';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';

export function usePlacePlayer({
  isInterestList,
  table,
  gameId
}: {
  isInterestList?: boolean;
  table?: Table;
  gameId: string;
}) {
  const { data: waitingListPlayers } = useGetWaitingListQuery(
    {
      gameId,
      size: 200
    },
    { skip: !gameId }
  );
  const [
    addNewPlayer,
    { isLoading: isLoadingAddNewPlayer }
  ] = useAddNewPlayerToTableMutation();
  const [
    addToWaitingList,
    { isLoading: isLoadingAddToWL }
  ] = useAddPlayerToWaitingListMutation();
  const [
    addToInterestList,
    { isLoading: isLoadingAddToIL }
  ] = useAddPlayerToInterestListMutation();

  const onSubmit = (data: any) => {
    if (isInterestList && gameId) {
      return addToInterestList({
        gameId,
        accountId: data.flopId,
        name: data.name
      }).unwrap();
    } // If there is a table Id, it's Add Player on Table, else it's Add Player on WL
    else if (table && table?.players?.length < table?.maxPlayers) {
      return addNewPlayer({ tableId: table.id, player: data }).unwrap();
    } // else we put it on the waiting list
    else {
      return addToWaitingList({
        gameId,
        payload: {
          ...data,
          order: waitingListPlayers ? waitingListPlayers.length + 1 : 1
        }
      }).unwrap();
    }
  };

  return {
    onSubmit,
    isSaving: isLoadingAddToWL || isLoadingAddNewPlayer || isLoadingAddToIL
  };
}

export function useRemovePlayer({
  isInterestList,
  table,
  gameId
}: {
  isInterestList: boolean;
  table: Table;
  gameId: string;
}) {
  const [
    removePlayer,
    { isLoading: isSavingRemovePlayer }
  ] = useRemovePlayerFromWaitingListMutation();
  const onSubmit = (data: any) => {
    console.log('data: ', data);
    if (isInterestList) {
      console.log('remove from IL');
    } else if (table && table?.players?.length < table?.maxPlayers) {
      console.log('stand from table');
    } else {
      console.log('remove from WL');
      // return removePlayer({ gameId, id: data.flopId });
    }
  };

  return {
    onSubmit,
    isSaving: isSavingRemovePlayer
  };
}
