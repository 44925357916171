import { takeLatest } from 'redux-saga/effects';

/* ------------- Types ------------- */
/* ------------- Sagas ------------- */
import DebugConfig from '../../../config/DebugConfig';
import { AuthenticationTypes } from '../redux/AuthenticationRedux';
import AuthenticationApi from '../services/AuthenticationApi';
import FixtureAuthenticationApi from '../services/FixtureAuthenticationApi';
import { changeEmail, resendEmail, validateEmail } from './AuthenticationSagas';

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = DebugConfig.useFixtures
  ? FixtureAuthenticationApi
  : AuthenticationApi.create();

// Authentication Saga
const authenticationSaga = [
  takeLatest(AuthenticationTypes.RESEND_EMAIL_REQUEST, resendEmail, api),
  takeLatest(AuthenticationTypes.CHANGE_EMAIL_REQUEST, changeEmail, api),
  takeLatest(AuthenticationTypes.VALIDATE_EMAIL_REQUEST, validateEmail, api)
];

// Export Default
export default authenticationSaga;
