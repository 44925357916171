import { api } from '@api/api';

export interface CasinoInfosDto {
  address?: string;
  alwaysOpen?: boolean;
  city?: string;
  country?: string;
  countryCode?: string;
  description?: string;
  dressCode?: string;
  facebookUrl?: string;
  flopPartner?: boolean;
  geographicalZone?: string;
  getWebSite?: string;
  googlePlusUrl?: string;
  id?: string;
  mailContact?: string;
  mainCurrency?: string | number;
  minimumAgeEntrance?: number; // int32
  name?: string;
  open?: boolean;
  openingDays?: DataApiDefinitions.CasinoOpeningDays[];
  pokerRoom?: boolean;
  position?: DataApiDefinitions.GeoPoint;
  postalCode?: string;
  spokenLanguages?: string;
  tablesNumber?: number; // int32
  telephoneNumber?: string;
  timeZoneStr?: string;
  webSite?: string;
  entryFee?: unknown;
  youtubeUrl?: string;
  resizedUrl?: string;
  establishmentType?: 'CASINO' | 'CLUB';
  offeredGames: unknown[]; //??? FIXME
  reserveAllowedTime: string;
  lat: number;
  lon: number;
  streetNumber: string;
}

export const casinoApi = api
  .enhanceEndpoints({ addTagTypes: ['Casino'] })
  .injectEndpoints({
    endpoints: builder => ({
      casino: builder.query<CasinoInfosDto, { id: string }>({
        providesTags: ['Casino'],
        async queryFn({ id }, _queryApi, _extraOptions, fetchWithBQ) {
          const { data } = (await fetchWithBQ(
            `/datas/api/casinos/${id}`
          )) as any;
          const image = (await fetchWithBQ(`/gallery/api/casino/${id}`)) as any;

          return {
            data: {
              ...data,
              resizedUrl: image?.data?.resizedUrl
            } as CasinoInfosDto
          };
        }
      }),
      updateCasinoLogo: builder.mutation<void, { data: unknown; id: string }>({
        query: ({ id, data }) => ({
          method: 'POST',
          url: `/gallery/api/casino/${id}`,
          body: data,
          headers: {
            // Accept: '*/*',
            // 'Content-Type': 'multipart/form-data;'
          }
        })
      }),
      updateCasino: builder.mutation<
        void,
        { id: string; data: CasinoInfosDto }
      >({
        invalidatesTags: ['Casino'],
        query: ({ id, data }) => ({
          method: 'PUT',
          url: `/datas/api/casinos/${id}`,
          body: data
        })
      }),
      deleteCasino: builder.mutation<void, { casinoId: string }>({
        invalidatesTags: ['Casino'],
        query: ({ casinoId }) => ({
          method: 'DELETE',
          url: `/datas/api/casinos/${casinoId}`
        })
      })
    })
  });

export const {
  useCasinoQuery,
  useUpdateCasinoMutation,
  useUpdateCasinoLogoMutation,
  useDeleteCasinoMutation
} = casinoApi;
