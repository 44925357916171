import { api } from '@api/api';
import { stringify } from 'qs';

export type EstimatedPlayersPayload = {
  authorId: string;
  authorRepresentativeId: string;
  contextId: string;
  entityId: string;
  'queryCriteria.gameSize': unknown[];
  'queryCriteria.gameVariants': unknown[];
  'queryCriteria.latitude': string | number;
  'queryCriteria.longitude': string | number;
  'queryCriteria.maxDistance': string | number;
  'queryCriteria.targetType': string;
  targetingNotificationType: string;
  targetingType: string;
};

export type Count = number;

export const targetingApi = api.injectEndpoints({
  endpoints: builder => ({
    notifyPlayers: builder.mutation<Count, EstimatedPlayersPayload>({
      query: body => ({
        method: 'POST',
        url: `/targeting/api/player`,
        body
      })
    }),
    getEstimatedPlayerCount: builder.query<Count, EstimatedPlayersPayload>({
      query: queryParams =>
        `/targeting/api/player/estimation?${stringify(queryParams)}`
    })
  })
});

export const {
  useGetEstimatedPlayerCountQuery,
  useNotifyPlayersMutation
} = targetingApi;
