import {
  CustomNotification,
  useCustomNotificationMutation,
  useGetCustomNotificationsQuery
} from '@api-3/endpoints/notifications';
import SimpleEditor from '@components-3/Editor/Editor';
import Spinner from '@routes-3/main/myPokerRoom/componenets/Spinner';
import { Button, Card } from 'antd';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineSave } from 'react-icons/ai';

const DEFAULT_MENTIONS = [
  {
    name: '{casino_name}',
    title: "Placeholder for the casino's name"
  },
  {
    name: '{player_name}',
    title: "Placeholder for the player's name"
  },
  {
    name: '{game_variant}',
    title: 'Placeholder for the game variant'
  },
  {
    name: '{game_size}',
    title: 'Placeholder for the game size'
  }
];

const TITLES: { [key: string]: string } = {
  GAME_CALL: 'Call to join a seat from a waiting list.',
  GAME_USER_REMOVED_FROM_WAITING_LIST:
    'User removed from a seat from a waiting list.'
};

const Notification = ({
  notification
}: {
  notification: CustomNotification;
}) => {
  const { t } = useTranslation();
  const [saveNotification, { isLoading }] = useCustomNotificationMutation();

  const defaultValues = { notification: notification.content };
  const formMethods = useForm<{ notification: string }>({
    defaultValues
  });

  async function onSend(data: { notification: string }) {
    return saveNotification({ ...notification, content: data.notification });
  }
  const [isEditing, setIsEditing] = useState(false);
  const onSave = formMethods.handleSubmit(onSend);
  const title = TITLES[notification.type] ?? notification.type;

  return (
    <Card>
      <div>
        <p className="gx-text-grey gx-font-weight-bold gx-text-underline">
          {title}
        </p>
        <SimpleEditor
          onChange={(value: string) =>
            formMethods.setValue('notification', value, { shouldDirty: true })
          }
          mentions={DEFAULT_MENTIONS}
          defaultText={formMethods.getValues('notification')}
          onSend={() => {}}
          disabled={!isEditing}
        />
        <Fragment>
          {!isEditing ? (
            <Button
              type="primary"
              className={'w-100'}
              onClick={() => setIsEditing(true)}
            >
              {t('EDIT_BUTTON')}
            </Button>
          ) : (
            <div className="d-flex w-100">
              <Button
                className={'w-50'}
                type="default"
                onClick={() => {
                  formMethods.reset(defaultValues);
                  setIsEditing(false);
                }}
              >
                {t('CANCEL_BUTTON')}
              </Button>
              <Button
                className={'w-50'}
                key="3"
                type="primary"
                disabled={!formMethods.formState.isDirty}
                icon={<AiOutlineSave />}
                loading={isLoading}
                onClick={() => {
                  onSave().then(() => {
                    if (_.isEmpty(formMethods.formState.errors)) {
                      setIsEditing(false);
                    }
                  });
                }}
              >
                {t('SAVE_BUTTON')}
              </Button>
            </div>
          )}
        </Fragment>
      </div>
    </Card>
  );
};

const NotificationTemplate = () => {
  const { data, isLoading } = useGetCustomNotificationsQuery();

  if (isLoading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  // the api returns unordered data, so we need to sort it by id
  return [...data]
    .sort((a, b) => a?.id - b?.id)
    .map(notification => (
      <Notification notification={notification} key={notification.id} />
    ));
};

export default NotificationTemplate;
