import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { useModals } from '@providers/ModalProvider';
import { Table } from '@routes-3/main/cashGameManagement/runningGamesMap/types';
import React from 'react';

const FreeChair = ({ table, seatNr }: { seatNr: number; table: Table }) => {
  const openModal = useModals();
  return (
    <div
      className="chair"
      style={{ opacity: 0.7 }}
      onClick={() =>
        openModal({
          type: 'DEALER_ACTION_MODAL',
          props: {
            table: table,
            seatNr: seatNr
          }
        })
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <UserOutlined style={{ color: '#fff', fontSize: 22 }} />
        <span className={'gx-font-weight-heavy'}>{seatNr}</span>
      </div>
    </div>
  );
};

export default FreeChair;
