// Public Routes
export const SIGN_IN = '/user-auth/sign-in';
export const SIGN_UP = '/user-auth/sign-up';
export const NEW_PASSWORD = '/auth/account/change_password';
export const CHANGE_PASSWORD_UNAUTHORIZED =
  '/user-auth/change_password_unauthorized';
export const EMAIL_UNVERIFIED = '/user-auth/email_unverified/:email';
export const ACTIVATE_ACCOUNT = '/user-auth/activate-account';
export const ACTIVATE_ACCOUNT_SUCCESS = '/user-auth/activate-account_success';
export const CHANGE_PASSWORD = '/user-auth/change-password';
export const RESET_PASSWORD_SUCCESS = '/user-auth/reset-password-succeed';
export const RESET_PASSWORD = '/user-auth/reset-password';
export const EMAIL_CHECK = '/user-auth/email-check/:email';
export const AUTH_BAD_REQUEST = '/user-auth/bad_request';
export const SIGN_IN_AFTER_SIGN_UP =
  '/user-auth/signin-after-signup/:email/:id/:customerId/:managedCasinoId/:authorization';
export const AUTH_ERROR = '/user-auth/error';
export const AUTH_UNAUTHORIZED = '/user-auth/unauthorized';
export const UNAUTHORIZED = '/unauthorized';

// Core Routes
export const ADMIN = '/admin';

//#### Profile ####//
export const ADMIN_PROFILE = `${ADMIN}/profile`;
export const ADMIN_PROFILE_MY_INFORMATION = `${ADMIN_PROFILE}/my-information`;
export const ADMIN_PROFILE_ACCOUNT_SETTINGS = `${ADMIN_PROFILE}/account-settings`;

//#### Cash Game ####//
export const ADMIN_MOBILE = `${ADMIN}/mobile-view`;
export const ADMIN_CASH_GAME_MOBILE = `${ADMIN_MOBILE}/cash-game`;
export const ADMIN_CASH_GAME = `${ADMIN}/cash-game`;
export const ADMIN_CASH_GAME_RUNNING_GAMES_OLD = `${ADMIN_CASH_GAME}/running-games-old`;
export const ADMIN_CASH_GAME_RUNNING_GAMES = `${ADMIN_CASH_GAME}/running-games`;
export const ADMIN_CASH_TEMPLATES = `${ADMIN_CASH_GAME}/templates`;
export const ADMIN_CASH_GAME_INTEREST_LIST = `${ADMIN_CASH_GAME}/interest-list`;
export const ADMIN_CASH_GAME_FULL_DISPLAY = `${ADMIN_CASH_GAME}/full-display`;
export const ADMIN_CASH_GAME_DEALER_DISPLAY = `${ADMIN_CASH_GAME}/dealer-display`;

//#### Chat ####//
export const ADMIN_CHAT = `${ADMIN}/chat`;

//#### Events & Tournaments ####//
export const ADMIN_EVENTS = `${ADMIN}/events`;
export const ADMIN_EVENTS_TOURNAMENTS = `${ADMIN_EVENTS}/tournaments`;
export const ADMIN_DAILY_TOURNAMENTS = `${ADMIN}/daily-tournaments`;
export const ADMIN_EVENTS_CLOCK = `${ADMIN}/clock`;

//#### Players ####//
export const ADMIN_PLAYERS = `${ADMIN}/players`;
export const ADMIN_SUB_GROUPS = `${ADMIN_PLAYERS}/sub-groups`;
export const ADMIN_MY_PLAYERS = `${ADMIN_PLAYERS}/my-players`;

//#### My Poker Room ####//
export const ADMIN_MY_POKER_ROOM = `${ADMIN}/my-poker-room`;
export const ADMIN_MY_POKER_ROOM_GENERAL = `${ADMIN_MY_POKER_ROOM}/general`;
export const ADMIN_MY_POKER_ROOM_GAMES = `${ADMIN_MY_POKER_ROOM}/games`;
export const ADMIN_MY_POKER_ROOM_SETTINGS = `${ADMIN_MY_POKER_ROOM}/settings`;
export const ADMIN_MY_POKER_ROOM_DASHBOARD = `${ADMIN_MY_POKER_ROOM}/dashboard`;
export const ADMIN_MY_POKER_ROOM_ANNOUNCEMENTS = `${ADMIN_MY_POKER_ROOM}/announcements`;
export const ADMIN_MY_POKER_ROOM_ADS = `${ADMIN_MY_POKER_ROOM}/ads`;

//#### Contact ####//
export const ADMIN_CONTACT_US = `${ADMIN}/contact-us`;

//#### Terms and conditions ####//
export const ADMIN_TERMS_AND_CONDITIONS = `${ADMIN}/terms-and-conditions`;

//#### Error ####//
export const ADMIN_BAD_REQUEST = `${ADMIN}/bad_request`;
export const ADMIN_ERROR = `${ADMIN}/error`;
export const ADMIN_UNAUTHORIZED = `${ADMIN}/unauthorized`;
