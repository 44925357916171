import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

const RangePicker = (DatePicker.RangePicker as unknown) as FunctionComponent<
  RangePickerProps
>;

const daysSinceFirstAugust = moment().diff(
  moment('2023-08-01', 'YYYY-MM-DD').endOf('day')
);

const ranges: RangePickerProps['ranges'] = {
  ...(daysSinceFirstAugust > 14
    ? {
        'Last 7 Days': [moment().add(-7, 'd'), moment()]
      }
    : {}),
  ...(daysSinceFirstAugust > 14
    ? {
        'Last 14 Days': [moment().add(-14, 'd'), moment()]
      }
    : {}),
  ...(daysSinceFirstAugust > 30
    ? {
        'Last 30 Days': [moment().add(-30, 'd'), moment()]
      }
    : {}),
  ...(daysSinceFirstAugust > 90
    ? { 'Last 90 Days': [moment().add(-90, 'd'), moment()] }
    : {})
};

export const DateRangePicker = ({
  onChange,
  start,
  end,
  ...rest
}: {
  start: Date;
  end: Date;
  onChange: (dates: Date[]) => void;
} & Omit<RangePickerProps, 'onChange'>) => (
  <RangePicker
    ranges={ranges}
    value={[moment(start), moment(end)]}
    format="YYYY/MM/DD"
    onChange={values => {
      if (values?.length == 2) {
        onChange([values[0]!.toDate(), values[1]!.toDate()]);
      }
    }}
    {...rest}
  />
);
