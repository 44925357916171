import FileDoneOutlined from '@ant-design/icons/lib/icons/FileDoneOutlined';
import flopLogo from '@assets-3/images/flop-pm-logo.png';
import React from 'react';
import { Link } from 'react-router-dom';

const ActivateAccountSuccessPage = () => {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container" style={{ maxWidth: '800px' }}>
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content gx-justify-content-center gx-align-items-center"
            style={{
              background: 'linear-gradient(153.33deg, #aabdd0 0%, #4F8DCA 100%)'
            }}
          >
            <div>
              <img alt="flop-logo" src={flopLogo} height={'60'} />
              <div className={'gx-text-center'}>
                <FileDoneOutlined style={{ fontSize: '100px' }} />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-login-header">
              <h1 className="gx-login-title gx-text-center">
                Welcome to flop PM
              </h1>
              <h4>Your account email address is now confirmed.</h4>
              <h4>
                To let the flop APP community knows more about your Poker Room,
                and offered games, please complete the following information.
              </h4>
            </div>
            <h4 className={'gx-text-center mt-3'}>
              {/*@ts-ignore*/}
              <Link
                className="gx-text-underline"
                style={{ color: '#4F8DCA' }}
                to="/"
              >
                Go to Login
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountSuccessPage;
