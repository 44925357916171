import { useGetSubGroupQuery } from '@api-3/endpoints/subGroup';
import SubGroupPlayersList from '@components-3/SubGroupPlayerTable/SubGroupPlayersList';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { formatBuyIn } from '@old-world/flop-2.0/utils/buyInFormatters';
import { useModals } from '@providers/ModalProvider';
import { Button, Col, Modal, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  subGroupId: number;
};

const EditSubGroupModal = ({ onClose, subGroupId }: Props) => {
  const { data: subGroup, isLoading } = useGetSubGroupQuery({ subGroupId });
  const openModal = useModals();
  const { t } = useTranslation();
  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={'SUB-GROUP PLAYERS'}
      onCancel={onClose}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      okButtonProps={{
        className: 'gx-btn',
        type: 'default'
      }}
      footer={[
        <Button onClick={onClose} className="">
          Close
        </Button>,
        <Button
          onClick={() => {
            openModal({
              type: 'ADD_PLAYERS',
              props: {
                subGroupId: subGroupId,
                showNonFopUser: false,
                addToSubGroup: true
              }
            });
          }}
          className="gx-btn"
        >
          Add player
        </Button>
      ]}
    >
      {isLoading ? (
        <Spin spinning={isLoading} size="large" />
      ) : (
        <>
          <Row
            className={'gx-flex-row'}
            style={{ marginLeft: -16, marginRight: -16, position: 'relative' }}
          >
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                {t('INTEREST_GAME_VARIANT_LABEL')}:
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <span className={'gx-font-weight-bold'}>
                {subGroup?.gameVariant}
              </span>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <hr className={'mt-2 mb-2'} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                Small Blind
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <span className={'gx-font-weight-bold'}>
                {subGroup?.smallBlind}
              </span>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <hr className={'mt-2 mb-2'} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                Big Blind
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <span className={'gx-font-weight-bold'}>
                {subGroup?.bigBlind}
              </span>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <hr className={'mt-2 mb-2'} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                Straddle
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <span className={'gx-font-weight-bold'}>{subGroup?.ante}</span>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <hr className={'mt-2 mb-2'} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                Min. Buy-In
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16}>
              <span className={'gx-font-weight-bold'}>
                {subGroup?.minBuyIn ? formatBuyIn(subGroup.minBuyIn) : 'NO'}
              </span>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <hr className={'mt-2 mb-2'} />
            </Col>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <span className={'gx-text-grey'} style={{ opacity: 0.7 }}>
                Max. Buy-In
              </span>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16} className={'mb-3'}>
              <span className={'gx-font-weight-bold'}>
                {subGroup?.maxBuyIn ? formatBuyIn(subGroup.maxBuyIn) : 'NO'}
              </span>
            </Col>
          </Row>
          <div style={{ maxHeight: 220, overflowY: 'scroll' }}>
            <SubGroupPlayersList subGroup={subGroup} />
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditSubGroupModal;
