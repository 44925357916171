import { Filter, useGetInterestListQuery } from '@api-3/endpoints/interestList';
import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import { currentCasinoSelector } from '@old-world/features/authentication/redux/AuthenticationRedux';
import { useModals } from '@providers/ModalProvider';
import InterestListDataTable from '@routes-3/main/cashGameManagement/interestList/components/InterestListDataTable';
import { INITIAL_GAME_STATES_FILTER } from '@routes-3/main/cashGameManagement/interestList/constants';
import { INTEREST_LIST_COLUMN } from '@routes-3/main/cashGameManagement/interestList/types';
import { Button, Card } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const InterestListPage = () => {
  const { t } = useTranslation();
  const casinoId = useSelector(currentCasinoSelector);
  const [sort, setSort] = useState<'ASC' | 'DESC'>('ASC');
  const [filter, setFilter] = useState<Filter>({
    gameStates: INITIAL_GAME_STATES_FILTER,
    gameVariant: []
  });
  const [page, setPage] = useState<number>(1);
  const openModal = useModals();
  const { data } = useGetInterestListQuery(
    {
      queryParams: {
        ...filter,
        page,
        size: isMobile || isTablet ? 50 : 5,
        casinoId: casinoId,
        sort,
        // date By Default is Current Date Minus 6 Hours
        dateFrom: encodeURIComponent(
          moment()
            .startOf('day')
            .subtract(12, 'hour')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
        )
      }
    },
    { pollingInterval: 10000 }
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<any, any>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setPage(pagination.current || 1);
    if (!Array.isArray(sorter)) {
      setSort(sorter.order == 'descend' ? 'DESC' : 'ASC');
    }
    setFilter({
      gameStates: filter[INTEREST_LIST_COLUMN.STATE] ?? [
        INITIAL_GAME_STATES_FILTER
      ],
      gameVariant: filter[INTEREST_LIST_COLUMN.GAME_VARIANT] ?? []
    });
  };

  const content = data?.content || [];
  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Cash Game Management'}
        pageIcon={
          <i
            className="icon icon-dasbhoard"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
        subPage={'Interest Lists'}
      />
      <div className={'d-flex flex-row justify-content-between pr-3'}>
        <div className={'gx-font-weight-bold'}>Interest Lists</div>
        <Button
          className={'gx-btn'}
          key="submit"
          type="default"
          style={{ position: 'relative', top: -8, marginBottom: 0 }}
          onClick={() => {
            openModal({
              type: 'INTEREST_LIST_CREATION',
              props: {}
            });
          }}
        >
          {t('CREATE_NEW_INTEREST_LIST')}
        </Button>
      </div>
      <Card
        className="gx-layouts-view mt-3"
        style={{ height: 'calc(100vh - 278px )', overflowY: 'scroll' }}
      >
        <InterestListDataTable
          data={content || []}
          totalElements={data?.totalElements}
          size={5}
          handleTableChange={handleTableChange}
        />
      </Card>
    </LayoutContentContainer>
  );
};

export default InterestListPage;
