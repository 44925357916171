import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React, { ReactNode } from 'react';

const PageBreadcrumb = ({
  page,
  pageIcon,
  subPage,
  subSubPage
}: {
  page: string;
  pageIcon?: ReactNode;
  subPage?: string;
  subSubPage?: string;
}) => {
  return (
    <Breadcrumb className={'mb-4'}>
      <Breadcrumb.Item>
        <span className="gx-link">
          <HomeOutlined style={{ position: 'relative', bottom: 4 }} />
        </span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span
          className={`gx-link ${
            subPage ? '' : 'gx-text-warning'
          } gx-position-relative`}
        >
          {pageIcon}
          <span
            className={`${subPage ? '' : 'gx-text-warning'}`}
            style={{ marginLeft: 26 }}
          >
            {page}
          </span>
        </span>
      </Breadcrumb.Item>
      {subPage && (
        <Breadcrumb.Item className={subSubPage ? '' : 'gx-text-warning'}>
          {subPage}
        </Breadcrumb.Item>
      )}
      {subSubPage && (
        <Breadcrumb.Item className={'gx-text-warning'}>
          {subSubPage}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
