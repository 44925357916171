import { useGetRunningGamesQuery } from '@api-3/endpoints/games';

export default function useGetGame({ gameId }: { gameId?: string }) {
  const { data, isLoading } = useGetRunningGamesQuery(
    {
      queryParams: {
        liveGameKey: gameId,
        page: 1,
        size: 1
      }
    },
    { skip: !gameId, pollingInterval: 5000 }
  );
  const game = data?.content?.[0];
  return { game, isLoading };
}
