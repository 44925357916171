import { Card } from 'antd';
import React from 'react';

type NumberOfPlayersSeatedProps = {
  playerCounter: number;
};
const NumberOfPlayersSeated = ({
  numberOfPlayersSeated
}: {
  numberOfPlayersSeated: NumberOfPlayersSeatedProps;
}) => {
  return (
    <Card
      className={
        'gx-card-widget gx-card-full gx-p-3 gx-flex-row  gx-align-items-center'
      }
      style={{ height: 152 }}
    >
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          <i
            className={`icon icon-user gx-fs-xlxl gx-text-xxxxx gx-d-flex`}
            style={{ fontSize: 45, color: '#fa8c15' }}
          />
        </div>
        <div className="gx-media-body">
          <h2 className="gx-fs-xxl gx-font-weight-medium gx-mb-1">
            {numberOfPlayersSeated.playerCounter}
          </h2>
          <p className="gx-text-grey gx-mb-0">Number Of Players Seated</p>
        </div>
      </div>
    </Card>
  );
};

export default NumberOfPlayersSeated;
