import LayoutContentContainer from '@components-3/LayoutContentContainer';
import PageBreadcrumb from '@components-3/PageBreadcrumb';
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography
} from 'antd';
import React, { useState } from 'react';

import ApiClient from '../../../../ApiClient';

export const dataTestIds = {
  root: 'contact-us-page'
};

export default function ContactUsPage() {
  const [subject, setSubject] = useState('Feedback & suggestions');
  const [content, setContent] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <LayoutContentContainer>
      <PageBreadcrumb
        page={'Contact'}
        pageIcon={
          <i
            className="icon icon-compose"
            style={{ fontSize: 18, position: 'absolute', top: 0 }}
          />
        }
      />
      <Card data-testid={dataTestIds.root} className="gx-card" title="Contact">
        <Row>
          <Typography.Text className={'gx-text-white'}>
            A question, some feedback, or just want to say “Hi”?
          </Typography.Text>
        </Row>
        <Row className="mb-4">
          <Typography.Text className={'gx-text-white'}>
            You’ve come to the right place!
          </Typography.Text>
        </Row>
        <form
          onSubmit={async e => {
            e.preventDefault();
            setSubmitting(true);
            await ApiClient.sendContactForm(subject, content);
            setSubmitting(false);
            setContent('');
            message.success(
              'Thank you for your message! We will get back to you as soon as possible.'
            );
          }}
        >
          <Form.Item label="Topic" className="mb-2 ant-column">
            <Select defaultValue={subject} onChange={setSubject}>
              {[
                'Feedback & suggestions',
                'Feature request',
                'Report a bug',
                'Other'
              ].map((x, index) => (
                <Select.Option key={index} value={x}>
                  {x}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Your message" className="mb-2">
            <Input.TextArea
              value={content}
              onChange={e => setContent(e.target.value)}
              rows={6}
            />
          </Form.Item>
          {/*@ts-ignore*/}
          <Row type="flex" justify="end">
            <Button htmlType="submit" type="primary" loading={isSubmitting}>
              Send message
            </Button>
          </Row>
        </form>
      </Card>
    </LayoutContentContainer>
  );
}
