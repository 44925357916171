import {
  InterestList,
  useGetRegisteredPlayersQuery
} from '@api-3/endpoints/interestList';
import { useGetTemplatesQuery } from '@api-3/endpoints/templates';
import ModalTitleWithGameInfo from '@components-3/ModalTitleWithGameInfo';
import RunningCashGamesConstants from '@old-world/features/cashgames/sub-domains/running-games/constants/RunningCashGamesConstants';
import { useModals } from '@providers/ModalProvider';
import RegisteredPlayersList from '@routes-3/main/cashGameManagement/interestList/modals/RegisteredPlayersList';
import { Modal } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const RegisteredPlayersModal = ({
  game,
  onClose
}: {
  game: InterestList;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const openModal = useModals();
  const { data: registeredPlayersList } = useGetRegisteredPlayersQuery(
    { id: game.id },
    { pollingInterval: 5000 }
  );

  console.log(game);

  return (
    <Modal
      open={true}
      destroyOnClose
      centered
      title={
        <ModalTitleWithGameInfo
          game={game}
          title={t('REGISTERED_PLAYERS_HEADER')}
        />
      }
      onCancel={onClose}
      okText={'Add player'}
      okButtonProps={{ className: 'gx-btn', type: 'default' }}
      width={RunningCashGamesConstants.MODAL_WIDTH}
      onOk={() => {
        openModal({
          type: 'ADD_PLAYERS',
          props: {
            game: game,
            subGroupId: undefined,
            showSubGroupForm: !!game?.templateId,
            isInterestList: true
          }
        });
      }}
      closable={false}
    >
      <RegisteredPlayersList players={registeredPlayersList} gameId={game.id} />
    </Modal>
  );
};

export default RegisteredPlayersModal;
