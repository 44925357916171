import * as React from 'react';
// @ts-ignore
import { useHistory } from 'react-router-dom';

export function useBlocker(blocker: (tx: any) => void, when = true): void {
  const history = useHistory();

  React.useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };

      return blocker(autoUnblockingTx);
    });

    return unblock;
  }, [history, blocker, when]);
}
