// this file is used to create mention entities for the editor from a raw text. copied & slightly adapted from gitlab
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState
} from 'draft-js';

const getIndicesOf = (
  searchStr: string,
  str: string,
  caseSensitive: boolean
) => {
  let tempStr = str;
  let tempSearchStr = searchStr;
  const searchStrLen = tempSearchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;
  const indices = [];
  if (!caseSensitive) {
    tempStr = tempStr.toLowerCase();
    tempSearchStr = tempSearchStr.toLowerCase();
  }

  while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

const getEntityRanges = (
  text: string,
  mentionName: string,
  mentionKey: string
) => {
  // @ts-ignore
  const indices = getIndicesOf(mentionName, text);
  if (indices.length > 0) {
    return indices.map(offset => ({
      key: mentionKey,
      length: mentionName.length,
      offset
    }));
  }

  return null;
};

const createMentionEntities = (
  text: string,
  tags: Record<string, string>[]
) => {
  const rawContent = convertToRaw(ContentState.createFromText(text));
  const rawState = tags.map(tag => ({
    type: 'mention',
    mutability: 'IMMUTABLE',
    data: { mention: { id: tag.name, name: tag.name } }
  }));
  //
  // @ts-ignore
  rawContent.entityMap = [...rawState];
  //
  rawContent.blocks = rawContent.blocks.map(block => {
    const ranges: any = [];
    tags.forEach((tag, index) => {
      const entityRanges = getEntityRanges(
        block.text,
        tag.name,
        (index as unknown) as string
      );
      if (entityRanges) {
        ranges.push(...entityRanges);
      }
    });

    return { ...block, entityRanges: ranges };
  });

  return EditorState.createWithContent(convertFromRaw(rawContent));
};

export default createMentionEntities;
