import './MobileViewPage.scss';

import DealerImage from '@assets/images/notifications/dealer.png';
import TableImage from '@assets/images/poker-table.png';
import rotateIcon from '@assets/images/retation.gif';
import MobileHeader from '@routes-3/main/mobileView/components/MobileHeader';
import {
  ADMIN_CASH_GAME_DEALER_DISPLAY,
  ADMIN_CASH_GAME_MOBILE
} from '@routes-3/routes';
import { Avatar, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
// @ts-ignore
import { useHistory } from 'react-router-dom';

const MobileViewPage = () => {
  const history = useHistory();

  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );
  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Layout className="mobile-page-wrapper">
      <Layout.Header className="mobile-page-header" style={{ height: 50 }}>
        <MobileHeader />
      </Layout.Header>
      <Layout.Content className="mobile-page-body">
        {isPortrait && isMobile ? (
          <div style={{ textAlign: 'center', margin: '0 30px' }}>
            <img src={rotateIcon} alt={'rotate your device'} />
            <div>
              Please rotate your device to landscape orientation to use this
              site.
            </div>
          </div>
        ) : (
          <div className={'justify-content-center d-flex flex-row'}>
            <div
              className={
                'justify-content-center gx-align-items-center d-flex flex-column mr-5 gx-text-center action-button'
              }
              onClick={() => history.push(ADMIN_CASH_GAME_MOBILE)}
            >
              <Avatar
                className={'cursor-pointer mb-2'}
                src={TableImage}
                size={50}
                style={{ opacity: 1 }}
              />
              <b>Tables</b>
            </div>
            <div
              className={
                'justify-content-center gx-align-items-center d-flex flex-column gx-text-center action-button'
              }
              onClick={() => history.push(ADMIN_CASH_GAME_DEALER_DISPLAY)}
            >
              <Avatar
                className={'cursor-pointer mb-2'}
                src={DealerImage}
                size={50}
                style={{ opacity: 1 }}
              />
              <b>Dealer</b>
            </div>
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default MobileViewPage;
